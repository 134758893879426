import React from 'react';
import PropTypes from 'prop-types';
import { Button, Dropdown } from 'semantic-ui-react';

const TopNavigation = ({
  isBusy,
  hasData,
  hasDataInvalid,
  onValidateClick,
  onImportExportReportClick,
  on321ReportClick
}) => {
  return (
    <>
      <Button
        primary
        compact
        disabled={isBusy || !hasData || !hasDataInvalid}
        onClick={onValidateClick}
      >
        1. Validate Information
      </Button>

      <Dropdown
        text={'2. Download Report'}
        primary
        compact
        style={{ textAlign: 'center' }}
        as={Button}
        disabled={isBusy || !hasData || hasDataInvalid}
      >
        <Dropdown.Menu>
          <Dropdown.Item onClick={onImportExportReportClick}>
            Download Import/Export Report
          </Dropdown.Item>

          <Dropdown.Item onClick={on321ReportClick}>
            Download 321 Report
          </Dropdown.Item>

          {/* <Dropdown.Item>
            Send 321 Report
          </Dropdown.Item> */}
        </Dropdown.Menu>
      </Dropdown>
    </>
  )
};

TopNavigation.propTypes = {
  isBusy: PropTypes.bool,
  hasData: PropTypes.bool,
  hasDataInvalid: PropTypes.bool,
  onValidateClick: PropTypes.func,
  onImportExportReportClick: PropTypes.func,
  on321ReportClick: PropTypes.func
};

export default TopNavigation;