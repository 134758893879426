import { FieldArray } from 'formik';
import React, { forwardRef, useState } from 'react';
import {
  Form,
  Segment,
  Header,
  Table,
  Modal,
  Button,
  Label,
} from 'semantic-ui-react';
import styled from 'styled-components';
import FormUbicacion from './FormUbicacion';

function getRandomInt(min, max) {
  min = Math.ceil(min);
  max = Math.floor(max);
  return Math.floor(Math.random() * (max - min + 1)) + min;
}

const getErrorsAndTouchs = (touched, errors, index, name) => {
  return (
    touched.ubicaciones &&
    touched.ubicaciones[index] &&
    touched.ubicaciones[index][name] &&
    errors.ubicaciones &&
    errors.ubicaciones[index] &&
    errors.ubicaciones[index][name]
  );
};

const FormUbicaciones = ({ formik }) => {
  const [open, setOpen] = useState(false);
  const [index, setIndex] = useState(0);
  let { values, errors, touched, handleChange, handleBlur, setFieldValue } =
    formik;
  const [startDate, setStartDate] = useState(new Date());

  const handleEdit = (index) => {
    setIndex(index);
    setOpen(true);
  };

  // console.log('ubicaciones', values.ubicaciones)
  return (
    <>
      <Segment>
        <Header as="h3">Ubicaciones</Header>
        <Table celled striped>
          <Table.Header>
            <Table.Row>
              <Table.HeaderCell collapsing> Ubicación </Table.HeaderCell>
              <Table.HeaderCell>Dirección</Table.HeaderCell>
              <Table.HeaderCell textAlign="center" collapsing>
                Acciones
              </Table.HeaderCell>
            </Table.Row>
          </Table.Header>
          <Table.Body>
            {values.ubicaciones.map((ubicacion, index) => (
              <Table.Row
                error={
                  errors.ubicaciones &&
                  touched.ubicaciones &&
                  errors.ubicaciones[index]
                }
              >
                <Table.Cell textAlign="center">{index + 1}</Table.Cell>
                <Table.Cell>
                  {ubicacion.IDUbicacion
                    ? `ID: "${ubicacion.IDUbicacion}"`
                    : null}
                  {ubicacion.RFCRemitenteDestinatario
                    ? `, | RFC: ${ubicacion.RFCRemitenteDestinatario}"`
                    : null}
                  {ubicacion.NumRegTributario
                    ? `, | Registro Tributario: "${ubicacion.NumRegTributario}"`
                    : null}
                </Table.Cell>
                <Table.Cell>
                  {/* {errors.ubicaciones && errors.ubicaciones[index] ? (
                    <Label ribbon color="red">
                      {' '}
                      Faltan Datos requeridos
                    </Label>
                  ) : null} */}
                  <pre>
                    <a
                      style={{ cursor: 'pointer', color: '#438D9B' }}
                      onClick={() => handleEdit(index)}
                    >
                      Editar
                    </a>
                    {' | '}
                    <FieldArray
                      name="ubicaciones"
                      render={(arrayHelpers) => (
                        <a
                          style={{
                            marginTop: '2px',
                            color: 'red',
                            cursor: 'pointer',
                          }}
                          onClick={() => arrayHelpers.remove(index)} // remove a friend from the list
                        >
                          Eliminar
                        </a>
                      )}
                    />
                  </pre>
                </Table.Cell>
              </Table.Row>
            ))}
          </Table.Body>
        </Table>
        <FieldArray
          name="ubicaciones"
          render={(arrayHelpers) => (
            <div>
              <Button
                type="button"
                content="Agregar una nueva ubicacion"
                primary
                onClick={() => (
                  arrayHelpers.push({ TipoConcepto: null }),
                  handleEdit(values?.ubicaciones?.length)
                )}
              >
                {/* show this when user has removed all friends from the list */}
              </Button>
            </div>
          )}
        />
        <ModalUbicacion
          formik={formik}
          index={index}
          open={open}
          setOpen={setOpen}
        />
      </Segment>
    </>
  );
};

const ModalUbicacion = ({ formik, setOpen, index, open }) => {
  let { values, errors, touched, handleChange, handleBlur, setFieldValue } =
    formik;

  return (
    <Modal
      onClose={() => setOpen(false)}
      onOpen={() => setOpen(true)}
      open={open}
      size={'fullscreen'}
    >
      <Modal.Header> Ubicación {index + 1} </Modal.Header>
      <Modal.Content>
        <FormUbicacion
          formik={formik}
          index={index}
          ubicacion={values.ubicaciones[index]}
        />
      </Modal.Content>
      <Modal.Actions>
        <Button
          content="Cerrar y guardar"
          labelPosition="right"
          icon="checkmark"
          onClick={() => setOpen(false)}
          positive
        />
      </Modal.Actions>
    </Modal>
  );
};

const WrapContent = styled.div``;

const BorderItem = styled.div`
  border: 1px solid #80808087;
  padding: 15px;
  border-radius: 12px;
  margin-bottom: 20px;
  box-shadow: rgb(99 99 99 / 20%) 0px 2px 8px 0px;
`;

export default forwardRef((props, ref) => (
  <FormUbicaciones {...props} innerRef={ref} />
));
