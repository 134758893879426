import React, { useState } from 'react';
import SlidingPanel from '@bit/softwareland.flex.sl-sliding-panel';
import DrawerFiltersWrapper from '../../../components/DrawerFiltersWrapper';
import Form from './Form';
import Actions from './Actions';
import swal from 'sweetalert';

const Drawer = ({ openPanel, bin, onClose, service }) => {
  const [isBusy, setIsBusy] = useState(false);
  const [section] = useState((bin._id && 'EDIT') || 'CREATE');
  let drawerTitles = {
    CREATE: { title: 'Create New Bin', subtitle: '' },
    EDIT: { title: 'Edit Bin', subtitle: '' },
  };

  let { title, subtitle } = drawerTitles[section];

  const handledOnClose = async () => {
    let confirm = await swal('Do you want to close this form?', {
      buttons: ['No', 'Yes'],
    });
    if (confirm) onClose(!openPanel);
  };

  const handledSuccess = (bin) => onClose(true);

  return (
    <SlidingPanel
      isOpen={openPanel}
      size={50}
      title={title}
      subtitle={subtitle}
      onClose={() => handledOnClose()}
    >
      <DrawerFiltersWrapper>
        <Form
          isBusy={isBusy}
          setIsBusy={setIsBusy}
          bin={bin}
          onSuccess={handledSuccess}
          service={service}
        >
          <Actions isBusy={isBusy} onBackClick={handledOnClose} />
        </Form>
      </DrawerFiltersWrapper>
    </SlidingPanel>
  );
};

export default Drawer;
