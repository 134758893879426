import React, { useReducer, useEffect } from 'react';
import styled from 'styled-components';
import moment from 'moment';
import { statusOptions, countryOptions } from '../../constants/statusOptions';
import DateRangePicker from '../DateRangePicker';
import SemanticInput from '../SemanticInput';
import SemanticDropdown from '../SemanticDropdown';
import ButtonsFilter from '../ButtonsFilter';

const queryInit = {
  integrator_created_date: {
    $gte: moment().startOf('day'),
    $lte: moment().endOf('day')
  },
  from_name: '',
  to_name: '',
  order_number: '',
  integrator: '',
  to_address: {
    city: '',
    country: []
  },
  status: []
};

const reducerQuery = (prevState, { name, value, type, query }) => {
  if (type === 'init' && query) {
    return query;
  } else if (name) {
    return { ...prevState, [name]: value }
  }

  return prevState;
};

const ParcelsFilters = ({
  isBusy,
  query: base,
  setQuery
}) => {
  // States
  const [query, dispatchQuery] = useReducer(reducerQuery, { ...queryInit });

  // Effects
  useEffect(() => {
    // console.count('ParcelsFilters query');
    const sendQuery = () => {
      handleSearchClick()
    }

    sendQuery();
  }, []);

  // Handles
  const handleChangeInput = (_, { name, value }) => {
    const names = name.split('.');
    const newQuery = {
      name: names.shift(),
      value
    };

    if (names.length) {
      newQuery.value = {
        ...query[newQuery.name],

        [names.shift()]: value
      };
    }

    dispatchQuery(newQuery);
  };

  const handleSearchClick = (event, data) => {
    const toSearch = ($search) => ({ $search });

    const { to_address, from_name, to_name, order_number, integrator, status } = query;
    const queryToAddress = Object.keys(to_address).reduce((prev, item) => {
      if (to_address[item] && to_address[item].length) {
        const value = item === 'country' ? { $in: [...to_address[item]] } : toSearch(to_address[item]);

        return {
          ...prev,

          [`to_address.${item}`]: value
        };
      }

      return prev;
    }, {});

    const queryMap = {
      line_items: { $exists: true, $ne: [] },

      integrator_created_date: query.integrator_created_date,

      ...(from_name && { from_name: toSearch(from_name) }),
      ...(to_name && { to_name: toSearch(to_name) }),
      ...(order_number && { order_number: toSearch(order_number) }),
      ...(integrator && { integrator: toSearch(integrator) }),

      ...queryToAddress,

      ...(status && status.length && {
        status: {
          $in: status
        }
      })
    };

    // console.debug('query', JSON.stringify(queryMap, null, 2));
    setQuery && setQuery(queryMap);
  };

  const handleClearClick = (event, data) => {
    dispatchQuery({
      type: 'init',
      query: {
        ...queryInit,

        integrator_created_date: {
          $gte: moment().startOf('day'),
          $lte: moment().endOf('day')
        }
      }
    });
  };

  return (
    <>
      {/* <pre>query: {JSON.stringify(query, null, 2)}</pre> */}

      <FiltersGrid>
        <DateRangePicker
          name="integrator_created_date"
          label="Parcel Date"
          timePicker={false}
          startDate={query.integrator_created_date.$gte}
          endDate={query.integrator_created_date.$lte}
          maxDate={moment()}

          onChange={([$gte, $lte]) => {
            dispatchQuery({
              name: 'integrator_created_date',
              value: { $gte, $lte }
            });
          }}

          style={{ gridArea: 'date' }}
          disabled={isBusy}
        />

        <SemanticInput
          name="from_name"
          label="Shipper Name"
          value={query.from_name}
          onChange={handleChangeInput}

          fluid
          style={{ gridArea: 'shipper' }}
          disabled={isBusy}
        />

        <SemanticInput
          name="to_name"
          label="Consignee Name"
          value={query.to_name}
          onChange={handleChangeInput}

          fluid
          style={{ gridArea: 'consignee' }}
          disabled={isBusy}
        />

        <SemanticInput
          name="order_number"
          label="Order #"
          value={query.order_number}
          onChange={handleChangeInput}

          fluid
          style={{ gridArea: 'order' }}
          disabled={isBusy}
        />

        <SemanticInput
          name="integrator"
          label="Service"
          value={query.integrator}
          onChange={handleChangeInput}

          fluid
          style={{ gridArea: 'service' }}
          disabled={isBusy}
        />

        <SemanticInput
          name="to_address.city"
          label="City"
          value={query.to_address.city}
          onChange={handleChangeInput}

          fluid
          style={{ gridArea: 'city' }}
          disabled={isBusy}
        />

        <SemanticDropdown
          name="status"
          label="Status"
          value={query.status}
          onChange={handleChangeInput}

          fluid
          multiple
          selection
          options={statusOptions}
          style={{ gridArea: 'status' }}
          disabled={isBusy}
        />

        <SemanticDropdown
          name="to_address.country"
          label="Country"
          value={query.to_address.country}
          onChange={handleChangeInput}

          fluid
          multiple
          selection
          options={countryOptions}
          style={{ gridArea: 'country' }}
          disabled={isBusy}
        />

        <ButtonsFilter
          isBusy={isBusy}
          onSearchClick={handleSearchClick}
          onClearClick={handleClearClick}
        />
      </FiltersGrid>
    </>
  );
};

const FiltersGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-template-rows: auto auto auto;
  grid-gap: 1em;
  grid-template-areas:
  'date shipper consignee order'
  'service city status country'
  '. . . buttons';
`;

export default ParcelsFilters;