import React, { useState, useEffect } from 'react';
import moment from 'moment';
import DefaultLayout from '../../layouts/DefaultLayout';
import TopNavigation from './TopNavigation';
import HeaderBG from '../../components/HeaderBG';
import SectionTitle from '../../components/SectionTitle';
import Filters from './Filters';
import TableNavigation from './TableNavigation';
import Table from './Table';
import Drawer from './Drawer';
import { Container } from 'semantic-ui-react';
import feathers from '../../services/feathers';
import swal from 'sweetalert';
import { pluralize } from '../../functions/common';

const DailyLogs = (props) => {
  const service = feathers.service('daily-logs');
  const serviceConf = feathers.service('configuration');

  // States
  const [currentDailyLog, setCurrentDailyLog] = useState({});
  const [isBusy, setIsBusy] = useState(false);
  const [openPanel, setOpenPanel] = useState(false);
  const [data, setData] = useState([]);
  const [todayData, setTodayData] = useState([]);
  const [startDate, setStartDate] = useState(moment().startOf('day'));
  const [endDate, setEndDate] = useState(
    moment().endOf('day').subtract(14, 'minute')
  );
  const [selection, setSelection] = useState([]);
  const [messageAction, setMessageAction] = useState();
  const [lookupDailyLog, setLookupDailyLog] = useState(null);
  const [query, setQuery] = useState(null);

  // Effects
  useEffect(() => {
    const filter = () => {
      if (query) {
        getData();
      }
    };

    filter();
  }, [query]);

  useEffect(() => {
    const configClean = async () => {
      const result = await serviceConf.find({
        query: {
          name: 'lookup-daily-log',
        },
      });

      const label =
        result && result.length && result[0].value === 'trackingNumber'
          ? 'Tracking'
          : 'Order';
      setLookupDailyLog({ key: result[0].value, label });
    };

    configClean();
  }, []);

  // Functions
  const getData = async () => {
    if (query) {
      let data = [],
        result = [];

      try {
        setIsBusy(true);
        setSelection([]);

        console.debug('query', JSON.stringify(query, null, 2));
        do {
          result = await service.find({
            query: {
              ...query,

              $skip: data.length,
            },
          });

          data = data.concat(result.data);

          console.debug('result', result);
        } while (data.length !== result.total);
      } catch (err) {
        console.error('Error', err);
        data = [];

        swal({
          title: 'Error',
          text: 'Failed get Daily Logs',
          icon: 'error',
          buttons: {
            cancel: 'Close',
          },
        });
      } finally {
        setData(data);
        setIsBusy(false);

        // refFilters && refFilters.current && refFilters.current.focus();
      }
    }
  };

  const openDrawer = (dailyLog) => {
    setCurrentDailyLog(dailyLog);
    setOpenPanel(true);
  };

  // Handles
  const handleCreateClick = async () => {
    setTodayData(await getTodayLogs());
    openDrawer({});
  };

  const handleEditClick = (dailyLog) => {
    openDrawer(dailyLog);
  };

  const handleDeleteClick = async () => {
    let success = false;
    const total = selection.length;

    try {
      let msg = `Do you want to delete ${total} daily ${pluralize(
        total,
        'log'
      )}?`;
      let confirmation = await swal(msg, {
        buttons: ['No', 'Yes'],
      });

      if (confirmation) {
        setIsBusy(true);
        setMessageAction('Deleting ...');
        const result = await service.remove(selection);

        if (result) {
          success = true;
        } else throw Error('Not found');
      }
    } catch (error) {
      // console.debug(error);
      await swal(
        'Error',
        `Don't Delete selected daily ${pluralize(total, 'log')}`,
        'error'
      );
    } finally {
      setMessageAction();
      setIsBusy(false);

      if (success) {
        await swal('Done', 'Finished', 'success');

        setSelection([]);
        getData();
      }
    }
  };

  const getTodayLogs = async () => {
    let mydata = [],
      result = [];
    const query = {
      ...((startDate || endDate) && {
        actualDate: {
          ...(startDate && { $gte: startDate }),
          ...(endDate && { $lte: endDate }),
        },
      }),
    };

    try {
      setIsBusy(true);

      do {
        result = await service.find({
          query: {
            ...query,

            $skip: mydata.length,
          },
        });

        mydata = mydata.concat(result.data);

        // console.debug('result', result);
      } while (mydata.length !== result.total);
    } catch (err) {
      // console.error('err', err);
      swal({
        title: 'Error',
        text: 'Failed get today daily logs',
        icon: 'error',
        buttons: {
          cancel: 'Close',
        },
      });
      return [];
    } finally {
      // setData(mydata);
      setIsBusy(false);
      return mydata;
    }
  };

  const handleClose = (isSuccess) => {
    setCurrentDailyLog({});
    setOpenPanel(false);

    isSuccess && getData();
  };

  return (
    <>
      <DefaultLayout
        currentPath={props.match.path}
        globalActions={
          <TopNavigation isBusy={isBusy} onCreateClick={handleCreateClick} />
        }
      >
        <HeaderBG />

        <Container>
          <SectionTitle>Daily Logs</SectionTitle>

          <Filters isBusy={isBusy} query={query} setQuery={setQuery} />

          <TableNavigation
            isBusy={isBusy}
            haveSelected={selection.length > 0}
            onDeleteClick={handleDeleteClick}
            messageAction={messageAction}
          />

          <Table
            data={data}
            onEditClick={handleEditClick}
            selection={selection}
            setSelection={setSelection}
            lookupDailyLog={lookupDailyLog}
          ></Table>
        </Container>
      </DefaultLayout>

      {openPanel ? (
        <Drawer
          openPanel={openPanel}
          onClose={handleClose}
          dailyLog={currentDailyLog}
          lookupDailyLog={lookupDailyLog}
          todayData={todayData}
        ></Drawer>
      ) : null}
    </>
  );
};

export default DailyLogs;
