import React, { forwardRef } from 'react';
import { Form, Segment } from 'semantic-ui-react';

const FormHeader = ({ formik }) => {
  let {
    values,
    errors,
    touched,
    handleChange,
    handleBlur,
    handleSubmit,
    isSubmitting,
    /* and other goodies */
  } = formik;
  return (
    <>
      <Segment>
        <Form.Group widths={'equal'}>
          <Form.Input
            placeholder="c País"
            label="C País"
            name="c_Pais"
            value={values.c_País}
            onChange={handleChange}
            onBlur={handleBlur}
            // error={
            //   validations.name && { content: 'Required idTipoComprobante' }
            // }
          />
          <Form.Input
            placeholder="Descripcion"
            label="Descripcion"
            name="Descripcion"
            value={values.Descripcion}
            onChange={handleChange}
            onBlur={handleBlur}
            // error={validations.lastName && { content: 'Required Last Name' }}
          />
        </Form.Group>
      </Segment>
    </>
  );
};

export default forwardRef((props, ref) => (
  <FormHeader {...props} innerRef={ref} />
));
