import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Popup } from 'semantic-ui-react';

const PopupComponent = ({ children, ...otherProps }) => (
  <PopupStyled
    {...otherProps}
    trigger={<WrapTrigger>{children}</WrapTrigger>}
  />
);

const PopupStyled = styled(Popup)`
  z-index: 100000 !important;
`;

const WrapTrigger = styled.div`
  margin: 0em 0.25em !important;
  display: -webkit-inline-box;

  & > * {
    margin: 0 !important;
  }
`;

PopupComponent.propTypes = {
  children: PropTypes.element,
};

export default PopupComponent;
