import React, { useEffect, forwardRef, useReducer } from 'react';
import moment from 'moment';
import statusDefault, {
  options as StatusOptions,
} from '../../../../constants/Status';
import DateRangePicker from '../../../../components/DateRangePicker';
import SemanticDropdown from '../../../../components/SemanticDropdown';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import DrawerFiltersWrapper from '../../../../components/DrawerFiltersWrapper';
import ButtonsFilter from '../../../../components/ButtonsFilter';

const reducerQuery = (prevState, { name, value, type, query }) => {
  if (type === 'init' && query) {
    return query;
  } else if (name) {
    return { ...prevState, [name]: value }
  }

  return prevState;
};

const getInitQuery = currentConsolidation => {
  const createdDateTime = {
    $gte: moment()
      .subtract(1, 'day')
      .hour(11)
      .minute(0)
      .second(0),

    $lte: (
      moment().hour() < 11
        ?
        moment()
        :
        moment()
          .hour(11)
          .minute(0)
          .second(0)
    )
  };

  if (currentConsolidation) {
    const { last_start_date, last_end_date } = currentConsolidation;
    if (last_start_date) {
      createdDateTime.$gte = moment.utc(last_start_date).local()
    }

    if (last_end_date) {
      createdDateTime.$lte = moment.utc(last_end_date).local()
    }
  }

  return {
    createdDateTime,
    status: [statusDefault]
  };
}

const Filter = ({
  isBusy,
  query: base,
  setQuery,
  currentConsolidation,
  handledOnSave

  // innerRef,
}) => {
  // States
  const [query, dispatchQuery] = useReducer(reducerQuery, { ...getInitQuery(currentConsolidation) });

  // Effects
  useEffect(() => {
    // console.count('Filter query');
    const sendQuery = () => {
      handleSearchClick()
    }

    sendQuery();
  }, []);

  useEffect(() => {
    // console.count('Filter useEffect currentConsolidation');
    const initData = () => {
      dispatchQuery({
        type: 'init',
        query: getInitQuery(currentConsolidation)
      });
    }

    initData();
  }, [currentConsolidation]);

  useEffect(() => {
    // console.count('Filter useEffect query.createdDateTime');
    const saveDates = () => {
      if (currentConsolidation) {
        let save = false;
        if (query && query.createdDateTime) {
          const { $gte, $lte } = query.createdDateTime;
          if ($gte && (!currentConsolidation.last_start_date || !$gte.isSame(currentConsolidation.last_start_date))) {
            currentConsolidation.last_start_date = $gte;
            save = true;
          }

          if ($lte && (!currentConsolidation.last_end_date || !$lte.isSame(currentConsolidation.last_end_date))) {
            currentConsolidation.last_end_date = $lte;
            save = true;
          }
        }

        save && handledOnSave && handledOnSave(false);
      }
    }

    saveDates();
  }, [query.createdDateTime]);

  // Handles
  const handleChangeInput = (_, { name, value }) => dispatchQuery({ name, value });

  const handleSearchClick = (event, data) => {
    // Create query for feathers
    const queryMap = {
      createdDateTime: query.createdDateTime,

      ...(query.status && query.status.length && {
        $or: query.status.map(status => ({ status }))
      })
    };

    // console.debug('query', JSON.stringify(queryMap, null, 2));

    // Send query to ImportShippedItems
    setQuery && setQuery(queryMap);
  };

  const handleClearClick = (event, data) => {
    dispatchQuery({
      type: 'init',
      query: getInitQuery(currentConsolidation)
    });
  };

  return (
    <>
      {/* <pre>query: {JSON.stringify(query, null, 2)}</pre> */}

      <DrawerFiltersWrapper>
        <FiltersGrid>
          <DateRangePicker
            name="createdDateTime"
            label="Created Date"
            timePicker={true}
            startDate={query.createdDateTime.$gte}
            endDate={query.createdDateTime.$lte}
            maxDate={moment()}

            onChange={([$gte, $lte]) => {
              dispatchQuery({
                name: 'createdDateTime',
                value: { $gte, $lte }
              });
            }}

            style={{ gridArea: 'range' }}
            disabled={isBusy}
          />

          <SemanticDropdown
            name="status"
            label="Status"
            value={query.status}
            onChange={handleChangeInput}

            fluid
            multiple
            selection

            options={StatusOptions}
            renderLabel={(item) =>
              item && {
                color: item.icon.color,
                content: item.text ? item.text : 'Without text',
              }
            }

            style={{ gridArea: 'status' }}
            disabled={isBusy}
          />

          <ButtonsFilter
            isBusy={isBusy}
            isSearchBusy={isBusy || !query.createdDateTime.$gte || !query.createdDateTime.$lte}
            onSearchClick={handleSearchClick}
            onClearClick={handleClearClick}
          />
        </FiltersGrid>
      </DrawerFiltersWrapper>
    </>
  );
};

const FiltersGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-template-rows: auto;
  grid-gap: 1em;
  grid-template-areas: 'range range status buttons';
`;

Filter.propTypes = {
  isBusy: PropTypes.bool,
  onSearch: PropTypes.func,
};

export default forwardRef((props, ref) => <Filter {...props} innerRef={ref} />);