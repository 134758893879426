import React, { forwardRef } from 'react';
import { Form, Segment } from 'semantic-ui-react';
import Dropdown from '../../../components/Formik/Dropdown';
import DropdownSearchFetch from '../../../components/Formik/DropdownSearchFetch';
import DropdownSimpleFetch from '../../../components/Formik/DropdownSimpleFetch';
import FormGroupLocations from './FormGroupLocations';
import ModalCertificado from '../../Emisores/Drawer/ModalCertificado';
import ModalLlave from '../../Emisores/Drawer/ModalLlave';
import ModalImg from './ModalImg';


const FormHeader = ({ formik }) => {
  let {
    values,
    errors,
    touched,
    handleChange,
    handleBlur,
    handleSubmit,
    isSubmitting,
    setFieldValue
    /* and other goodies */
  } = formik;
  
  return (
    <>
      <Segment>
        
        <Form.Group widths={'equal'}>
           <DropdownSearchFetch
            fluid
            selection
            label="Sucursal"
            value={values.idSucursal}
            onChange={(e, { value }) => setFieldValue('idSucursal', value)}
            placeholder="Configuracion Autotransporte"
            featherService="sucursales"
            columName="Nombre"
            columValue="idSucursal"
            mapValues={(value) => {
              return {
                key: Math.random(),
                text: `${value['Nombre']}`,
                value: value['idSucursal'],
              };
            }}
            error={
              errors.idSucursal && { content: errors.idSucursal }
            }
          />
          <Form.Input
            placeholder="idAlmacen"
            label="idAlmacen"
            name="idAlmacen"
            value={values.idAlmacen}
            onChange={handleChange}
            onBlur={handleBlur}
            error={
              errors.idAlmacen && { content: errors.idAlmacen }
            }
          />
          <Form.Input
            placeholder="idModulo"
            label="idModulo"
            name="idModulo"
            value={values.idModulo}
            onChange={handleChange}
            onBlur={handleBlur}
            error={
              errors.idModulo && { content: errors.idModulo }
            }
          />
          <Form.Input
            placeholder="idTipoPOS"
            label="idTipoPOS"
            name="idTipoPOS"
            value={values.idTipoPOS}
            onChange={handleChange}
            onBlur={handleBlur}
            error={
              errors.idTipoPOS && { content: errors.idTipoPOS }
            }
          />
        </Form.Group>
        <Form.Group widths={'equal'}>
          
          <Form.Input
            placeholder="Nombre"
            label="Nombre"
            name="Nombre"
            value={values.Nombre}
            onChange={handleChange}
            onBlur={handleBlur}
            error={
              errors.Nombre && { content: errors.Nombre }
            }
          />
          
          <Form.Input
            placeholder="Descripcion"
            label="Descripcion"
            name="Descripcion"
            value={values.Descripcion}
            onChange={handleChange}
            onBlur={handleBlur}
            error={
              errors.Descripcion && { content: errors.Descripcion }
            }
          />
          
          <Form.Input
            placeholder="Telefono1"
            label="Telefono1"
            name="Telefono1"
            value={values.Telefono1?values.Telefono1:""}
            onChange={handleChange}
            onBlur={handleBlur}
            error={
              errors.Telefono1 && { content: errors.Telefono1 }
            }
          />
          
          <Form.Input
            placeholder="Telefono2"
            label="Telefono2"
            name="Telefono2"
            value={values.Telefono2}
            onChange={handleChange}
            onBlur={handleBlur}
            error={
              errors.Telefono2 && { content: errors.Telefono2 }
            }
          />
        </Form.Group>
        <Form.Group widths={'equal'}>
          
          <Form.Input
            placeholder="Celular"
            label="Celular"
            name="Celular"
            value={values.Celular}
            onChange={handleChange}
            onBlur={handleBlur}
            error={
              errors.Celular && { content: errors.Celular }
            }
          />
          
          <Form.Input
            placeholder="Correo"
            label="Correo"
            name="Correo"
            value={values.Correo}
            onChange={handleChange}
            onBlur={handleBlur}
            error={
              errors.Correo && { content: errors.Correo }
            }
          />
          
          <Form.Input
            placeholder="SitioWeb"
            label="SitioWeb"
            name="SitioWeb"
            value={values.SitioWeb}
            onChange={handleChange}
            onBlur={handleBlur}
            error={
              errors.SitioWeb && { content: errors.SitioWeb }
            }
          />
          
          <Form.Input
            placeholder="GoogleMaps"
            label="GoogleMaps"
            name="GoogleMaps"
            value={values.GoogleMaps?values.GoogleMaps:""}
            onChange={handleChange}
            onBlur={handleBlur}
            error={
              errors.GoogleMaps && { content: errors.GoogleMaps }
            }
          />
        </Form.Group>
        <Form.Group widths={'equal'}>
          
        <Form.Field>
            <label>Logo</label>
            <ModalImg formik={formik} />
          </Form.Field>
          
          <Form.Input
            placeholder="LogoRuta"
            label="LogoRuta"
            name="LogoRuta"
            value={values.LogoRuta}
            onChange={handleChange}
            onBlur={handleBlur}
            error={
              errors.LogoRuta && { content: errors.LogoRuta }
            }
          />
          
          <Form.Input
            placeholder="Status"
            label="Status"
            name="Status"
            value={values.Status}
            onChange={handleChange}
            onBlur={handleBlur}
            error={
              errors.Status && { content: errors.Status }
            }
          />
          
          <Form.Input
            placeholder="TaxID"
            label="TaxID"
            name="TaxID"
            value={values.TaxID}
            onChange={handleChange}
            onBlur={handleBlur}
            error={
              errors.TaxID && { content: errors.TaxID }
            }
          />
        </Form.Group>
        <Form.Group widths={'equal'}>
          
          <Form.Input
            placeholder="RazonSocial"
            label="RazonSocial"
            name="RazonSocial"
            value={values.RazonSocial}
            onChange={handleChange}
            onBlur={handleBlur}
            error={
              errors.RazonSocial && { content: errors.RazonSocial }
            }
          />
          
          <Form.Input
            placeholder="RegimenFiscal"
            label="RegimenFiscal"
            name="RegimenFiscal"
            value={values.RegimenFiscal}
            onChange={handleChange}
            onBlur={handleBlur}
            error={
              errors.RegimenFiscal && { content: errors.RegimenFiscal }
            }
          />
          
          <Form.Input
            placeholder="idPAC"
            label="idPAC"
            name="idPAC"
            value={values.idPAC}
            onChange={handleChange}
            onBlur={handleBlur}
            error={
              errors.idPAC && { content: errors.idPAC }
            }
          />
          
          <Form.Input
            placeholder="idCertificado"
            label="idCertificado"
            name="idCertificado"
            value={values.idCertificado}
            onChange={handleChange}
            onBlur={handleBlur}
            error={
              errors.idCertificado && { content: errors.idCertificado }
            }
          />
        </Form.Group>
        <Form.Group widths={'equal'}>
          
          <Form.Input
            placeholder="version_cfd"
            label="version_cfd"
            name="version_cfd"
            value={values.version_cfd}
            onChange={handleChange}
            onBlur={handleBlur}
            error={
              errors.version_cfd && { content: errors.version_cfd }
            }
          />
          <Form.Field>
            <label>&nbsp;</label>
            &nbsp;
          </Form.Field>
          <Form.Field>
            <label>Certificado</label>
            <ModalCertificado formik={formik} />
          </Form.Field>
          <Form.Field>
            <label>Llave</label>
            <ModalLlave formik={formik} />
          </Form.Field>
          
        </Form.Group>
        <FormGroupLocations
          formik={formik}
          values={{
            Pais: values.Pais,
            Estado: values.Estado,
            Municipio: values.Municipio,
            Localidad: values.Localidad,
            CodigoPostal: values.CodPos,
            Colonia: values.Colonia,
            Calle: values.Calle,
            NumExterior: values.NumExterior,
            NumInterior: values.NumInterior,
            Referencia: values.Referencia,
          }}
          names={{
            Pais: "Pais",
            Estado: "Estado",
            Municipio: "Municipio",
            Localidad: "Localidad",
            CodigoPostal: "CodPos",
            Colonia: "Colonia",
            Calle: "Calle",
            NumExterior: "NumExterior",
            NumInterior: "NumInterior",
            Referencia: "Referencia",
          }}
          errors={{
            Pais: errors.Pais,
            Estado: errors.Estado,
            Municipio: errors.Municipio,
            Localidad: errors.Localidad,
            CodPos: errors.CodigoPostal,
            Colonia: errors.Colonia,
            Calle: errors.Calle,
            NumExterior: errors.NumExterior,
            NumInterior: errors.NumInterior,
            Referencia: errors.Referencia
          }}
        />
      </Segment>
    </>
  );
};

export default forwardRef((props, ref) => (
  <FormHeader {...props} innerRef={ref} />
));
