import React, { forwardRef } from 'react';
import { Form, Segment } from 'semantic-ui-react';
import DropdownSearchFetch from '../../../components/Formik/DropdownSearchFetch';
import FormGroupLocations from './FormGroupLocations';

const getErrorsAndTouchs = (touched, errors, index, name) => {
  return (
    touched.ubicaciones &&
    touched.ubicaciones[index] &&
    touched.ubicaciones[index][name] &&
    errors.ubicaciones &&
    errors.ubicaciones[index] &&
    errors.ubicaciones[index][name]
  );
};
const FormHeader = ({ formik }) => {
  let {
    values,
    errors,
    touched,
    handleChange,
    handleBlur,
    handleSubmit,
    isSubmitting,
    setFieldValue,
    /* and other goodies */
  } = formik;
  console.log(values)
  return (
    <>
      <Segment>
        <Form.Group widths={'equal'}>
          <Form.Input
            placeholder="RFC"
            label="RFC"
            name="RFC"
            value={values.RFC}
            onChange={handleChange}
            onBlur={handleBlur}
            error={errors.RFC && { content: errors.RFC }}
          />
          <Form.Input
            placeholder="Nombre"
            label="Nombre"
            name="Nombre"
            value={values.Nombre}
            onChange={handleChange}
            onBlur={handleBlur}
            error={errors.Nombre && { content: errors.Nombre }}
          />
          <DropdownSearchFetch
            fluid
            selection
            label="Regimen Fiscal"
            value={values.idRegimen}
            onChange={(e, { value }) => setFieldValue('idRegimen', value)}
            placeholder="Regimen fiscal"
            featherService="catalogo-regimen-fiscal"
            columName="Descripcion"
            columValue="CRfiscalID"
            mapValues={(value) => {
              return {
                key: Math.random(),
                text: `${value['c_RegimenFiscal']} - ${value['Descripcion']}`,
                value: value['CRfiscalID'],
              };
            }}
            error={errors.idRegimen && { content: errors.idRegimen }}
          />
          <Form.Input
            placeholder="Registro Tributario Exterior"
            label="Registro Tributario Exterior"
            name="RegistroTributarioExterior"
            value={values.RegistroTributarioExterior}
            onChange={handleChange}
            onBlur={handleBlur}
            error={
              errors.RegistroTributarioExterior && {
                content: errors.RegistroTributarioExterior,
              }
            }
          />
        </Form.Group>
        <Form.Group widths={'equal'}>
          <Form.Input
            placeholder="ExentoIVA"
            label="ExentoIVA"
            name="ExentoIVA"
            type="checkbox"
            value={values.ExentoIVA}
            onChange={handleChange}
            onBlur={handleBlur}
            error={errors.ExentoIVA && { content: errors.ExentoIVA }}
          />
          <Form.Input
            placeholder="NoRetieneIVA"
            label="NoRetieneIVA"
            name="NoRetieneIVA"
            type="checkbox"
            value={values.NoRetieneIVA}
            onChange={handleChange}
            onBlur={handleBlur}
            error={errors.NoRetieneIVA && { content: errors.NoRetieneIVA }}
          />
          <Form.Input
            placeholder="NoRetieneISR"
            label="NoRetieneISR"
            name="NoRetieneISR"
            type="checkbox"
            value={values.NoRetieneISR}
            onChange={handleChange}
            onBlur={handleBlur}
            error={errors.NoRetieneISR && { content: errors.NoRetieneISR }}
          />
          <Form.Input
            placeholder="NoRetieneIEPS"
            label="NoRetieneIEPS"
            name="NoRetieneIEPS"
            value={values.NoRetieneIEPS}
            type="checkbox"
            onChange={handleChange}
            onBlur={handleBlur}
            error={errors.NoRetieneIEPS && { content: errors.NoRetieneIEPS }}
          />
        </Form.Group>
        <FormGroupLocations
          formik={formik}
          values={{
            Pais: values.Pais,
            Estado: values.Estado,
            Municipio: values.Municipio,
            Localidad: values.Localidad,
            CodigoPostal: values.CodPos,
            Colonia: values.Colonia,
            Calle: values.Calle,
            NumExterior: values.NumExterior,
            NumInterior: values.NumInterior,
            Referencia: values.Referencia,
          }}
          names={{
            Pais: 'Pais',
            Estado: 'Estado',
            Municipio: 'Municipio',
            Localidad: 'Localidad',
            CodigoPostal: 'CodPos',
            Colonia: 'Colonia',
            Calle: 'Calle',
            NumExterior: 'NumExterior',
            NumInterior: 'NumInterior',
            Referencia: 'Referencia',
          }}
          errors={{
            Pais: errors.Pais,
            Estado: errors.Estado,
            Municipio: errors.Municipio,
            Localidad: errors.Localidad,
            CodigoPostal: errors.CodPos,
            Colonia: errors.Colonia,
            Calle: errors.Calle,
            NumExterior: errors.NumExterior,
            NumInterior: errors.NumInterior,
            Referencia: errors.Referencia,
          }}
        />
      </Segment>
    </>
  );
};

export default forwardRef((props, ref) => (
  <FormHeader {...props} innerRef={ref} />
));
