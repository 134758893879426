import React from 'react';
import PropTypes from 'prop-types';
import { Button } from 'semantic-ui-react';

const TopNavigation = ({ isBusy, onCreateClick, isValid }) => {
  return (
    <Button
      primary
      compact
      onClick={onCreateClick}
      disabled={isBusy || !isValid}
    >
      Create Receptor
    </Button>
  );
};

TopNavigation.propTypes = {
  isBusy: PropTypes.bool,
  onCreateClick: PropTypes.func,
  isValid: PropTypes.bool,
};

export default TopNavigation;
