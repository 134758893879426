import React, { useReducer, useEffect } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import DrawerFiltersWrapper from '../../components/DrawerFiltersWrapper';
import DateRangePicker from '../../components/DateRangePicker';
import ButtonsFilter from '../../components/ButtonsFilter';
import moment from 'moment';

const queryInit = {
  createdDateTime: moment()
};

const reducerQuery = (prevState, { name, value, type, query }) => {
  if (type === 'init' && query) {
    return query;
  } else if (name) {
    return { ...prevState, [name]: value }
  }

  return prevState;
};

const Filters = ({
  isBusy,
  query: base,
  setQuery
}) => {
  // States
  const [query, dispatchQuery] = useReducer(reducerQuery, { ...queryInit });

  // Effects
  useEffect(() => {
    // console.count('Filters query');
    const sendQuery = () => {
      handleSearchClick()
    }

    sendQuery();
  }, []);

  // Handles
  const handleSearchClick = () => {
    // Create query for feathers
    const queryMap = {
      ...query,

      importExport: true,
      createdDateTime: query.createdDateTime.clone().format('MM/DD/YYYY')
    };
    // console.debug('queryMap', JSON.stringify(queryMap, null, 2));

    // Send query to ImportExport
    setQuery && setQuery(queryMap);
  };

  const handleClearClick = (event, data) => {
    dispatchQuery({
      type: 'init',
      query: {
        ...queryInit,

        createdDateTime: moment()
      }
    });
  };

  return (
    <>
      {/* <pre>query: {JSON.stringify(query, null, 2)}</pre> */}

      <DrawerFiltersWrapper>
        <FiltersGrid>
          <DateRangePicker
            name="createdDateTime"
            label="Sent Date"
            timePicker={false}
            startDate={query.createdDateTime}
            maxDate={moment()}
            singleDatePicker={true}
            autoApply={true}

            onChange={date => {
              dispatchQuery({ name: 'createdDateTime', value: date });
            }}

            style={{ gridArea: 'date' }}
            disabled={isBusy}
          />

          <ButtonsFilter
            isBusy={isBusy}
            onSearchClick={handleSearchClick}
            onClearClick={handleClearClick}
          />
        </FiltersGrid>
      </DrawerFiltersWrapper>
    </>
  );
};

const FiltersGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-template-rows: auto;
  grid-gap: 1em;
  grid-template-areas: 'date . . buttons';
`;

Filters.propTypes = {
  isBusy: PropTypes.bool,
  query: PropTypes.object,
  setQuery: PropTypes.func
};

export default Filters;