import React, { forwardRef } from 'react';
import { Form, Segment } from 'semantic-ui-react';
import DropdownSearchFetch from '../../../components/Formik/DropdownSearchFetch';
import FormGroupLocations from './FormGroupLocations';

const getErrorsAndTouchs = (touched, errors, index, name) => {
  return (
    touched.sucursales &&
    touched.sucursales[index] &&
    touched.sucursales[index][name] &&
    errors.sucursales &&
    errors.sucursales[index] &&
    errors.sucursales[index][name]
  );
};
const FormHeader = ({ formik }) => {
  let {
    values,
    errors,
    touched,
    handleChange,
    handleBlur,
    handleSubmit,
    isSubmitting,
    setFieldValue,
    /* and other goodies */
  } = formik;
  return (
    <>
      <Segment>
        <Form.Group widths={'equal'}>
          <Form.Input
            placeholder="Nombre"
            label="Nombre"
            name="Nombre"
            value={values.Nombre}
            onChange={handleChange}
            onBlur={handleBlur}
            error={
              errors.Nombre && { content: errors.Nombre }
            }
          />
          <Form.Input
            placeholder="Descripcion"
            label="Descripcion"
            name="Descripcion"
            value={values.Descripcion}
            onChange={handleChange}
            onBlur={handleBlur}
            error={
              errors.Descripcion && { content: errors.Descripcion }
            }
          />
          <Form.Input
            placeholder="Comentarios"
            label="Comentarios"
            name="Comentarios"
            value={values.Comentarios}
            onChange={handleChange}
            onBlur={handleBlur}
            error={
              errors.Comentarios && { content: errors.Comentarios }
            }
          />
          <Form.Input
            placeholder="Telefono1"
            label="Telefono1"
            name="Telefono1"
            value={values.Telefono1}
            onChange={handleChange}
            onBlur={handleBlur}
            error={
              errors.Telefono1 && { content: errors.Telefono1 }
            }
          />
        </Form.Group>
        <Form.Group widths={'equal'}>
          <Form.Input
            placeholder="Telefono2"
            label="Telefono2"
            name="Telefono2"
            value={values.Telefono2}
            onChange={handleChange}
            onBlur={handleBlur}
            error={
              errors.Telefono2 && { content: errors.Telefono2 }
            }
          />
          <Form.Input
            placeholder="Celular"
            label="Celular"
            name="Celular"
            value={values.Celular}
            onChange={handleChange}
            onBlur={handleBlur}
            error={
              errors.Celular && { content: errors.Celular }
            }
          />
          <Form.Input
            placeholder="Correo"
            label="Correo"
            name="Correo"
            value={values.Correo}
            onChange={handleChange}
            onBlur={handleBlur}
            error={
              errors.Correo && { content: errors.Correo }
            }
          />
          <Form.Input
            placeholder="SitioWeb"
            label="SitioWeb"
            name="SitioWeb"
            value={values.SitioWeb}
            onChange={handleChange}
            onBlur={handleBlur}
            error={
              errors.SitioWeb && { content: errors.SitioWeb }
            }
          />
        </Form.Group>
        <Form.Group widths={'equal'}>
          <Form.Input
            placeholder="GoogleMaps"
            label="GoogleMaps"
            name="GoogleMaps"
            value={values.GoogleMaps}
            onChange={handleChange}
            onBlur={handleBlur}
            error={
              errors.GoogleMaps && { content: errors.GoogleMaps }
            }
          />
          <Form.Input
            placeholder="LogoImg"
            label="LogoImg"
            name="LogoImg"
            value={values.LogoImg}
            onChange={handleChange}
            onBlur={handleBlur}
            error={
              errors.LogoImg && { content: errors.LogoImg }
            }
          />
          <Form.Input
            placeholder="LogoRuta"
            label="LogoRuta"
            name="LogoRuta"
            value={values.LogoRuta}
            onChange={handleChange}
            onBlur={handleBlur}
            error={
              errors.LogoRuta && { content: errors.LogoRuta }
            }
          />
           <Form.Input
            placeholder="Status"
            label="Status"
            name="Status"
            value={values.Status}
            onChange={handleChange}
            onBlur={handleBlur}
            error={
              errors.Status && { content: errors.Status }
            }
          />
        </Form.Group>
        <Form.Group widths={'equal'}>
         
          <Form.Input
            placeholder="TaxID"
            label="TaxID"
            name="TaxID"
            value={values.TaxID}
            onChange={handleChange}
            onBlur={handleBlur}
            error={
              errors.TaxID && { content: errors.TaxID }
            }
          />
          <Form.Input
            placeholder="RazonSocial"
            label="RazonSocial"
            name="RazonSocial"
            value={values.RazonSocial}
            onChange={handleChange}
            onBlur={handleBlur}
            error={
              errors.RazonSocial && { content: errors.RazonSocial }
            }
          />
          <Form.Input
            placeholder="RegimenFiscal"
            label="RegimenFiscal"
            name="RegimenFiscal"
            value={values.RegimenFiscal}
            onChange={handleChange}
            onBlur={handleBlur}
            error={
              errors.RegimenFiscal && { content: errors.RegimenFiscal }
            }
          />
        </Form.Group>
        
        <FormGroupLocations
          formik={formik}
          values={{
            Pais: values.Pais,
            Estado: values.Estado,
            Municipio: values.Municipio,
            Localidad: values.Localidad,
            CodigoPostal: values.CodigoPostal,
            Colonia: values.Colonia,
            Calle: values.Calle,
            NumExterior: values.NumExterior,
            NumInterior: values.NumInterior,
            Referencia: values.Referencia,
          }}
          names={{
            Pais: 'Pais',
            Estado: 'Estado',
            Municipio: 'Municipio',
            Localidad: 'Localidad',
            CodigoPostal: 'CodigoPostal',
            Colonia: 'Colonia',
            Calle: 'Calle',
            NumExterior: 'NumExterior',
            NumInterior: 'NumInterior',
            Referencia: 'Referencia',
          }}
          errors={{
            Pais: getErrorsAndTouchs(touched, errors, 0, 'Pais'),
            Estado: getErrorsAndTouchs(touched, errors, 0, 'Estado'),
            Municipio: getErrorsAndTouchs(touched, errors, 0, 'Municipio'),
            Localidad: getErrorsAndTouchs(touched, errors, 0, 'Localidad'),
            CodPos: getErrorsAndTouchs(touched, errors, 0, 'CodigoPostal'),
            Colonia: getErrorsAndTouchs(touched, errors, 0, 'Colonia'),
            Calle: getErrorsAndTouchs(touched, errors, 0, 'Calle'),
            NumExterior: getErrorsAndTouchs(touched, errors, 0, 'NumExterior'),
            NumInterior: getErrorsAndTouchs(touched, errors, 0, 'NumInterior'),
            Referencia: getErrorsAndTouchs(touched, errors, 0, 'Referencia'),
          }}
        />
      </Segment>
    </>
  );
};

export default forwardRef((props, ref) => (
  <FormHeader {...props} innerRef={ref} />
));
