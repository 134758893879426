import React, { forwardRef } from 'react';
import styled from 'styled-components';
import FormHeader from './FormHeader';

const FormDocument = ({ formik}) => {
  let {
    values,
    errors,
    touched,
    handleChange,
    handleSearchChange,
    handleBlur,
    handleSubmit,
    isSubmitting,
    /* and other goodies */
  } = formik;

  return (
    <WrapContent>
      <FormHeader 
      formik={formik} />
    </WrapContent>
  );
};

const WrapContent = styled.div``;

export default forwardRef((props, ref) => (
  <FormDocument {...props} innerRef={ref} />
));
