import React, { useEffect, useState } from 'react';
import { Dropdown, Grid, GridColumn, GridRow } from 'semantic-ui-react';
import styled from 'styled-components';

import ActivityIndicator from './ActivityIndicator';
import feathers from '../services/feathers';
import {
  setExternalTripId,
  setOptimaTrips,
} from '../stores/Parcels/ParcelsActions';
import { useDispatch, useSelector } from 'react-redux';

const DropdownSearchSelection = () => {
  const dispatch = useDispatch();
  const [autocomplete, setAutocomplete] = useState('');
  const [loader, setLoader] = useState(false);
  const { optimaTripSelected } = useSelector((state) => state.ParcelReducer);
  const { tripNumber } = optimaTripSelected;
  const { parcelOptimaTrips } = useSelector((state) => state.ParcelReducer);

  const getTrips = async () => {
    try {
      autocomplete === '' ? setLoader(false) : setLoader(true);
      const response = await feathers
        .service(`export-optima-trips/${autocomplete}`)
        .find();
      dispatch(setOptimaTrips(response));
      setLoader(false);
    } catch (error) {
      console.error('Error obtaining trips: ', error);
    }
  };

  useEffect(() => {
    getTrips();
  }, [autocomplete]);

  const handleSearchChange = (e, { searchQuery }) => {
    if (searchQuery.length > 3) setAutocomplete(searchQuery);
  };

  const setTripIdByDispatch = (element) => {
    let { value } = element;
    let selection = parcelOptimaTrips.filter((item) => item.key === value);
    let { key, text } = selection[0];
    dispatch(
      setExternalTripId({
        tripId: key,
        tripNumber: text,
      })
    );
  };

  return (
    <Grid columns={2} verticalAlign="middle" padded="vertically">
      <GridRow stretched>
        <GridColumn>
          {loader ? (
            <ActivityIndicator title="Searching Trips..." />
          ) : (
            'Create E-Manifest'
          )}
        </GridColumn>
        <GridColumn textAlign="right">
          <Grid columns={2}>
            <GridRow stretched>
              <GridColumn textAlign="right">
                <ToolbarLabel style={{ textAlign: 'right', marginTop: '7px' }}>
                  Trip #
                </ToolbarLabel>
              </GridColumn>
              <GridColumn>
                <Dropdown
                  placeholder="Select a Trip"
                  search
                  fluid
                  selection
                  style={{ fontSize: 12 }}
                  onChange={(e, element) => setTripIdByDispatch(element)}
                  onSearchChange={handleSearchChange}
                  options={parcelOptimaTrips}
                  text={tripNumber}
                />
              </GridColumn>
            </GridRow>
          </Grid>
        </GridColumn>
      </GridRow>
    </Grid>
  );
};

export default DropdownSearchSelection;

const ToolbarLabel = styled.span`
  font-family: 'Roboto', sans-serif;
  font-size: 14px;
  color: rgba(0, 0, 0, 0.87);
  letter-spacing: 0.25px;
  text-align: left;
  line-height: 20px;
  margin-right: 16px;
`;
