import React, { useState, useEffect } from 'react';
import DefaultLayout from '../../layouts/DefaultLayout';
import TopNavigation from './TopNavigation';
import HeaderBG from '../../components/HeaderBG';
import SectionTitle from '../../components/SectionTitle';
import Filters from './Filters';
import TableNavigation from './TableNavigation';
import Table from './Table';
import Drawer from './Drawer';
import { Container } from 'semantic-ui-react';
import feathers, { useAuth } from '../../services/feathers';
import swal from 'sweetalert';
import { pluralize } from '../../functions/common';

const Users = (props) => {
  const service = feathers.service('users');
  const auth = useAuth();
  // console.debug('auth', JSON.stringify(auth, null, 2));

  // States
  const [currentUser, setCurrentUser] = useState([]);
  const [isBusy, setIsBusy] = useState(false);
  const [openPanel, setOpenPanel] = useState(false);
  const [data, setData] = useState([]);
  const [selection, setSelection] = useState([]);
  const [messageAction, setMessageAction] = useState();
  const [isSupport, setIsSupport] = useState(false);

  const [client, setClient] = useState(null);
  const [isValid, setIsValid] = useState(false);

  const [query, setQuery] = useState(null);

  // Effects
  useEffect(() => {
    const filter = () => {
      if (query) {
        getData();
      }
    };

    filter();
  }, [query]);

  useEffect(() => {
    // console.debug('auth', JSON.stringify(auth, null, 2));
    const { isSupport } = (auth && auth.user) || {},
      client = (auth && auth.user && auth.user.client) || null,
      isInvalid = (!isSupport && !client && true) || false;

    setIsSupport(isSupport);
    setClient(client);
    setIsValid(!isInvalid);
  }, [auth.user]);

  // Functions
  const getData = async () => {
    if (query) {
      let data = [],
        result = [];

      try {
        setIsBusy(true);
        setSelection([]);

        // console.debug('query', JSON.stringify(query, null, 2));
        do {
          result = await service.find({
            query: {
              ...query,

              $skip: data.length,
            },
          });

          data = data.concat(result.data);

          // console.debug('result', result.data);
        } while (data.length !== result.total);
      } catch (err) {
        console.error('Error', err);
        data = [];

        swal({
          title: 'Error',
          text: 'Failed get Users',
          icon: 'error',
          buttons: {
            cancel: 'Close',
          },
        });
      } finally {
        setData(data);
        setIsBusy(false);
      }
    }
  };

  const openDrawer = (user) => {
    setCurrentUser(user);
    setOpenPanel(true);
  };

  // Handles
  const handleCreateClick = () => {
    const [clients, client] = auth.user.isSupport
      ? [[], {}]
      : [[{ ...auth.user.client }], { ...auth.user.client }];
    openDrawer({ clients, client });
  };

  const handleEditClick = (user) => {
    openDrawer(user);
  };

  const handleDeleteClick = async () => {
    let success = false;

    try {
      const total = selection.length;
      let msg = `Do you want to delete ${total} ${pluralize(total, 'user')}?`;
      let confirmation = await swal(msg, {
        buttons: ['No', 'Yes'],
      });

      if (confirmation) {
        setIsBusy(true);
        setMessageAction('Deleting ...');
        await service.remove(selection);

        success = true;
      }
    } catch (error) {
      console.debug(error);
      await swal('Error', "Don't Delete selected users", 'error');
    } finally {
      setMessageAction();
      setIsBusy(false);

      if (success) {
        await swal('Done', 'Finished', 'success');

        setSelection([]);
        getData();
      }
    }
  };

  const handleClose = (isSuccess) => {
    setCurrentUser({});
    setOpenPanel(false);

    isSuccess && getData();
  };

  return (
    <>
      <DefaultLayout
        currentPath={props.match.path}
        globalActions={
          <TopNavigation
            isBusy={isBusy}
            onCreateClick={handleCreateClick}
            isValid={isValid}
          />
        }
      >
        <HeaderBG />

        <Container>
          <SectionTitle>Users</SectionTitle>

          <Filters
            isBusy={isBusy}
            setQuery={setQuery}
            isValid={isValid}
            isSupport={isSupport}
            client={client}
          />

          <TableNavigation
            isBusy={isBusy}
            haveSelected={selection.length > 0}
            onDeleteClick={handleDeleteClick}
            messageAction={messageAction}
          />

          <Table
            data={data}
            onEditClick={handleEditClick}
            selection={selection}
            setSelection={setSelection}
            isSupport={isSupport}
          ></Table>
        </Container>
      </DefaultLayout>

      {openPanel ? (
        <Drawer
          openPanel={openPanel}
          onClose={handleClose}
          currentUser={currentUser}
        ></Drawer>
      ) : null}
    </>
  );
};

export default Users;
