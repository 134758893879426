import React from 'react';
import PropTypes from 'prop-types';
import { Checkbox } from 'semantic-ui-react';
import Popup from './Popup';

const CheckboxComponent = ({ checked, handleOnChange, title }) => {
  const handleChange = (_, { checked }) => {
    handleOnChange && handleOnChange(checked);
  };

  return (
    <>
      <Popup content={title}>
        <Checkbox label="Not" checked={checked} onChange={handleChange} />
      </Popup>
    </>
  );
};

CheckboxComponent.propTypes = {
  checked: PropTypes.bool.isRequired,
  handleOnChange: PropTypes.func.isRequired,
  title: PropTypes.string.isRequired,
};

export default CheckboxComponent;
