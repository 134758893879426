import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Dropdown } from 'semantic-ui-react';
import Popup from './Popup';

const DropdownSelector = ({
  handleOnChange,
  options: sourceOptions,
  title,
  value,
}) => {
  // console.debug('DropdownSelector', props);
  const [options, setOptions] = useState([]);

  useEffect(() => {
    const options = Array.isArray(sourceOptions)
      ? sourceOptions.map(({ name, label }, index) => ({
          key: name,
          text: label,
          value: name,
        }))
      : [];

    setOptions(options);
  }, [sourceOptions]);

  const handleChange = (_, { value }) => {
    handleOnChange && handleOnChange(value);
  };

  return (
    <>
      <Popup content={title}>
        <Dropdown
          selection
          options={options}
          value={value}
          onChange={handleChange}
        />
      </Popup>
    </>
  );
};

DropdownSelector.propTypes = {
  handleOnChange: PropTypes.func.isRequired,
  options: PropTypes.array.isRequired,
  title: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired,
};

export default DropdownSelector;
