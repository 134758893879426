import React, {
  useState,
  useEffect,
  forwardRef,
  useImperativeHandle,
  useReducer,
} from 'react';
import { Form } from 'semantic-ui-react';
import styled from 'styled-components';
import feathers from '../services/feathers';

const reducerOptions = (_, options) => {
  // console.debug('reducerOptions', options);
  if (options) {
    const { data = [], values = [] } = options;

    return [...new Set([...data, ...values])].map((ec) => ({
      key: ec,
      text: ec,
      value: ec,
    }));
  }

  return [];
};

const service = feathers.service('product-data');
const FormAddProduct = ({
  innerRef,
  currentProduct,
  onSubmit,
  onSave,
  setIsValid,
  addFromValidation = false,
  isImportExport = false,
}) => {
  // State
  const [loading, setLoading] = useState(false);
  const [isFetching, setFetching] = useState(false);
  const [options, setOptions] = useReducer(reducerOptions, []);
  const [searchQuery, setSearchQuery] = useState('');
  const [product, setProduct] = useState({});
  const [validations, setValidations] = useState({});

  // Refs
  useEffect(() => {
    // console.debug('useEffect product, searchQuery', product, searchQuery);
    const getExportCodes = async () => {
      try {
        setFetching(true);

        if (product && product.isKit) {
          let result = await service.find({
            query: {
              $exportCode: searchQuery,
              $limit: 100,
            },
          });

          setOptions({
            data: result,
            values: product.exportCode,
          });
        } else {
          setOptions({ data: [], values: [] });
        }
      } catch (err) {
        console.error('FormAddProduct', err);
      } finally {
        setFetching(false);
      }
    };

    getExportCodes();
  }, [product, searchQuery]);

  useEffect(() => {
    console.count('FormAddProduct currentProduct');
    const initData = () => {
      const initProduct = { ...currentProduct };
      const initValidations = {
        sku: false,
        name: false,
        cost: false,

        ...(!addFromValidation && {
          barcode: false,
          coco: false,
          hts: false,
          rate: false,
          customer: false,
          exportCode: false,
          description: false,
          //tariffAdditional: false,
          //tariff321: false,
        }),

        ...(isImportExport && {
          exportCode: false,
        }),
      };

      if (currentProduct) {
        initProduct.is321 =
          (currentProduct.is321 || false) && currentProduct.is321 === 'YES';

        if (initProduct.isTextile) {
          initValidations.fiber = false;
        }
      }

      setProduct(initProduct);
      setValidations(initValidations);
    };

    initData();
  }, [addFromValidation, currentProduct, isImportExport]);

  const refBtnSubmit = React.useRef(null);

  useImperativeHandle(innerRef, () => ({
    submit: () => {
      refBtnSubmit.current.click();
    },
  }));
  // Handles
  const handleChange = (_, { name, value }) => {
    setProduct({ ...product, [name]: value });
    if (validations.hasOwnProperty(name)) validateField(name, value);
  };

  const handleBlur = ({ target }) => {
    if (target && validations.hasOwnProperty(target.name))
      validateField(target.name, target.value);
  };

  const handleChangeCheckbox = (_, { name, checked }) => {
    const newValidations = { ...validations };
    const newProduct = { ...product, [name]: checked };

    switch (name) {
      case 'isTextile':
        newValidations.fiber = false;
        if (!checked) delete newValidations.fiber;
        break;

      case 'isKit':
        delete newProduct.exportCode;

        newValidations.exportCode = false;
        if (addFromValidation && !isImportExport)
          delete newValidations.exportCode;
        break;

      default:
        break;
    }

    setValidations(newValidations);
    setProduct(newProduct);
  };

  const handlKeyPressCheckbox = (event) => {
    const {
      which,
      target: { name, checked },
    } = event;

    if (which === 13) {
      event.preventDefault();

      handleChangeCheckbox(null, { name, checked: !checked });
    }
  };

  const handleSearchChange = async (event, { searchQuery }) => {
    setSearchQuery(searchQuery);
  };

  const validateField = (name, value) => {
    setValidations({
      ...validations,
      [name]:
        value !== null && value !== undefined && String(value).length
          ? false
          : true,
    });
  };

  const handleSubmit = async (event) => {
    setLoading(true);

    try {
      event.preventDefault();

      let isValidForm = true;
      const newValidations = Object.keys(validations).reduce((prev, field) => {
        const ret = {
          ...prev,
          [field]:
            product[field] !== null &&
            product[field] !== undefined &&
            String(product[field]).length
              ? false
              : true,
        };
        isValidForm = isValidForm && !ret[field];

        return ret;
      }, {});

      setValidations(newValidations);

      if (isValidForm) {
        let productTmp = {
          ...product,

          isTextile: product.isTextile || false,
          isKit: product.isKit || false,

          is321: product.is321 ? 'YES' : 'NOT',
          addFromValidation: isImportExport ? false : addFromValidation,
        };

        if (onSubmit) {
          onSubmit(productTmp);
          return;
        } else {
          let result;
          if (!currentProduct._id) {
            result = await service.create(productTmp);
          } else {
            result = await service.patch(currentProduct._id, productTmp);
          }

          if (result) {
            onSave && onSave(result);
            return;
          }
        }
      }
    } catch (err) {
      console.error('Error: ', err);
    }

    setLoading(false);
  };

  useEffect(() => {
    setIsValid(Object.values(validations).filter((val) => val).length >= 1);
  }, [setIsValid, validations]);

  return (
    <WrapContent>
      <>
        {/* <strong>onChange:</strong>
                <pre>{JSON.stringify(product, null, 2)}</pre>

                <strong>product.barcode: </strong>
                <pre>{product.barcode ? false : JSON.stringify({ content: 'Required Barcode' }, null, 2)}</pre> */}
      </>

      <Form onSubmit={handleSubmit} loading={loading}>
        <Form.Group widths={'equal'}>
          <Form.Input
            placeholder="Barcode"
            label={`Barcode ${!addFromValidation ? '*' : ''}`}
            name="barcode"
            value={product.barcode || ''}
            autoFocus
            onChange={handleChange}
            onBlur={handleBlur}
            error={validations.barcode && { content: 'Required Barcode' }}
          />

          <Form.Input
            placeholder="SKU"
            label="SKU *"
            name="sku"
            value={product.sku || ''}
            onChange={handleChange}
            onBlur={handleBlur}
            error={validations.sku && { content: 'Required SKU' }}
            disabled={addFromValidation}
          />
        </Form.Group>

        <Form.Input
          placeholder="Product Name"
          label="Product Name *"
          name="name"
          value={product.name || ''}
          onChange={handleChange}
          onBlur={handleBlur}
          error={validations.name && { content: 'Required Product Name' }}
        />

        <Form.Group widths={'equal'}>
          <Form.Input
            placeholder="Price"
            label="Price *"
            name="cost"
            value={product.cost}
            onChange={handleChange}
            onBlur={handleBlur}
            error={validations.cost && { content: 'Required Price' }}
          />

          <Form.Input
            placeholder="COO"
            label={`COO ${!addFromValidation ? '*' : ''}`}
            name="coco"
            value={product.coco || ''}
            onChange={handleChange}
            onBlur={handleBlur}
            error={validations.coco && { content: 'Required Coco' }}
          />

          <Form.Input
            placeholder="Hts Code"
            label={`Hts Code ${!addFromValidation ? '*' : ''}`}
            name="hts"
            value={product.hts || ''}
            onChange={handleChange}
            onBlur={handleBlur}
            error={validations.hts && { content: 'Required Hts Code' }}
          />
        </Form.Group>

        <Form.Group widths={'equal'}>
          <Form.Input
            placeholder="Rate"
            label={`Rate ${!addFromValidation ? '*' : ''}`}
            name="rate"
            value={product.rate || ''}
            onChange={handleChange}
            onBlur={handleBlur}
            error={validations.rate && { content: 'Required Rate' }}
          />

          <Form.Input
            placeholder="Customer"
            label={`Customer ${!addFromValidation ? '*' : ''}`}
            name="customer"
            value={product.customer || ''}
            onChange={handleChange}
            onBlur={handleBlur}
            error={validations.customer && { content: 'Required Customer' }}
          />
        </Form.Group>

        <Form.Group>
          <Form.Checkbox
            label="Is Textile"
            name="isTextile"
            checked={product.isTextile}
            style={{ marginTop: 30 }}
            width={3}
            onChange={handleChangeCheckbox}
            onKeyPress={handlKeyPressCheckbox}
          />

          <Form.Input
            placeholder="Fiber/Content"
            label={`Fiber/Content ${product.isTextile ? '*' : ''}`}
            name="fiber"
            value={product.fiber || ''}
            width={5}
            onChange={handleChange}
            onBlur={handleBlur}
            error={validations.fiber && { content: 'Required Fiber' }}
          />

          <Form.Checkbox
            label="Is kit"
            name="isKit"
            checked={product.isKit}
            style={{ marginTop: 30 }}
            width={2}
            onChange={handleChangeCheckbox}
            onKeyPress={handlKeyPressCheckbox}
          />

          {!product.isKit ? (
            <Form.Input
              placeholder="Export Code"
              label={`Export Code ${
                !addFromValidation || isImportExport ? '*' : ''
              }`}
              name="exportCode"
              value={product.exportCode || ''}
              width={6}
              onChange={handleChange}
              onBlur={handleBlur}
              error={
                validations.exportCode && { content: 'Required Export Code' }
              }
            />
          ) : (
            <Form.Dropdown
              value={product.exportCode || []}
              placeholder="Kit"
              label={`Kit ${!addFromValidation || isImportExport ? '*' : ''}`}
              name="exportCode"
              fluid
              multiple
              selection
              search
              onChange={handleChange}
              onSearchChange={handleSearchChange}
              onBlur={(_, data) => handleBlur({ target: { ...data } })}
              searchQuery={searchQuery}
              options={options}
              width={6}
              error={validations.exportCode && { content: 'Required Kit' }}
              loading={isFetching}
            />
          )}
        </Form.Group>

        <Form.Input
          placeholder="Description"
          label={`Description ${!addFromValidation ? '*' : ''}`}
          name="description"
          value={product.description || ''}
          onChange={handleChange}
          onBlur={handleBlur}
          error={validations.description && { content: 'Required Description' }}
        />

        <Form.Group widths={2}>
          <Form.Input
            placeholder="Tariff Additional"
            label="Tariff Additional"
            name="tariffAdditional"
            value={product.tariffAdditional || ''}
            onChange={handleChange}
            onBlur={handleBlur}
            error={
              validations.tariffAdditional && {
                content: 'Required Tariff Additional',
              }
            }
          />

          <Form.Input
            placeholder="Tariff 321"
            label="Tariff 321"
            name="tariff321"
            value={product.tariff321 || ''}
            onChange={handleChange}
            onBlur={handleBlur}
            error={validations.tariff321 && { content: 'Required Tariff 321' }}
          />

          <Form.Checkbox
            label="Is 321"
            name="is321"
            checked={product.is321}
            style={{ marginTop: 30 }}
            width={3}
            onChange={handleChangeCheckbox}
            onKeyPress={handlKeyPressCheckbox}
          />
        </Form.Group>

        <Form.TextArea
          placeholder="Notes"
          label="Notes"
          name="notes"
          value={product.notes || ''}
          onChange={handleChange}
          onBlur={handleBlur}
          error={validations.notes && { content: 'Required Notes' }}
        />

        <input type="submit" style={{ display: 'none' }} ref={refBtnSubmit} />
      </Form>
    </WrapContent>
  );
};

const WrapContent = styled.div``;

export default forwardRef((props, ref) => (
  <FormAddProduct {...props} innerRef={ref} />
));
