import React, { useState, useEffect, useRef } from 'react';
import ReactDOM from 'react-dom';
import swal from 'sweetalert';

const BinInput = (props) => {
  const [bin, setBin] = useState(String(props.bin || '').toLowerCase());
  const refBin = useRef(null);

  useEffect(() => {
    refBin && refBin.current && refBin.current.focus();
  }, [refBin]);

  const handleChange = ({ target: { value } }) => {
    const bin = value.toLowerCase();

    setBin(bin);
    swal.setActionValue(bin);
  };

  return (
    <>
      <input
        value={bin}
        onChange={handleChange}
        className="swal-content__input"
        placeholder
        ref={refBin}
      />
    </>
  );
};

const Modal = async (defaultBin) => {
  let bin = null;
  try {
    // We want to retrieve BinInput as a pure DOM node:
    const wrapper = document.createElement('div');
    ReactDOM.render(<BinInput bin={defaultBin} />, wrapper);
    const el = wrapper.firstChild;

    bin = await swal({
      text: 'BIN',
      content: el,
      button: {
        text: 'Ok',
        closeModal: true,
      },
    });
  } catch (err) {
    console.error('Error ::>', err);
    bin = null;
  }
  return (bin && String(bin).length && bin) || null;
};

export default Modal;
