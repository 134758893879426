import React, { forwardRef } from 'react';
import { Form, Segment } from 'semantic-ui-react';
import DropdownSearchFetch from '../../../components/Formik/DropdownSearchFetch';

const FormHeader = ({ formik }) => {
  let {
    values,
    errors,
    touched,
    handleChange,
    handleBlur,
    handleSubmit,
    isSubmitting,
    setFieldValue
    /* and other goodies */
  } = formik;
  return (
    <>
      <Segment>
        
        <Form.Group widths={'equal'}>
          <Form.Input
            placeholder="Nombre"
            label="Nombre"
            name="Nombre"
            value={values.Nombre}
            onChange={handleChange}
            onBlur={handleBlur}
            error={
              errors.Nombre && { content: errors.Nombre }
            }
          />
          <Form.Input
            placeholder="Placa"
            label="Placa"
            name="Placa"
            value={values.Placa}
            onChange={handleChange}
            onBlur={handleBlur}
            error={
              errors.Placa && { content: errors.Placa }
            }
          />
          <Form.Input
            placeholder="Año Modelo"
            label="Año Modelo"
            name="AnioModelo"
            value={values.AnioModelo }
            onChange={handleChange}
            onBlur={handleBlur}
            error={
              errors.AnioModelo && { content: errors.AnioModelo }
            }
          />
          <DropdownSearchFetch
            fluid
            selection
            label="Configuracion Autotransporte"
            value={values.ConfiguracionAutotransporte}
            onChange={(e, { value }) => setFieldValue('ConfiguracionAutotransporte', value)}
            placeholder="Configuracion Autotransporte"
            featherService="catalogo-config-autotransporte"
            columName="Descripcion"
            columValue="CcAutoTransID"
            mapValues={(value) => {
              return {
                key: Math.random(),
                text: `${value['c_ConfigAutotransporte']} - ${value['Descripcion']}`,
                value: value['CcAutoTransID'],
              };
            }}
            error={
              errors.ConfiguracionAutotransporte && { content: errors.ConfiguracionAutotransporte }
            }
          />
          </Form.Group>
          <Form.Group widths={'equal'}>
          
          <Form.Input
            placeholder="Aseguradora Responsabilidad Civil"
            label="Aseguradora Responsabilidad Civil"
            name="AseguradoraResponsabilidadCivil"
            value={values.AseguradoraResponsabilidadCivil}
            onChange={handleChange}
            onBlur={handleBlur}
            error={
              errors.AseguradoraResponsabilidadCivil && { content: errors.AseguradoraResponsabilidadCivil }
            }
          />
          <Form.Input
            placeholder="Poliza Responsabilidad Civil"
            label="Poliza Responsabilidad Civil"
            name="PolizaResponsabilidadCivil"
            value={values.PolizaResponsabilidadCivil}
            onChange={handleChange}
            onBlur={handleBlur}
            error={
              errors.PolizaResponsabilidadCivil && { content: errors.PolizaResponsabilidadCivil }
            }
          />
          <Form.Input
            placeholder="Aseguradora Medio Ambiente"
            label="Aseguradora Medio Ambiente"
            name="AseguradoraMedioAmbiente"
            value={values.AseguradoraMedioAmbiente}
            onChange={handleChange}
            onBlur={handleBlur}
            error={
              errors.AseguradoraMedioAmbiente && { content: errors.AseguradoraMedioAmbiente }
            }
          />
          <Form.Input
            placeholder="Poliza Medio Ambiente"
            label="Poliza Medio Ambiente"
            name="PolizaMedioAmbiente"
            value={values.PolizaMedioAmbiente}
            onChange={handleChange}
            onBlur={handleBlur}
            error={
              errors.PolizaMedioAmbiente && { content: errors.PolizaMedioAmbiente }
            }
          />
          </Form.Group>
          <Form.Group widths={'equal'}>
          
          <Form.Input
            placeholder="Aseguradora Carga"
            label="Aseguradora Carga"
            name="AseguradoraCarga"
            value={values.AseguradoraCarga}
            onChange={handleChange}
            onBlur={handleBlur}
            error={
              errors.AseguradoraCarga && { content: errors.AseguradoraCarga }
            }
          />
          <Form.Input
            placeholder="Poliza Carga"
            label="Poliza Carga"
            name="PolizaCarga"
            value={values.PolizaCarga}
            onChange={handleChange}
            onBlur={handleBlur}
            error={
              errors.PolizaCarga && { content: errors.PolizaCarga }
            }
          />
          <Form.Input
            placeholder="Prima Seguro"
            label="Prima Seguro"
            name="PrimaSeguro"
            value={values.PrimaSeguro}
            onChange={handleChange}
            onBlur={handleBlur}
            error={
              errors.PrimaSeguro && { content: errors.PrimaSeguro }
            }
          />
          <DropdownSearchFetch
            fluid
            selection
            label="Tipo Permiso SCT"
            value={values.TipoPermisoSCT}
            onChange={(e, { value }) => setFieldValue('TipoPermisoSCT', value)}
            placeholder="Tipo Permiso SCT"
            featherService="catalogo-tipo-permiso"
            columName="Descripcion"
            columValue="CTpermisoID"
            mapValues={(value) => {
              return {
                key: Math.random(),
                text: `${value['c_TipoPermiso']} - ${value['Descripcion']}`,
                value: value['CTpermisoID'],
              };
            }}
            error={
              errors.TipoPermisoSCT && { content: errors.TipoPermisoSCT }
            }
          />
          </Form.Group>
          <Form.Group widths={'equal'}>
          
          <Form.Input
            placeholder="Permiso SCT"
            label="Permiso SCT"
            name="NumPermisoSCT"
            value={values.NumPermisoSCT}
            onChange={handleChange}
            onBlur={handleBlur}
            error={
              errors.NumPermisoSCT && { content: errors.NumPermisoSCT }
            }
          />
        </Form.Group>
      </Segment>
    </>
  );
};

export default forwardRef((props, ref) => (
  <FormHeader {...props} innerRef={ref} />
));
