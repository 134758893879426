import React, { useEffect } from 'react';
import { useMemo } from 'react';
import { Modal, Button, Icon, List, Loader, Label } from 'semantic-ui-react';
import moment from 'moment';

const ValidateData = ({ data, setData, state, setState }) => {
  useEffect(() => {
    setState({ ...state, isValid: true });
  }, [data]);

  const { permissions, orders, startDate, endDate } = data;

  const renderOrders = useMemo(() => {
    const render = permissions.includes('import-rush-order');
    const ordersValid = (Array.isArray(orders) ? orders : []).filter(
      (str) => String(str).trim().length
    );

    if (render) {
      return (
        <List.Item>
          <List.Icon name="list" style={{ paddingLeft: '0' }} />
          <List.Content>
            <List.Header>Orders</List.Header>

            {ordersValid.length ? (
              <List.List as="ol" style={{ paddingLeft: '0' }}>
                {ordersValid.map((str, key) => (
                  <List.Item
                    as="li"
                    key={key}
                    style={{ paddingLeft: '0.5rem' }}
                  >
                    <List.Content>
                      <List.Header>{String(str).trim()}</List.Header>
                    </List.Content>
                  </List.Item>
                ))}
              </List.List>
            ) : (
              <Label>Automatically get orders from Rush Order</Label>
            )}
          </List.Content>
        </List.Item>
      );
    }

    return <></>;
  }, [permissions, orders]);

  const renderDateRange = useMemo(() => {
    const render = permissions.includes('csv-shipped-items');

    if (render && moment(startDate).isValid && moment(endDate).isValid) {
      return (
        <List.Item>
          <List.Icon name="calendar" />
          <List.Content>
            <List.Header>Date Range</List.Header>
            <List.Description>
              {startDate.format('MM/DD/YYYY')}-{endDate.format('MM/DD/YYYY')}
            </List.Description>
          </List.Content>
        </List.Item>
      );
    }

    return <></>;
  }, []);

  return (
    <>
      {/* <pre>state: {JSON.stringify(state, null, 2)}</pre>
      <pre>data: {JSON.stringify(data, null, 2)}</pre> */}
      <List divided>
        <List.Item>
          <List.Icon name="options" />
          <List.Content>
            <List.Header>Options</List.Header>

            {permissions && permissions.length ? (
              <List.List as="ol" style={{ paddingLeft: '0' }}>
                {permissions.map((perm, key) => {
                  const { title } = ((state && state.permissions) || []).find(
                    ({ key }) => perm === key
                  );

                  return (
                    <List.Item
                      as="li"
                      key={key}
                      style={{ paddingLeft: '0.5rem' }}
                    >
                      <List.Content>
                        <List.Header>{title}</List.Header>
                      </List.Content>
                    </List.Item>
                  );
                })}
              </List.List>
            ) : (
              <></>
            )}
          </List.Content>
        </List.Item>

        {renderOrders}
        {renderDateRange}
      </List>
    </>
  );
};

export default ValidateData;
