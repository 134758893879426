import {
  TOGGLE_SH_IMPORT,
  SENT_DATA,
  DATA_VALIDATIONS,
  TOGGLE_VALIDATION_MODAL,
  SET_EXTERNAL_DATA,
  SET_OPTIMA_TRIPS,
  TOGGLE_ADD_PRODUCT
} from './constants/ParcelsConstants';

export const toggleSHImport = () => {
  return (dispatch, getState) => {
    const { shImportVisible } = getState().ParcelReducer;

    dispatch({
      type: TOGGLE_SH_IMPORT,
      payload: !shImportVisible
    });
  };
};

export const dataToSend = payload => {
  return dispatch => {
    dispatch({
      type: SENT_DATA,
      payload
    });
  };
};

export const validatingData = payload => {
  return dispatch => {
    dispatch({ type: DATA_VALIDATIONS, payload });
  };
};

export const toggleValidationModal = () => {
  return (dispatch, getState) => {
    const { validationModalVisible } = getState().ParcelReducer;

    dispatch({
      type: TOGGLE_VALIDATION_MODAL,
      payload: !validationModalVisible
    });
  };
};

export const setExternalTripId = payload => {
  return dispatch => {
    dispatch({
      type: SET_EXTERNAL_DATA,
      payload
    });
  };
};

export const setOptimaTrips = payload => {
  return dispatch => {
    dispatch({
      type: SET_OPTIMA_TRIPS,
      payload
    });
  };
};

export const toggleAddProduct = () => {
  return (dispatch, getState) => {
    const { addProductVisible } = getState().ParcelReducer;

    dispatch({
      type: TOGGLE_ADD_PRODUCT,
      payload: !addProductVisible
    });
  };
};