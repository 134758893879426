import React from 'react';
import FormConsolidatedCargo from './FormConsolidatedCargo';
import ImportShippedItems from './ImportShippedItems';
import ValidateShippedItems from './ValidateShippedItems';

const SectionSwitch = ({
  currentSection,
  setCurrentSection,
  getConsolidationData,
  currentConsolidation,
  onChangeFilter,
  setOpenPanel,
  handledOnSave,
  getData,
  isBusy,
  inputs,
  setInputs,
  handleOnBulk,
  selection,
  setSelection,
  isDissociating,
  handledOnClose,
  valueProductGetter,
  valueDailyLogGetter,
  openImportModal,
}) => {
  switch (currentSection) {
    case 'FORM_CONSOLIDATED_CARGO':
      return (
        <FormConsolidatedCargo
          setCurrentSection={setCurrentSection}
          getConsolidationData={getConsolidationData}
          currentConsolidation={currentConsolidation}
          onChangeFilter={onChangeFilter}
          setOpenPanel={setOpenPanel}
          getData={getData}
          handledOnSave={handledOnSave}
          isBusy={isBusy}
          inputs={inputs}
          setInputs={setInputs}
          handleOnBulk={handleOnBulk}
          selection={selection}
          setSelection={setSelection}
          isDissociating={isDissociating}
          handledOnClose={handledOnClose}
          valueProductGetter={valueProductGetter}
          valueDailyLogGetter={valueDailyLogGetter}
        />
      );
    case 'IMPORT_SHIPPED_ITEMS':
      return (
        <ImportShippedItems
          setCurrentSection={setCurrentSection}
          currentConsolidation={currentConsolidation}
          getData={getData}
          handledOnSave={handledOnSave}
          getConsolidationData={getConsolidationData}
          setOpenPanel={setOpenPanel}
          isBusy={isBusy}
          inputs={inputs}
          setInputs={setInputs}
          handleOnBulk={handleOnBulk}
          selection={selection}
          setSelection={setSelection}
          isDissociating={isDissociating}
          modalOpened={openImportModal}
        />
      );
    case 'VALIDATE_SHIPPED_ITEMS':
      return (
        <ValidateShippedItems
          currentConsolidation={currentConsolidation}
          isDissociating={isDissociating}
          isBusy={isBusy}
          selection={selection}
          setSelection={setSelection}
          handleOnBulk={handleOnBulk}
          valueProductGetter={valueProductGetter}
          setCurrentSection={setCurrentSection}
        />
      );
    default:
      return (
        <FormConsolidatedCargo
          setCurrentSection={setCurrentSection}
          getConsolidationData={getConsolidationData}
          currentConsolidation={currentConsolidation}
          onChangeFilter={onChangeFilter}
          setOpenPanel={setOpenPanel}
          getData={getData}
          handledOnSave={handledOnSave}
          isBusy={isBusy}
          inputs={inputs}
          setInputs={setInputs}
          handleOnBulk={handleOnBulk}
          selection={selection}
          setSelection={setSelection}
          isDissociating={isDissociating}
          valueProductGetter={valueProductGetter}
          valueDailyLogGetter={valueDailyLogGetter}
        />
      );
  }
};

export default SectionSwitch;
