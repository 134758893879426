export const options = [
    { key: 'Draft', color: 'grey' },
    { key: 'Sent', color: 'olive' },
    { key: 'Associated', color: 'yellow' }
].map(item => ({
    key: item.key.toUpperCase(),
    text: item.key,
    value: item.key.toUpperCase(),
    icon: {
        name: 'circle',
        color: item.color
    }
}));

export default options[0].key;