export const allowedStores = (data) => [
  ...new Set(data.map((item) => item.from_name)),
];

export const allowedData = (data) =>
  data.filter(
    (item) =>
      item.status === 'pending' &&
      (item.from_name === 'La tiendita' || item.from_name === 'Store Wars')
  );

export const allowedCities = (data) => [
  ...new Set(
    data.map(
      (item) => item && item.to_address && item.to_address.city.toLowerCase()
    )
  ),
];

export const getOnlyIds = (data) => [
  ...new Set(data.map((item) => item.order_number)),
];
