import React, { Component, forwardRef } from 'react';
import DatePickerReact from 'react-datepicker';
import { Icon } from 'semantic-ui-react';
import SemanticInput from './SemanticInput';
import moment from 'moment';
const formatMongo = 'YYYY-MM-DDTHH:mm:00.00';
export const formatGrid = 'MM/DD/YYYY';
export const formatTimeGrid = formatGrid + ' HH:mm:ss';

const CustomInput = forwardRef(({ value, onClear, onClick, ...other }, ref) => {
  return (
    <SemanticInput
      {...other}
      icon={
        <Icon
          name={value ? 'close' : 'calendar'}
          link
          onClick={() => {
            if (value) {
              onClear();
            } else {
              onClick();
            }
          }}
        />
      }
      iconPosition="left"
      value={value}
      onClick={onClick}
      ref={ref}
    />
  );
});

class DatePicker extends Component {
  constructor(props) {
    super(props);
    this.inputRef = React.createRef();
    this.focus = this.focus.bind(this);

    this.state = {
      selected: this.toDate(this.props.selected),
    };
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.selected !== this.props.selected) {
      const selected = this.toDate(this.props.selected);

      this.handleChange(selected);
    }
  }

  handleChange(date) {
    this.setState({ selected: date });

    this.props.onChange(convertDateMongo(date) || null);
  }

  toDate(date) {
    return date && moment(date, formatMongo).toDate();
  }

  focus() {
    if (
      this.inputRef &&
      this.inputRef.current &&
      this.inputRef.current.input &&
      this.inputRef.current.input.focus
    ) {
      this.inputRef.current.input.focus();
    }
  }

  render() {
    const {
      style,
      startDate,
      endDate,
      minDate,
      maxDate,
      InputProps = {},
      dateFormat,
      timeCaption,
      timeFormat,
      ...other
    } = this.props;
    const { selected } = this.state;
    const defaultProps = {
      ...(selected && { selected: selected }),
      ...(startDate && { startDate: this.toDate(startDate) }),
      ...(endDate && { endDate: this.toDate(endDate) }),
      ...(minDate && { minDate: this.toDate(minDate) }),

      maxDate: this.toDate(maxDate || moment()),
      dateFormat:
        dateFormat ||
        (other.showTimeSelect ? 'MM/dd/yyyy h:mm aa' : 'MM/dd/yyyy'),
      timeCaption: timeCaption || 'Time',
      timeFormat: timeFormat || 'h:mm aa',
    };

    return (
      <div style={style}>
        <DatePickerReact
          {...other}
          {...defaultProps}
          ref={this.inputRef}
          // locale="en-US"
          onChange={(date) => this.handleChange(date)}
          customInput={
            <CustomInput {...InputProps} onClear={(_) => this.handleChange()} />
          }
        />
      </div>
    );
  }
}

export const convertDateMongo = (date) =>
  date && `${moment(date).format(formatMongo)}`;

// export default forwardRef((props, ref) => <DatePicker {...props} innerRef={ref} />);
export default DatePicker;
