import React from 'react';

import DropdownSearchFetch from '../../../components/Formik/DropdownSearchFetch';

function DropdownColonia({ formik, values, names, errors }) {
  let { setFieldValue } = formik;
  return (
    <DropdownSearchFetch
      fluid
      selection
      label="Colonia"
      value={values.Colonia}
      allowAdditions={values.Pais !== 'MEX'}
      onChange={(e, { value }) => setFieldValue(names.Colonia, value)}
      name={names.Colonia}
      valuesChangesToUpdate={[
        values.Pais,
        values.Estado,
        values.Municipios,
        values.Localidad,
        values.CodigoPostal,
        values.Colonia,
      ]}
      placeholder="Colonia"
      featherService="catalogo-colonia"
      columName="Nombre"
      columValue="c_Colonia"
      mapValues={(value) => {
        return {
          key: Math.random(),
          text: `${value['Nombre']}`,
          value: value['c_Colonia'],
        };
      }}
      queryWithSearch={(columName, columValue, searchQuery) => {
        let newQuery = {
          $or: [
            {
              [columName]: {
                $like: '%' + searchQuery + '%',
              },
            },
            {
              [columValue]: {
                $like: '%' + searchQuery + '%',
              },
            },
          ],
          c_CodigoPostal: values.CodigoPostal,
          $limit: 10,
        };

        return newQuery;
      }}
      queryInit={(columName, columValue, value) => {
        let newQuery;
        if (value) {
          newQuery = {
            [columValue]: { $like: value },
            c_CodigoPostal: values.CodigoPostal,
            $limit: 1,
          };
        } else {
          newQuery = {
            $limit: 10,
            c_CodigoPostal: values.CodigoPostal,
          };
        }

        return newQuery;
      }}
    />
  );
}

export default DropdownColonia;
