import React, { forwardRef } from 'react';
import {
  Form,
  Segment,
  Header,
  Divider,
  Checkbox,
  Label,
} from 'semantic-ui-react';
import styled from 'styled-components';
import DropdownSearchFetch from '../../../components/Formik/DropdownSearchFetch';
import Dropdown from '../../../components/Formik/Dropdown';

const FormConcept = ({
  formik,
  isCartaPorte,
  isMaterialPeligroso,
  setIsMaterialPeligroso,
  concepto,
  index,
}) => {
  let { values, errors, touched, handleChange, handleBlur, setFieldValue } =
    formik;

  return (
    <div key={index} className="ui form">
      <Form.Group widths={'equal'}>
        <Dropdown
          isDisabled={values.idTipoComprobante === 'T' ? true : false}
          fluid
          selection
          label="Tipo de Concepto"
          value={
            values.idTipoComprobante === 'T'
              ? (concepto.TipoConcepto = 2)
              : concepto.TipoConcepto
          }
          onChange={(e, { value }) => {
            setFieldValue(`conceptos.${index}.TipoConcepto`, value);
          }}
          placeholder="Tipo de Concepto"
          options={
            values.idTipoComprobante === 'I'
              ? [
                  { key: 0, value: 0, text: 'Ingreso' },
                  { key: 1, value: 1, text: 'Traslado' },
                ]
              : [
                  { key: 0, value: 0, text: 'Ingreso' },
                  { key: 1, value: 1, text: 'Traslado' },
                  {
                    key: 2,
                    value: 2,
                    text: 'Conceptos/Traslados',
                  },
                ]
          }
          onBlur={handleBlur}
          error={
            errors.conceptos &&
            errors.conceptos[index] &&
            errors.conceptos[index].TipoConcepto &&
            touched.conceptos &&
            touched.conceptos[index] &&
            touched.conceptos[index].TipoConcepto && {
              content:
                errors.conceptos[index] && errors.conceptos[index].TipoConcepto,
            }
          }
        />

        <Form.Input
          placeholder="Descripción"
          label="Descripción"
          name={`conceptos.${index}.Descripcion`}
          value={concepto.Descripcion}
          onChange={handleChange}
          onBlur={handleBlur}
          error={
            errors.conceptos &&
            errors.conceptos[index] &&
            errors.conceptos[index].Descripcion &&
            touched.conceptos &&
            touched.conceptos[index] &&
            touched.conceptos[index].Descripcion && {
              content:
                errors.conceptos[index] && errors.conceptos[index].Descripcion,
            }
          }
        />
        <DropdownSearchFetch
          fluid
          selection
          label="Código Producto/Servicio"
          value={concepto.ClaveProdServ}
          onChange={(e, { value }) => {
            setFieldValue(`conceptos.${index}.ClaveProdServ`, value);
          }}
          placeholder="Código Producto/Servicio"
          featherService={
            concepto.TipoConcepto === 2
              ? 'catalogo-clave-prod-serv-cp'
              : 'catalogo-clave-prod-serv'
          }
          columName="Descripcion"
          columValue={
            concepto.TipoConcepto === 2
              ? 'c_ClaveProdServCP'
              : 'c_ClaveProdServ'
          }
          queryInit={(columName, columValue, value) => {
            let newQuery;
            if (value) {
              newQuery = {
                [columValue]: { $like: value },
                $limit: 10,
              };
            } else {
              if (concepto.TipoConcepto === 0) {
                newQuery = {
                  [columValue]: {
                    $in: [
                      78101500, 78101501, 78101502, 78101503, 78101600,
                      78101601, 78101602, 78101603, 78101604, 78101700,
                      78101701, 78101702, 78101703, 78101704, 78101705,
                      78101706, 78101800, 78101801, 78101802, 78101803,
                      78101804, 78101806, 78101807, 78101900, 78101901,
                      78101902, 78101903, 78101904, 78101905, 78102200,
                      78102201, 78102203, 78102204, 78102205, 78121603,
                      78141500, 78141501, 84121806, 92121800, 92121801,
                      92121802,
                    ],
                  },
                  $limit: 10,
                };
              } else {
                newQuery = {
                  $limit: 10,
                };
              }
            }

            return newQuery;
          }}
          queryWithSearch={(columName, columValue, searchQuery) => {
            let newQuery;
            if (concepto.TipoConcepto === 0) {
              newQuery = {
                [columValue]: {
                  $in: [
                    78101500, 78101501, 78101502, 78101503, 78101600, 78101601,
                    78101602, 78101603, 78101604, 78101700, 78101701, 78101702,
                    78101703, 78101704, 78101705, 78101706, 78101800, 78101801,
                    78101802, 78101803, 78101804, 78101806, 78101807, 78101900,
                    78101901, 78101902, 78101903, 78101904, 78101905, 78102200,
                    78102201, 78102203, 78102204, 78102205, 78121603, 78141500,
                    78141501, 84121806, 92121800, 92121801, 92121802,
                  ],
                },
                $limit: 10,
              };
            } else {
              newQuery = {
                $or: [
                  { [columName]: { $like: '%' + searchQuery + '%' } },
                  { [columValue]: { $like: '%' + searchQuery + '%' } },
                ],
                $limit: 10,
              };
            }

            return newQuery;
          }}
          mapValues={(value) => {
            return {
              key: Math.random(),
              text: `${
                value[
                  concepto.TipoConcepto === 2
                    ? 'c_ClaveProdServCP'
                    : 'c_ClaveProdServ'
                ]
              } - ${value['Descripcion']}`,
              value:
                value[
                  concepto.TipoConcepto === 2
                    ? 'c_ClaveProdServCP'
                    : 'c_ClaveProdServ'
                ],
            };
          }}
          error={
            errors.conceptos &&
            errors.conceptos[index] &&
            errors.conceptos[index].ClaveProdServ &&
            touched.conceptos &&
            touched.conceptos[index] &&
            touched.conceptos[index].ClaveProdServ && {
              content:
                errors.conceptos[index] &&
                errors.conceptos[index].ClaveProdServ,
            }
          }
        />
      </Form.Group>
      <Form.Group widths={'equal'}>
        <DropdownSearchFetch
          fluid
          selection
          label="Unidad de Medida"
          value={concepto.ClaveUnidad}
          onChange={(e, data) => {
            let UnidadMedida = data.options.find((o) => o.value === data.value);
            setFieldValue(`conceptos.${index}.ClaveUnidad`, data.value);

            if (UnidadMedida && UnidadMedida.text) {
              setFieldValue(
                `conceptos.${index}.UnidadMedida`,
                UnidadMedida.text
              );
            }
          }}
          placeholder="Unidad de Medida"
          featherService="catalogo-clave-unidad"
          columName="Nombre"
          columValue="c_ClaveUnidad"
          mapValues={(value) => {
            return {
              key: Math.random(),
              text: `${value['c_ClaveUnidad']} - ${value['Nombre']}`,
              value: value['c_ClaveUnidad'],
            };
          }}
          error={
            errors.conceptos &&
            errors.conceptos[index] &&
            errors.conceptos[index].UnidadMedida &&
            touched.conceptos &&
            touched.conceptos[index] &&
            touched.conceptos[index].UnidadMedida && {
              content:
                errors.conceptos[index] && errors.conceptos[index].UnidadMedida,
            }
          }
        />
        <Form.Input
          type="number"
          min={1}
          placeholder="Cantidad"
          label="Cantidad"
          name={`conceptos.${index}.Cantidad`}
          value={concepto.Cantidad}
          onChange={handleChange}
          onBlur={handleBlur}
          // disabled={
          //   values.idTipoComprobante === 'T' ||
          //   concepto.TipoConcepto !== 0
          //}
          error={
            errors.conceptos &&
            errors.conceptos[index] &&
            errors.conceptos[index].Cantidad &&
            touched.conceptos &&
            touched.conceptos[index] &&
            touched.conceptos[index].Cantidad && {
              content:
                errors.conceptos[index] && errors.conceptos[index].Cantidad,
            }
          }
        />
        <Form.Input
          type="number"
          min={0}
          placeholder="Precio Unitario"
          label="Precio Unitario"
          name={`conceptos.${index}.PrecioUnitario`}
          value={
            concepto.TipoConcepto !== 0
              ? (concepto.PrecioUnitario = 0.0)
              : concepto.PrecioUnitario
          }
          onChange={handleChange}
          onBlur={handleBlur}
          disabled={
            values.idTipoComprobante === 'T' || concepto.TipoConcepto !== 0
          }
          error={
            errors.conceptos &&
            errors.conceptos[index] &&
            errors.conceptos[index].PrecioUnitario &&
            touched.conceptos &&
            touched.conceptos[index] &&
            touched.conceptos[index].PrecioUnitario && {
              content:
                errors.conceptos[index] &&
                errors.conceptos[index].PrecioUnitario,
            }
          }
        />
        {values.idTipoComprobante === 'I' && concepto.TipoConcepto === 0 && (
          <Dropdown
            // isDisabled={
            //   values.idTipoComprobante === 'T' ? true : false
            // }
            fluid
            selection
            label="Perfil de Impuestos"
            value={concepto.PerfilImpuestos ? concepto.PerfilImpuestos : 0}
            onChange={(e, { value }) => {
              setFieldValue(`conceptos.${index}.PerfilImpuestos`, value);
            }}
            placeholder="Perfil de Impuestos"
            options={[
              {
                key: 0,
                value: 0,
                text: 'Traslado IVA Tasa 16%',
              },
              {
                key: 1,
                value: 1,
                text: 'Traslado IVA Tasa 8%',
              },
              {
                key: 2,
                value: 2,
                text: 'Traslado IVA Tasa 0%',
              },
              { key: 3, value: 3, text: 'Excento de IVA' },
            ]}
            onBlur={handleBlur}
            error={
              errors.conceptos &&
              errors.conceptos[index] &&
              errors.conceptos[index].PerfilImpuestos &&
              touched.conceptos &&
              touched.conceptos[index] &&
              touched.conceptos[index].PerfilImpuestos && {
                content:
                  errors.conceptos[index] &&
                  errors.conceptos[index].PerfilImpuestos,
              }
            }
          />
        )}
        {/* <Form.Input
          type="number"
          min={0}
          placeholder="Subtotal"
          label="Subtotal"
          name={`conceptos.${index}.Subtotal`}
          value={
            concepto.TipoConcepto === 0
              ? (concepto.Subtotal =
                  concepto.Cantidad * concepto.PrecioUnitario)
              : (concepto.Subtotal = 0.0)
          }
          onChange={handleChange}
          onBlur={handleBlur}
          disabled={true}
          step=".01"
          error={
            errors.conceptos &&
            errors.conceptos[index] &&
            errors.conceptos[index].Subtotal &&
            touched.conceptos &&
            touched.conceptos[index] &&
            touched.conceptos[index].Subtotal && {
              content:
                errors.conceptos[index] &&
                errors.conceptos[index].Subtotal,
            }
          }
        /> */}
        <Form.Field>
          <label>Subtotal</label>
          <Label>
            {concepto.TipoConcepto === 0
              ? (concepto.Subtotal =
                  concepto.Cantidad * concepto.PrecioUnitario)
              : (concepto.Subtotal = 0.0)}
          </Label>
        </Form.Field>
        <Form.Input
          placeholder="Número de Identificación"
          label="Número de Identificación"
          name={`conceptos.${index}.NumeroIdentificacionComercio`}
          value={concepto.NumeroIdentificacionComercio}
          onChange={handleChange}
          onBlur={handleBlur}
          disabled={
            values.idTipoComprobante === 'T' || concepto.TipoConcepto !== 0
          }
          //   error={
          //     validations.name && {
          //       content: 'Required idTipoComprobante',
          //     }
          //   }
        />
      </Form.Group>
      {/* <Form.Group widths={'equal'}>
        {values.idTipoComprobante === 'I' && (
          <>
            {' '}
            <Form.Input
              placeholder="Impuestos Traslados"
              label="Impuestos Traslados"
              name={`conceptos.${index}.ImpuestosTraslados`}
              value={
                concepto.TipoConcepto === 0
                  ? (concepto.ImpuestosTraslados =
                      (concepto.Subtotal * concepto.Valor) / 100)
                  : (concepto.ImpuestosTraslados = 0)
              }
              onChange={handleChange}
              onBlur={handleBlur}
              disabled={true}
              //   error={
              //     validations.name && {
              //       content: 'Required idTipoComprobante',
              //     }
              //   }
            />
            <Form.Input
              placeholder="Impuestos Retenciones"
              label="Impuestos Retenciones"
              name={`conceptos.${index}.ImpuestosRetenciones`}
              value={concepto.ImpuestosRetenciones}
              onChange={handleChange}
              onBlur={handleBlur}
              disabled={true}
              //   error={
              //     validations.name && {
              //       content: 'Required idTipoComprobante',
              //     }
              //   }
            />
          </>
        )}
      </Form.Group> */}
      <Form.Group widths={'equal'}>
        {/* <Form.Input
          type="number"
          min={0}
          placeholder="ImporteBase"
          label="ImporteBase"
          name={`conceptos.${index}.ImporteBase`}
          value={
            concepto.TipoConcepto === 0
              ? (concepto.ImporteBase = concepto.Subtotal)
              : (concepto.ImporteBase = 0)
          }
          onChange={handleChange}
          onBlur={handleBlur}
          disabled={true}
          error={
            errors.conceptos &&
            errors.conceptos[index] &&
            errors.conceptos[index].ImporteBase &&
            touched.conceptos &&
            touched.conceptos[index] &&
            touched.conceptos[index].ImporteBase && {
              content:
                errors.conceptos[index] &&
                errors.conceptos[index].ImporteBase,
            }
          }
        /> */}
        <Form.Field>
          <label>Importe Base</label>
          <Label>
            {concepto.TipoConcepto === 0
              ? (concepto.ImporteBase = concepto.Subtotal)
              : (concepto.ImporteBase = 0)}
          </Label>
        </Form.Field>
        {/* <Form.Input
          type="number"
          min={0}
          placeholder="Valor %"
          label="Valor %"
          name={`conceptos.${index}.Valor`}
          value={
            concepto.PerfilImpuestos === 0
              ? (concepto.Valor = 16)
              : concepto.PerfilImpuestos === 1
              ? (concepto.Valor = 8)
              : concepto.PerfilImpuestos === 2
              ? (concepto.Valor = 0)
              : concepto.PerfilImpuestos === 3 &&
                (concepto.Valor = 0)
          }
          onChange={handleChange}
          onBlur={handleBlur}
          disabled={true}
          error={
            errors.conceptos &&
            errors.conceptos[index] &&
            errors.conceptos[index].Valor &&
            touched.conceptos &&
            touched.conceptos[index] &&
            touched.conceptos[index].Valor && {
              content:
                errors.conceptos[index] &&
                errors.conceptos[index].Valor,
            }
          }
        /> */}
        <Form.Field>
          <label>Valor %</label>
          <Label>
            {concepto.PerfilImpuestos === 0
              ? (concepto.Valor = 16)
              : concepto.PerfilImpuestos === 1
              ? (concepto.Valor = 8)
              : concepto.PerfilImpuestos === 2
              ? (concepto.Valor = 0)
              : concepto.PerfilImpuestos === 3 && (concepto.Valor = 0)}
          </Label>
        </Form.Field>
        {/* <Form.Input
          type="decimal"
          min={0}
          placeholder="Impuesto"
          label="Impuesto"
          name={`conceptos.${index}.Importe`}
          value={
            concepto.TipoConcepto === 0
              ? (concepto.Importe =
                  (concepto.Subtotal * concepto.Valor) / 100)
              : (concepto.Importe = 0)
          }
          onChange={handleChange}
          onBlur={handleBlur}
          disabled={true}
          step=".01"
          error={
            errors.conceptos &&
            errors.conceptos[index] &&
            errors.conceptos[index].Importe &&
            touched.conceptos &&
            touched.conceptos[index] &&
            touched.conceptos[index].Importe && {
              content:
                errors.conceptos[index] &&
                errors.conceptos[index].Importe,
            }
          }
        /> */}
        <Form.Field>
          <label>Impuesto</label>
          <Label>
            {concepto.TipoConcepto === 0
              ? (concepto.Importe = (concepto.Subtotal * concepto.Valor) / 100)
              : (concepto.Importe = 0)}
          </Label>
        </Form.Field>
        {/* <Form.Input
          placeholder="CodigoSAT"
          label="CodigoSAT"
          name={`conceptos.${index}.CodigoSAT`}
          value={concepto.CodigoSAT}
          onChange={handleChange}
          onBlur={handleBlur}
          disabled={true}
          //   error={
          //     validations.name && {
          //       content: 'Required idTipoComprobante',
          //     }
          //   }
        /> */}
        <Form.Field>
          <label>Código SAT</label>
          <Label>{concepto.CodigoSAT ? concepto.CodigoSAT : '002'}</Label>
        </Form.Field>
        {/* <Form.Input
          type="decimal"
          min={0}
          placeholder="Total"
          label="Total"
          name={`conceptos.${index}.Total`}
          step=".01"
          value={
            concepto.TipoConcepto === 0
              ? (concepto.Total =
                  concepto.Subtotal + concepto.Importe)
              : (concepto.Total = 0)
          }
          onChange={handleChange}
          onBlur={handleBlur}
          disabled={true}
          error={
            errors.conceptos &&
            errors.conceptos[index] &&
            errors.conceptos[index].Total &&
            touched.conceptos &&
            touched.conceptos[index] &&
            touched.conceptos[index].Total && {
              content:
                errors.conceptos[index] &&
                errors.conceptos[index].Total,
            }
          }
        /> */}
        <Form.Field>
          <label>Total</label>
          <Label>
            {concepto.TipoConcepto === 0
              ? (concepto.Total = concepto.Subtotal + concepto.Importe)
              : (concepto.Total = 0)}
          </Label>
        </Form.Field>
      </Form.Group>

      {concepto.TipoConcepto !== 0 && isCartaPorte && (
        <>
          <Divider horizontal>Carta Porte</Divider>
          <Form.Group widths={'equal'}>
            <DropdownSearchFetch
              fluid
              selection
              label="Fracción Arancelaria"
              value={concepto.FraccionArancelariaComercio}
              onChange={(e, { value }) =>
                setFieldValue(
                  `conceptos.${index}.FraccionArancelariaComercio`,
                  value
                )
              }
              placeholder="Fracción Arancelaria"
              featherService="catalogo-fraccion-arancelaria"
              columName="Descripcion"
              columValue="c_FraccionArancelaria"
              mapValues={(value) => {
                return {
                  key: Math.random(),
                  text: `${value['c_FraccionArancelaria']} - ${value['Descripcion']}`,
                  value: value['c_FraccionArancelaria'],
                };
              }}
              error={
                errors.conceptos &&
                errors.conceptos[index] &&
                errors.conceptos[index].FraccionArancelariaComercio &&
                touched.conceptos &&
                touched.conceptos[index] &&
                touched.conceptos[index].FraccionArancelariaComercio && {
                  content:
                    errors.conceptos[index] &&
                    errors.conceptos[index].FraccionArancelariaComercio,
                }
              }
            />
            <Form.Input
              type="number"
              min={0}
              placeholder="Peso en Kgs"
              label="Peso en Kgs"
              name={`conceptos.${index}.PesoEnKg`}
              value={concepto.PesoEnKg}
              onChange={handleChange}
              onBlur={handleBlur}
              error={
                errors.conceptos &&
                errors.conceptos[index] &&
                errors.conceptos[index].PesoEnKg &&
                touched.conceptos &&
                touched.conceptos[index] &&
                touched.conceptos[index].PesoEnKg && {
                  content:
                    errors.conceptos[index] && errors.conceptos[index].PesoEnKg,
                }
              }
            />
            <Form.Input
              type="number"
              min={0}
              placeholder="Valor Mercancía"
              label="Valor Mercancía"
              name={`conceptos.${index}.ValorMercancia`}
              value={concepto.ValorMercancia}
              onChange={handleChange}
              onBlur={handleBlur}
              error={
                errors.conceptos &&
                errors.conceptos[index] &&
                errors.conceptos[index].ValorMercancia &&
                touched.conceptos &&
                touched.conceptos[index] &&
                touched.conceptos[index].ValorMercancia && {
                  content:
                    errors.conceptos[index] &&
                    errors.conceptos[index].ValorMercancia,
                }
              }
            />
            <DropdownSearchFetch
              fluid
              selection
              label="Moneda"
              value={concepto.MonedaMercancia}
              onChange={(e, { value }) =>
                setFieldValue(`conceptos.${index}.MonedaMercancia`, value)
              }
              placeholder="Moneda"
              find
              featherService="catalogo-moneda"
              columName="Descripcion"
              columValue="c_Moneda"
              mapValues={(value) => {
                return {
                  key: Math.random(),
                  text: `${value['c_Moneda']} - ${value['Descripcion']}`,
                  value: value['c_Moneda'],
                };
              }}
              error={
                errors.conceptos &&
                errors.conceptos[index] &&
                errors.conceptos[index].MonedaMercancia &&
                touched.conceptos &&
                touched.conceptos[index] &&
                touched.conceptos[index].MonedaMercancia && {
                  content:
                    errors.conceptos[index] &&
                    errors.conceptos[index].MonedaMercancia,
                }
              }
            />
          </Form.Group>
          {true && (
            <>
              <Form.Field>
                <label>Material Peligroso</label>
                <Checkbox
                  toggle
                  onChange={(e, { checked }) => setIsMaterialPeligroso(checked)}
                />
              </Form.Field>
              {isMaterialPeligroso && (
                <Form.Group widths={'equal'}>
                  <DropdownSearchFetch
                    fluid
                    selection
                    label="Material Peligroso"
                    value={concepto.CveMaterialPeligroso}
                    onChange={(e, { value }) =>
                      setFieldValue(
                        `conceptos.${index}.CveMaterialPeligroso`,
                        value
                      )
                    }
                    placeholder="Material Peligroso"
                    featherService="catalogo-material-peligroso"
                    columName="Descripcion"
                    columValue="c_MaterialPeligroso"
                    mapValues={(value) => {
                      return {
                        key: Math.random(),
                        text: `${value['c_MaterialPeligroso']} - ${value['Descripcion']}`,
                        value: value['c_MaterialPeligroso'],
                      };
                    }}
                  />
                  <DropdownSearchFetch
                    fluid
                    selection
                    label="Embalaje"
                    value={concepto.Embalaje}
                    onChange={(e, { value }) =>
                      setFieldValue(`conceptos.${index}.Embalaje`, value)
                    }
                    placeholder="Embalaje"
                    disabled={concepto.CveMaterialPeligroso === '' && true}
                    featherService="catalogo-tipo-embalaje"
                    columName="Descripcion"
                    columValue="c_TipoEmbalaje"
                    mapValues={(value) => {
                      return {
                        key: Math.random(),
                        text: `${value['c_TipoEmbalaje']} - ${value['Descripcion']}`,
                        value: value['c_TipoEmbalaje'],
                      };
                    }}
                  />
                  <Form.Input
                    placeholder="Descripción Embalaje"
                    disabled={concepto.CveMaterialPeligroso === '' && true}
                    label="Descripción Embalaje"
                    name={`conceptos.${index}.EmbalajeDescripcion`}
                    value={concepto.EmbalajeDescripcion}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    //   error={
                    //     validations.name && {
                    //       content: 'Required idTipoComprobante',
                    //     }
                    //   }
                  />
                </Form.Group>
              )}
              <Form.Group widths={'equal'}>
                <Form.Input
                  placeholder="UUID CFDI Comercio Exterior"
                  label="UUID CFDI Comercio Exterior"
                  name={`conceptos.${index}.UUIDComercioExterior`}
                  value={concepto.UUIDComercioExterior}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  //   error={
                  //     validations.name && {
                  //       content: 'Required idTipoComprobante',
                  //     }
                  //   }
                />
                <Form.Input
                  placeholder="Pedimentos"
                  label="Pedimentos"
                  name={`conceptos.${index}.Pedimento`}
                  value={concepto.Pedimento}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  //   error={
                  //     validations.name && {
                  //       content: 'Required idTipoComprobante',
                  //     }
                  //   }
                />
              </Form.Group>
            </>
          )}
        </>
      )}
    </div>
  );
};

export default forwardRef((props, ref) => (
  <FormConcept {...props} innerRef={ref} />
));
