import React from 'react';
import { Dropdown } from 'semantic-ui-react';
import styled from 'styled-components';

const SemanticDropdown = (props) => {
  let { label } = props;
  return (
    <GeneralContainer>
      <Label>{label || 'Select an option'}</Label>
      <Dropdown {...props} />
    </GeneralContainer>
  );
};

const GeneralContainer = styled.div``;

const Label = styled.label`
  display: block;
  color: rgba(0, 0, 0, 0.87);
  text-transform: none;
`;

export default SemanticDropdown;
