import React from 'react';
import Routes from './Routes'
import { ProvideAuth } from './authentication';

export default props => {
  return (
    <ProvideAuth>
      <Routes />
    </ProvideAuth>
  );
};