import template321 from '../../Consolidations/Drawer/FormConsolidatedCargo/function/321Template';

export default data => {
  const shippedItems = data.reduce((acc, productOrigin) => {
    const product = { ...productOrigin };
    const { shippeditems } = product;
    delete product.shippeditems;

    return [
      ...acc,

      ...shippeditems.map(item => ({
        ...item,

        product: [{ ...product }]
      }))
    ];
  }, []);

  // console.debug('shippedItems', shippedItems.length);
  template321(shippedItems);
};