import React, { useEffect, useReducer } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import DrawerFiltersWrapper from '../../components/DrawerFiltersWrapper';
import moment from 'moment';
import DateRangePicker from '../../components/DateRangePicker';
import ButtonsFilter from '../../components/ButtonsFilter';

const queryInit = {
  actualDate: {
    $gte: moment().startOf('day'),
    $lte: moment().endOf('day')
  }
};

const reducerQuery = (prevState, { name, value, type, query }) => {
  if (type === 'init' && query) {
    return query;
  } else if (name) {
    return { ...prevState, [name]: value }
  }

  return prevState;
};

const Filters = ({
  isBusy,
  query: base,
  setQuery
}) => {
  // States
  const [query, dispatchQuery] = useReducer(reducerQuery, { ...queryInit });

  // Effects
  useEffect(() => {
    // console.count('Filters query');
    const sendQuery = () => {
      handleSearchClick()
    }

    sendQuery();
  }, []);

  // Handles
  const handleSearchClick = () => {
    // Create query for feathers
    const queryMap = {
      ...query
    };
    // console.debug('queryMap', JSON.stringify(queryMap, null, 2));

    // Send query to Dailylogs
    setQuery && setQuery(queryMap);
  };

  const handleClearClick = (event, data) => {
    dispatchQuery({
      type: 'init',
      query: {
        ...queryInit,

        actualDate: {
          $gte: moment().startOf('day'),
          $lte: moment().endOf('day')
        }
      }
    });
  };

  return (
    <>
      {/* <pre>query: {JSON.stringify(query, null, 2)}</pre> */}

      <DrawerFiltersWrapper>
        <FiltersGrid>
          <DateRangePicker
            name="actualDate"
            label="Date"
            timePicker={false}
            startDate={query.actualDate.$gte}
            endDate={query.actualDate.$lte}
            maxDate={moment()}

            onChange={([$gte, $lte]) => {
              dispatchQuery({
                name: 'actualDate',
                value: { $gte, $lte }
              });
            }}

            style={{ gridArea: 'range' }}
            disabled={isBusy}
          />

          <ButtonsFilter
            isBusy={isBusy}
            onSearchClick={handleSearchClick}
            onClearClick={handleClearClick}
          />
        </FiltersGrid>
      </DrawerFiltersWrapper>
    </>
  );
};

const FiltersGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-template-rows: auto;
  grid-gap: 1em;
  grid-template-areas: 'range range . buttons';
`;

Filters.propTypes = {
  isBusy: PropTypes.bool,
  query: PropTypes.object,
  setQuery: PropTypes.func
};

export default Filters;