import React from 'react';
import { Button } from 'semantic-ui-react';


const TopNavigation = ({ openPanel, setOpenPanel, setDrawerTitle }) => {
  const handleCreateConsolidation = () => {
    setDrawerTitle('Create new');
    setOpenPanel(!openPanel);
  }

  return (
    <Button primary compact onClick={handleCreateConsolidation}>
      Create Consolidation
    </Button>
  );
};

export default TopNavigation;
