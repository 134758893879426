import React from 'react';
import { Link } from 'react-router-dom';
import { Dropdown, Item } from 'semantic-ui-react';
import styled, { css } from 'styled-components';
import '../styles/styles.scss';
export default (props) => {
  return props.menuCategory === props.itemCategory ? (
    <Myspan>
      <Item
        as={Link}
        to={props.linkTo}
        className={props.active ? 'item-child' : 'item-child-2'}
      >
        {props.title}
      </Item>
    </Myspan>
  ) : null;
};

const Myspan = styled.div``;
