import React, { forwardRef, useImperativeHandle, useRef } from 'react';
import { Ref, Form } from 'semantic-ui-react';

const SemanticInput = ({ innerRef, ...others }) => {
  const focus = () => {
    const input = findInput(refInput.current);

    // console.debug('SemanticInput', refInput, input);
    if (input) {
      input.focus();
    }
  }

  const findInput = (ref) => {
    let ret = null;
    if (ref && ref.nodeType === 1) {
      if (ref.nodeName === 'INPUT') {
        ret = ref;
      }

      if (ref.children && ref.children.length > 0) {
        [...ref.children].forEach((current, _, array) => {
          ret = ret || findInput(current);

          if (ret) {
            array.splice(1);
          }
        });
      }
    }

    return ret;
  }

  const refInput = useRef(null);
  useImperativeHandle(innerRef, () => ({
    focus: () => {
      focus();
    }
  }));

  return (
    <Ref innerRef={refInput}>
      <Form.Input {...others} />
    </Ref>
  );
};

export default forwardRef((props, ref) => <SemanticInput {...props} innerRef={ref} />);