import React, { useState, useEffect } from 'react';
import { Label } from 'semantic-ui-react';
import PropTypes from 'prop-types';
import SlidingPanel from '@bit/softwareland.flex.sl-sliding-panel';
import swal from 'sweetalert';
import Actions from './Actions';
import Form from './Form';
import Table from '../Table';
import feathers from '../../../services/feathers';
import DrawerFiltersWrapper from '../../../components/DrawerFiltersWrapper';
import styled from 'styled-components';

const Drawer = ({
  openPanel,
  onClose,
  dailyLog,
  lookupDailyLog,
  todayData,
}) => {
  const [current] = useState({ ...dailyLog });
  const [data, setData] = useState(todayData ? todayData : []);
  const [currentSection] = useState(dailyLog._id ? 'EDIT' : 'CREATE');
  const [isBusy, setIsBusy] = useState(false);
  const service = feathers.service('bin');
  const [binCounter, setBinCounter] = useState([]);
  const [binData, setBinData] = useState([]);

  useEffect(() => {
    let tempCount = {};
    data.map((log) => {
      !tempCount[log.bin] ? (tempCount[log.bin] = 1) : tempCount[log.bin]++;
    });

    setBinCounter(tempCount);
  }, []);

  useEffect( () => {
    const funcAsync =  async () => {
      let bins = await service.find({});
      setBinData(bins.data && bins.data.length ? bins.data : []);
    }
    funcAsync();
  }, []);

  let drawerTitles = {
    CREATE: {
      title: 'Create New Daily Log',
      subtitle: '',
    },
    EDIT: {
      title: 'Edit Daily Log',
      subtitle: '',
    },
  };

  let title = drawerTitles[currentSection].title;
  let subtitle = drawerTitles[currentSection].subtitle;

  const handledOnClose = async () => {
    let confirmation = await swal('Do you want close this form?', {
      buttons: ['No', 'Yes'],
    });

    if (confirmation) {
      onClose(data.length > 0);
    }
  };

  const handleSuccess = (dailyLog) => {
    setData([...data, dailyLog]);

    if (current._id) {
      onClose(true);
    }
  };

  const handleDeleteSuccess = (dailyLogs) => {
    setData(dailyLogs);
  };

  const isDailyLogInMemoryData = async (dailyLog) => {
    let result = [];
    // if (lookupDailyLog.key == 'trackingNumber')
    result = data.filter(
      (dl) =>
        dl.trackingNumber === dailyLog.trackingNumber && dl.bin === dailyLog.bin
    );

    return result.length ? true : false;
  };

  return (
    <SlidingPanel
      isOpen={openPanel}
      size={70}
      title={title}
      subtitle={subtitle}
      onClose={handledOnClose}
    >
      <DrawerFiltersWrapper>
        <Form
          isBusy={isBusy}
          setIsBusy={setIsBusy}
          dailyLog={current}
          binCounter={binCounter}
          setBinCounter={setBinCounter}
          binData={binData}
          onSuccess={handleSuccess}
          onTrySave={isDailyLogInMemoryData}
          data={data}
          lookupDailyLog={lookupDailyLog}
        >
          <Actions isBusy={isBusy} onBackClick={handledOnClose} />
        </Form>
      </DrawerFiltersWrapper>

      {currentSection == 'EDIT' ? undefined : (
        <Table
          data={data}
          pagination={false}
          lookupDailyLog={lookupDailyLog}
          onDeleteClick={handleDeleteSuccess}
          setIsBusy={setIsBusy}
          suppressScroll={true}
          goToBottomOnChange={true}
        />
      )}
      <FooterDiv>
        <Label>
          Daily Logs
          <Label.Detail>{data.length}</Label.Detail>
        </Label>
      </FooterDiv>
    </SlidingPanel>
  );
};

Actions.propTypes = {
  openPanel: PropTypes.func,
  onClose: PropTypes.func,
  dailyLog: PropTypes.object,
};
const FooterDiv = styled.div`
  display: flex;
  flex-direction: row-reverse;
`;
export default Drawer;
