import React from 'react';

import DropdownSearchFetch from '../../../components/Formik/DropdownSearchFetch';

function DropdownMunicipio({ formik, values, names, errors }) {
  let { setFieldValue } = formik;
  return (
    <DropdownSearchFetch
      fluid
      selection
      label="Municipio"
      value={values.Municipio}
      allowAdditions={values.Pais !== 'MEX'}
      name={names.Municipio}
      onChange={(e, { value }) => {
        setFieldValue(names.Municipio, value);
        setFieldValue(names.Localidad, null);
        setFieldValue(names.CodigoPostal, null);
        setFieldValue(names.Colonia, null);
      }}
      valuesChangesToUpdate={[values.Pais, values.Estado, values.Municipio]}
      placeholder="Municipio"
      featherService="catalogo-municipio"
      columName="Descripcion"
      columValue="c_Municipio"
      mapValues={(value) => {
        return {
          key: Math.random(),
          text: `${value['Descripcion']}`,
          value: value['c_Municipio'],
        };
      }}
      queryWithSearch={(columName, columValue, searchQuery) => {
        let newQuery = {
          $or: [
            {
              [columName]: {
                $like: '%' + searchQuery + '%',
              },
            },
            {
              [columValue]: {
                $like: '%' + searchQuery + '%',
              },
            },
          ],
          c_Estado: values.Estado,
          $limit: 10,
        };

        return newQuery;
      }}
      queryInit={(columName, columValue, value) => {
        let newQuery;
        if (value) {
          newQuery = {
            [columValue]: { $like: value },
            c_Estado: values.Estado,
            $limit: 1,
          };
        } else {
          newQuery = {
            $limit: 10,
            c_Estado: values.Estado,
          };
        }

        return newQuery;
      }}
      error={
        errors.Municipio && {
          content: errors.Municipio,
        }
      }
    />
  );
}

export default DropdownMunicipio;
