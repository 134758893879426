import React, { useState, useRef, useEffect } from 'react';
import TableContainer from '../../components/TableContainer';
import { AgGridReact } from '@ag-grid-community/react';
import { AllModules } from '@ag-grid-enterprise/all-modules';
import { Button } from 'semantic-ui-react';
import swal from 'sweetalert';

const Table = ({
  data,
  getData,
  onEditClick,
  setSelection,
  clearSelection,
}) => {
  const [columnDefs] = useState([
    {
      headerName: 'Pitneybowes transactionId',
      field: 'transactionId',
      checkboxSelection: true,
      headerCheckboxSelection: true,
      width: 250,
      resizable: true,
    },
    {
      headerName: 'Client',
      field: 'client',
      width: 110,
      resizable: true,
    },
    {
      headerName: 'Transaction Date',
      field: 'transactionDateTime',
      width: 180,
      resizable: true,
      alignContent: 'center',
    },
    {
      headerName: 'Transaction Type',
      field: 'transactionType',
      width: 220,
      resizable: true,
      alignContent: 'center',
    },
    {
      headerName: 'Tracking Number',
      field: 'parcelTrackingNumber',
      width: 230,
      resizable: true,
    },
    {
      headerName: 'Package Type',
      field: 'packageType',
      width: 150,
      resizable: true,
      alignContent: 'center',
    },
    {
      headerName: 'Method',
      field: 'mailClass',
      width: 230,
      resizable: true,
    },
    {
      headerName: 'Pitneybowes Shipment Id',
      field: 'shipmentId',
      width: 210,
      resizable: true,
    },
    {
      headerName: 'Label cost',
      field: 'developerRateAmount',
      width: 130,
      resizable: true,
    },
  ]);

  const [defaultColDef] = useState({ flex: 1 });
  const gridApi = useRef();

  useEffect(() => {
    const checkSelection = () => {
      if (clearSelection && gridApi) {
        gridApi.current.api.deselectAll();
      }
    };

    checkSelection();
  }, [clearSelection]);

  const handledEditClick = (shipper) => {
    onEditClick && onEditClick({ ...shipper });
  };

  const onGridReady = (params) => {
    // getData && getData();
    // gridApi.current = params.api;
    console.log('hi');
  };

  const handledSelectionChanged = ({ api }) => {
    let rows;
    try {
      if (api) {
        rows = api.getSelectedRows();
      }
    } catch (error) {
      console.error('error :>> ', error);
      rows = [];
      let err = {
        title: 'Error',
        text: 'Failed to select Partners',
        icon: 'error',
        buttons: { cancel: 'Close' },
      };
      swal(err);
    } finally {
      setSelection(rows);
    }
  };

  return (
    <TableContainer className="ag-theme-material" rowFilters={'D1'}>
      <AgGridReact
        rowData={data}
        modules={AllModules}
        ref={gridApi}
        columnDefs={columnDefs}
        defaultColDef={{
          filter: false,
          sortable: true,
          enableRowGroup: true,
          resizable: true,
          width: 250,
        }}
        sideBar={{
          toolPanels: ['columns'],
          defaultToolPanel: 'filters',
        }}
        rowSelection="multiple"
        pagination={true}
        paginationPageSize={100}
        suppressRowClickSelection={true}
        onGridReady={() => onGridReady()}
        onSelectionChanged={handledSelectionChanged}
        suppressDragLeaveHidesColumns={true}
        suppressMakeColumnVisibleAfterUnGroup={true}
      />
    </TableContainer>
  );
};

export default Table;
