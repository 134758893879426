import React from 'react';
import styled from 'styled-components';

const ActionsLayout = ({ actions }) => <Actions>{actions}</Actions>;

const Actions = styled.div`
  position: fixed;
  bottom: 0;
  width: 95%;
  padding: 25px;
  background: white;
`;

export default ActionsLayout;
