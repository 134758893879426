import React, { useState, useEffect, useCallback } from 'react';
import styled from 'styled-components';
import {
  Container,
  Grid,
  Segment,
  Button,
  Icon,
  Popup,
} from 'semantic-ui-react';
import DefaultLayout from '../../../layouts/DefaultLayout';
import HeaderBG from '../../../components/HeaderBG';
import SectionTitle from '../../../components/SectionTitle';
import TopNavigation from './TopNavigation';
import Status from './Status';
import Table from './Table';
import sample from '../sample';
import { useParams } from 'react-router-dom';
import sampleDetail from './sample';
import { Link } from 'react-router-dom';
import Tab from './Tab';
import CalculateShippingRates from './CalculateShippingRates';

const Detail = ({ match: { path } }) => {
  const { id } = useParams();

  // States
  const [order, setOrder] = useState({});
  const [data, setData] = useState([]);
  const [isBusy, setIsBusy] = useState(false);
  const [event, setEvent] = useState(null);
  const [totalProducts, setTotalProducts] = useState(0);
  const [isOpen, setIsOpen] = useState(false);

  // Handles
  const handlePackingClick = useCallback(
    (product) => {
      setIsBusy(true);
      const indexProduct = data.findIndex(({ _id }) => _id === product._id);
      const newDataUnpacking = [
        ...data.slice(0, indexProduct),
        ...data.slice(indexProduct + 1),
      ];

      setEvent({ type: 'packing', product });
      setData(newDataUnpacking);
      setIsBusy(false);
    },
    [data, setData, setEvent, setIsBusy]
  );

  const handleUnpackingClick = useCallback(
    (product) => {
      setData([...data, product]);
    },
    [data, setData]
  );

  const handleCalculateShippingRatesClick = () => {
    setIsOpen(true);
  };

  const handleAddPackageClick = useCallback(
    (evt) => {
      evt.preventDefault();

      setEvent({ type: 'addPackage' });
    },
    [setEvent]
  );

  const handleDeletePackageClick = useCallback(
    (dataRemoved) => {
      setData([...data, ...dataRemoved]);
    },
    [data, setData]
  );

  // Effects
  useEffect(() => {
    const order = sample.find((item) => item._id === id);

    setOrder(order);
    setData([...sampleDetail]);
    setTotalProducts([...sampleDetail].length);
  }, [id]);

  return (
    <>
      <DefaultLayout
        currentPath={path}
        globalActions={<TopNavigation isBusy={isBusy} />}
      >
        <HeaderBG />

        <ContainerMain>
          <SectionTitle>
            <Popup
              content="Back Packing"
              trigger={
                <Link to="/packing">
                  <Icon name="reply" style={{ color: '#4f9aa8' }} />
                </Link>
              }
            />{' '}
            Packing &gt; {order.order}
          </SectionTitle>
          <Status isBusy={true} order={order} />
          <Grid columns={2} divided style={{ flexGrow: 1 }}>
            <Grid.Row stretched>
              <Grid.Column>
                <Table data={data} onPackingClick={handlePackingClick} />
              </Grid.Column>

              <Grid.Column>
                {totalProducts !== data.length ? (
                  <>
                    <Segment>
                      <Grid columns={2}>
                        <Grid.Row stretched>
                          <Grid.Column>
                            Shipt To:
                            <br />
                            Carlos Fuentes
                            <br />
                            192 E CROYDON PARK RD
                            <br />
                            TUCSON, AZ 85704-5775, US
                          </Grid.Column>

                          <Grid.Column>
                            <br />
                            Shipping Method: api / label
                            <br />
                            <Button onClick={handleCalculateShippingRatesClick}>
                              Calculate shipping rates
                            </Button>
                          </Grid.Column>
                        </Grid.Row>
                      </Grid>
                    </Segment>

                    <Segment>
                      <Grid>
                        <Grid.Row stretched>
                          <Grid.Column>
                            <a href="/#" onClick={handleAddPackageClick}>
                              Add package
                            </a>
                          </Grid.Column>
                        </Grid.Row>

                        <Grid.Row stretched>
                          <Grid.Column>
                            <Tab
                              event={event}
                              setEvent={setEvent}
                              isBusy={isBusy}
                              setIsBusy={setIsBusy}
                              onDeletePackageClick={handleDeletePackageClick}
                              onUnpackingClick={handleUnpackingClick}
                            />
                          </Grid.Column>
                        </Grid.Row>

                        <Grid.Row stretched>
                          <Grid.Column>
                            <div>
                              <Button primary floated="right">
                                Print Label
                              </Button>
                            </div>
                          </Grid.Column>
                        </Grid.Row>
                      </Grid>
                    </Segment>
                  </>
                ) : (
                  <Segment></Segment>
                )}
              </Grid.Column>
            </Grid.Row>
          </Grid>
        </ContainerMain>
      </DefaultLayout>

      {isOpen ? (
        <CalculateShippingRates
          onClose={(isSuccess) => {
            setIsOpen(false);
            console.debug('CalculateShippingRates onClose isSuccess');
          }}
        />
      ) : (
        <></>
      )}
    </>
  );
};

const ContainerMain = styled(Container)`
  width: 80% !important;

  flex-grow: 1 !important;
  display: flex !important;
  flex-direction: column !important;
`;

Detail.propTypes = {};

export default Detail;
