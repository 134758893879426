import React, { useState } from 'react';
import LogoImage from '../assets/graphics/flexlogic.png';
import styled from 'styled-components';
import {
  Button,
  Form,
  Grid,
  Header,
  Message,
  Segment,
} from 'semantic-ui-react';
import { useAuth } from '../authentication';
import swal from 'sweetalert';

export default (props) => {
  const auth = useAuth();
  const [user, setUser] = useState({});

  const handleChange = (_, { name, value }) =>
    setUser({ ...user, [name]: value });

  const handleSubmit = async () => {
    const ret = await auth.login(user.email, user.password);

    if (!ret) {
      swal('Login Error', 'Invalid User or Password', 'error');
    }
  };

  return (
    <Grid textAlign="center" style={{ height: '100vh' }} verticalAlign="middle">
      <Grid.Column style={{ maxWidth: 450 }}>
        <Header as="h2" color="grey" textAlign="center">
          <Logo src={LogoImage} />
          <div style={{ marginTop: 20 }}>Ingresa a tu cuenta</div>
        </Header>
        <Form size="large">
          <Segment raised>
            <Form.Input
              name="email"
              value={user.email || ''}
              fluid
              icon="user"
              iconPosition="left"
              placeholder="Correo electrónico"
              onChange={handleChange}
            />

            <Form.Input
              name="password"
              value={user.password || ''}
              fluid
              icon="lock"
              iconPosition="left"
              placeholder="Clave"
              type="password"
              onChange={handleChange}
            />

            {/* <strong>user:</strong>
            <pre>{JSON.stringify(user, null, 2)}</pre> */}

            <Button
              primary
              fluid
              size="large"
              onClick={() => handleSubmit()}
              disabled={!user || !user.email || !user.password}
            >
              Ingresar
            </Button>
          </Segment>
        </Form>
        <Message>
          <a href="/forgot">¿Olvidaste tu password?</a>
        </Message>

        <Message>
          <a href="http://onboarding.app.flexlogic.co/" _target="_blank">
            Regístrate
          </a>
        </Message>
      </Grid.Column>
    </Grid>
  );
};

const Logo = styled.img`
  width: 280px !important;
  height: 50px !important;
`;
