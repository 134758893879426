import React, { useReducer, useEffect } from 'react';
import styled from 'styled-components';
import { List, Loader } from 'semantic-ui-react';
import permissions from './permissions.json';
import swal from 'sweetalert';
import feathers from '../../../../../services/feathers';
import moment from 'moment';

const getColor = ({ status }) => {
  if (status === 'complete') {
    return 'olive';
  } else if (status === 'await' || status === 'progress') {
    return 'grey';
  } else if (status === 'error') {
    return 'red';
  } else {
    return 'black';
  }
};

const getTask = (task) => {
  let todo = <></>;
  if (task.todo && task.todo.length) {
    todo = <List.List>{task.todo.map(getTask)}</List.List>;
  }

  return (
    <List.Item key={`task${task.key}`}>
      <List.Icon name="check" color={getColor(task)} />

      <List.Content>
        <List.Header>
          {task.title}

          {task.status === 'progress' ? (
            <Loading active inline size="tiny" />
          ) : (
            <></>
          )}
        </List.Header>

        {todo}
      </List.Content>
    </List.Item>
  );
};

const cleanTask = (task, prefix) => {
  task.status = 'await';

  if (task.todo) {
    task.todo.forEach((todo) => cleanTask(todo, task.key));
  }
};

const reducerTasks = (stateOrigin, action) => {
  const state = [...stateOrigin];

  switch (action.status) {
    case 'init':
      return Array.isArray(action.tasks) ? action.tasks : [];
    case 'start':
      state.forEach((task) => cleanTask(task));
      break;

    case 'complete':
    case 'progress':
    case 'error':
      action.key.reduce((tasks, key, index, array) => {
        const task = tasks.find((task) => task.key === key);

        if (task) {
          if (index + 1 < array.length) {
            return task.todo;
          } else {
            task.status = action.status;
          }
        }

        return null;
      }, state);
      break;
    default:
      break;
  }

  return state;
};

let once = false;
const Tasks = ({ data, onSuccess, onFail }) => {
  const [tasks, dispatchTasks] = useReducer(reducerTasks, []);

  useEffect(() => {
    if (data && data.permissions) {
      const tasks = data.permissions.map((key) => {
        const perm = permissions.find((perm) => perm.key === key);

        return { ...perm, key: key !== 'import-rush-order' ? key : 'orders' };
      });
      console.debug('tasks', JSON.stringify(tasks, null, 2));

      once = false;
      dispatchTasks({ status: 'init', tasks });
    }
  }, [data]);

  // Functions
  const importGoogleSheets = async () => {
    const service = feathers.service('import-google-sheets');
    const promises = await Promise.all(
      tasks
        .filter(({ key }) =>
          ['import-daily-log', 'import-product-data'].includes(key)
        )
        .map(async ({ key: type }) => await importData(service, { type }))
    );

    if (promises.length) {
      return promises.reduce((prev, res) => (prev && res ? true : false), true);
    }

    return true;
  };

  const importShiphero = async () => {
    if (tasks.some(({ key }) => key === 'csv-shipped-items')) {
      const service = feathers.service('import-shiphero');
      const from_date = moment(data.startDate).clone().format('YYYY-MM-DD');
      const to_date = moment(data.endDate)
        .clone()
        .add(1, 'day')
        .format('YYYY-MM-DD');

      return await importData(service, {
        query: {
          type: 'csv-shipped-items',
          from_date,
          to_date,
        },
      });
    }

    return true;
  };

  const importRushOrders = async () => {
    if (tasks.some(({ key }) => key === 'orders')) {
      const service = feathers.service('rush-orders');

      return await importData(service, {
        query: {
          type: 'orders',
          orderList: data.orders,
        },
      });
    }

    return true;
  };

  useEffect(() => {
    const init = async () => {
      if (tasks.length) {
        let success;
        try {
          dispatchTasks({ status: 'start' });
          // Import order
          success = await [
            importGoogleSheets,
            importShiphero,
            importRushOrders,
          ].reduce(async (acc, func) => ((await acc) ? func() : false), true);
        } catch (err) {
          console.error('Error', err);
          success = false;
        } finally {
          if (success) {
            await swal({
              title: 'Process Completed',
              text: 'Data Imported Successfully',
              type: 'success',
              confirmButtonText: 'Ok!',
              closeOnConfirm: true,
            });

            onSuccess && onSuccess();
          } else {
            await swal({
              title: 'Error',
              text: 'Failed Data Import',
              icon: 'error',
              buttons: {
                cancel: 'Close',
              },
            });

            // Esto es para que el usuario alcance a ver que salió mal. 5s
            // console.debug('Take a break!');
            await new Promise((resolve) => setTimeout(resolve, 5000));
            // console.debug('Keep going!');

            onFail && onFail();
          }
        }
      }
    };

    if (tasks.length && !once) {
      once = true;
      init();
    }
  }, [onFail, onSuccess, tasks]);

  const importData = async (service, params) => {
    const keyCurrentTask =
      params.type || (params.query && params.query.type) || null;
    let success = false;
    const todoStack = [];

    console.time(keyCurrentTask || 'importData');
    try {
      const task = tasks.find((task) => task.key === keyCurrentTask);
      let taskTodo = task && task.todo && task.todo.map((task) => task.key);
      service.on(keyCurrentTask, (data) => {
        // console.debug(keyCurrentTask, data);
        if (taskTodo.includes(data.key)) {
          if (data.status === 'complete') {
            const index = taskTodo.indexOf(data.key);
            taskTodo = taskTodo
              .slice(0, index)
              .concat(taskTodo.slice(index + 1));
          }

          todoStack.unshift(data.key);

          dispatchTasks({
            key: [keyCurrentTask, data.key],
            status: data.status,
          });
        } else if (data.key === 'error') {
          console.error('Error', data.error);
        }
      });

      let result = await service.create(params);
      // console.debug(`Finish! ${keyCurrentTask}`, result);

      if (result) {
        if (typeof result === 'object') {
          if (result.result) result = result.result;

          if (result.status !== 'failed') success = true;
          else if (result.errors) {
            throw Error(result.errors[0].detail);
          }
        } else {
          success = true;
        }

        // Quedaron tareas por hacer
        if (success && taskTodo.length > 0) success = false;
      }
    } catch (err) {
      success = false;
      console.error('importData', err);
    } finally {
      service.removeListener(keyCurrentTask);

      if (success) {
        dispatchTasks({ key: [keyCurrentTask], status: 'complete' });
      } else {
        todoStack.length &&
          dispatchTasks({
            key: [keyCurrentTask, todoStack[0]],
            status: 'error',
          });
        dispatchTasks({ key: [keyCurrentTask], status: 'error' });
      }
    }

    console.timeEnd(keyCurrentTask || 'importData');
    return success;
  };

  return tasks && tasks.length ? <List>{tasks.map(getTask)}</List> : <></>;
};

const Loading = styled(Loader)`
  &&&& {
    margin-left: 0.5rem;

    &:after {
      width: 100%;
      height: 100%;
      border-color: #a0a0a0 transparent transparent;
    }
  }
`;

export default Tasks;
