import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import UpdateNavigation from './UpdateNavigation';
import Actions from './Actions';
import Filters from './Filters';
import Table from './Table';
import TableNavigation from './TableNavigation';
import ImportModal from './Import';
import feathers from '../../../../services/feathers';
import ActionsLayout from '../../../../components/ActionsLayout';
import swal from 'sweetalert';

const ImportShippedItems = ({
  setCurrentSection,
  currentConsolidation,
  handledOnSave,
  handleOnBulk,
  setSelection,
  selection,
  isDissociating,
  modalOpened,
  valueProductGetter,
  valueDailyLogGetter,
}) => {
  const service = feathers.service('shipped-items');

  const [isOpen, setIsOpen] = useState(false);
  const [isBusy, setIsBusy] = useState(false);
  const [data, setData] = useState([]);
  const [query, setQuery] = useState(null);

  // Effects
  useEffect(() => {
    const filter = () => {
      if (query) {
        getDataImportShippedItems();
      }
    };

    filter();
  }, [query]);

  useEffect(() => {
    modalOpened && setIsOpen(true);
  }, modalOpened);

  const getDataImportShippedItems = async () => {
    if (query) {
      let data = [];

      try {
        setIsBusy(true);
        setSelection([]);

        // console.debug('query', JSON.stringify(query, null, 2));
        data = await service.find({ query });
      } catch (err) {
        console.error('Error', err);
        data = [];

        swal({
          title: 'Error',
          text: 'Failed get Shipped Items',
          icon: 'error',
          buttons: {
            cancel: 'Close',
          },
        });
      } finally {
        setData(data);
        setIsBusy(false);

        // refFilters && refFilters.current && refFilters.current.focus();
      }
    }
  };

  return (
    <WrapContent>
      <UpdateNavigation isOpen={isOpen} setIsOpen={setIsOpen} isBusy={isBusy} />

      <Filters
        isBusy={isBusy}
        query={query}
        setQuery={setQuery}
        currentConsolidation={currentConsolidation}
        handledOnSave={handledOnSave}
      />

      <TableNavigation isDissociating={isDissociating} isBusy={isBusy} />

      <TableContent>
        <Table data={data} selection={selection} setSelection={setSelection} />
      </TableContent>

      <ActionsLayout
        actions={
          <Actions
            selection={selection}
            currentConsolidation={currentConsolidation}
            setCurrentSection={setCurrentSection}
            isDissociating={isDissociating}
            isBusy={isBusy}
            handleOnBulk={handleOnBulk}
          />
        }
      />

      {isOpen ? (
        <ImportModal
          onClose={(isSuccess) => {
            setIsOpen(false);
            isSuccess && getDataImportShippedItems();
          }}
        />
      ) : (
        <></>
      )}
    </WrapContent>
  );
};

const WrapContent = styled.div``;

const TableContent = styled.div`
  padding-bottom: 35px;
`;

export default ImportShippedItems;
