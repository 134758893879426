import React, { forwardRef } from 'react';
import { Form, Segment, Header, Divider } from 'semantic-ui-react';
import styled from 'styled-components';
import feathers, { useAuth } from '../../../services/feathers';
import { Formik, Field, FieldArray } from 'formik';
import FormInput from '../../../components/Formik/Input';

const FormFiguraTransporte = ({ formik }) => {
  let {
    values,
    errors,
    touched,
    handleChange,
    handleBlur,
    handleSubmit,
    isSubmitting,
    /* and other goodies */
  } = formik;
  return (
    <>
      <Segment>
        <Header as="h3">Figura Transporte</Header>
        <FieldArray
          name="figuraTransportes"
          render={(arrayHelpers) => (
            <div>
              {values.figuraTransportes &&
              values.figuraTransportes.length > 0 ? (
                values.figuraTransportes.map((figuraTransporte, index) => (
                  <div key={index}>
                    <Form.Group widths={'equal'}>
                      <Form.Input
                        placeholder="Tipo Figura"
                        label="Tipo Figura"
                        name={`figuraTransportes.${index}.TipoFiguraTransporte`}
                        value={figuraTransporte.id}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        //   error={
                        //     validations.name && {
                        //       content: 'Required idTipoComprobante',
                        //     }
                        //   }
                      />
                      <Form.Input
                        placeholder="Nombre"
                        label="Nombre"
                        name={`figuraTransportes.${index}.NombreFiguraTransporte`}
                        value={figuraTransporte.id}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        //   error={
                        //     validations.name && {
                        //       content: 'Required idTipoComprobante',
                        //     }
                        //   }
                      />
                      <Form.Input
                        placeholder="Número de Licencia"
                        label="Número de Licencia"
                        name={`figuraTransportes.${index}.NumLicenciaFiguraTransporte`}
                        value={figuraTransporte.id}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        //   error={
                        //     validations.name && {
                        //       content: 'Required idTipoComprobante',
                        //     }
                        //   }
                      />
                      <Form.Input
                        placeholder="RFC"
                        label="RFC"
                        name={`figuraTransportes.${index}.RFCFiguraTransporte`}
                        value={figuraTransporte.id}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        //   error={
                        //     validations.name && {
                        //       content: 'Required idTipoComprobante',
                        //     }
                        //   }
                      />
                      <Form.Input
                        placeholder="Residencia Fiscal"
                        label="Residencia Fiscal"
                        name={`figuraTransportes.${index}.ResidenciaFiscalFiguraTransporte`}
                        value={figuraTransporte.id}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        //   error={
                        //     validations.name && {
                        //       content: 'Required idTipoComprobante',
                        //     }
                        //   }
                      />
                    </Form.Group>
                    <button
                      type="button"
                      style={{ marginBottom: 16 }}
                      onClick={() =>
                        arrayHelpers.insert(index, figuraTransporte)
                      } // insert an empty string at a position: ;
                    >
                      +
                    </button>
                    <button
                      type="button"
                      onClick={() => arrayHelpers.remove(index)} // remove a friend from the list
                    >
                      -
                    </button>
                  </div>
                ))
              ) : (
                <button type="button" onClick={() => arrayHelpers.push('')}>
                  {/* show this when user has removed all friends from the list */}
                  Agregar Figura Transporte
                </button>
              )}
            </div>
          )}
        />
      </Segment>
    </>
  );
};

const WrapContent = styled.div``;

export default forwardRef((props, ref) => (
  <FormFiguraTransporte {...props} innerRef={ref} />
));
