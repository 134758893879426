import React from 'react';
import InputsForm from './InputsForm';
import Navigation from './Navigation';
import Actions from './Actions';
import styled from 'styled-components';
import TableNavigation from './TableNavigation';
import Table from './Table';
import Filters from './Filters';
import ActionsLayout from '../../../../components/ActionsLayout';

const FormConsolidatedCargo = ({
  setCurrentSection,
  currentConsolidation,
  inputs,
  setInputs,
  handledOnSave,
  isBusy,
  getData,
  handleOnBulk,
  selection,
  setSelection,
  isDissociating,
  handledOnClose,
  onChangeFilter,
  valueProductGetter,
  valueDailyLogGetter,
}) => {
  return (
    <MainGridContainer>
      <Row>
        <InputsForm
          inputs={inputs}
          setInputs={setInputs}
          currentConsolidation={currentConsolidation}
        >
          {currentConsolidation._id && (
            <Navigation
              isBusy={isBusy}
              setCurrentSection={setCurrentSection}
              currentConsolidation={currentConsolidation}
            />
          )}
        </InputsForm>
      </Row>
      {currentConsolidation._id && (
        <ContentWrapper>
          <Filters
            onChange={onChangeFilter}
            isBusy={isBusy}
            shippedItems={currentConsolidation.shippedItems}
          />

          <TableNavigation
            isDissociating={isDissociating}
            selection={selection}
            isBusy={isBusy}
            handleOnBulk={handleOnBulk}
          />
          <Table
            data={
              (currentConsolidation &&
                currentConsolidation.shippedItems_filtered) ||
              currentConsolidation.shippedItems ||
              []
            }
            selection={selection}
            setSelection={setSelection}
            getData={getData}
            valueProductGetter={valueProductGetter}
            valueDailyLogGetter={valueDailyLogGetter}
          />
        </ContentWrapper>
      )}
      {(currentConsolidation._id && (
        <ActionsLayout
          actions={
            <Actions
              onSave={handledOnSave}
              inputs={inputs}
              isBusy={isBusy}
              handledOnClose={handledOnClose}
            />
          }
        />
      )) || (
        <Actions
          onSave={handledOnSave}
          inputs={inputs}
          isBusy={isBusy}
          handledOnClose={handledOnClose}
        />
      )}
    </MainGridContainer>
  );
};

const MainGridContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(1, 1fr);
  grid-template-rows: auto;
  grid-template-areas: '.';
`;

const ContentWrapper = styled.div`
  /* padding-bottom: 35px; */
`;

const Row = styled.div`
  display: grid;
  grid-template-columns: repeat(1, 1fr);
  grid-template-rows: auto;
  grid-template-areas: 'date';
`;

export default FormConsolidatedCargo;
