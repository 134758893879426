import React, { useState, useEffect, forwardRef } from 'react';
import styled from 'styled-components';
import { Form as SemanticForm } from 'semantic-ui-react';
import SemanticInput from '../../../components/SemanticInput';
import feathers from '../../../services/feathers';
import swal from 'sweetalert';

const Form = ({
  isBusy,
  setIsBusy,
  shipper: current,
  innerRef,
  children,
  onSuccess
}) => {
  const service = feathers.service('shippers');

  // States
  const [shipper, setShipper] = useState({ ...current });
  const [validations, setValidations] = useState({
    name: false
  });

  // Refs
  const refName = React.useRef(null);

  // Effects
  useEffect(() => {
    if (refName.current)
      refName.current.focus();
  }, []);

  // Handles
  const handleChange = (_, { name, value }) => setShipper({ ...shipper, [name]: value });

  const handleSubmit = async (event, data) => {
    let success = false, result = null;

    try {
      setIsBusy(true);
      event.preventDefault();

      const firstInvalid = isInvalid();
      if (!firstInvalid) {
        let shipperDTO = { ...shipper };
        result = await (!shipper._id ?
          service.create(shipperDTO)
          :
          service.patch(shipper._id, shipperDTO)
        );

        // console.debug('result', result);

        success = true;
      } else {
        refName.current.focus();
      }
    } catch (err) {
      console.error('Error: ', err);
      await swal('Error', 'Don\'t Add shipper', 'error');
    } finally {
      setIsBusy(false);

      if (success) {
        await swal('Done', 'Finished', 'success');
        onSuccess && onSuccess(result);
      }
    }
  };

  // Functions
  const isInvalid = () => {
    let firstInvalid = null;

    const newValidations = Object.keys(validations).reduce((prev, field) => {
      const ret = { ...prev, [field]: shipper[field] ? false : true };

      firstInvalid = firstInvalid || (ret[field] && field);

      return ret;
    }, {});

    setValidations(newValidations);

    return firstInvalid;
  }

  return (
    <SemanticForm onSubmit={handleSubmit} loading={isBusy}>
      <FormGrid>
        <div style={{ gridArea: 'name' }}>
          <SemanticInput
            placeholder="Name"
            label="Name *"
            name="name"
            value={shipper.name || ''}
            onChange={handleChange}
            error={validations.name && { content: 'Required name' }}

            disabled={isBusy}
            fluid
            ref={refName}
          />
        </div>
      </FormGrid>

      {children}
    </SemanticForm>
  );
};

const FormGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-template-rows: auto auto;
  grid-gap: 1em;
  grid-template-areas: 'name . .' '. . .';
`;

export default forwardRef((props, ref) => <Form {...props} innerRef={ref} />);