import React, { useState } from 'react';
import { Button, Header, Image, Modal } from 'semantic-ui-react';
import { Dropzone, FileItem } from '@dropzone-ui/react';

function ModalUpload({ open, setOpen, importHandled }) {
  const [files, setFiles] = useState([]);
  const updateFiles = (incommingFiles) => {
    console.log('incomming files', incommingFiles);
    setFiles(incommingFiles);
  };
  const handleDelete = (id) => {
    setFiles(files.filter((x) => x.id !== id));
  };
  return (
    <Modal
      onClose={() => setOpen(false)}
      onOpen={() => setOpen(true)}
      open={open}
    >
      <Modal.Header>Seleccionar archivo</Modal.Header>
      <Modal.Content>
        <Modal.Description>
          <Dropzone
            onChange={updateFiles}
            value={files}
            onClean
            // accept={'image/jpeg,.ts, video/*'}
            label={'Arrastrar aquí o dar click para seleccionar. '}
            minHeight={'195px'}
            maxHeight={'500px'}
            disableScroll
          >
            {files.map((file) => (
              <FileItem
                {...file}
                key={file.id}
                onDelete={handleDelete}
                alwaysActive
                preview
                info
                resultOnTooltip
              />
            ))}
          </Dropzone>
        </Modal.Description>
      </Modal.Content>
      <Modal.Actions>
        <Button color="black" onClick={() => setOpen(false)}>
          Cancelar
        </Button>
        <Button
          content="Importar"
          labelPosition="right"
          icon="checkmark"
          onClick={importHandled}
          positive
        />
      </Modal.Actions>
    </Modal>
  );
}

export default ModalUpload;
