import React, { useState, useEffect, useMemo } from 'react';
import styled from 'styled-components';
import { Modal, Button, Segment, Step, Card } from 'semantic-ui-react';
import moment from 'moment';
import Permissions from './Permissions';
import OrderList from './OrderList';
import DateRange from './DateRange';
import ValidateData from './ValidateData';
import PreviousImportInfo from './PreviousImportInfo';
import Tasks from './Tasks';

const STEPS = [
  {
    key: 'info',
    icon: 'info',
    title: 'Import Information',
    description: 'Check last import info',
    component: PreviousImportInfo,
  },
  {
    key: 'options',
    icon: 'options',
    title: 'Options',
    description: 'Choose import options',
    component: Permissions,
  },
  {
    key: 'rushOrder',
    icon: 'list',
    title: 'Orders',
    description: 'Choose the order list',
    component: OrderList,
  },
  {
    key: 'dateRange',
    icon: 'calendar',
    title: 'Date Range',
    description: 'Choose the date range',
    component: DateRange,
  },
  {
    key: 'confirmData',
    icon: 'info',
    title: 'Confirm Data',
    description: 'Verify the import details',
    component: ValidateData,
  },
];

const Import = ({ onClose }) => {
  const [steps, setSteps] = useState(STEPS);
  const [activeIndex, setActiveIndex] = useState(0);
  const [isBusy, setIsBusy] = useState(false);
  const [state, setState] = useState({
    isValid: false,
    permissions: null,
  });
  const [data, setData] = useState({
    permissions: [],
    orders: [],
    info: {},
    startDate: moment().subtract(1, 'day').startOf('day'),
    endDate: moment().endOf('day'),
  });

  useEffect(() => {
    setSteps((prev) =>
      prev.map((step, index) => {
        delete step.completed;
        delete step.active;
        delete step.disabled;

        if (activeIndex > index) {
          return { ...step, completed: true };
        } else if (index === activeIndex) {
          return { ...step, active: true };
        } else if (activeIndex < index) {
          return { ...step, disabled: true };
        }
      })
    );
  }, [activeIndex]);

  // Functions
  const moveNext = (activeIndex) => {
    if (steps.length > 1 && activeIndex < steps.length) {
      if (
        (steps[activeIndex].key === 'rushOrder' &&
          !data.permissions.includes('import-rush-order')) ||
        (steps[activeIndex].key === 'dateRange' &&
          !data.permissions.includes('csv-shipped-items'))
      ) {
        moveNext(activeIndex + 1);
      } else {
        setState({ ...state, isValid: false });
        setActiveIndex(activeIndex);
      }
    }
  };

  const movePrev = (activeIndex) => {
    if (steps.length > 1 && activeIndex >= 0) {
      if (
        (steps[activeIndex].key === 'rushOrder' &&
          !data.permissions.includes('import-rush-order')) ||
        (steps[activeIndex].key === 'dateRange' &&
          !data.permissions.includes('csv-shipped-items'))
      ) {
        movePrev(activeIndex - 1);
      } else {
        setState({ ...state, isValid: false });
        setActiveIndex(activeIndex);
      }
    }
  };

  // Handles
  const handleCancel = () => onClose && onClose(false);

  const handleBack = () => movePrev(activeIndex - 1);

  const handleNext = () => moveNext(activeIndex + 1);

  const handleFinish = () => {
    setIsBusy(true);
  };

  const handleSuccess = () => onClose && onClose(true);

  const handleFail = () => {
    setIsBusy(false);
  };

  // Memos
  const content = useMemo(() => {
    if (
      Array.isArray(steps) &&
      steps.length &&
      activeIndex >= 0 &&
      activeIndex < steps.length
    ) {
      const Content = steps[activeIndex].component;

      return Content ? (
        <Content
          data={data}
          setData={setData}
          state={state}
          setState={setState}
        />
      ) : (
        <></>
      );
    }
  }, [steps, activeIndex, data, state]);

  return steps && steps.length ? (
    <Modal basic={!isBusy} open={true} size={isBusy ? 'small' : 'large'}>
      <Modal.Content>
        {isBusy ? (
          <Tasks data={data} onSuccess={handleSuccess} onFail={handleFail} />
        ) : (
          <>
            <Group
              attached="top"
              widths={steps.length}
              items={steps.map(({ component, ...step }) => step)}
            />

            <Segment attached style={{ padding: 0 }}>
              <Card fluid style={{ boxShadow: 'none' }}>
                <Card.Content style={{ color: 'black' }}>
                  {/* <pre>data: {JSON.stringify(data, null, 2)}</pre>
              <pre>state: {JSON.stringify(state, null, 2)}</pre> */}
                  {content}
                </Card.Content>

                <Card.Content extra style={{ textAlign: 'end' }}>
                  {activeIndex === 0 ? (
                    <Button onClick={handleCancel}>Cancel</Button>
                  ) : (
                    <></>
                  )}

                  {activeIndex > 0 ? (
                    <Button onClick={handleBack}>Back</Button>
                  ) : (
                    <></>
                  )}

                  {activeIndex + 1 < steps.length ? (
                    <Button
                      onClick={handleNext}
                      primary
                      disabled={!state.isValid}
                    >
                      Next
                    </Button>
                  ) : (
                    <></>
                  )}

                  {activeIndex + 1 === steps.length ? (
                    <Button
                      onClick={handleFinish}
                      primary
                      disabled={!state.isValid}
                    >
                      Finish
                    </Button>
                  ) : (
                    <></>
                  )}
                </Card.Content>
              </Card>
            </Segment>
          </>
        )}
      </Modal.Content>
    </Modal>
  ) : (
    <></>
  );
};
const Group = styled(Step.Group)`
  // width: 1042px !important;
  border-radius: 0 !important;
  margin-left: -2px !important;
`;

export default Import;
