import io from 'socket.io-client';
import feathers from '@feathersjs/client';
import { useAuth } from '../authentication';

const socket = io(process.env.REACT_APP_FEATHERS_URL, {
  transports: ['websocket'],
});
const app = feathers();

let second = 1000;
let minute = second * 60;
let quarter = minute * 15;
let half = quarter * 2;
let hour = half * 2;

app.configure(
  feathers.socketio(socket, {
    timeout: hour * 3,
  })
);

// Use localStorage to store our login token
app.configure(
  feathers.authentication({
    storage: window.localStorage,
    storageKey: 'flex-key',
  })
);

app.hooks({
  before: {
    all: [
      async (ctx) => {
        if (ctx.path !== 'authentication') {
          try {
            await ctx.app.reAuthenticate(true);
          } catch (error) {
            window.location.reload();
          }
        }
      },
    ],
    find: [],
    get: [],
    create: [],
    update: [],
    patch: [],
    remove: [],
  },

  after: {
    all: [],
    find: [],
    get: [],
    create: [],
    update: [],
    patch: [],
    remove: [],
  },

  error: {
    all: [],
    find: [],
    get: [],
    create: [],
    update: [],
    patch: [],
    remove: [],
  },
});

export default app;
export { useAuth };
