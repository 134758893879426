import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Form, Button, Icon, Segment, Grid, Divider } from 'semantic-ui-react';
import QueryBuilder from './QueryBuilder';
import feathers from '../../../services/feathers';
import services from './services';
import carriers from './carriers';

const FormComponent = ({ currentRule, onSave, onClose }) => {
  const service = feathers.service('automation-rule');

  // State
  const [loading, setLoading] = useState(false);
  const [isValid, setIsValid] = useState(false);
  const [rule, setRule] = useState({
    ...currentRule,
    action: undefined,

    service: (currentRule.action && currentRule.action.service) || null,
    carrier: (currentRule.action && currentRule.action.carrier) || null,
  });
  const [validations, setValidations] = useState({
    name: false,
    description: false,
    service: false,
    carrier: false,
    condition: false,
  });

  const [availableServices, setAvailableServices] = useState([]);

  // Effects
  useEffect(() => {
    let isValid = Object.values(validations).filter((val) => val).length >= 1;

    setIsValid(isValid);
  }, [validations]);

  useEffect(() => {
    rule &&
      rule.carrier &&
      setAvailableServices(filterAndBuildAvailableServices(rule.carrier));
  }, []);

  // Functions
  const validateField = (name, value) => {
    setValidations({
      ...validations,
      [name]: value && value.length ? false : true,
    });
  };

  // Handles
  const handleChange = (_, { name, value }) => {
    setRule({ ...rule, [name]: value });
    if (validations.hasOwnProperty(name)) validateField(name, value);

    if (name === 'carrier') {
      rule.service = '';

      let availableServicesTemp = filterAndBuildAvailableServices(value);
      setAvailableServices(availableServicesTemp);
    }
  };

  const handleQueryChange = (query) => {
    setRule({ ...rule, condition: query });

    if (validations.condition) {
      setValidations({
        ...validations,
        condition: query === null,
      });
    }
  };

  const handleBlur = ({ target }) => {
    if (target && validations.hasOwnProperty(target.name))
      validateField(target.name, target.value);
  };

  const handleSubmit = async (event) => {
    setLoading(true);

    try {
      event.preventDefault();

      let isValidForm = true;
      const newValidations = Object.keys(validations).reduce((prev, field) => {
        const ret = { ...prev };
        if (field === 'condition') {
          ret[field] = rule[field] === null;
        } else {
          ret[field] = rule[field] && rule[field].length ? false : true;
        }

        isValidForm = isValidForm && !ret[field];

        return ret;
      }, {});

      setValidations(newValidations);

      if (isValidForm) {
        let result;
        const tempRule = {
          priority: rule.priority,
          name: rule.name,
          description: rule.description,
          condition: rule.condition,
          action: {
            service: rule.service,
            carrier: rule.carrier,
          },
        };
        if (!currentRule._id) {
          result = await service.create(tempRule);
        } else {
          result = await service.patch(currentRule._id, tempRule);
        }

        if (result) {
          onSave && onSave(result);
          return;
        }
      }
    } catch (err) {
      console.error('Error: ', err);
    }

    setLoading(false);
  };

  const handleOnClose = (event) => {
    event.preventDefault();
    onClose && onClose();
  };

  const filterAndBuildAvailableServices = (carrier) => {
    let availableServicesTemp = services
      .filter((item) => item.availableFor.includes(carrier))
      .map(({ name, label }) => {
        return {
          key: 'key-' + name,
          text: label,
          value: name,
        };
      });

    return availableServicesTemp;
  };

  return (
    <>
      <Form
        onSubmit={handleSubmit}
        loading={loading}
        style={{ marginRight: '15px' }}
      >
        <Form.Group widths={'equal'}>
          <Form.Input
            placeholder="Ex. Domestic packages"
            label="Name *"
            name="name"
            value={rule.name || ''}
            autoFocus
            onChange={handleChange}
            onBlur={handleBlur}
            error={validations.name && { content: 'Required Name' }}
          />

          <Form.Input
            placeholder="Ex. Rule applied to domestic shipments"
            label="Description *"
            name="description"
            value={rule.description || ''}
            onChange={handleChange}
            onBlur={handleBlur}
            error={
              validations.description && { content: 'Required Description' }
            }
          />

          <Form.Dropdown
            placeholder="Ex. USPS"
            label="Triggered Carrier *"
            name="carrier"
            selection
            value={rule.carrier || ''}
            onChange={handleChange}
            onBlur={handleBlur}
            options={carriers.map(({ name, label }, index) => ({
              key: name,
              text: label,
              value: name,
            }))}
            error={
              validations.description && { content: 'Required Description' }
            }
          />

          <Form.Dropdown
            placeholder="Ex. First Mail"
            value={rule.service || null}
            label="Triggered Service *"
            name="service"
            selection
            onChange={handleChange}
            onBlur={(_, data) => handleBlur({ target: { ...data } })}
            options={availableServices}
            error={validations.service && { content: 'Required Service' }}
          />
        </Form.Group>

        <QueryBuilder
          query={rule.condition}
          onQueryChange={handleQueryChange}
          error={validations.condition && { content: 'Condition invalid' }}
        />

        {/* <Segment>
          <pre>rule: {JSON.stringify({ ...rule, condition: null }, null, 2)}</pre>
        </Segment> */}

        <ActionContainer>
          <Button primary compact type="submit" disabled={isValid}>
            <Icon name="save" /> Save
          </Button>

          <Button compact onClick={handleOnClose}>
            <Icon name="reply" /> Back
          </Button>
        </ActionContainer>
      </Form>
    </>
  );
};

FormComponent.propTypes = {
  currentRule: PropTypes.object.isRequired,
  onSave: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
};

const ActionContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(8, 1fr);
  grid-template-rows: auto;
  grid-template-areas: '. . . . . . . .';
  margin-top: 1em;
`;

export default FormComponent;
