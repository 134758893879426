import React, { useState, useEffect, useContext, createContext } from 'react';
import feathers from '../services/feathers';
import { getPermissions } from '../constants/Roles';

const authContext = createContext();

// Provider component that wraps your app and makes auth object ...
// ... available to any child component that calls useAuth().
export const ProvideAuth = ({ children }) => {
  const auth = useProvideAuth();
  return <authContext.Provider value={auth}>{children}</authContext.Provider>;
};

// Hook for child components to get the auth object ...
// ... and re-render when it changes.
export const useAuth = () => {
  return useContext(authContext);
};

// Provider hook that creates auth object and handles state
function useProvideAuth() {
  const serviceClients = feathers.service('clients');

  const [user, setUser] = useState(null);

  const populateUser = async ({ clients, client, ...user }) => {
    if (Array.isArray(clients) && clients.length && client) {
      const result = await serviceClients.find({
        query: { _id: { $in: clients }, $sort: { name: 1 } },
      });

      if (result && result.data && result.data.length) {
        clients = [...result.data];
        client = clients.find(({ _id }) => _id === client) || client;
      }
    }
    const userPop = {
      ...user,
      clients,
      client,
      ...getPermissions(user),
    };

    // console.debug('user', JSON.stringify({ ...userPop }, null, 2));
    setUser(userPop);
  };

  const login = async (email, password) => {
    try {
      if (!(await reAuthenticate())) {
        // If that errors, log in with email/password
        // Here we would normally show a login page
        // to get the login information
        if (email && password) {
          const ret = await feathers.authenticate({
            strategy: 'local',
            email: email,
            password: password,
          });

          populateUser(ret.user);
          return ret.user;
        }
      }
    } catch (error) {
      console.error('useProvideAuth', error);
    }

    setUser(false);
  };

  const logout = async () => {
    await feathers.logout();
    setUser(false);
  };

  const reAuthenticate = async () => {
    try {
      // First try to log in with an existing JWT
      const ret = await feathers.reAuthenticate();
      populateUser(ret.user);
      return true;
    } catch (error) {
      console.error('useProvideAuth', error);
    }

    setUser(false);
    return false;
  };

  // Subscribe to user on mount
  // Because this sets state in the callback it will cause any ...
  // ... component that utilizes this hook to re-render with the ...
  // ... latest auth object.
  useEffect(() => {
    // console.debug('useEffect login');

    const reAuthenticateClean = async () => {
      await reAuthenticate();
    };

    reAuthenticateClean();
  }, []);

  return {
    user,
    login,
    logout,
  };
}
