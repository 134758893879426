import React, { useState } from 'react';
import {
  Form as SemanticForm,
  Segment as SegmentForm,
} from 'semantic-ui-react';
import styled from 'styled-components';
import SemanticInput from '../../../components/SemanticInput';
import swal from 'sweetalert';
import { Formik } from 'formik';
import DropdownSearchFetch from '../../../components/Formik/DropdownSearchFetch';

const Form = ({
  isBusy,
  setIsBusy,
  client: current,
  service,
  onSuccess,
  children,
}) => {
  const [client, setClient] = useState({ ...current });
  const [validations, setValidations] = useState({
    name: false,
    email: false,
  });

  console.log(client)
  const handledOnSubmit = async (event) => {
    let success = false,
      result = null;
    try {
      setIsBusy(true);
      event.preventDefault();

      const firstInvalid = isInvalid();

      if (!firstInvalid) {
        let clientDTO = { ...client };
        result = await (!client._id
          ? service.create(clientDTO)
          : service.patch(client._id, clientDTO));

        success = true;
      }
    } catch (error) {
      console.error('error :>> ', error);
      await swal('Error', `Client not added`, 'error');
    } finally {
      setIsBusy(false);

      if (success) {
        await swal('Done', 'Finished', 'success');
        onSuccess && onSuccess(result);
      }
    }
  };

  const isInvalid = () => {
    let firstInvalid = null;

    const newValidations = Object.keys(validations).reduce((prev, field) => {
      const ret = { ...prev, [field]: client[field] ? false : true };

      firstInvalid = firstInvalid || (ret[field] && field);

      return ret;
    }, {});

    setValidations(newValidations);

    return firstInvalid;
  };

  const handleChange = (_, { name, value }) => {
    console.log(name,value,_)
    if (name == 'merchantId' || name == 'key' || name == 'secret') {
      setClient({
        ...client,
        pitneybowesCredentials: {
          ...client.pitneybowesCredentials,
          [name]: value,
        },
      });
    } else if (name == 'preparer') {
      setClient({
        ...client,
        optima: {
          ...client.optima,
          [name]: value,
        },
      });
    } else {
      setClient({ ...client, [name]: value });
    }
  };
  const handleBlur = ({ target }) => {
    // console.debug('handleBlur', target);
    if (target && validations.hasOwnProperty(target.name))
      validateField(target.name, target.value);
  };

  const validateField = (name, value) => {
    setValidations({
      ...validations,
      [name]: value && value.length ? false : true,
    });
  };

  return (
    <SemanticForm onSubmit={handledOnSubmit} loading={isBusy}>
      <SemanticForm.Group widths={'equal'}>
        <SemanticForm.Input
          placeholder="Name"
          label="Name *"
          name="name"
          value={client.name || ''}
          autoFocus
          onChange={handleChange}
          onBlur={handleBlur}
          error={validations.name && { content: 'Required Name' }}
        />

        <SemanticForm.Input
          placeholder="Email"
          label="Email *"
          name="email"
          type="email"
          value={client.email || ''}
          onChange={handleChange}
          onBlur={handleBlur}
          error={
            validations.email
              ? { content: 'Required Email' }
              : client.email &&
                !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(
                  client.email
                ) && { content: 'Invalid Email Address' }
          }
        />
      </SemanticForm.Group>
      <SegmentForm>
        <h4>Pitney Bowes</h4>
        <SemanticForm.Input
          placeholder="Merchant Id"
          label="Merchant Id"
          name="merchantId"
          value={
            (client.pitneybowesCredentials &&
              client.pitneybowesCredentials.merchantId) ||
            ''
          }
          onChange={handleChange}
          onBlur={handleBlur}
        />
        <SemanticForm.Input
          placeholder="Pitneybowes API KEY"
          label="API Key"
          type="text"
          name="key"
          value={
            (client.pitneybowesCredentials &&
              client.pitneybowesCredentials.key) ||
            ''
          }
          onChange={handleChange}
          onBlur={handleBlur}
        />
        <SemanticForm.Input
          placeholder="Pitneybowes secret"
          label="Secret"
          name="secret"
          type="password"
          value={
            (client.pitneybowesCredentials &&
              client.pitneybowesCredentials.secret) ||
            ''
          }
          onChange={handleChange}
          onBlur={handleBlur}
        />
      </SegmentForm>
      <SegmentForm>
        <h4>Optima Config</h4>
        <Formik
       initialValues={{ preparerId: (client.optima && parseInt(client.optima.preparer)) || 0 }}
     >
       {props => (
         <form onSubmit={props.handleSubmit}>
           <DropdownSearchFetch
            fluid
            selection
            label="Preparador"
            value={props.values.preparerId}
            onChange={(e, { value }) => {
              props.setFieldValue('preparerId', value)
              setClient({
                ...client,
                optima: {
                  ...client.optima,
                  'preparer': value,
                },
              });
            }}
            placeholder="Preparador"
            featherService="optima-preparer"
            columName="PreparerName"
            columValue="PreparerID"
            mapValues={(value) => {
              return {
                key: Math.random(),
                text: `${value['PreparerName']}`,
                value: value['PreparerID'],
              };
            }}
          />
          </form>
       )}
       </Formik>
      </SegmentForm>
      {children}
    </SemanticForm>
  );
};

const FormGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-template-rows: auto auto;
  grid-gap: 1em;
  grid-template-areas: 'name . .' '. . .';
`;

export default Form;
