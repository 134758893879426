import React, { useEffect, useState } from 'react';
import { Dropdown, Form, Label } from 'semantic-ui-react';
import feathers from '../../services/feathers';

/**
 * Search Fetch DropDown
 * 
  <DropdownSearchFetch
    fluid
    selection
    label="Tipo de Comprobante"
    value={values.idTipoComprobante}
    onChange={(e, { value }) =>
      setFieldValue('idTipoComprobante', value)
    }
    placeholder="Tipo de Comprobante"
    featherService="catalogo-tipo-de-comprobante"
    columName="Descripcion"
    columValue="c_TipoDeComprobante"
  />
 */

//Functions Query
let queryInitDefault = (columName, columValue, value) => {
  let newQuery;
  if (value) {
    newQuery = {
      [columValue]: { $like: value },
      $limit: 10,
    };
  } else {
    newQuery = {
      $limit: 10,
    };
  }

  return newQuery;
};

let queryWithSearchDefault = (columName, columValue, searchQuery) => {
  let newQuery = {
    $or: [
      { [columName]: { $like: '%' + searchQuery + '%' } },
      { [columValue]: { $like: '%' + searchQuery + '%' } },
    ],
    $limit: 10,
  };

  return newQuery;
};

const DropDown = ({
  value,
  name,
  label,
  onChange,
  featherService,
  columName,
  columValue,
  mapValues,
  error,
  queryWithSearch,
  queryInit,
  allowAdditions,
  valuesChangesToUpdate,
  disabled,
  clearable,
}) => {
  let service = feathers.service(featherService);
  let [isFetching, setIsFetching] = useState(true);
  let [options, setOptions] = useState([]);

  const mapValuesDefault = (value) => {
    return {
      key: Math.random(),
      text: value[columName],
      value: value[columValue],
    };
  };

  const handleSearchChange = (e, { searchQuery }) => {
    fetchOptions(searchQuery);
  };

  const fetchInitdata = async () => {
    let options;
    let resultOptions = await service.find({
      query:
        (queryInit && queryInit(columName, columValue, value)) ||
        queryInitDefault(columName, columValue, value),
    });

    //console.log('resultOptions', resultOptions);

    options = resultOptions.data.map(mapValues || mapValuesDefault);

    if (!options.length) {
      if (allowAdditions) {
        options = [
          {
            key: Math.random(),
            text: value,
            value: value,
          },
        ];
      }
    }

    setOptions(options);
    setIsFetching(false);
  };

  useEffect(() => {
    fetchInitdata();
  }, []);

  useEffect(() => {
    fetchInitdata();
  }, [value]);

  // useEffect(() => {
  //   fetchOptions('');
  //   console.log('change value', value);
  // }, [value]);

  useEffect(() => {
    fetchOptions('');
  }, [JSON.stringify(valuesChangesToUpdate)]);

  const fetchOptions = async (searchQuery) => {
    setIsFetching(true);
    let options;
    if (searchQuery.length > 2) {
      let resultOptions = await service.find({
        query:
          (queryWithSearch &&
            queryWithSearch(columName, columValue, searchQuery)) ||
          queryWithSearchDefault(columName, columValue, searchQuery),
      });

      options = resultOptions.data.map(mapValues || mapValuesDefault);
    } else {
      let resultOptions = await service.find({
        query:
          (queryInit && queryInit(columName, columValue, value)) ||
          queryInitDefault(columName, columValue, value),
      });

      options = resultOptions.data.map(mapValues || mapValuesDefault);
    }

    if (!options.length) {
      if (allowAdditions) {
        options = [
          {
            key: Math.random(),
            text: value,
            value: value,
          },
        ];
      }
    }

    setOptions(options);
    setIsFetching(false);
  };

  return (
    <Form.Field>
      <label>{label}</label>
      <Dropdown
        clearable
        fluid
        selection
        search={true}
        options={options}
        value={value}
        onChange={onChange}
        onSearchChange={handleSearchChange}
        disabled={disabled}
        loading={isFetching}
        error={error}
        allowAdditions={allowAdditions}
        additionPosition="bottom"
        additionLabel="Añadir "
      />
      {error && (
        <Label pointing prompt>
          {error.content}
        </Label>
      )}
    </Form.Field>
  );
};

export default DropDown;
