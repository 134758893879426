import React, { useEffect, useState } from 'react';
import { Button, Icon, Radio } from 'semantic-ui-react';
import { price } from './AgGridColumns';

const serviceName = service => {
  switch (service) {
    case 'lightWeight':
      return 'Parcel Select Lightweight®';
    case 'priorityMailCubic':
      return 'Priority Mail® Cubic Commercial';
    case 'priorityMail':
      return 'Priority Mail® Commercial Base';
    case 'selectGround':
      return 'Parcel Select Ground';
    default:
      return service;
  }
};

export default props => {
  const { quotes, carrier } = props.data;
  const [selectedService, setSelectedService] = useState(carrier);

  useEffect(() => {
    setSelectedService(carrier);
  }, [carrier]);

  const changeHandler = (e, { value }) => {
    setSelectedService(value);
  };

  const saveHandler = () => {
    props.onSave({ item: props.data, service: selectedService });
  };

  return (
    <>
      {/* Quotes List */}
      {quotes &&
        quotes.map((quote, index) => {
          return (
            <div className="item__best_price mg-top" key={index}>
              <Radio
                name="selectBestPrice"
                value={quote.service}
                checked={quote.service === selectedService}
                onChange={changeHandler}
              />
              <div className="label__item">
                <div className={`label${index === 0 ? ' active' : null}`}>
                  {price.valueFormatter({ value: quote.pricing })}
                </div>
                <div className="description">{`${quote.weight_lb}${quote.WeightUnit}`}</div>
              </div>

              <div className="label__item">
                <div className="label">USPS</div>
                <div className="description">{serviceName(quote.service)}</div>
              </div>
            </div>
          );
        })}

      <div className="buttons__checkbox mg-top">
        <Button primary onClick={saveHandler}>
          <Icon name="save" />
          Save
        </Button>
      </div>
    </>
  );
};
