import { useAuth } from '../authentication';
import feathersSocket from './feathers';

const feathers = require('@feathersjs/client');
const rest = require('@feathersjs/rest-client');

const app = feathers();

// Connect to the same as the browser URL (only in the browser)
const restClient = rest(process.env.REACT_APP_FEATHERS_URL);

app.configure(restClient.fetch(window.fetch));

// Use localStorage to store our login token
app.hooks({
  before: {
    all: [
      async (ctx) => {
        if (ctx.path !== 'authentication') {
          try {
            console.log('window.localStorage');
            await feathersSocket.reAuthenticate(true);
            ctx.params.headers = {
              Authorization: `Bearer ${window.localStorage.getItem(
                'flex-key'
              )}`,
            };
          } catch (error) {
            window.location.reload();
          }
        }
      },
    ],
    find: [],
    get: [],
    create: [],
    update: [],
    patch: [],
    remove: [],
  },

  after: {
    all: [],
    find: [],
    get: [],
    create: [],
    update: [],
    patch: [],
    remove: [],
  },

  error: {
    all: [],
    find: [],
    get: [],
    create: [],
    update: [],
    patch: [],
    remove: [],
  },
});

export default app;
export { useAuth };
