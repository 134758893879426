import React from 'react';
import styled from 'styled-components';

export default ({ children, className, rowFilters }) => {
  switch (rowFilters) {
    case 1:
      return (
        <TableContainer1 className={className}>{children}</TableContainer1>
      );
    case 2:
      return (
        <TableContainer2 className={className}>{children}</TableContainer2>
      );
    case 3:
      return (
        <TableContainer3 className={className}>{children}</TableContainer3>
      );
    case 'D0':
      return (
        <DrawerTableContainer0 className={className}>
          {children}
        </DrawerTableContainer0>
      );
    case 'D1':
      return (
        <DrawerTableContainer1 className={className}>
          {children}
        </DrawerTableContainer1>
      );
    case 'D3':
      return (
        <DrawerTableContainer3 className={className}>
          {children}
        </DrawerTableContainer3>
      );
    default:
      return (
        <TableContainer0 className={className}>{children}</TableContainer0>
      );
  }
};

const TableContainer0 = styled.div`
  height: 72vh;
  width: 100%;
  border: 1px solid #e2e2e2;
  border-radius: 4px;
  overflow: hidden;
  margin-bottom: 10px;
`;

const TableContainer1 = styled.div`
  height: calc(100vh - 270px);
  width: 100%;
  border: 1px solid #e2e2e2;
  border-radius: 4px;
  overflow: hidden;
  margin-bottom: 10px;
`;

const TableContainer2 = styled.div`
  height: 53vh;
  width: 100%;
  border: 1px solid #e2e2e2;
  border-radius: 4px;
  overflow: hidden;
  margin-bottom: 10px;
`;

const TableContainer3 = styled.div`
  height: 40vh;
  width: 100%;
  border: 1px solid #e2e2e2;
  border-radius: 4px;
  overflow: hidden;
  margin-bottom: 10px;
`;

const DrawerTableContainer0 = styled.div`
  height: 76vh;
  width: 100%;
  border: 1px solid #e2e2e2;
  border-radius: 4px;
  overflow: hidden;
  margin-bottom: 10px;
`;

const DrawerTableContainer1 = styled.div`
  height: 58vh;
  width: 100%;
  border: 1px solid #e2e2e2;
  border-radius: 4px;
  overflow: hidden;
  margin-bottom: 10px;
`;

const DrawerTableContainer3 = styled.div`
  height: 40vh;
  width: 100%;
  border: 1px solid #e2e2e2;
  border-radius: 4px;
  overflow: hidden;
  margin-bottom: 10px;
`;
