import swal from 'sweetalert';
import downloadFile from '../../../../../functions/downloadFile';
import moment from 'moment';
import { SDZips } from '../../../../../constants/SanDiegoZipCodes';
import { NYZips } from '../../../../../constants/NewYorkZipCodes';

const ExcelJS = require('exceljs');
export default async (exportData, fromDate, toDate) => {
  let ElementsInNY = [];
  let ElementsInSD = [];

  if (exportData && exportData.length > 0) {
    try {
      let mappedData = exportData.map((item) => {
        const usefulZip = item.to_address.zip
          ? String(item.to_address.zip).length > 5
            ? String(item.to_address.zip).slice(0, 5)
            : String(item.to_address.zip)
          : item.to_address.zip;

        let popupedItem = {
          carrier: item.carrier,
          shipping_method: item.shipping_method,
          shipping_name: item.shipping_name,
          shipping_cost: item.shipping_cost
            ? isNaN(Number(item.shipping_cost))
              ? item.shipping_cost
              : Number(item.shipping_cost)
            : 0,
          integrator_created_date: moment(item.integrator_created_date).format(
            'YYYY-MM-DD hh:mm'
          ),
          integrator_order_number: item.integrator_order_number,
          tracking_number: item.tracking_number,

          'dimensions[weight_unit]': item.dimensions.weight_unit,
          'dimensions[size_unit]': item.dimensions.size_unit,
          'dimensions[weight]': item.dimensions.weight
            ? isNaN(Number(item.dimensions.weight))
              ? item.dimensions.weight
              : Number(item.dimensions.weight)
            : 0,
          'dimensions[height]': item.dimensions.height
            ? isNaN(Number(item.dimensions.height))
              ? item.dimensions.height
              : Number(item.dimensions.height)
            : 0,
          'dimensions[width]': item.dimensions.width
            ? isNaN(Number(item.dimensions.width))
              ? item.dimensions.width
              : Number(item.dimensions.width)
            : 0,
          'dimensions[length]': item.dimensions.length
            ? isNaN(Number(item.dimensions.length))
              ? item.dimensions.length
              : Number(item.dimensions.length)
            : 0,

          'to_address[city]': item.to_address.city,
          'to_address[state]': item.to_address.state,
          'to_address[country]': item.to_address.country,
          'to_address[zip]': usefulZip
            ? isNaN(Number(usefulZip))
              ? usefulZip
              : Number(usefulZip)
            : '-',
          'to_address[address1]': item.to_address.address1,
          'to_address[address2]': item.to_address.address2,
          'to_address[name]': item.to_address.name,

          best_carrier: item.best_carrier,
          best_quote: item.best_quote,
          best_service: item.best_service,

          zone: item.zone
            ? isNaN(Number(item.zone))
              ? item.zone
              : Number(item.zone)
            : 0,
        };

        popupedItem['to_address[zip]'] = popupedItem[
          'to_address[zip]'
        ].toString();

        if (SDZips.includes(usefulZip)) {
          ElementsInSD.push(popupedItem);
        } else {
          if (NYZips.includes(usefulZip)) {
            ElementsInNY.push(popupedItem);
          }
        }

        return popupedItem;
      });

      // let startsWith = (zipsToCompare) => (element, index, array) => {
      //   return zipsToCompare.includes(element['to_address[zip]']) === true;
      // };

      // let ElementsInNY = mappedData.filter(startsWith(NYZips));

      // let ElementsInSD = mappedData.filter(startsWith(SDZips));

      const exportName =
        'Baja Packages Report from ' + fromDate + ' to ' + toDate;

      const wb = new ExcelJS.Workbook();
      const ws = wb.addWorksheet('MAIN ' + fromDate + ' TO ' + toDate);
      const wsNY = wb.addWorksheet('New York Update');
      const wsSD = wb.addWorksheet('San Diego Update');

      let headers = [];

      ws.columns = Object.keys(mappedData[0]).map((field) => {
        headers.push(field);
        return { key: field, width: 25 };
      }, {});

      wsNY.columns = ws.columns;
      wsSD.columns = ws.columns;

      ws.getRow(1).values = headers;
      wsNY.getRow(1).values = headers;
      wsSD.getRow(1).values = headers;

      ws.addRows(mappedData);
      wsNY.addRows(ElementsInNY);
      wsSD.addRows(ElementsInSD);

      const buf = await wb.xlsx.writeBuffer();
      let res = await downloadFile(
        buf,
        exportName,
        'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
      );
      console.error('res', res);
      return true;
    } catch (error) {
      console.error('error', error);
      swal(`Could Not Export`, error, 'error');
      return false;
    }
  }
};
