import React, { useEffect, useReducer } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import DrawerFiltersWrapper from '../../components/DrawerFiltersWrapper';
import SemanticInput from '../../components/SemanticInput';
import ButtonsFilter from '../../components/ButtonsFilter';

const queryInit = {
  name: ''
};

const reducerQuery = (prevState, { name, value, type, query }) => {
  if (type === 'init' && query) {
    return query;
  } else if (name) {
    return { ...prevState, [name]: value }
  }

  return prevState;
};

const Filters = ({
  isBusy,
  query: base,
  setQuery
}) => {
  // States
  const [query, dispatchQuery] = useReducer(reducerQuery, { ...queryInit });

  // Effects
  useEffect(() => {
    // console.count('Filters query');
    const sendQuery = () => {
      handleSearchClick()
    }

    sendQuery();
  }, []);

  // Handles
  const handleChangeInput = (_, { name, value }) => dispatchQuery({ name, value });

  const handleSearchClick = (event, data) => {
    // Create query for feathers
    const queryMap = {
      ...query.name && { name: { $search: query.name } }
    };
    // console.debug('query', JSON.stringify(queryMap, null, 2));

    // Send query to Partners
    setQuery && setQuery(queryMap);
  };

  const handleClearClick = (event, data) => {
    dispatchQuery({
      type: 'init',
      query: {
        ...queryInit
      }
    });
  };

  return (
    <>
      {/* <pre>query: {JSON.stringify(query, null, 2)}</pre> */}

      <DrawerFiltersWrapper>
        <FiltersGrid>
          <SemanticInput
            name="name"
            label="Name"
            value={query.name}
            onChange={handleChangeInput}

            fluid
            style={{ gridArea: 'search' }}
            disabled={isBusy}
          />

          <ButtonsFilter
            isBusy={isBusy}
            onSearchClick={handleSearchClick}
            onClearClick={handleClearClick}
          />
        </FiltersGrid>
      </DrawerFiltersWrapper>
    </>
  );
};

const FiltersGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-template-rows: auto;
  grid-gap: 1em;
  grid-template-areas: 'search search . buttons';
`;

Filters.propTypes = {
  isBusy: PropTypes.bool,
  onSearch: PropTypes.func,
};

export default Filters;