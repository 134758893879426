import React, { forwardRef } from 'react';
import { Form, Segment } from 'semantic-ui-react';
import DropdownSearchFetch from '../../../components/Formik/DropdownSearchFetch';

const FormHeader = ({ formik }) => {
  let {
    values,
    errors,
    touched,
    handleChange,
    handleBlur,
    handleSubmit,
    isSubmitting,
    setFieldValue
    /* and other goodies */
  } = formik;
  return (
    <>
      <Segment>
        
        <Form.Group widths={'equal'}>
          <Form.Input
            placeholder="Descripcion"
            label="Descripcion"
            name="Descripcion"
            value={values.Descripcion}
            onChange={handleChange}
            onBlur={handleBlur}
            error={
              errors.Descripcion && { content: errors.Descripcion }
            }
          />
          <Form.Input
            placeholder="NoSerie"
            label="PlNoSerieaca"
            name="NoSerie"
            value={values.NoSerie}
            onChange={handleChange}
            onBlur={handleBlur}
            error={
              errors.NoSerie && { content: errors.NoSerie }
            }
          />
          <Form.Input
            placeholder="Ruta"
            label="Ruta"
            name="Ruta"
            value={values.Ruta }
            onChange={handleChange}
            onBlur={handleBlur}
            error={
              errors.Ruta && { content: errors.Ruta }
            }
          />
          <Form.Input
            placeholder="VigenciaInicio"
            label="VigenciaInicio"
            name="VigenciaInicio"
            type="date"
            value={values.VigenciaInicio }
            onChange={handleChange}
            onBlur={handleBlur}
            error={
              errors.VigenciaInicio && { content: errors.VigenciaInicio }
            }
          />
          
          </Form.Group>
          <Form.Group widths={'equal'}>
          
          <Form.Input
            placeholder="VigenciaFin"
            label="VigenciaFin"
            name="VigenciaFin"
            type="date"
            value={values.VigenciaFin}
            onChange={handleChange}
            onBlur={handleBlur}
            error={
              errors.VigenciaFin && { content: errors.VigenciaFin }
            }
          />
          <Form.Input
            placeholder="RutaLlave"
            label="RutaLlave"
            name="RutaLlave"
            value={values.RutaLlave}
            onChange={handleChange}
            onBlur={handleBlur}
            error={
              errors.RutaLlave && { content: errors.RutaLlave }
            }
          />
          <Form.Input
            placeholder="Contrasena"
            label="Contrasena"
            name="Contrasena"
            value={values.Contrasena}
            onChange={handleChange}
            onBlur={handleBlur}
            error={
              errors.Contrasena && { content: errors.Contrasena }
            }
          />
          <Form.Input
            placeholder="RFC"
            label="RFC"
            name="RFC"
            value={values.RFC}
            onChange={handleChange}
            onBlur={handleBlur}
            error={
              errors.RFC && { content: errors.RFC }
            }
          />
          </Form.Group>
          <Form.Group widths={'equal'}>
          
          <Form.Input
            placeholder="Status"
            label="Status"
            name="Status"
            value={values.Status}
            onChange={handleChange}
            onBlur={handleBlur}
            error={
              errors.Status && { content: errors.Status }
            }
          />
          </Form.Group>
      </Segment>
    </>
  );
};

export default forwardRef((props, ref) => (
  <FormHeader {...props} innerRef={ref} />
));
