import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import TableNavigation from "./TableNavigation";
import Table from "./Table";
import { isInvalidProduct } from '../functions/validationProduct';
import ActionsLayout from '../../../../components/ActionsLayout';
import Actions from './Actions';
import AddProductModal from './Modals/AddProductModal';
import AssociateProductModal from './Modals/AssociateProductModal';
import swal from 'sweetalert';

const ValidateShippedItems = ({
  currentConsolidation,
  isDissociating,
  isBusy,
  selection,
  setSelection,
  handleOnBulk,
  valueProductGetter,
  setCurrentSection
}) => {
  // States
  const [data, setData] = useState([]);
  const [shippedItem, setShippedItem] = useState(null);

  // Functions
  const closeValidate = () => {
    setCurrentSection('FORM_CONSOLIDATED_CARGO');
  }

  // Effects
  useEffect(() => {
    // console.count('ValidateShippedItems useEffect currentConsolidation shippedItem');
    const initData = () => {
      if (currentConsolidation && currentConsolidation.shippedItems) {
        if (!shippedItem) {
          const shippedItems = currentConsolidation.shippedItems.filter(isInvalidProduct);
          setData(shippedItems);
        }
      } else {
        setData([]);
      }
    }

    initData();
  }, [currentConsolidation, shippedItem]);

  // Handles
  const handleShippedItemClick = shippedItem => {
    setShippedItem(shippedItem);
  };

  const handleClose = async isSuccess => {
    setShippedItem(null);
  };

  const handleDoneClick = async event => {
    const shippedItems = currentConsolidation.shippedItems.filter(isInvalidProduct).length;

    if (shippedItems) {
      let confirmation = await swal({
        icon: 'warning',
        title: 'Oops...',
        text: `Missing to add or associate products to ${shippedItems} packages, Do you want to continue?`,
        buttons: ['No', 'Yes'],
      });

      if (confirmation) {
        console.debug('Continuamos entonces...');
      } else {
        console.debug("Don't worry... Be happy.");
      }
    }

    closeValidate();
  };

  const handleBackClick = (event) => {
    closeValidate();
  };

  return (
    <>
      <WrapContent>
        <TableNavigation
          isDissociating={isDissociating}
          isBusy={isBusy}
          selection={selection}
          onOnBulk={handleOnBulk}
        />

        <Table
          data={data}
          onShippedItemClick={handleShippedItemClick}
          selection={selection}
          setSelection={setSelection}
          valueProductGetter={valueProductGetter}
        />

        <ActionsLayout
          actions={
            <Actions
              onDoneClick={handleDoneClick}
              onBackClick={handleBackClick}
            />
          }
        />
      </WrapContent>

      {shippedItem
        ? (
          shippedItem._id
            ? <AssociateProductModal
              shippedItem={shippedItem}
              onClose={handleClose}
              shippedItems={currentConsolidation.shippedItems}
            />

            : <AddProductModal
              shippedItem={shippedItem}
              onClose={handleClose}
              shippedItems={currentConsolidation.shippedItems}
            />
        )
        : <></>}
    </>
  );
};

const WrapContent = styled.div`
  padding-right: 1em;
`;

ValidateShippedItems.propTypes = {
  currentConsolidation: PropTypes.object,
  isDissociating: PropTypes.bool,
  isBusy: PropTypes.bool,
  selection: PropTypes.array,
  setSelection: PropTypes.func,
  handleOnBulk: PropTypes.func,
  valueProductGetter: PropTypes.func,
  setCurrentSection: PropTypes.func
};

export default ValidateShippedItems;