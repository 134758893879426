import React from 'react';
import SlidingPanel from 'react-sliding-side-panel';
import styled from 'styled-components';
import CloseIcon from './assets/close-icon.svg';
import './assets/styles.css';

const SLSlidingPanel = (props) => {
  return (
    <SlidingPanel
      size={props.size}
      type={props.type}
      isOpen={props.isOpen}
      backdropClicked={props.onClose}
    >
      <div style={props.style}>
        <Header>
          <TitleGroup>
            {props.title && <Title>{props.title}</Title>}
            {props.subtitle && <Subtitle>{props.subtitle}</Subtitle>}
          </TitleGroup>
          <CloseButton>
            <CloseButtonImage
              alt=""
              src={CloseIcon}
              width={22}
              height={22}
              onClick={props.onClose}
            />
          </CloseButton>
        </Header>
        <Main>{props.children}</Main>
      </div>
    </SlidingPanel>
  );
};

SLSlidingPanel.defaultProps = {
  type: 'right',
  size: 35,
  children: <div style={{ color: '#ccc' }}>No content provided</div>,
};

const Header = styled.header`
  display: grid;
  grid-template-columns: 1fr 22px;
  grid-column-gap: 16px;
  padding: 16px;
  /* background-color: #f8f8f8; */
  /* border-bottom: 1px solid rgba(127, 127, 127, 0.15); */
`;

const TitleGroup = styled.div``;

const Title = styled.h2`
  font-family: 'Roboto', sans-serif;
  font-size: 24px;
  line-height: 24px;
  font-weight: 400;
  color: rgba(57, 57, 57, 0.87);
  margin-bottom: 0px;
`;

const Subtitle = styled.h3`
  margin-top: 8px;
  font-family: 'Roboto', sans-serif;
  font-size: 16px;
  line-height: 16px;
  font-weight: 400;
  color: rgba(57, 57, 57, 0.6);
`;

const CloseButton = styled.div`
  text-align: right;
`;

const CloseButtonImage = styled.img`
  cursor: pointer;
  transition-duration: 0.25s;

  &:hover {
    opacity: 0.5;
  }
`;

const Main = styled.main`
  padding: 16px;
`;

export default SLSlidingPanel;
