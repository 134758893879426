import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Button, Icon } from 'semantic-ui-react';

const Actions = ({
  onDoneClick,
  onBackClick
}) => {
  return (
    <ActionContainer>
      <Button primary compact onClick={onDoneClick}>
        Done
      </Button>

      <Button style={{ width: '50%' }} compact onClick={onBackClick}>
        <Icon name="reply" /> Back
      </Button>
    </ActionContainer>
  );
};

const ActionContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(6, 1fr);
  grid-template-rows: auto;
  grid-template-areas: '. . . . . .';
`;

Actions.propTypes = {
  onDoneClick: PropTypes.func,
  onBackClick: PropTypes.func
};

export default Actions;