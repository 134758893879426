import React, { useState } from 'react';
import PropTypes from 'prop-types';
import FormAddProduct from '../../../components/FormAddProduct';
import { Modal, Button, Icon } from 'semantic-ui-react';
import swal from 'sweetalert';

const ProductModal = ({
  product,
  onClose
}) => {
  // States
  const [isValid, setIsValid] = useState(false);
  const refForm = React.useRef(null);

  // Handles
  const handleSaveClick = async () => {
    refForm.current.submit();
  };

  const handleSave = async product => {
    await swal('Done', 'Finished', 'success');
    onClose && onClose(true);
  }

  const handleCloseClick = event => onClose && onClose(false);

  return (
    <Modal open={true} size="small">
      <Modal.Header>
        Edit product
      </Modal.Header>

      <Modal.Content scrolling>
        <FormAddProduct
          currentProduct={product}
          onSave={handleSave}
          ref={refForm}
          setIsValid={setIsValid}
          addFromValidation={true}
          isImportExport={true}
        />
      </Modal.Content>

      <Modal.Actions>
        <Button
          primary
          compact
          disabled={isValid}

          onClick={handleSaveClick}
        >
          <Icon name="save" /> Save
          </Button>

        <Button
          compact
          onClick={handleCloseClick}
        >
          <Icon name="reply" /> Close
          </Button>
      </Modal.Actions>
    </Modal>
  );
};

ProductModal.propTypes = {
  product: PropTypes.object,
  onClose: PropTypes.func
};

export default ProductModal;