import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { AgGridReact } from '@ag-grid-community/react';
import { AllModules } from '@ag-grid-enterprise/all-modules';
import { Button } from 'semantic-ui-react';
import swal from 'sweetalert';
import TableContainer from '../../components/TableContainer';
import { valueFormatter, number, price, dateTime } from '../../components/AgGridColumns';

const Table = ({
  data,
  onEditClick,
  selection,
  setSelection
}) => {
  // States
  const [gridApi, setGridApi] = useState();
  const [defaultColDef] = useState({
    flex: 1,
    filter: true,
    sortable: true,
    resizable: true,
  });

  const [columnDefs] = useState([
    { ...number, field: 'count', headerName: 'Count Order Num' },
    { field: 'exportCode', headerName: 'Import Code', valueFormatter },
    { field: 'coco', headerName: 'COO', valueFormatter },
    { field: 'fiber', headerName: 'Fiber', valueFormatter },
    { field: 'description', headerName: 'DESC', valueFormatter },
    { ...price, field: 'cost', headerName: 'Cost' },

    ...(onEditClick
      ? [
        {
          headerName: '',
          field: '',
          pinned: 'right',
          cellStyle: { textAlign: 'center' },

          cellRendererFramework: (params) => {
            if (params.data)
              return (
                <Button
                  compact
                  primary
                  onClick={() => handleEditClick(params.data)}
                >
                  Edit
                </Button>
              );
          },
        },
      ]
      : []),
  ]);

  // Effects
  useEffect(() => {
    // console.debug('useEffect Table', selection, (gridApi && gridApi.getSelectedRows()));
    if (
      selection &&
      !selection.length &&
      gridApi &&
      gridApi.getSelectedRows().length
    ) {
      gridApi.deselectAll();
    }
  }, [selection, gridApi]);

  // Handles
  const handleEditClick = product => {
    onEditClick && onEditClick({ ...product });
  };

  const handleSelectionChanged = (event) => {
    if (selection && setSelection) {
      let rows = [];

      try {
        if (event && event.api) {
          rows = event.api.getSelectedRows().map(row => row._id);
        }
      } catch (err) {
        rows = [];

        swal({
          title: 'Error',
          text: 'Failed to select Products',
          icon: 'error',
          buttons: {
            cancel: 'Close',
          },
        });
      } finally {
        // console.debug('onSelectionChanged event rows', '[' + rows.map(item => `ObjectId('${item}')`).join(', ') + ']');
        setSelection(rows);
      }
    }
  };

  const handleGridReady = (params) => setGridApi(params.api);

  return (
    <TableContainer className="ag-theme-material" rowFilters={onEditClick ? 'D0' : 1}>
      <AgGridReact
        modules={AllModules}
        columnDefs={columnDefs}
        defaultColDef={defaultColDef}
        rowSelection="multiple"
        suppressRowClickSelection={true}
        onSelectionChanged={handleSelectionChanged}
        rowData={data}
        pagination={true}
        paginationPageSize={100}
        onGridReady={handleGridReady}
      />
    </TableContainer>
  );
};

Table.propTypes = {
  data: PropTypes.array,
  selection: PropTypes.array,
  setSelection: PropTypes.func
};

export default Table;