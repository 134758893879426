import React, { forwardRef, useState, useEffect } from 'react';
import { Form, Segment, Checkbox } from 'semantic-ui-react';
import moment from 'moment';
import DropdownSearchFetch from '../../../components/Formik/DropdownSearchFetch';
import DropdownSimpleFetch from '../../../components/Formik/DropdownSimpleFetch';
import Dropdown from '../../../components/Formik/Dropdown';
import DatePicker from 'react-datepicker';
import { Icon, Label } from 'semantic-ui-react';
import feathers from '../../../services/feathers';

const FormHeader = ({ formik, setIsCartaPorte }) => {
  let { values, errors, touched, handleChange, handleBlur, setFieldValue } =
    formik;
  const [startDate, setStartDate] = useState(new Date());

  const posFoliosService = feathers.service('pos-folios');

  useEffect(() => {
    const getFolios = async () => {
      if(values.idPOS){
        let POSFolio = await getPOSFolios(values.idPOS,values.idTipoComprobante)
        if(POSFolio){
          setFieldValue('Serie', POSFolio.Serie);
          setFieldValue('Folio', ++POSFolio.Folio);
        }else{
          setFieldValue('Serie', "");
          setFieldValue('Folio', "");
        }
        
      }
    }
    getFolios();
  }, []);

  const getPOSFolios = async (idPOS, tipoComprobante) => {
    let response = await posFoliosService.find({
      query: {
        idPOS: idPOS,
        idTipoComprobante: tipoComprobante,
      },
    });

    let data =
      (response && response.data && response.data.length && response.data[0]) ||
      null;
    return data;
  };

  return (
    <>
      <Segment>
        <Form>
          <Form.Group widths={'equal'}>
            <DropdownSimpleFetch
              clearable
              fluid
              selection
              label="Tipo de Comprobante"
              value={values.idTipoComprobante}
              onChange={async (e, { value }) =>{
                setFieldValue('idTipoComprobante', value)
                if(values.idPOS){
                  let POSFolio = await getPOSFolios(values.idPOS,value)
                  if(POSFolio){
                    setFieldValue('Serie', POSFolio.Serie);
                    setFieldValue('Folio', ++POSFolio.Folio);
                  }else{
                    setFieldValue('Serie', "");
                    setFieldValue('Folio', "");
                  }
                }
                }
              }
              placeholder="Tipo de Comprobante"
              featherService="catalogo-tipo-de-comprobante"
              columName="Descripcion"
              columValue="c_TipoDeComprobante"
              error={
                errors.idTipoComprobante &&
                touched.idTipoComprobante && {
                  content: errors.idTipoComprobante,
                }
              }
            />
            <Form.Field>
              <label>Carta Porte</label>
              <Checkbox
                disabled
                defaultChecked
                toggle
                onChange={(e, { checked }) => setIsCartaPorte(checked)}
              />
            </Form.Field>
            <Form.Field>
              <label>Serie</label>
              <Label>
                {values.Serie}
              </Label>
            </Form.Field>
            <Form.Field>
              <label>Folio</label>
                <Label>
              {values.Folio}
            </Label>
          </Form.Field>
            <div className="field">
              <label>
                <b>Fecha Documento</b>
              </label>
              <DatePicker
                selected={startDate}
                //onChange={(date) => setStartDate(date)}
                onChange={(data) => {
                  //console.log(data);
                  setFieldValue('FechaDocumento', moment(data));
                }}
                timeInputLabel="Hora:"
                dateFormat="MM/dd/yyyy h:mm aa"
                showTimeInput
                name="FechaDocumento"
                value={moment(values.FechaDocumento).format(
                  'YYYY-MM-DD h:mm a'
                )}
                onBlur={handleBlur}
                error={
                  errors.FechaDocumento &&
                  touched.FechaDocumento && { content: errors.FechaDocumento }
                }
              />
            </div>

            {/* <Form.Group widths={'equal'}>
          <DropdownSimpleFetch
            fluid
            selection
            label="Regimen"
            value={values.idRegimen}
            onChange={(e, { value }) => setFieldValue('idRegimen', value)}
            placeholder="Regimen"
            featherService="catalogo-regimen-fiscal"
            columName="Descripcion"
            columValue="c_RegimenFiscal"
            mapValues={(value) => {
              return {
                key: Math.random(),
                text: `${value['c_RegimenFiscal']} - ${value['Descripcion']}`,
                value: value['c_RegimenFiscal'],
              };
            }}
            error={
              errors.idRegimen &&
              touched.idRegimen && { content: errors.idRegimen }
            }
          />
          <Form.Input
            placeholder="Lugar de expedición"
            label="Lugar de expedición"
            name="LugarExpedicion"
            value={values.LugarExpedicion}
            onChange={handleChange}
            onBlur={handleBlur}
            error={
              errors.LugarExpedicion &&
              touched.LugarExpedicion && { content: errors.LugarExpedicion }
            }
          />
        </Form.Group> */}

            <DropdownSimpleFetch
              fluid
              selection
              label="Uso Comprobante"
              value={values.idUsoCFDI}
              onChange={(e, { value }) => setFieldValue('idUsoCFDI', value)}
              placeholder="Uso Comprobante"
              featherService="catalogo-uso-cfdi"
              columName="Descripcion"
              columValue="c_UsoCFDI"
              mapValues={(value) => {
                return {
                  key: Math.random(),
                  text: `${value['c_UsoCFDI']} - ${value['Descripcion']}`,
                  value: value['c_UsoCFDI'],
                };
              }}
              disabled={values.idTipoComprobante === 'T' ? true : false}
              error={
                errors.idUsoCFDI &&
                touched.idUsoCFDI && { content: errors.idUsoCFDI }
              }
            />
          </Form.Group>
          <Form.Group widths={'equal'}>
            <DropdownSearchFetch
              fluid
              selection
              label="Moneda"
              value={values.idMoneda}
              onChange={(e, { value }) => setFieldValue('idMoneda', value)}
              placeholder="Moneda"
              featherService="catalogo-moneda"
              columName="Descripcion"
              columValue="c_Moneda"
              mapValues={(value) => {
                return {
                  key: Math.random(),
                  text: `${value['c_Moneda']} - ${value['Descripcion']}`,
                  value: value['c_Moneda'],
                };
              }}
              disabled={values.idTipoComprobante === 'T' ? true : false}
              error={
                errors.idMoneda &&
                touched.idMoneda && { content: errors.idMoneda }
              }
            />
            {values.idTipoComprobante === 'I' && values.idMoneda !== 'MXN' ? (
              <Form.Input
                type="number"
                min={0}
                placeholder="TipoDeCambio"
                label="TipoDeCambio"
                name="TipoDeCambio"
                value={
                  values.idMoneda === 'MXN'
                    ? (values.TipoDeCambio = 1.0)
                    : values.TipoDeCambio
                }
                onChange={handleChange}
                onBlur={handleBlur}
                error={
                  errors.TipoDeCambio &&
                  touched.TipoDeCambio && { content: errors.TipoDeCambio }
                }
              />
            ) : null}
            <DropdownSearchFetch
              fluid
              selection
              label="Forma de Pago"
              value={
                values.idTipoComprobante === 'T'
                  ? (values.idFormaPago = '99')
                  : values.idFormaPago
              }
              onChange={(e, { value }) => setFieldValue('idFormaPago', value)}
              placeholder="Forma de Pago"
              featherService="catalogo-forma-pago"
              columName="Descripcion"
              columValue="c_FormaPago"
              mapValues={(value) => {
                return {
                  key: Math.random(),
                  text: `${value['c_FormaPago']} - ${value['Descripcion']}`,
                  value: value['c_FormaPago'],
                };
              }}
              disabled={values.idTipoComprobante === 'T' ? true : false}
              error={
                errors.idFormaPago &&
                touched.idFormaPago && { content: errors.idFormaPago }
              }
            />
            <DropdownSearchFetch
              fluid
              selection
              label="Método de Pago"
              value={
                values.idTipoComprobante === 'T'
                  ? (values.idMetodoPago = 'PPD')
                  : values.idMetodoPago
              }
              onChange={(e, { value }) => setFieldValue('idMetodoPago', value)}
              placeholder="Método de Pago"
              featherService="catalogo-metodo-pago"
              columName="Descripcion"
              columValue="c_MetodoPago"
              mapValues={(value) => {
                return {
                  key: Math.random(),
                  text: `${value['c_MetodoPago']} - ${value['Descripcion']}`,
                  value: value['c_MetodoPago'],
                };
              }}
              disabled={values.idTipoComprobante === 'T' ? true : false}
              error={
                errors.idMetodoPago &&
                touched.idMetodoPago && { content: errors.idMetodoPago }
              }
            />

            <Dropdown
              fluid
              selection
              label="Tipo de Viaje"
              value={values.TipoViaje}
              onChange={(e, { value }) => setFieldValue('TipoViaje', value)}
              placeholder="Tipo de Viaje"
              options={[
                {
                  key: 'Internacional',
                  value: 'Internacional',
                  text: 'Internacional',
                },
                { key: 'Nacional', value: 'Nacional', text: 'Nacional' },
              ]}
            />
            <Dropdown
              fluid
              selection
              label="Entrada/Salida"
              value={values.EntradaSalida}
              onChange={(e, { value }) => setFieldValue('EntradaSalida', value)}
              placeholder="Entrada/Salida"
              options={[
                { key: 'Entrada', value: 'Entrada', text: 'Entrada' },
                { key: 'Salida', value: 'Salida', text: 'Salida' },
              ]}
            />
            <DropdownSimpleFetch
              fluid
              selection
              label="Pais"
              value={values.PaisInicial}
              onChange={(e, { value }) => setFieldValue('PaisInicial', value)}
              placeholder="País"
              featherService="catalogo-pais"
              columName="Descripcion"
              columValue="c_Pais"
              mapValues={(value) => {
                return {
                  key: Math.random(),
                  text: `${value['c_Pais']} - ${value['Descripcion']}`,
                  value: value['c_Pais'],
                };
              }}
              error={
                errors.PaisInicial &&
                touched.PaisInicial && { content: errors.PaisInicial }
              }
            />
          </Form.Group>
        </Form>
      </Segment>
    </>
  );
};

export default forwardRef((props, ref) => (
  <FormHeader {...props} innerRef={ref} />
));
