import swal from 'sweetalert';
import downloadFile from '../../../../../functions/downloadFile';
import moment from 'moment';
import { number, date } from '../../../../../components/AgGridColumns';

const ExcelJS = require('exceljs');

export default async (exportData) => {
  if (exportData && exportData.length > 0) {
    try {
      let mappedData = exportData.map((item) => {
        const dailylog =
          (item && item.dailylog && item.dailylog.length && item.dailylog[0]) ||
          null;
        const product =
          (item && item.product && item.product.length && item.product[0]) ||
          null;
        const cost =
          (product && number.valueFormatter({ value: product.cost })) || 'N/A';

        const usefullZip = item.zip
          ? String(item.zip).length > 5
            ? String(item.zip).slice(0, 5)
            : String(item.zip)
          : item.zip;

        return {
          'Created Date': date.valueFormatter({ value: item.createdDate }),
          BIN: ((dailylog && dailylog.bin) || '-').toLowerCase(),
          'Is 321': (product && product.is321) || '-',
          'Order Number': item.orderNumber
            ? isNaN(Number(item.orderNumber))
              ? item.orderNumber
              : Number(item.orderNumber)
            : '-',
          'Tracking Number': item.trackingNumber
            ? isNaN(Number(item.trackingNumber))
              ? item.trackingNumber
              : Number(item.trackingNumber)
            : '-',
          Name: (product && product.name) || '-',
          Address: item.address ? item.address : '-',
          City: item.city ? item.city : '-',
          State: item.state ? item.state : '-',
          Zip: usefullZip
            ? isNaN(Number(usefullZip))
              ? usefullZip
              : Number(usefullZip)
            : '-',
          DESC: (product && product.description) || '-',
          'sum Cost': cost === 'N/A' ? 0 : cost,
          Country: item.country ? item.country : '-',
          Fiber: (product && product.fiber) || '-',
          '3PL Customer': item.customer3PL || '-',
          Total: item.qtyShipped
            ? isNaN(Number(item.qtyShipped))
              ? 0
              : Number(item.qtyShipped)
            : 0,
        };
      });

      //Duty Paid INV-2437 5-18-20
      let exportName;
      exportName = 'No 321 LOG 2.0 - No 321 Template.xlsx';

      const wb = new ExcelJS.Workbook();

      const ws = wb.addWorksheet('No 321 Template');

      ws.getCell('A1').value = 'Date';
      ws.getCell('A2').value = 'Bultos';
      ws.getCell('B1').value = moment().startOf('day').format('DD/MM/YYYY');
      ws.getCell('B1').alignment = { horizontal: 'right' };
      ws.getCell('B2').value = {
        formula: 'SUM(O4:O' + (mappedData.length + 3) + ')',
      };

      let headers = [];
      ws.columns = Object.keys(mappedData[0]).map((field) => {
        headers.push(field);
        return { key: field, width: 30 };
      }, {});
      ws.getRow(3).values = headers;
      ws.addRows(mappedData);

      ws.getColumn('Q').font = {
        color: { argb: '00FFFFFF' },
      };

      ws.getCell('A3').font = {
        name: 'Courier New',
        size: 11,
        bold: true,
      };
      ws.getCell('B3').font = {
        bold: true,
      };

      ws.getCell('C3').font = {
        bold: true,
      };

      ws.getColumn('A').alignment = { horizontal: 'right' };
      ws.getColumn('D').numFmt = '###0';
      ws.getColumn('D').alignment = { horizontal: 'left' };
      ws.getColumn('E').numFmt = '###0';
      ws.getColumn('E').alignment = { horizontal: 'left' };

      let cells = [
        'A3',
        'B3',
        'C3',
        'D3',
        'E3',
        'F3',
        'G3',
        'H3',
        'I3',
        'J3',
        'K3',
        'L3',
        'M3',
        'N3',
        'O3',
      ];
      cells.forEach((cell) => {
        ws.getCell(cell).fill = {
          type: 'pattern',
          pattern: 'solid',
          fgColor: { argb: 'A4C2F4' },
        };
      });

      const buf = await wb.xlsx.writeBuffer();
      downloadFile(
        buf,
        exportName,
        'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
      );
    } catch (error) {
      console.error('error', error);
      swal(`Could Not Export`, error, 'error');
    }
  } else {
    swal(`Could Not Export`, `No records were on No 321 template`, 'error');
  }
};
