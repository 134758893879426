import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import {
  Grid,
  GridRow,
  GridColumn
} from 'semantic-ui-react';
import ActivityIndicator from '../../components/ActivityIndicator';

const TableNavigation = ({
  isBusy
}) => {
  return (
    <>
      <Grid columns={1} verticalAlign="middle" padded="vertically">
        <GridRow stretched>
          <GridColumn>
            {isBusy ? (
              <ActivityIndicator title="Loading..." />
            ) : (
                <Instructions>
                  &nbsp;
                </Instructions>
              )}
          </GridColumn>
        </GridRow>
      </Grid>
    </>
  );
};

const Instructions = styled.div`
  font-family: 'Roboto', sans-serif;
  font-size: 14px;
  color: rgba(0, 0, 0, 0.6);
  letter-spacing: 0.25px;
  text-align: left;
  line-height: 20px;
`;

TableNavigation.propTypes = {
  isBusy: PropTypes.bool
};

export default TableNavigation;