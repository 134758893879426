import React, { useState, useEffect } from 'react';
import { Form } from 'semantic-ui-react';
import swal from 'sweetalert';
import { Formik } from 'formik';
import Actions from './Actions';
import FormTransporte from './FormTransporte';
import axios from 'axios';
import feathers from '../../../services/feathers';
import SlidingPanel from '@bit/softwareland.flex.sl-sliding-panel';
import * as Yup from 'yup';

const Drawer = ({ openPanel, onClose, currentTransporte }) => {
  const service = feathers.service('transportes');
  const [currentSection, setCurrentSection] = useState(
    (currentTransporte && currentTransporte.id && 'EDIT') || 'CREATE'
  );
  useEffect(() => {});
  console.log(currentTransporte);
  const handleSave = async (values, { setSubmitting }) => {
    if (values) {
      let success = true;
      try{
        if(values.id){
          let result = await service.patch(values.id, values);
          console.log('resultCreate', result);
          swal('Actualizado', '¡Se ha actualizado correctamente!', 'success');
        }else{
        
          let resultCreate = await service.create(values);
          console.log('resultCreate', resultCreate);
          swal('Guardado', '¡Se ha guardado correctamente!', 'success');
        }
      }catch(exception){
        console.log(exception)
        swal('Error', 'Dont Save transporte', 'error');
      }
      if(success){
        setCurrentSection('CREATE');
        onClose(true);
      }
    } else swal('Error', 'Dont Save transporte', 'error');
  };

  const handledOnClose = async () => {
    let confirmation = await swal('Do you want close this form?', {
      buttons: ['No', 'Yes'],
    });

    if (confirmation) {
      setCurrentSection('CREATE');
      onClose(false);
    }
  };

  const validationSchema = Yup.object().shape({
    Nombre: Yup.string()
      .max(100, 'Too Long!'),
      Placa: Yup.string()
      .max(20)
      .required("Required"),
      AnioModelo: Yup.number()
      .required("Required"),
      ConfiguracionAutotransporte: Yup.number()
      .required("Required"),
      AseguradoraResponsabilidadCivil: Yup.string()
      .max(100)
      .required("Required"),
      PolizaResponsabilidadCivil: Yup.string()
      .max(30)
      .required("Required"),
      AseguradoraMedioAmbiente: Yup.string()
      .max(100)
      .nullable(true),
      PolizaMedioAmbiente: Yup.string()
      .max(30)
      .nullable(true),
      AseguradoraCarga: Yup.string()
      .max(100)
      .nullable(true),
      PolizaCarga: Yup.string()
      .max(30)
      .nullable(true),
      PrimaSeguro: Yup.string()
      .max(9)
      .nullable(true),
      TipoPermisoSCT: Yup.number()
      .required("Required"),
      NumPermisoSCT: Yup.string()
      .max(100)
      .required("Required"),
  });

  let drawerTitles = {
    CREATE: {
      title: 'Nuevo Transporte',
      subtitle: '',
    },
    EDIT: {
      title: 'Editar transporte',
      subtitle: '',
    },
  };

  let title = drawerTitles[currentSection].title;
  let subtitle = drawerTitles[currentSection].subtitle;


  return (
    <SlidingPanel
      isOpen={openPanel}
      size={100}
      title={title}
      subtitle={subtitle}
      onClose={handledOnClose}
    >
      <Formik
        initialValues={currentTransporte}
        onSubmit={handleSave}
        validationSchema={validationSchema}
      >
        {(formikProps) => (
          
          <Form
            onSubmit={formikProps.handleSubmit}
            style={{ marginRight: '15px' }}
            
          >
            <FormTransporte
              onSave={handleSave}
              // ref={refForm}
              // isValid={isValid}
              // setIsValid={setIsValid}
              formik={formikProps}
            />

            <Actions formik={formikProps} 
            handledOnClose={handledOnClose}/>
          </Form>
        )}
      </Formik>
    </SlidingPanel>
  );
};

export default Drawer;
