import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { AgGridReact } from '@ag-grid-community/react';
import { AllModules } from '@ag-grid-enterprise/all-modules';
import { Button, Label, Icon } from 'semantic-ui-react';
import swal from 'sweetalert';
import TableContainer from '../../components/TableContainer';
import { number, date, valueFormatter } from '../../components/AgGridColumns';
import statusOptions from './status';
import { Link } from 'react-router-dom';

const Table = ({
  data,
  onEditClick,
  selection,
  setSelection
}) => {
  // States
  const [gridApi, setGridApi] = useState();
  const [gridColumnApi, setGridColumnApi] = useState(null);
  const [defaultColDef] = useState({
    // flex: 1,
    filter: true,
    sortable: true,
    resizable: true,
  });

  const [columnDefs] = useState([
    { field: 'order', headerName: 'Order', valueFormatter },
    { field: 'tote', headerName: 'Tote', valueFormatter },
    { ...number, field: 'items', headerName: 'Items' },
    {
      field: '',
      headerName: 'Preview',
      width: 336,
      cellRendererFramework: ({ data }) => {
        const maxImg = 4;
        let preview = null;
        if (data && data.preview && data.preview.length) {
          preview = data.preview.slice(0, maxImg).map((imgPath, key) => (
            <WrapperPreview key={key}>
              <div>
                <img src={imgPath} width="46px" height="46px" />
              </div>
            </WrapperPreview>
          ));

          if (data.preview.length > maxImg) {
            preview.push(
              <Label as="a" size="big" key={maxImg}>
                +{data.preview.length - maxImg}
              </Label>
            );
          }

          return (
            <div style={{ display: 'flex' }}>
              {preview}
            </div>
          );
        }

        return (
          <WrapperPreview>
            <div>
              <object type="image/svg+xml"
                data="data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSI5NCIgaGVpZ2h0PSIxMDMiIHZpZXdCb3g9IjAgMCA5NCAxMDMiPgogICAgPHBhdGggZmlsbD0iI0MwQ0NEQiIgZmlsbC1ydWxlPSJldmVub2RkIiBkPSJNNjcuMTQ1IDcwLjQyNWMuMDkxLjE3LjE2LjM1My4yMDMuNTQxYTIuODgyIDIuODgyIDAgMCAxLTIuODg0IDIuODQ2SDMwLjUzNmEzLjA1OCAzLjA1OCAwIDAgMS0xLjIyMi0uMjcxIDIuODc3IDIuODc3IDAgMCAxLTEuMzU3LTMuODI4bDExLjkxLTI0Ljg2MWEyLjk0OSAyLjk0OSAwIDAgMSAxLjE1My0xLjI1MyAyLjkyIDIuOTIgMCAwIDEgMy45MzUgMS4wNWw1LjE1NyA5LjE3OS4yMDQuMzA1YTIuOTIxIDIuOTIxIDAgMCAwIDQuMDM4LjU3NWw0LjEwNS0zLjA4MmEyLjg1NCAyLjg1NCAwIDAgMSA0LjUxMiAxLjU5Mmw0LjE3NCAxNy4yMDd6TTI3Ljk5IDQyLjA0Yy0zLjYxNiAwLTYuNTQ4LTMuMDMzLTYuNTQ4LTYuNzc1IDAtMy43NCAyLjkzMi02Ljc3NCA2LjU0OC02Ljc3NCAzLjYxNyAwIDYuNTQ4IDMuMDMzIDYuNTQ4IDYuNzc0IDAgMy43NDItMi45MzEgNi43NzUtNi41NDggNi43NzV6bTE4LjQyMyA2MC44MzJhMTMuMDQ2IDEzLjA0NiAwIDAgMS02LjQxMi0xLjY5NEw2LjQ0NiA4MS45MDdBMTIuOTM1IDEyLjkzNSAwIDAgMSAwIDcwLjc2M1YzMi4xNWExMi44MzMgMTIuODMzIDAgMCAxIDYuNDQ2LTExLjExTDQwLjEzOCAxLjdhMTIuODc4IDEyLjg3OCAwIDAgMSAxMi43OSAwTDg2LjYyIDIxLjA0YTEyLjgzMyAxMi44MzMgMCAwIDEgNi40NDYgMTEuMTF2MzguNjEzYTEyLjkzNSAxMi45MzUgMCAwIDEtNi40NDYgMTEuMTQ0bC0zMy42OTEgMTkuMzRhMTMuMTQ4IDEzLjE0OCAwIDAgMS02LjUxNSAxLjYyNnptMC05NC41Yy0uNzYxLjAwMi0xLjUxLjItMi4xNzEuNTc2TDEwLjUxOCAyOC4zMjNhNC4zMzQgNC4zMzQgMCAwIDAtMi4xNzIgMy43NnYzOC42MTNhNC40MzYgNC40MzYgMCAwIDAgMi4xNzIgMy43OTNsMzMuNzI1IDE5LjM0YTQuMzE1IDQuMzE1IDAgMCAwIDQuMzQzIDBsMzMuNjktMTkuMzRhNC40MDIgNC40MDIgMCAwIDAgMi4yMDYtMy43OTNWMzIuMDgzYTQuNDAyIDQuNDAyIDAgMCAwLTIuMjA1LTMuNzk0TDQ4LjU4NiA4Ljk1YTQuNTE4IDQuNTE4IDAgMCAwLTIuMTcyLS41NzZ6Ii8+Cjwvc3ZnPgo=">
              </object>
              <img data-ui="Preview" width="46px" height="46px"></img>
            </div>
          </WrapperPreview>
        );
        return '';
      }
    },
    { ...date, field: 'shipDate', headerName: 'Ship by date' },
    {
      field: '',
      headerName: 'Status',
      cellRendererFramework: ({ data }) => {
        if (data && data.status && data.status.length) {
          const status = statusOptions.find(status => status.value === data.status);

          if (status && status.text) {
            return (
              <WrapperLabelStatus>
                <Label as="a" circular size="large">
                  {status.text}
                </Label>
              </WrapperLabelStatus>
            );
          }
        }

        return '-';
      }
    },
    {
      field: '',
      headerName: 'Marked as',
      cellRendererFramework: ({ data }) => {
        if (data && data.markedAs && data.markedAs.length) {
          return (
            <WrapperLabelMarkedAs>
              <Label as="a" circular size="large">
                <Icon name="arrow up" /> {data.markedAs}
              </Label>
            </WrapperLabelMarkedAs>
          );
        }

        return '-';
      }
    },
    { field: 'tags', headerName: 'Tags', valueFormatter },
    {
      headerName: '',
      field: '',
      pinned: 'right',
      cellStyle: { textAlign: 'center' },

      cellRendererFramework: (params) => {
        if (params.data)
          return (
            <Link to={`/packing/${params.data._id}`}>
              <Button
                compact
                primary
              >
                Edit
            </Button>
            </Link>
          );
      }
    }
  ]);

  // Effects
  useEffect(() => {
    // console.debug('useEffect Table', selection, (gridApi && gridApi.getSelectedRows()));
    if (
      selection &&
      !selection.length &&
      gridApi &&
      gridApi.getSelectedRows().length
    ) {
      gridApi.deselectAll();
    }
  }, [selection, gridApi]);

  useEffect(() => {
    if (gridColumnApi) {
      var allColumnIds = gridColumnApi
        .getAllColumns()
        .filter(column => column.colDef.field !== '')
        .map(column => column.colId);

      gridColumnApi.autoSizeColumns && gridColumnApi.autoSizeColumns(allColumnIds, false);
    }
  }, [data, gridColumnApi]);

  // Handles
  const handleEditClick = product => {
    onEditClick && onEditClick({ ...product });
  };

  const handleSelectionChanged = (event) => {
    if (selection && setSelection) {
      let rows = [];

      try {
        if (event && event.api) {
          rows = event.api.getSelectedRows().map(row => row._id);
        }
      } catch (err) {
        rows = [];

        swal({
          title: 'Error',
          text: 'Failed to select Products',
          icon: 'error',
          buttons: {
            cancel: 'Close',
          },
        });
      } finally {
        // console.debug('onSelectionChanged event rows', '[' + rows.map(item => `ObjectId('${item}')`).join(', ') + ']');
        setSelection(rows);
      }
    }
  };

  const handleGridReady = ({ api, columnApi }) => {
    setGridApi(api);
    setGridColumnApi(columnApi);
  }

  return (
    <TableContainer className="ag-theme-material" rowFilters={1}>
      <AgGridReact
        modules={AllModules}
        columnDefs={columnDefs}
        defaultColDef={defaultColDef}
        rowSelection="multiple"
        suppressRowClickSelection={true}
        onSelectionChanged={handleSelectionChanged}
        rowData={data}
        pagination={true}
        paginationPageSize={100}
        onGridReady={handleGridReady}
      />
    </TableContainer>
  );
};

const LabelStatusColor = `
  color: rgb(222, 146, 9) !important;
  background-color: rgb(253, 241, 207) !important;
`;

const WrapperLabelStatus = styled.div`
  && {
    a.label { 
      ${LabelStatusColor}
      padding: 0.5em 1em !important;
      font-weight: normal !important;

      &:hover {
        ${LabelStatusColor}
      }
    }
  }
`;

const LabelMarkedAsColor = `
  color: rgb(239, 82, 82) !important;
  background-color: rgb(255, 255, 255) !important;
`;

const WrapperLabelMarkedAs = styled.div`
  && {
    a.label { 
      ${LabelMarkedAsColor}
      padding: 0.5em 1em !important;
      font-weight: normal !important;

      box-shadow: rgb(231, 236, 244) 0px 0px 0px 1px !important;
      white-space: nowrap !important;
      cursor: auto !important;
      border-radius: 15px !important;

      &:hover {
        ${LabelMarkedAsColor}
      }
    }
  }
`;

const WrapperPreview = styled.div`
  width: 46px;
  height: 46px;
  margin-right: 10px;
  overflow: hidden;

  div {
    position: relative;
    left: 0px;
    width: 46px;
    height: 46px;
    background-color: rgb(249, 250, 252);
    border-radius: 3px;
    
    object {
      width: 28px;
      height: 31px;
      position: absolute;
      margin: 7px 8px;
    }

    img {
      width: 46px;
      height: 46px;
      position: absolute;
      border-radius: 3px;
    }
  }
`;

Table.propTypes = {
  data: PropTypes.array,
  selection: PropTypes.array,
  setSelection: PropTypes.func,
};

export default Table;