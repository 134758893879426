import React from 'react';
import styled from 'styled-components';

export default props => <SectionTitle>{props.children}</SectionTitle>;

const SectionTitle = styled.h2`
  font-family: 'Roboto', sans-serif;
  font-size: 34px;
  font-weight: 400;
  color: rgba(57, 57, 57, 0.87);
  letter-spacing: 0;
  line-height: 36px;
  margin-top: 24px !important;
`;
