import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import DefaultLayout from '../../layouts/DefaultLayout';
import TopNavigation from './TopNavigation';
import HeaderBG from '../../components/HeaderBG';
import SectionTitle from '../../components/SectionTitle';
import { Container } from 'semantic-ui-react';
import TableNavigation from './TableNavigation';
import Table from './Table';
import Filters from './Filters';
import feathers from '../../services/feathers';
import swal from 'sweetalert';
import { pluralize } from '../../functions/common';
import Drawer from './Drawer';
import maxBy from 'lodash/maxBy';

const AutomationRules = (props) => {
  const service = feathers.service('automation-rule');

  // States
  const [currentRule, setCurrentRule] = useState({});
  const [isBusy, setIsBusy] = useState(false);
  const [openPanel, setOpenPanel] = useState(false);
  const [data, setData] = useState([]);
  const [selection, setSelection] = useState([]);
  const [messageAction, setMessageAction] = useState();
  const [query, setQuery] = useState(null);
  const [nextPriority, setNextPriority] = useState(0);

  // Effects
  useEffect(() => {
    const filter = () => {
      if (query) {
        getData();
      }
    };

    filter();
  }, [query]);

  // Functions
  const getData = async () => {
    if (query) {
      let data = [],
        result = [];

      try {
        setIsBusy(true);
        setSelection([]);

        // console.debug('query', JSON.stringify(query, null, 2));
        do {
          result = await service.find({
            query: {
              // ...query,

              $skip: data.length,
              $sort: {
                priority: 1,
              },
            },
          });

          data = data.concat(result.data);

          // console.debug('result', result);
        } while (data.length !== result.total);
      } catch (err) {
        console.error('Error', err);
        data = [];

        swal({
          title: 'Error',
          text: 'Failed get Rules',
          icon: 'error',
          buttons: {
            cancel: 'Close',
          },
        });
      } finally {
        setData(data);
        const rule = maxBy(data, 'priority');
        setNextPriority(((rule && rule.priority) || 0) + 1);
        setIsBusy(false);

        // refFilters && refFilters.current && refFilters.current.focus();
      }
    }
  };

  // Handles
  const openDrawer = (rule) => {
    setCurrentRule(rule);
    setOpenPanel(true);
  };

  const handleCreateClick = () => {
    openDrawer({ priority: nextPriority });
  };

  const handleEditClick = (rule) => {
    openDrawer(rule);
  };

  const handleDeleteClick = async () => {
    let success = false;
    const total = selection.length;

    try {
      let msg = `Do you want to delete ${total} ${pluralize(total, 'rule')}?`;
      let confirmation = await swal(msg, {
        buttons: ['No', 'Yes'],
      });

      if (confirmation) {
        setIsBusy(true);
        setMessageAction('Deleting ...');
        const result = await service.remove(selection);
        // console.debug('result', result);

        if (result) {
          success = true;
        } else throw Error('Not found');
      }
    } catch (error) {
      // console.debug(error);
      await swal(
        'Error',
        `Don't Delete selected ${pluralize(total, 'rule')}`,
        'error'
      );
    } finally {
      setMessageAction();
      setIsBusy(false);

      if (success) {
        await swal('Done', 'Finished', 'success');

        setSelection([]);
        getData();
      }
    }
  };

  const handleClose = (isSuccess) => {
    setCurrentRule({});
    setOpenPanel(false);

    isSuccess && getData();
  };

  return (
    <>
      <DefaultLayout
        currentPath={props.match.path}
        globalActions={
          <TopNavigation isBusy={isBusy} onCreateClick={handleCreateClick} />
        }
      >
        <HeaderBG />

        <Container>
          <SectionTitle>Automation Rules</SectionTitle>

          <Filters isBusy={isBusy} query={query} setQuery={setQuery} />

          <TableNavigation
            isBusy={isBusy}
            haveSelected={selection.length > 0}
            onDeleteClick={handleDeleteClick}
            messageAction={messageAction}
          />

          <Table
            data={data}
            setData={setData}
            onEditClick={handleEditClick}
            selection={selection}
            setSelection={setSelection}
          />
        </Container>
      </DefaultLayout>

      {openPanel ? (
        <Drawer
          openPanel={openPanel}
          onClose={handleClose}
          rule={currentRule}
        ></Drawer>
      ) : null}
    </>
  );
};

AutomationRules.propTypes = {
  match: PropTypes.object.isRequired,
};

export default AutomationRules;
