import React, { useState } from 'react';
import SlidingPanel from '@bit/softwareland.flex.sl-sliding-panel';
import swal from 'sweetalert';
import Actions from './Actions';
import FormAddProduct from '../../../components/FormAddProduct';
import DrawerFiltersWrapper from '../../../components/DrawerFiltersWrapper';

const Drawer = ({
  openPanel,
  onClose,
  product,
}) => {
  const [currentSection, setCurrentSection] = useState((product && product._id && 'EDIT') || 'CREATE');
  const [isValid, setIsValid] = useState(false);
  const refForm = React.useRef(null);

  let drawerTitles = {
    CREATE: {
      title: 'Create New Product',
      subtitle: '',
    },
    EDIT: {
      title: 'Edit Product',
      subtitle: '',
    },
  };

  let title = drawerTitles[currentSection].title;
  let subtitle = drawerTitles[currentSection].subtitle;

  const handledOnClose = async () => {
    let confirmation = await swal('Do you want close this form?', {
      buttons: ['No', 'Yes'],
    });

    if (confirmation) {
      setCurrentSection('CREATE');
      onClose(false);
    }
  };

  const handledSaveClick = () => {
    refForm.current.submit();
  };

  const handleSave = (product) => {
    setCurrentSection('EDIT');
    onClose(true);
  };

  return (
    <SlidingPanel
      isOpen={openPanel}
      size={50}
      title={title}
      subtitle={subtitle}
      onClose={handledOnClose}
    >
    <DrawerFiltersWrapper>
      <FormAddProduct
        currentProduct={product}
        onSave={handleSave}
        ref={refForm}
        isValid={isValid}
        setIsValid={setIsValid}
      />

      <Actions 
        onSaveClick={handledSaveClick} 
        isValid={isValid}
        handledOnClose={handledOnClose}
      />
      </DrawerFiltersWrapper>
    </SlidingPanel>
  );
};

export default Drawer;
