import React, { useState, useEffect } from 'react';
import swal from 'sweetalert';
import DefaultLayout from '../../layouts/DefaultLayout';
import HeaderBG from '../../components/HeaderBG';
import { Container } from 'semantic-ui-react';
import SectionTitle from '../../components/SectionTitle';
import TopNavigation from './TopNavigation';
import Filters from './Filters';
import TableNavigation from './TableNavigation';
import Table from './Table';
import Drawer from './Drawer';
import feathers from '../../services/feathers';
import { ReportImportExport, Report321 } from "./Functions";

const ImportExport = ({ match: { path } }) => {
  const service = feathers.service('product-data');

  // States
  const [isBusy, setIsBusy] = useState(false);
  const [openPanel, setOpenPanel] = useState(false);
  const [data, setData] = useState([]);
  const [dataInvalid, setDataInvalid] = useState([]);
  const [selection, setSelection] = useState([]);
  const [query, setQuery] = useState(null);

  // Effects
  useEffect(() => {
    const filter = () => {
      if (query) {
        getData();
      }
    };

    filter();
  }, [query]);

  // Functions
  const getData = async () => {
    console.time('Get Produts');
    if (query) {
      let data = [],
        result = [];

      try {
        setIsBusy(true);
        setSelection([]);
        setData([]);
        setDataInvalid([]);

        // console.debug('query', JSON.stringify(query, null, 2));
        do {
          result = await service.find({
            query: {
              ...query,

              $skip: data.length,
            },
          });

          data = data.concat(result.data);

          // console.debug('result', result);
        } while (data.length !== result.total);
      } catch (err) {
        console.error('Error', err);
        data = [];

        swal({
          title: 'Error',
          text: 'Failed Get Products',
          icon: 'error',
          buttons: {
            cancel: 'Close',
          },
        });
      } finally {
        setData(data);

        // Sacamos los productos invalidos
        const invalid = data.filter(({ exportCode, isTextile, fiber }) => !exportCode || (isTextile && !fiber) || false);
        setDataInvalid(invalid);

        setIsBusy(false);

        // refFilters && refFilters.current && refFilters.current.focus();
      }
    }
    console.timeEnd('Get Produts');
  };

  // Handles
  const handleValidateClick = () => dataInvalid.length && setOpenPanel(true);

  const handleImportExportReportClick = () => ReportImportExport(data);

  const handle321ReportClick = () => Report321(data);

  const handleClose = isSuccess => {
    setOpenPanel(false);

    isSuccess && getData();
  };

  return (
    <>
      <DefaultLayout
        currentPath={path}
        globalActions={<TopNavigation
          isBusy={isBusy}
          hasData={data.length > 0}
          hasDataInvalid={dataInvalid.length > 0}
          onValidateClick={handleValidateClick}
          onImportExportReportClick={handleImportExportReportClick}
          on321ReportClick={handle321ReportClick}
        />}
      >
        <HeaderBG />

        <Container>
          <SectionTitle>Import-Export Report</SectionTitle>

          <Filters isBusy={isBusy} query={query} setQuery={setQuery} />

          <TableNavigation isBusy={isBusy} />

          <Table
            data={data}
            selection={selection}
            setSelection={setSelection}
          />
        </Container>
      </DefaultLayout>

      {openPanel && dataInvalid.length ? (
        <Drawer
          onClose={handleClose}
          data={dataInvalid}
        ></Drawer>
      ) : null}
    </>
  );
};

export default ImportExport;