module.exports = (importing = true) => {
  let row = {
    id: 40487,
    idTipoComprobante: 'T',
    Serie: 'AA',
    Folio: 109,
    FechaDocumento: '2022-02-04T06:49:02.000Z',
    EsGlobal: false,
    idSucursal: 1,
    idPOS: 1,
    idModulo: 1,
    idUser: 'irving',
    Fecha_Log: null,
    Source_Log: null,
    idStatus: 1,
    EmisorRFC: 'LCT121113ES0',
    EmisorNombre: 'LECAR CONSULTORIA EN TI SC',
    idRegimen: '601',
    LugarExpedicion: '83249',
    idSocioNegocio: 10372,
    ReceptorRFC: 'LCT121113ES0',
    ReceptorNombre: 'LECAR CONSULTORIA EN TI SC',
    idUsoCFDI: 'P01',
    idMetodoPago: 'PPD',
    idFormaPago: '99',
    idMoneda: 'MXN',
    TipoDeCambio: 1,
    Subtotal: 0,
    TotalDescuentos: 0,
    ImpuestosTraslados: 0,
    ImpuestosRetenciones: 0,
    ImpuestosGlobalTraslados: 0,
    ImpuestosGlobalRetenciones: 0,
    Total: 0,
    TotalLetra: 'CERO PESOS 00/100 M.N.',
    MotivoDescuento: '',
    Condicion: 'CONTADO',
    Comentarios: 'Traslado Internacional Salida',
    NumAprobacion: null,
    AnioAprobacion: null,
    FechaAprobacion: null,
    cfd_tipo: 0,
    cfd_version: 3.3,
    cfd_noCertificado: null,
    cfd_Certificado: null,
    cfd_sello:
      'j/89JRoR52plfADaw6p/xiYfhSIKWIjZwUd1/AMBouayoptlNFVwy0UvCDEG+GcpmfbsQzpMKQbWK1yqL05AAoJwFdrQZAIcJwUmVcfj9KjuP9ZYQNxbj9Qdehib6TFXJhuU2QEc3HMiCv3zEypW+0R8iT8w8kIWVKA7BOTGuWYBRCfuOogbmCEY+sTcf+cwRxPRpqHywunlPmqdXtMNOadC2OAmPAfa0M8EN+WBOufSyV99J7DGcxOFWHgESw8lKqzAwjrK/jzmTiL56Vt7EDChBZTXDlHE9xf7cNyYUJyWMHYDcHteTnQxg5SPDGKdFO3pftSCWgzZDIZdZYrVJg==',
    cfd_cadenaOriginal:
      '||3.3|AA|109|2022-02-04T06:49:02|00001000000506131044|0|XXX|0|T|83249|LCT121113ES0|LECAR CONSULTORIA EN TI SC|601|LCT121113ES0|LECAR CONSULTORIA EN TI SC|P01|43211600|25.0000|H87|Pieza|ADAPTADOR DE CORRIENTE|0.0000|0.00|2.0|Sí|Salida|USA|01|15.00|Origen|O0001|LCT121113ES0|2022-02-04T11:49:02|SOLANO|16|2456|07|030|SON|MEX|83249|Destino|D0002|XEXX010101000|12345678|USA|2022-02-04T11:49:02|15.00||CA|USA|34343|10.000|KGM|1|43211600|ADAPTADOR DE CORRIENTE|25.0000|H87|10.000|1500.00|MXN|0101290300|25.0000|O0001|D0002|TPAF01||C2|2342342|465464|AseguraRespCivil|PolizaRespCivil|AseguraMedAmbiente|PolizaMedAmbiente|AseguraCarga|654654.00|01|RFG33243|124|23423|AFG||',
    cfd_xml: null,
    timbre_version: null,
    timbre_uuid: null,
    timbre_FechaTimbrado: null,
    timbre_noCertificadoSAT: null,
    timbre_selloSAT: null,
    timbre_RFCPAC: null,
    EmisorCalle: 'ENRIQUE GARCIA SANCHEZ',
    EmisorNumExterior: '30',
    EmisorNumInterior: '',
    EmisorColonia: 'SAN BENITO',
    EmisorLocalidad: 'HERMOSILLO',
    EmisorReferencia: '',
    EmisorMunicipio: '',
    EmisorEstado: '',
    EmisorPais: '',
    EmisorCodPos: '',
    EmisorCURP: null,
    ReceptorCalle: 'ENRIQUE GARCIA SANCHEZ',
    ReceptorNumExterior: '30',
    ReceptorNumInterior: '',
    ReceptorColonia: 'SAN BENITO',
    ReceptorLocalidad: 'HERMOSILLO',
    ReceptorReferencia: '',
    ReceptorMunicipio: '',
    ReceptorEstado: '',
    ReceptorPais: '',
    ReceptorCodPos: '',
    ReceptorCURP: null,
    FechaSolicitudCancelacion: null,
    FechaAceptacionCancelacion: null,
    idSocioNegocioDireccion: null,
    idSocioNegocioRFC: null,
    idSocioNegocioRFCDireccion: null,
    FechaCorteCancelacion: null,
    NombreHuesped: null,
    Organizacion: '620c3c2ce3f1f283dbec5dc8',
    Importando: true,
  };

  let newRows = [];

  [1, 2, 3, 4, 5, 6, 7, 8, 9, 10].map((index) => {
    let newRow = JSON.parse(JSON.stringify(row));

    newRow.id = newRow.id + index;
    newRow.Folio = newRow.Folio + index;

    if (importing) {
      newRow.Importando = true;
      newRows.push(newRow);
    } else {
      newRow.Importando = false;
      newRows.push(newRow);
    }
  });

  return newRows;
};
