import React, { useState } from 'react';
import TableContainer from '../../components/TableContainer';
import { AgGridReact } from '@ag-grid-community/react';
import { AllModules } from '@ag-grid-enterprise/all-modules';
import { Button } from 'semantic-ui-react';
import swal from 'sweetalert';

const Table = ({ data, getData, onEditClick, setSelection }) => {
  const [columnDefs] = useState([
    {
      headerName: 'Name',
      field: 'name',
      checkboxSelection: true,
      headerCheckboxSelection: true,
    },
    {
      headerName: 'Actions',
      field: '',
      pinned: 'right',
      cellStyle: { textAlign: 'center' },
      cellRendererFramework: (params) =>
        (params && params.data && (
          <Button compact primary onClick={() => handledEditClick(params.data)}>
            Edit
          </Button>
        )) ||
        null,
    },
  ]);

  const [defaultColDef] = useState({ flex: 1 });

  const handledEditClick = (shipper) => {
    onEditClick && onEditClick({ ...shipper });
  };

  const onGridReady = () => {
    getData && getData();
  };

  const handledSelectionChanged = ({ api }) => {
    let rows;
    try {
      if (api) {
        rows = api.getSelectedRows();
      }
    } catch (error) {
      console.error('error :>> ', error);
      rows = [];
      let err = {
        title: 'Error',
        text: 'Failed to select Partners',
        icon: 'error',
        buttons: { cancel: 'Close' },
      };
      swal(err);
    } finally {
      setSelection(rows);
    }
  };

  return (
    <TableContainer className="ag-theme-material" rowFilters={1}>
      <AgGridReact
        rowData={data}
        modules={AllModules}
        columnDefs={columnDefs}
        defaultColDef={defaultColDef}
        rowSelection="multiple"
        pagination={true}
        paginationPageSize={100}
        suppressRowClickSelection={true}
        onGridReady={() => onGridReady()}
        onSelectionChanged={handledSelectionChanged}
      />
    </TableContainer>
  );
};

export default Table;
