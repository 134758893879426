import React from 'react';
import PropTypes from 'prop-types';
import { Button } from 'semantic-ui-react';

const TopNavigation = ({
  isBusy,
  onCreateClick
}) => (
    <Button primary compact onClick={onCreateClick} disabled={isBusy}>
      Create Shipper
    </Button>
  );

TopNavigation.propTypes = {
  isBusy: PropTypes.bool,
  onCreateClick: PropTypes.func
};

export default TopNavigation;