export const SDZips = [
  '91902',
  '91901',
  '91905',
  '91903',
  '91908',
  '91906',
  '91910',
  '91912',
  '91911',
  '91914',
  '91913',
  '91916',
  '91915',
  '91917',
  '91932',
  '91931',
  '91934',
  '91933',
  '91941',
  '91935',
  '91942',
  '91945',
  '91946',
  '91950',
  '91948',
  '91962',
  '91963',
  '91978',
  '91977',
  '91980',
  '91979',
  '92004',
  '92003',
  '92008',
  '92007',
  '92010',
  '92009',
  '92011',
  '92018',
  '92014',
  '92020',
  '92019',
  '92021',
  '92024',
  '92026',
  '92025',
  '92028',
  '92027',
  '92029',
  '92036',
  '92033',
  '92037',
  '92040',
  '92049',
  '92052',
  '92051',
  '92055',
  '92054',
  '92057',
  '92056',
  '92059',
  '92058',
  '92061',
  '92060',
  '92065',
  '92064',
  '92067',
  '92066',
  '92069',
  '92068',
  '92071',
  '92070',
  '92074',
  '92072',
  '92078',
  '92075',
  '92081',
  '92079',
  '92083',
  '92082',
  '92085',
  '92084',
  '92086',
  '92091',
  '92093',
  '92092',
  '92101',
  '92096',
  '92103',
  '92102',
  '92105',
  '92104',
  '92107',
  '92106',
  '92109',
  '92108',
  '92111',
  '92110',
  '92113',
  '92112',
  '92115',
  '92114',
  '92117',
  '92116',
  '92119',
  '92118',
  '92121',
  '92120',
  '92123',
  '92122',
  '92126',
  '92124',
  '92128',
  '92127',
  '92130',
  '92129',
  '92132',
  '92131',
  '92134',
  '92136',
  '92135',
  '92138',
  '92140',
  '92139',
  '92145',
  '92155',
  '92154',
  '92159',
  '92158',
  '92163',
  '92165',
  '92171',
  '92173',
  '92172',
  '92175',
  '92177',
  '92176',
  '92179',
  '92178',
  '92182',
  '92186',
  '92197',
  '92198',
  '92672',
];
export default { SDZips };
