import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Input, Dropdown, Form, Label } from 'semantic-ui-react';
import ValueEditorOld from '../../../components/QueryBuilder/ValueEditor';
import Popup from '../../../components/QueryBuilder/Popup';
import styled from 'styled-components';

const ValueEditor = (props) => {
  // console.debug('ValueEditor', props);
  const [options, setOptions] = useState([]);
  const [multiple, setMultiple] = useState(false);

  useEffect(() => {
    const newValue = ['in', 'notIn'].includes(props.operator);

    setMultiple(newValue);
  }, [props.operator]);

  useEffect(() => {
    const newOptions = (Array.isArray(props.values) ? props.values : []).map(
      ({ name, label: text }) => ({
        key: name,
        text,
        value: name,
      })
    );

    setOptions(newOptions);
  }, [props.values]);

  const handleChange = (event, { value }) => {
    let newValue = value;
    if (multiple) {
      if (props.inputType === 'select') {
        newValue = Array.isArray(value) ? value.join(', ') : '';
      } else if (props.inputType === 'number' && value.length) {
        if (/^(\d|\.|,|\s)+?$/.test(value)) {
          newValue = value.replace(/\s/g, '');
          let point = newValue.slice(-1);
          if (point === ',' || point === '.') {
            newValue = newValue.slice(0, -1);
          } else {
            point = '';
          }

          if (/^(\s*\d+(\.\d+)?\s*,?\s*)+$/.test(newValue)) {
            if (point === '.') {
              const last = newValue.split(',').slice(-1)[0];
              if (last.length === 0 || last.includes('.')) {
                point = '';
              }
            }

            newValue = newValue.replace(/,/g, ', ') + point;
          } else {
            // console.debug('handleChange FormatInvalid', event, value);
            event.preventDefault();
            return;
          }
        } else {
          // console.debug('handleChange ValueInvalid', event, value);
          event.preventDefault();
          return;
        }
      }
    }

    props.handleOnChange && props.handleOnChange(newValue);
  };

  const getValue = () => {
    if (multiple) {
      return (props.value && props.value.split(', ')) || [];
    }
    return props.value || null;
  };

  const getMessage = () => {
    let messages = [];
    switch (props.field) {
      case 'line_items.sku':
      case 'line_items.product_name':
        messages.push(
          <>
            Since a package could contain more than one different product, the
            <br />
            rule will apply if at least one of the products contained in the
            <br />
            package matches the rule.
          </>
        );
        break;

      case 'line_items.quantity':
        messages.push(
          <>
            The quantity considered is the total of pieces in the package,
            <br />
            regardless of whether they are different products.
          </>
        );
        break;
      case 'line_items.price':
        messages.push(
          <>
            The price corresponds to the sum of the individual prices of the
            <br />
            pieces contained in the package.
          </>
        );
        break;

      default:
        break;
    }

    if (props.operator === 'contains') {
      messages.unshift(
        <>
          This operator checks if the indicated string is a substring of the
          <br />
          indicated field.
        </>
      );
    }

    if (multiple) {
      messages.unshift(<>Value 1, Value 2, Value 3</>);
    }

    if (messages.length) {
      const labelMsg =
        messages.length > 1 ? (
          <ol style={{ paddingLeft: '0.8em' }}>
            {messages.map((msg, key) => (
              <li key={key}>{msg}</li>
            ))}
          </ol>
        ) : (
          <>{messages[0]}</>
        );

      return (
        <LabelStyled pointing basic size="large">
          {labelMsg}
        </LabelStyled>
      );
    } else {
      return <></>;
    }
  };

  return (
    <>
      <WrapContent>
        {props.inputType === 'select' ? (
          <Popup content={props.title}>
            <Dropdown
              placeholder={props.fieldData.label}
              value={getValue()}
              selection
              options={options}
              onChange={handleChange}
              multiple={multiple}
            />
          </Popup>
        ) : multiple ||
          [
            'line_items.sku',
            'line_items.product_name',
            'line_items.quantity',
            'line_items.price',
          ].includes(props.field) ||
          props.operator === 'contains' ? (
          <Popup content={props.title}>
            <Form.Field>
              <Input
                placeholder={props.fieldData.label}
                onChange={handleChange}
                value={props.value}
                type={multiple ? 'text' : props.inputType}
              />
              {getMessage()}
            </Form.Field>
          </Popup>
        ) : (
          <ValueEditorOld {...props} />
        )}
      </WrapContent>
    </>
  );
};

const WrapContent = styled.div`
  display: inline-block !important;
  vertical-align: top !important;
`;

const LabelStyled = styled(Label)`
  color: #4f9aa8 !important;
  border-color: #4f9aa8 !important;
`;

ValueEditor.propTypes = {
  field: PropTypes.string.isRequired,
  fieldData: PropTypes.object.isRequired,
  handleOnChange: PropTypes.func.isRequired,
  inputType: PropTypes.string.isRequired,
  operator: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired,
  values: PropTypes.array.isRequired,
};

export default ValueEditor;
