import React, { useEffect } from 'react';
import styled from 'styled-components';
import DateRangePicker from '../../../../../components/DateRangePicker';
import moment from 'moment';
import swal from 'sweetalert';
import { VerticalDirection } from '@ag-grid-community/core';

const DateRange = ({ data, setData, state, setState }) => {
  const minDate = moment().startOf('day').subtract(7, 'days');
  const maxDate = moment().endOf('day');

  // Effects
  useEffect(() => {
    setState({ ...state, isValid: true });
  }, [data]);

  // Handles
  const handleDateRangeChange = (range) => {
    if (range) {
      setData({ ...data, startDate: range[0], endDate: range[1] });

      const from = range[0].clone().startOf('day');
      const to = range[1].clone().endOf('day');
      const diff = to.diff(from, 'days');

      if (diff >= 4) {
        swal(
          'BIG DATE RANGE',
          `The date range selected is bigger than 4 days, this could get data incomplete.`,
          'warning'
        );
      }
    }
  };

  return (
    <div>
      <WrapperDateRange>
        <div
          style={{
            gridArea: 'range',
          }}
        >
          <div style={{ display: 'block' }}>
            <Title> Firts item on last cargo: </Title>
            {data.info.firstCargoItem}
          </div>
          <div style={{ display: 'block' }}>
            <Title>Last item on last cargo: </Title>
            {data.info.lastCargoItem}
          </div>
        </div>
      </WrapperDateRange>
      <WrapperDateRange>
        <DateRangePicker
          style={{ gridArea: 'range' }}
          startDate={data.startDate}
          endDate={data.endDate}
          minDate={minDate}
          maxDate={maxDate}
          onChange={handleDateRangeChange}
        />
      </WrapperDateRange>
    </div>
  );
};

const WrapperDateRange = styled.div`
  width: '100%';
  height: 12vh;
  vertical-align: center;
  align-items: center;
  align-content: center;

  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-template-rows: auto;
  grid-gap: 1em;
  grid-template-areas: '. range range .';
`;

const Title = styled.label`
  font-weight: bold;
  margin-right: 10px;
`;
export default DateRange;
