import React from 'react';

import DropdownSearchFetch from '../../../components/Formik/DropdownSearchFetch';

function DropdownLocalidad({ formik, values, names, errors }) {
  let { setFieldValue } = formik;
  return (
    <DropdownSearchFetch
      fluid
      selection
      label="Localidad"
      value={values.Localidad}
      allowAdditions={values.Pais !== 'MEX'}
      name={names.Localidad}
      onChange={(e, { value }) => {
        setFieldValue(names.Localidad, value);
        setFieldValue(names.CodigoPostal, '');
        setFieldValue(names.Colonia, '');
      }}
      valuesChangesToUpdate={[
        values.Pais,
        values.Estado,
        values.Municipios,
        values.Localidad,
      ]}
      placeholder="Localidad"
      featherService="catalogo-localidad"
      columName="Descripcion"
      columValue="c_Localidad"
      mapValues={(value) => {
        return {
          key: Math.random(),
          text: `${value['Descripcion']}`,
          value: value['c_Localidad'],
        };
      }}
      queryWithSearch={(columName, columValue, searchQuery) => {
        let newQuery = {
          $or: [
            {
              [columName]: {
                $like: '%' + searchQuery + '%',
              },
            },
            {
              [columValue]: {
                $like: '%' + searchQuery + '%',
              },
            },
          ],
          c_Estado: values.Estado,
          $limit: 10,
        };

        return newQuery;
      }}
      queryInit={(columName, columValue, value) => {
        let newQuery;
        if (value) {
          newQuery = {
            [columValue]: { $like: value },
            c_Estado: values.Estado,
            $limit: 1,
          };
        } else {
          newQuery = {
            $limit: 10,
            c_Estado: values.Estado,
          };
        }

        return newQuery;
      }}
    />
  );
}

export default DropdownLocalidad;
