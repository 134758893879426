import { filterByTag } from './FilterShippedItems';
// const validateToCreateReport = (shippedItem) => {
//   return (
//     (!shippedItem.dailylog.length ||
//       !shippedItem.dailylog[0].bin ||
//       !shippedItem.dailylog[0].orderNumber) ||
//     (!shippedItem.product.length ||
//       (shippedItem.product[0].isTextile && !shippedItem.product[0].fiber) ||
//       (shippedItem.product[0].isKit && !shippedItem.product[0].exportCode) ||
//       !shippedItem.product[0].name ||
//       !shippedItem.product[0].description ||
//       !shippedItem.product[0].cost)
//   );
// };

export const isInvalidProduct = (shippedItem) => {
  return (
    filterByTag(shippedItem) &&
    (!shippedItem.product.length ||
      (shippedItem.product[0].isTextile && !shippedItem.product[0].fiber))
  );
};

export const isValidDailylog = (shippedItem) => {
  return shippedItem.dailylog.length && shippedItem.dailylog[0].bin;
};

export const isInvalidToCreateReport = (shippedItem) => {
  return !Array.isArray(shippedItem) || !shippedItem.length;
};
