import React, { useState, useEffect } from 'react';
import DefaultLayout from '../../layouts/DefaultLayout';
import HeaderBG from '../../components/HeaderBG';
import SectionTitle from '../../components/SectionTitle';
import Drawer from './Drawer';
import { Container } from 'semantic-ui-react';
import Table from '../Emisores/Table';
import swal from 'sweetalert';
import feathers, { useAuth } from '../../services/feathers';
import Filters from './Filters';
import TopNavigation from './TopNavigation';

const Emisores = (props) => {
  const service = feathers.service('pos');
  const auth = useAuth();

  let [query, setQuery] = useState({Nombre : {$like:'%%'}});
  const [isBusy, setIsBusy] = useState(false);
  const [selection, setSelection] = useState([]);
  const [data, setData] = useState([]);
  const [isSupport, setIsSupport] = useState(false);
  const [isValid, setIsValid] = useState(false);
  const [openPanel, setOpenPanel] = useState(false);
  const [currentEmisor, setCurrentEmisor] = useState({});
  const [emisor, setEmisor] = useState(null);
  

  useEffect(() => {
    const filter = () => {
      
      if(!query)
        query = {
          Nombre : {$like:'%%'}
        };
      getData();
    };

    filter();
  }, [query]);

  useEffect(() => {
    // console.debug('auth', JSON.stringify(auth, null, 2));
    const { isSupport } = (auth && auth.user) || {},
      client = (auth && auth.user && auth.user.client) || null,
      isInvalid = (!isSupport && !client && true) || false;

    setIsSupport(isSupport);
    setEmisor(client);
    setIsValid(!isInvalid);
  }, [auth.user]);

  const handleCreateClick = () => {
    const [clients, client] = auth.user.isSupport
      ? [[], {}]
      : [[{ ...auth.user.client }], { ...auth.user.client }];
    openDrawer({ clients, client });
  };

  const openDrawer = (emisor) => {
    setCurrentEmisor(emisor);
    setOpenPanel(true);
  };
  const handleClose = (isSuccess) => {
    setCurrentEmisor({});
    setOpenPanel(false);

    isSuccess && getData();
  };
  const getData = async () => {
    if (query) {
      let data = [],
        result = [];

      try {
        setIsBusy(true);
        setSelection([]);

        // console.debug('query', JSON.stringify(query, null, 2));
        
        if(query.Nombre != ''){
          do {
            result = await service.find({
              query: {
                ...query,
                $skip: data.length,
              },
            });
            data = data.concat(result.data);
          } while (data.length !== result.total);
        }
      } catch (err) {
        console.error('Error', err);
        data = [];
        swal({
          title: 'Error',
          text: 'Failed get emisor',
          icon: 'error',
          buttons: {
            cancel: 'Close',
          },
        });
      } finally {
        setData(data);
        setIsBusy(false);
      }
    }
  };
  const handleEditClick = (emisor) => {
    openDrawer(emisor);
  };

  const handleDeleteClick = async (data) => {
    let confirmation = await swal('Do you want delete this item?', {
      buttons: ['No', 'Yes'],
    });

    if (confirmation) {
      try{
        await service.remove(data.id)
        swal('Eliminado', '¡Se ha borrado correctamente!', 'success');
      }catch(exception){
        swal('Error', 'Dont delete transporte', 'error');
      }
      getData();
    }
  };

  return (
     <>
      <DefaultLayout currentPath={props.match.path}
       globalActions={
        <TopNavigation
          isBusy={isBusy}
          onCreateClick={handleCreateClick}
          isValid={isValid}
        />
      }
      >
        <HeaderBG />
        <Container className="fluid-95">
          <SectionTitle>Catálogo emisores</SectionTitle>
          <Filters
            isBusy={isBusy}
            setQuery={setQuery}
            isValid={isValid}
            getData = {getData}
          />
          <Table data={data}
            selection={selection}
            setSelection={setSelection}
            isSupport={isSupport}
            onEditClick={handleEditClick}
            onDeleteClick={handleDeleteClick}></Table>
        </Container>
      </DefaultLayout>
      {openPanel ? (
        <Drawer
          openPanel={openPanel}
          onClose={handleClose}
          currentEmisor={currentEmisor}
        ></Drawer>
      ) : null}
    </>
  );
  
};



export default Emisores;
