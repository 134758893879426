import React from 'react';
import styled from 'styled-components';
import { Menu, Button, Label } from 'semantic-ui-react';

const TabMenuItemPackage = (key, onDeleteClick, totalProducts) => (
  <Menu.Item as="span" key={key}>
    Package {key + 1} &nbsp;
    <ButtonDelete
      circular
      basic
      size="mini"
      icon="delete"
      onClick={(event, data) => {
        event.stopPropagation();
        onDeleteClick && onDeleteClick(key);
      }}
    />
    {totalProducts ? (
      <Label color="teal" floating>
        {totalProducts}
      </Label>
    ) : (
      <></>
    )}
  </Menu.Item>
);

const ButtonDelete = styled(Button)`
  border: 0px solid transparent !important;
  box-shadow: transparent 0px 0px 0px 1px !important;
  background: transparent !important;
`;

export default TabMenuItemPackage;
