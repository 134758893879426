import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Button, Icon } from 'semantic-ui-react';

const Actions = ({
  isBusy,
  onBackClick
}) => {
  return (
    <WrapperButtons>
      <Button
        compact
        disabled={isBusy}
        onClick={onBackClick}
      >
        <Icon name="reply" /> Back
      </Button>
    </WrapperButtons>
  );
};

const WrapperButtons = styled.div`
  position: fixed;
  bottom: 0;
  width: 95%;
  padding: 25px;
  background: white;
`;

Actions.propTypes = {
  isBusy: PropTypes.bool,
  onBackClick: PropTypes.func
};

export default Actions;