import React, { useState } from 'react';
import DefaultLayout from '../../layouts/DefaultLayout';
import TopNavigation from './TopNavigation';
import HeaderBG from '../../components/HeaderBG';
import SectionTitle from '../../components/SectionTitle';
import Filters from './Filters';
import TableNavigation from './TableNavigation';
import Table from './Table';
import Drawer from './Drawer';
import { Container } from 'semantic-ui-react';
import feathers from '../../services/feathers';
import swal from 'sweetalert';
import { pluralize } from '../../functions/common';

const Products = (props) => {
  const service = feathers.service('product-data');

  // States
  const [currentProduct, setCurrentProduct] = useState({});
  const [isBusy, setIsBusy] = useState(false);
  const [openPanel, setOpenPanel] = useState(false);
  const [selection, setSelection] = useState([]);
  const [messageAction, setMessageAction] = useState();
  const [query, setQuery] = useState(null);

  const openDrawer = (product) => {
    setCurrentProduct(product);
    setOpenPanel(true);
  };

  // Handles
  const handleSearch = (newQuery) => {
    setQuery(newQuery || { ...query });
  };

  const handleCreateClick = () => {
    openDrawer({});
  };

  const handleEditClick = (product) => {
    openDrawer(product);
  };

  const handleDeleteClick = async () => {
    if (selection.length) {
      let success = false;
      const total = selection.length;

      try {
        let msg = `Do you want to delete ${total} ${pluralize(
          total,
          'product'
        )}?`;
        let confirmation = await swal(msg, {
          buttons: ['No', 'Yes'],
        });

        if (confirmation) {
          setIsBusy(true);
          setMessageAction('Deleting ...');
          const result = await service.remove(selection);

          if (result && result.length) {
            success = true;
          } else throw Error('Not found');
        }
      } catch (error) {
        // console.debug(error);
        await swal(
          'Error',
          `Don't Delete selected ${pluralize(total, 'product')}`,
          'error'
        );
      } finally {
        setMessageAction();
        setIsBusy(false);

        if (success) {
          await swal('Done', 'Finished', 'success');

          setSelection([]);
          handleSearch();
        }
      }
    }
  };

  const handleClose = (isSuccess) => {
    setCurrentProduct({});
    setOpenPanel(false);

    isSuccess && handleSearch();
  };

  return (
    <>
      <DefaultLayout
        currentPath={props.match.path}
        globalActions={
          <TopNavigation isBusy={isBusy} onCreateClick={handleCreateClick} />
        }
      >
        <HeaderBG />

        <Container>
          <SectionTitle>Products</SectionTitle>

          <Filters isBusy={isBusy} onSearch={handleSearch} />

          {/* <pre>query: {JSON.stringify(query, null, 2)}</pre> */}

          <TableNavigation
            isBusy={isBusy}
            haveSelected={selection.length > 0}
            onDeleteClick={handleDeleteClick}
            messageAction={messageAction}
          />

          <Table
            query={query}
            onEditClick={handleEditClick}
            selection={selection}
            setSelection={setSelection}
          ></Table>
        </Container>
      </DefaultLayout>

      {openPanel ? (
        <Drawer
          openPanel={openPanel}
          onClose={handleClose}
          product={currentProduct}
        ></Drawer>
      ) : null}
    </>
  );
};

export default Products;
