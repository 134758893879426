import React from 'react';
import { Segment } from 'semantic-ui-react';
import styled from 'styled-components';

export default (props) => {
  return <DrawerFiltersWrapper raised>{props.children}</DrawerFiltersWrapper>;
};

const DrawerFiltersWrapper = styled(Segment)`
  .ui[class*='left icon'].input {
    display: flex;
  }

  .ui[class*='left icon'].input > input {
    width: 100%;
  }
`;
