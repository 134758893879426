export const statusOptions = [
  {
    key: 'ACCEPTED',
    text: 'Accepted',
    value: 'ACCEPTED',
  },
  {
    key: 'AWAITING_PROCESSING',
    text: 'Awaiting Processing',
    value: 'AWAITING_PROCESSING',
  },
  { key: 'CANCELLED', text: 'Cancelled', value: 'CANCELLED' },
  { key: 'DELIVERY', text: 'Delivery', value: 'DELIVERY' },
  {
    key: 'FAILED LABEL CREATION',
    text: 'Failed Label Creation',
    value: 'FAILED LABEL CREATION',
  },
  {
    key: 'IN_TRANSIT',
    text: 'In Transit',
    value: 'IN_TRANSIT',
  },
  { key: 'PACKED', text: 'Packed', value: 'PACKED' },
  { key: 'PENDING', text: 'Pending', value: 'pending' },
  { key: 'PICK_UP', text: 'Pick Up', value: 'PICK_UP' },
  {
    key: 'SENT_TO_OFFER',
    text: 'Sent to offer',
    value: 'SENT_TO_OFFER',
  },
  { key: 'SHIPPED', text: 'Shipped', value: 'SHIPPED' },
];

export const countryOptions = [
  { key: 'US', text: 'United States', value: 'US' },
  { key: 'NUS', text: 'International', value: 'NUS' },
  { key: 'CA', text: 'Canada', value: 'CA' },
  { key: 'MX', text: 'Mexico', value: 'MX' },
  { key: 'ES', text: 'Spain', value: 'ES' },
];
