import React, { useState, useReducer, useEffect } from 'react';
import { Modal, Button, Icon, List, Loader } from 'semantic-ui-react';
import styled from 'styled-components';
import swal from 'sweetalert';
import moment from 'moment';
import feathers from '../../../../services/feathers';
import reportTemplate from '../FormBajaShipmentsReport/function/reportTemplate';

const cleanTask = (task, prefix) => {
  task.status = 'await';

  if (task.todo) {
    task.todo.forEach((todo) => cleanTask(todo, task.key));
  }
};

const reducerTasks = (stateOrigin, action) => {
  const state = [...stateOrigin];

  switch (action.status) {
    case 'init':
      return Array.isArray(action.tasks) ? action.tasks : [];
    case 'start':
      state.forEach((task) => cleanTask(task));
      break;

    case 'complete':
    case 'progress':
    case 'error':
      action.key.reduce((tasks, key, index, array) => {
        if (tasks && tasks.length) {
          const task = tasks.find((task) => task.key === key);

          if (task) {
            task.status = action.status;
            if (task.key === 'quoting' && task.status === 'complete')
              tasks.map((innerTask) => {
                if (innerTask.status === 'progress')
                  innerTask.status = 'complete';
              });
          } else {
            let lastTask = tasks.find((task) => task.status === 'progress');
            if (lastTask) lastTask.status = 'error';
          }
        }
        return null;
      }, state);
      break;
    default:
      break;
  }

  return state;
};

const ImportBajaReport = ({ query, onClose }) => {
  //States
  const [isBusy, setIsBusy] = useState(false);
  const [tasks, dispatchTasks] = useReducer(reducerTasks, []);
  useEffect(() => {
    async function TasksTodo() {
      const tasks = [];
      tasks.push(
        {
          key: 'download',
          title: 'Shiphero Shipment Download',
          todo: [],
        },
        {
          key: 'parsing',
          title: 'Shipment to Parcel Parsing',
          todo: [],
        },
        {
          key: 'saving',
          title: 'Parcel Saving on DB',
          todo: [],
        },
        {
          key: 'quoting',
          title: 'Adding Quotes on Parcels',
          todo: [],
        },
        {
          key: 'generating',
          title: 'Generating XLS file',
          todo: [],
        }
      );
      await dispatchTasks({ status: 'init', tasks });
    }

    TasksTodo();
  }, []);

  // Funtions
  const getColor = ({ status }) => {
    if (status === 'complete') {
      return 'olive';
    } else if (status === 'await' || status === 'progress') {
      return 'grey';
    } else if (status === 'error') {
      return 'red';
    } else {
      return 'black';
    }
  };

  const getTask = (task) => {
    let todo = <></>;
    if (task.todo && task.todo.length) {
      todo = <List.List>{task.todo.map(getTask)}</List.List>;
    }

    return (
      <List.Item key={`task${task.key}`}>
        <List.Icon name="check" color={getColor(task)} />

        <List.Content>
          <List.Header>
            {task.title}

            {task.status === 'progress' ? (
              <Loading active inline size="tiny" />
            ) : (
              <></>
            )}
          </List.Header>

          {todo}
        </List.Content>
      </List.Item>
    );
  };

  const getTasks = () => {
    if (tasks.length) {
      const tasksMap = tasks.map(getTask);

      return <List>{tasksMap}</List>;
    }
    return 'No Tasks';
  };

  const generateReport = async (fromDate, toDate) => {
    dispatchTasks({
      key: ['generating'],
      status: 'progress',
    });

    const serviceParcel = feathers.service('parcels');
    let response;
    let allShipmentLabels = [];
    let page = 0;
    let success;

    // response = await serviceParcel.get('5eb5a34d92988a6d70d80493', {});

    do {
      response = await serviceParcel.find({
        query: {
          $skip: page,
          $select: [
            'integrator_created_date',
            'carrier',
            'shipping_method',
            'shipping_name',
            'shipping_cost',
            'tracking_number',
            'integrator_order_number',
            'dimensions',
            'to_address',
            'best_quote',
            'best_carrier',
            'best_service',
            'zone',
          ],

          integrator_created_date: {
            $gte: fromDate,
            $lte: toDate,
          },
          'to_address.country': 'US',
          best_service: { $exists: true },
        },
      });
      page += response.data.length;
      allShipmentLabels.push(...response.data);
    } while (response.data.length > 0);

    let subResult = await reportTemplate(allShipmentLabels, fromDate, toDate);

    subResult ? (success = true) : (success = false);
    if (success)
      dispatchTasks({
        key: ['generating'],
        status: 'complete',
      });
    else
      dispatchTasks({
        key: ['error'],
        status: 'error',
      });

    return success;
  };

  const importData = async (service, params) => {
    const keyCurrentTask =
      params.type || (params.query && params.query.type) || null;
    let success = false;
    const todoStack = [];
    try {
      let todoDailyLog = tasks && tasks.map((task) => task.key);
      service.on(keyCurrentTask, async (data) => {
        if (todoDailyLog.includes(data.key)) {
          if (data.status === 'complete') {
            const index = todoDailyLog.indexOf(data.key);
            todoDailyLog = todoDailyLog
              .slice(0, index)
              .concat(todoDailyLog.slice(index + 1));
          }

          todoStack.unshift(data.key);

          dispatchTasks({
            key: [data.key],
            status: data.status,
          });

          if (data.key === 'quoting' && data.status === 'complete') {
            success = await generateReport(
              params.query.from_date,
              params.query.to_date
            );
            if (success) {
              await swal({
                title: 'Process Completed',
                text: 'Data Imported Successfully',
                type: 'success',
                confirmButtonText: 'Ok!',
                closeOnConfirm: true,
              });

              onClose(true);
            }
            return success;
          }
        } else if (data.key === 'error') {
          console.error('Error', data.error);
        }
      });

      let result = await service.create(params);
      if (result) {
        if (typeof result === 'object') {
          if (result.result) result = result.result;
          if (result.status !== 'failed') {
            success = true;
          } else if (result.errors) {
            throw Error(result.errors[0].detail);
          }
        } else {
          success = true;
        }

        // Quedaron tareas por hacer
        if (success && todoDailyLog.length > 1) success = false;
      }
    } catch (err) {
      success = false;
      console.error('importData', err);
    } finally {
      service.removeListener(keyCurrentTask);
      if (success) {
        // dispatchTasks({ key: [keyCurrentTask], status: 'complete' });
        success = await generateReport(
          params.query.from_date,
          params.query.to_date
        );
      } else {
        tasks.map((task) => {
          if (task.status === 'await' || task.status === 'progress')
            dispatchTasks({
              key: [task.key],
              status: 'error',
            });
        });
      }
    }
    return success;
  };

  const handleImportClick = async (event) => {
    if (tasks.length) {
      let success = false;
      setIsBusy(true);
      dispatchTasks({ status: 'start' });
      try {
        const serviceShiphero = feathers.service('import-shiphero');

        success = await importData(serviceShiphero, {
          query: {
            type: 'csv-parcels',
            from_date: moment(query.shipped_date.$gte)
              .clone()
              .format('YYYY-MM-DD'),
            to_date: moment(query.shipped_date.$lte)
              .clone()
              .format('YYYY-MM-DD'),
          },
        });
      } catch (err) {
        console.error('Error', err);
        success = false;
      } finally {
        setIsBusy(false);
        if (success) {
          await swal({
            title: 'Process Completed',
            text: 'Data Imported Successfully',
            type: 'success',
            confirmButtonText: 'Ok!',
            closeOnConfirm: true,
          });

          onClose(true);
        } else {
          await swal({
            title: 'Error',
            text: 'Failed Data Import',
            icon: 'error',
            buttons: {
              cancel: 'Close',
            },
          });

          // Esto es para que el usuario alcance a ver que salió mal. 5s
          // console.debug('Take a break!');
          await new Promise((resolve) => setTimeout(resolve, 5000));
          // console.debug('Keep going!');

          setIsBusy(false);
        }
      }
    }
  };

  const handleCloseClick = (event) => {
    onClose(false);
  };
  return (
    <Modal open={true} size="small">
      <Modal.Header>
        Getting Shiphero Shipments from{' '}
        {query.shipped_date.$gte.format('YYYY/MM/DD')} to{' '}
        {query.shipped_date.$lte.format('YYYY/MM/DD')}
      </Modal.Header>

      <Modal.Content>{getTasks()}</Modal.Content>
      <ActionContainer>
        <Button primary compact disabled={isBusy} onClick={handleImportClick}>
          <Icon name="save" /> Import
        </Button>

        <Button compact disabled={isBusy} onClick={handleCloseClick}>
          <Icon name="reply" /> Close
        </Button>
      </ActionContainer>
    </Modal>
  );
};
const DateField = styled.div`
  width: '200%';
  height: 15vh;
  vertical-align: center;
  align-items: center;
  align-content: center;

  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-template-rows: auto;
  grid-gap: 1em;
  grid-template-areas: '. range range .';
`;

const ActionContainer = styled(Modal.Actions)``;

const Loading = styled(Loader)`
  &&&& {
    &:after {
      width: 100%;
      height: 100%;
      border-color: #a0a0a0 transparent transparent;
    }
  }
`;

export default ImportBajaReport;
