import swal from 'sweetalert';
import moment from 'moment';
import downloadFile from '../../../../../functions/downloadFile';
import { number } from '../../../../../components/AgGridColumns';

const ExcelJS = require('exceljs');

export default async (exportData) => {
  if (exportData && exportData.length > 0) {
    try {
      let mappedData = await exportData.map((item) => {
        const product = (item && item.product && item.product.length && item.product[0]) || null;
        const cost = (product && number.valueFormatter({ value: product.cost })) || 'N/A';
        const usefullZip = item.zip
          ? String(item.zip).length > 5
            ? String(item.zip).slice(0, 5)
            : String(item.zip)
          : item.zip;

        return {
          'Is 321': (product && product.is321) || '-',
          'EXPORT CODE': (product && product.exportCode) || '-',
          'FIBER/CONTENT': (product && product.fiber) || '-',
          COO: (product && product.coco) || '-',
          DESCRIPTION: (product && product.description) || '-',
          COST: cost === 'N/A' ? 0 : cost,
          'Create Date': item.createdDateTime
            ? moment
              .utc(item.createdDateTime)
              .local()
              .format('DD/MM/YYYY HH:mm')
            : 'N/A',
          'Product Name': (product && product.name) || '-',
          SKU: product && product.sku
            ? isNaN(Number(product.sku))
              ? product.sku
              : Number(product.sku)
            : '-',
          QTY: item.qtyShipped ? item.qtyShipped : '-',
          Price: item.price ? item.price : '-',
          'Shipped Subtotal': item.shippedSubtotal ? item.shippedSubtotal : '-',
          '3PL Customer': item.customer3PL ? item.customer3PL : '-',
          'Order Number': item.orderNumber
            ? isNaN(Number(item.orderNumber))
              ? item.orderNumber
              : Number(item.orderNumber)
            : '-',
          'Tracking Number': item.trackingNumber
            ? isNaN(Number(item.trackingNumber))
              ? item.trackingNumber
              : Number(item.trackingNumber)
            : '-',
          Email: item.email ? item.email : '-',
          Name: (product && product.name) || '-',
          Address: item.address ? item.address : '-',
          City: item.city ? item.city : '-',
          Zip: usefullZip
            ? isNaN(Number(usefullZip))
              ? usefullZip
              : Number(usefullZip)
            : '-',
          State: item.state ? item.state : '-',
          Country: item.country ? item.country : '-',
          Phone: item.phone
            ? isNaN(Number(item.phone))
              ? item.phone
              : Number(item.phone)
            : '-',
          'Product Tariff Code': item.productTariffCode
            ? item.productTariffCode
            : '-',
          'Customs Value': item.customsValues ? item.customsValues : '-',
          Total: (item.qtyShipped ? item.qtyShipped : '0') + ' Boxes',
        };
      });

      //Duty Paid INV-2437 5-18-20
      const exportName =
        'Duty Paid ' + moment().startOf('day').format('M-D-YY') + '.xlsx';

      const wb = new ExcelJS.Workbook();
      const ws = wb.addWorksheet('ShipHero - Simplify How You Pic');
      ws.columns = Object.keys(mappedData[0]).map((field) => {
        return { header: field, key: field, width: 30 };
      }, {});
      ws.addRows(mappedData);
      ws.getColumn('F').numFmt = '$#,##0.00;[Red]-$#,##0.00';
      ws.getColumn('G').alignment = { horizontal: 'right' };
      ws.getColumn('I').numFmt = '###0';

      ws.getColumn('N').numFmt = '###0';
      ws.getColumn('N').alignment = { horizontal: 'left' };

      ws.getColumn('O').numFmt = '###0';
      ws.getColumn('O').alignment = { horizontal: 'left' };

      ws.getColumn('T').numFmt = '###0';
      ws.getColumn('W').numFmt = '###0';
      ws.getCell('Z1').value = null;

      const buf = await wb.xlsx.writeBuffer();
      downloadFile(
        buf,
        exportName,
        'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
      );
    } catch (error) {
      console.error('error', error);
      swal(`Could Not Export`, error, 'error');
    }
  } else {
    swal(`Could Not Export`, `No records were the Duty Paid`, 'error');
  }
};
