import React from 'react';
import { Button, Dropdown, Icon } from 'semantic-ui-react';
import template321 from './function/321Template';
import templateNo321 from './function/No321Template';
import templateIntegrator from './function/integratorTemplate';
import dutyPay from './function/dutyPay';
import internacionalAndNot321 from './function/internacionalAndNot321';
import driverGuide from './function/driverGuide';
import ReactTooltip from 'react-tooltip';
import {
  isInvalidToCreateReport,
  isValidDailylog,
} from '../functions/validationProduct';
import styled from 'styled-components';
import { Tags } from '../../../../constants/Tags';
import Integrators from '../../../../constants/Integrators';
import {
  filterByTag,
  filterByIntegrator,
} from '../functions/FilterShippedItems';

const Navigation = ({ isBusy, setCurrentSection, currentConsolidation }) => {
  const getDataByTag = (tag) => {
    return currentConsolidation.shippedItems.filter(
      (item) =>
        filterByTag(item, tag) &&
        ((tag !== 'EXTERNAL' && tag != 'No321' && isValidDailylog(item)) ||
          tag === 'EXTERNAL' ||
          tag === 'No321')
    );
  };

  const getDataByIntegrator = (integrator) => {
    return currentConsolidation.shippedItems.filter((item) =>
      filterByIntegrator(item, [integrator])
    );
  };

  const handleDownloadReportClick = (option) => {
    const filteredData = getDataByTag(option);

    switch (option) {
      case Tags._321:
        template321(filteredData);
        break;
      case Tags._No321:
        templateNo321(filteredData);
        break;
      case Tags.dutyPay:
        dutyPay(filteredData);
        break;
      case Tags.international:
        internacionalAndNot321(filteredData);
        break;
      case Tags.external:
        template321(filteredData, false);
        break;
      default:
        console.log('Default', filteredData);
        break;
    }
  };

  const handleDownloadReportByIntegratorClick = (integrator) => {
    const filteredData = getDataByIntegrator(integrator);
    const name =
      integrator === Integrators.rushOrder ? 'Rush Order' : 'Shiphero';

    templateIntegrator(name, filteredData);
  };

  const handleDriverGuideClick = () => {
    const filteredData = getDataByTag();

    driverGuide(filteredData);
  };

  return (
    <NavigationContainer>
      <ReactTooltip id="toolTip321">
        Shows the items TAGGED as "321 Report" that have a BIN ASSIGNED
      </ReactTooltip>
      <ReactTooltip id="toolTipNo321">
        Shows the items TAGGED as "No 321 Report". They DON'T have to have a BIN
        ASSIGNED
      </ReactTooltip>
      <ReactTooltip id="toolTipDutyPay">
        Shows the items TAGGED as "Duty Pay Report" that have a BIN ASSIGNED
      </ReactTooltip>
      <ReactTooltip id="toolTipInternational">
        Shows the items TAGGED as "International Report" that have a BIN
        ASSIGNED
      </ReactTooltip>
      <ReactTooltip id="toolTipDriverGuide">
        Shows ALL the items that have a BIN ASSIGNED
      </ReactTooltip>
      <ReactTooltip id="toolTipExternal">
        Shows the items TAGGED as "External Report". They DON'T have to have a
        BIN ASSIGNED
      </ReactTooltip>
      <ReactTooltip id="toolTipRushOrder">
        Shows ALL items associated with the consolidation that come from
        SHIPHERO
      </ReactTooltip>
      <ReactTooltip id="toolTipShiphero">
        Shows ALL items associated with the consolidation that come from RUSH
        ORDER
      </ReactTooltip>
      <Button
        primary
        compact
        onClick={() => {
          setCurrentSection('IMPORT_SHIPPED_ITEMS');
        }}
        disabled={isBusy}
      >
        1. Import Shipped Items
      </Button>
      <Dropdown
        text={'2. Download Report'}
        primary
        compact
        style={{ textAlign: 'center' }}
        as={Button}
        disabled={
          isBusy || isInvalidToCreateReport(currentConsolidation.shippedItems)
        }
      >
        <Dropdown.Menu>
          <Dropdown.Item onClick={() => handleDownloadReportClick(Tags._321)}>
            <Icon
              name="question circle"
              color="teal"
              size="large"
              data-tip="msg to show"
              data-for="toolTip321"
              data-place="top"
            />
            Download <strong>321</strong>
          </Dropdown.Item>

          <Dropdown.Item onClick={() => handleDownloadReportClick(Tags._No321)}>
            <Icon
              name="question circle"
              color="teal"
              size="large"
              data-tip="msg to show"
              data-for="toolTipNo321"
              data-place="top"
            />
            Download <strong>No 321</strong>
          </Dropdown.Item>

          <Dropdown.Item
            onClick={() => handleDownloadReportClick(Tags.dutyPay)}
          >
            <Icon
              name="question circle"
              color="teal"
              size="large"
              data-tip="msg to show"
              data-for="toolTipDutyPay"
              data-place="top"
            />
            Download <strong>Duty Pay</strong>
          </Dropdown.Item>

          <Dropdown.Item
            onClick={() => handleDownloadReportClick(Tags.international)}
          >
            <Icon
              name="question circle"
              color="teal"
              size="large"
              data-tip="msg to show"
              data-for="toolTipInternational"
              data-place="top"
            />
            Download <strong>Internacional</strong>
          </Dropdown.Item>

          <Dropdown.Item
            onClick={() => handleDownloadReportClick(Tags.external)}
          >
            <Icon
              name="question circle"
              color="teal"
              size="large"
              data-tip="msg to show"
              data-for="toolTipExternal"
              data-place="top"
            />
            Download <strong>External</strong>
          </Dropdown.Item>

          <Dropdown.Item onClick={handleDriverGuideClick}>
            <Icon
              name="question circle"
              color="teal"
              size="large"
              data-tip="msg to show"
              data-for="toolTipDriverGuide"
              data-place="top"
            />
            Download <strong>Driver Guide</strong>
          </Dropdown.Item>

          <Dropdown.Item
            onClick={() =>
              handleDownloadReportByIntegratorClick(Integrators.rushOrder)
            }
          >
            <Icon
              name="question circle"
              color="teal"
              size="large"
              data-tip="msg to show"
              data-for="toolTipRushOrder"
              data-place="top"
            />
            Download <strong>Rush Order Report</strong>
          </Dropdown.Item>

          <Dropdown.Item
            onClick={() =>
              handleDownloadReportByIntegratorClick(Integrators.shiphero)
            }
          >
            <Icon
              name="question circle"
              color="teal"
              size="large"
              data-tip="msg to show"
              data-for="toolTipShiphero"
              data-place="top"
            />
            Download <strong>Shiphero Report</strong>
          </Dropdown.Item>
        </Dropdown.Menu>
      </Dropdown>
    </NavigationContainer>
  );
};
// const myDiv = Popup.style(`
//   z-index: 1900;
//   display: 'flex';
// `);

const NavigationContainer = styled.div``;

export default Navigation;
