import React from 'react';
import {
  Grid,
  GridColumn,
  GridRow,
  Button,
  Dropdown,
  Icon,
} from 'semantic-ui-react';
import styled from 'styled-components';

import ActivityIndicator from '../../../../components/ActivityIndicator';
import { Tags } from '../../../../constants/Tags';
import ReactTooltip from 'react-tooltip';

const TableNavigation = ({
  isDissociating,
  selection,
  handleOnBulk,
  isBusy,
}) => (
  <Grid columns={2} verticalAlign="middle" padded="vertically">
    <ReactTooltip id="toolTip321Tag">
      Warning: You CAN NOT tag as "321 Report" any item aleady TAGGED as "No 321
      Report".
    </ReactTooltip>
    <ReactTooltip id="toolTipNo321Tag">
      Warning: You CAN NOT tag as "No 321 Report" any item aleady TAGGED as "321
      Report".
    </ReactTooltip>
    <GridRow stretched>
      <GridColumn>
        {(isDissociating && (
          <ActivityIndicator title="Applying bulk action..." />
        )) ||
          (isBusy && <ActivityIndicator title="Loading data..." />) || (
            <Instructions>
              Select one or more Shipped Items to do actions
            </Instructions>
          )}
      </GridColumn>

      <GridColumn textAlign="right">
        <div>
          <Dropdown
            text="Bulk Actions"
            as={Button}
            primary
            compact
            style={{ marginRight: 0 }}
            disabled={(selection && selection.length === 0) || isBusy}
          >
            <Dropdown.Menu style={{ right: 0, left: 'auto' }}>
              <Dropdown.Item onClick={() => handleOnBulk('tag', Tags._321)}>
                <Icon
                  name="question circle"
                  color="teal"
                  size="large"
                  data-tip="msg to show"
                  data-for="toolTip321Tag"
                  data-place="top"
                />
                Tag as <b>321 Report</b>
              </Dropdown.Item>

              <Dropdown.Item onClick={() => handleOnBulk('tag', Tags._No321)}>
                <Icon
                  name="question circle"
                  color="teal"
                  size="large"
                  data-tip="msg to show"
                  data-for="toolTipNo321Tag"
                  data-place="top"
                />
                Tag as <b>No 321 Report</b>
              </Dropdown.Item>

              <Dropdown.Item onClick={() => handleOnBulk('tag', Tags.dutyPay)}>
                Tag as <b>Duty Pay Report</b>
              </Dropdown.Item>

              <Dropdown.Item
                onClick={() => handleOnBulk('tag', Tags.international)}
              >
                Tag as <b>International Report</b>
              </Dropdown.Item>

              <Dropdown.Item onClick={() => handleOnBulk('tag', Tags.external)}>
                Tag as <b>External Report</b>
              </Dropdown.Item>

              <Dropdown.Item onClick={() => handleOnBulk('createBin')}>
                Create <b>BIN</b>
              </Dropdown.Item>

              <Dropdown.Item
                text="Untag"
                style={{ color: 'red' }}
                onClick={() => handleOnBulk('tag', null)}
              />

              <Dropdown.Item
                text="Unassociate"
                style={{ color: 'red' }}
                onClick={() => handleOnBulk('unassociate', null)}
              />
            </Dropdown.Menu>
          </Dropdown>
        </div>
      </GridColumn>
    </GridRow>
  </Grid>
);

const Instructions = styled.div`
  font-family: 'Roboto', sans-serif;
  font-size: 14px;
  color: rgba(0, 0, 0, 0.6);
  letter-spacing: 0.25px;
  text-align: left;
  line-height: 20px;
`;

export default TableNavigation;
