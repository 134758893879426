import React, { forwardRef, useState } from 'react';
import { Button, Modal } from 'semantic-ui-react';
import DropdownSearchTripFetch from '../../../components/Formik/DropdownSearchTripFetch';
import { Dropzone, FileItem } from '@dropzone-ui/react';
import * as xlsx from 'xlsx';

function exampleReducer(state, action) {
  switch (action.type) {
    case 'OPEN_MODAL':
      return { open: true, dimmer: action.dimmer };
    case 'CLOSE_MODAL':
      return { open: false };
    default:
      throw new Error();
  }
}

const ModalImg = ({ formik }) => {
  let { values, errors, touched, handleChange, handleBlur, setFieldValue } =
    formik;

  let [state, dispatch] = React.useReducer(exampleReducer, {
    open: false,
    dimmer: undefined,
  });

  let { open, dimmer } = state;

  const [files, setFiles] = useState([]);
  const [dataFiles, setDataFiles] = useState();
  const updateFiles = (incommingFiles) => {
    //console.log('incomming files', incommingFiles);
    if (incommingFiles) {
      const reader = new FileReader();
      reader.onload = (e) => {
        const data = e.target.result;
        console.log(data)
      };
      reader.readAsArrayBuffer(incommingFiles[0].file);
    }
    setFiles(incommingFiles);
  };
  const handleDelete = (id) => {
    setFiles(files.filter((x) => x.id !== id));
  };

  return (
    <div>
      <div style={{ marginBottom: 10 }}>
        <Button onClick={() => dispatch({ type: 'OPEN_MODAL' })} type="button">
          Cargar Logo
        </Button>
      </div>

      <Modal
        dimmer={dimmer}
        open={open}
        onClose={() => dispatch({ type: 'CLOSE_MODAL' })}
      >
        <Modal.Header>Importar Imagen</Modal.Header>
        <Modal.Content>
          <Dropzone
            onChange={updateFiles}
            value={files}
            onClean
            accept={'.image,.jpeg,.jpg'}
            label={'Arrastrar aquí o dar click para seleccionar. '}
            minHeight={'195px'}
            maxHeight={'500px'}
            disableScroll
          >
            {files.map((file) => (
              <FileItem
                {...file}
                key={file.id}
                onDelete={handleDelete}
                alwaysActive
                preview
                info
                resultOnTooltip
              />
            ))}
          </Dropzone>
        </Modal.Content>
        <Modal.Actions>
          <Button negative onClick={() => dispatch({ type: 'CLOSE_MODAL' })}>
            Cancelar
          </Button>
          <Button
            positive
            onClick={() => {
              dispatch({ type: 'CLOSE_MODAL' });
              if(files.length){
                formik.values.LogoImg = files[0]
                console.log("values", formik.values)
              }
            }}
          >
            Importar
          </Button>
        </Modal.Actions>
      </Modal>
    </div>
  );
};

export default forwardRef((props, ref) => (
  <ModalImg {...props} innerRef={ref} />
));
