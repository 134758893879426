import React from 'react';

import DropdownSearchFetch from '../../../components/Formik/DropdownSearchFetch';

function DropdownEstado({ formik, values, names, errors }) {
  let { setFieldValue } = formik;
  return (
    <DropdownSearchFetch
      fluid
      selection
      label="Estado"
      value={values.Estado}
      onChange={(e, { value }) => {
        setFieldValue(names.Estado, value);
        setFieldValue(names.Municipio, '');
        setFieldValue(names.CodigoPostal, '');
        setFieldValue(names.Colonia, '');
      }}
      placeholder="Estado"
      valuesChangesToUpdate={[values.Pais, values.Estado]}
      featherService="catalogo-estado"
      columName="Nombre"
      columValue="c_Estado"
      mapValues={(value) => {
        return {
          key: Math.random(),
          text: `${value['c_Estado']} - ${value['Nombre']}`,
          value: value['c_Estado'],
        };
      }}
      queryWithSearch={(columName, columValue, searchQuery) => {
        let newQuery = {
          $or: [
            {
              [columName]: { $like: '%' + searchQuery + '%' },
            },
            {
              [columValue]: {
                $like: '%' + searchQuery + '%',
              },
            },
          ],
          c_Pais: values.Pais,
          $limit: 50,
        };

        return newQuery;
      }}
      queryInit={(columName, columValue, value) => {
        let newQuery;
        if (value) {
          newQuery = {
            [columValue]: { $like: value },
            $limit: 1,
          };
        } else {
          newQuery = {
            $limit: 50,
            c_Pais: values.Pais,
          };
        }

        return newQuery;
      }}
      error={
        errors.Estado && {
          content: errors.Estado,
        }
      }
    />
  );
}

export default DropdownEstado;
