import {
  TOGGLE_SH_IMPORT,
  SENT_DATA,
  DATA_VALIDATIONS,
  TOGGLE_VALIDATION_MODAL,
  SET_OPTIMA_TRIPS,
  SET_EXTERNAL_DATA,
  TOGGLE_ADD_PRODUCT
} from './constants/ParcelsConstants';

const initialState = {
  shImportVisible: false,
  parcelsToSend: [],
  validationModalVisible: false,
  parcelOptimaTrips: [],
  optimaTripSelected: {
    tripId: null,
    tripNumber: null
  },
  addProductVisible: false
};

export default (state = initialState, action) => {
  switch (action.type) {
    case TOGGLE_SH_IMPORT:
      return { ...state, shImportVisible: action.payload };
    case SENT_DATA:
    case DATA_VALIDATIONS:
      return { ...state, parcelsToSend: action.payload };
    case TOGGLE_VALIDATION_MODAL:
      return { ...state, validationModalVisible: action.payload };
    case SET_OPTIMA_TRIPS:
      return { ...state, parcelOptimaTrips: action.payload };
    case SET_EXTERNAL_DATA:
      return { ...state, optimaTripSelected: action.payload };
    case TOGGLE_ADD_PRODUCT:
      return { ...state, addProductVisible: action.payload };
    default:
      return state;
  }
};
