import React from 'react';
import PropTypes from 'prop-types';
import { Modal, Button, Icon } from 'semantic-ui-react';
import styled from 'styled-components';
import AssociateForm from './AssociateForm';
import feathers from '../../../../../services/feathers';
import swal from 'sweetalert';

const AssociateProductModal = ({
  shippedItem,
  onClose,
  shippedItems
}) => {
  const handleAssociate = async product => {
    const lstShippedItems = shippedItems
      .filter(item => item.SKU === shippedItem.SKU)
      .map(item => item._id);

    if (lstShippedItems.length > 1) {
      const response = await swal({
        title: 'What items do you want to associate?',
        text: `There are ${lstShippedItems.length - 1} other items with the same SKU`,
        icon: "warning",
        buttons: {
          only: {
            text: 'Only this',
            value: 'only'
          },

          all: {
            text: 'All',
            value: 'all'
          },

          cancel: true
        },
      });

      switch (response) {
        case 'only':
          lstShippedItems.length = 0;
          lstShippedItems.push(shippedItem._id);
          break;

        case 'all':
          break;

        default:
          lstShippedItems.length = 0;
      }
    }

    if (lstShippedItems.length) {
      const result = await feathers.service('shipped-items').patch({}, {
        sku: product.sku,
        status: 'ASSOCIATED',
        shippedItems: lstShippedItems
      });

      if (result && result.ok) {
        shippedItems.forEach(item => {
          if (lstShippedItems.includes(item._id)) {
            item.product = [product];
          }
        });

        swal('Done', 'Finished', 'success');
      } else {
        swal('Error', result.message, 'error');
      }

      onClose && onClose(true);
    }
  };

  const handleCloseClick = event => onClose && onClose(false);

  return (
    <SuperModal>
      <Modal open={true} size="small">
        <Modal.Header>Associate Product</Modal.Header>

        <Modal.Content scrolling>
          <FormContent>
            <AssociateForm
              onAssociate={handleAssociate}
              shippedItem={shippedItem}
            />
          </FormContent>
        </Modal.Content>

        <Modal.Actions>
          <Button compact onClick={handleCloseClick}>
            <Icon name="reply" /> Close
          </Button>
        </Modal.Actions>
      </Modal>
    </SuperModal>
  );
};

const SuperModal = styled.div`
  z-index: 15002;
`;

const FormContent = styled.div`
  height: 60vh;
`;

AssociateProductModal.propTypes = {
  shippedItem: PropTypes.object,
  onClose: PropTypes.func,
  shippedItems: PropTypes.array
};

export default AssociateProductModal;