import React from 'react';
import PropTypes from 'prop-types';
import { Button, Icon } from 'semantic-ui-react';
import Popup from './Popup';

const ButtonAction = ({ handleOnClick, title, label }) => {
  const handleClick = (evt) => {
    handleOnClick && handleOnClick(evt);
  };

  return (
    <>
      <Popup content={title}>
        {label.includes('x') ? (
          <Icon
            color="red"
            name="delete"
            size="large"
            link
            onClick={handleClick}
          />
        ) : (
          <Button primary compact onClick={handleClick}>
            <Icon name="plus" /> {label.replace('+', '').replace('x', '')}
          </Button>
        )}
      </Popup>
    </>
  );
};

ButtonAction.propTypes = {
  handleOnClick: PropTypes.func.isRequired,
  label: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
};

export default ButtonAction;
