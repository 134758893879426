import React, { useReducer } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import DrawerFiltersWrapper from '../../components/DrawerFiltersWrapper';
import moment from 'moment';
import DateRangePicker from '../../components/DateRangePicker';
import ButtonsFilter from '../../components/ButtonsFilter';

const queryInit = {
  shipped_date: {
    $gte: moment().subtract(1, 'months').startOf('day'),
    $lte: moment().endOf('day'),
  },
};

const reducerQuery = (prevState, { name, value, type, query }) => {
  if (type === 'init' && query) {
    return query;
  } else if (name) {
    return { ...prevState, [name]: value };
  }

  return prevState;
};
const Filters = ({ isBusy, query: base, setQuery, setIsOpen }) => {
  // States
  const [query, dispatchQuery] = useReducer(reducerQuery, { ...queryInit });

  // Effects

  // Handles
  const handleSearchClick = () => {
    // Send query to Consolidation
    if (!isBusy) setIsOpen(true);
    setQuery && setQuery({ ...query });
  };

  const handleClearClick = (event, data) => {
    dispatchQuery({
      type: 'init',
      query: {
        ...queryInit,

        shipped_date: {
          $gte: moment().subtract(1, 'months').startOf('day'),
          $lte: moment().endOf('day'),
        },
      },
    });
  };
  return (
    <>
      {/* <pre>query: {JSON.stringify(query, null, 2)}</pre> */}

      <DrawerFiltersWrapper>
        <FiltersGrid>
          <DateRangePicker
            name="shipped_date"
            label="Shipped Date"
            timePicker={false}
            startDate={query.shipped_date.$gte}
            endDate={query.shipped_date.$lte}
            maxDate={moment()}
            onChange={([$gte, $lte]) => {
              dispatchQuery({
                name: 'shipped_date',
                value: { $gte, $lte },
              });
            }}
            style={{ gridArea: 'range' }}
            disabled={isBusy}
          />

          <ButtonsFilter
            isBusy={isBusy}
            onSearchClick={handleSearchClick}
            onClearClick={handleClearClick}
          />
        </FiltersGrid>
      </DrawerFiltersWrapper>
    </>
  );
};

const FiltersGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-template-rows: auto;
  grid-gap: 1em;
  grid-template-areas: 'range range . buttons';
`;

Filters.propTypes = {
  isBusy: PropTypes.bool,
  query: PropTypes.object,
  setQuery: PropTypes.func,
};

export default Filters;
