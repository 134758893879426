import React, {
  useState,
  useEffect,
  forwardRef,
  useImperativeHandle,
} from 'react';
import { Form, Segment } from 'semantic-ui-react';
import styled from 'styled-components';
import roleDefault, {
  options as RolesOptions,
  support,
} from '../../../constants/Roles';
import feathers, { useAuth } from '../../../services/feathers';
import isString from 'lodash/isString';

const FormAddUser = ({ currentUser, onSave, setIsValid, innerRef }) => {
  const service = feathers.service('users');
  const serviceClients = feathers.service('clients');
  const auth = useAuth();

  // State
  const [loading, setLoading] = useState(false);
  const [user, setUser] = useState({
    ...currentUser,
    clients: (currentUser.clients || []).map((client) =>
      isString(client) ? client : client._id
    ),
    client: isString(currentUser.client)
      ? currentUser.client
      : (currentUser.client || { _id: null })._id,
  });
  //const [menssage, setMenssage] = useState('');
  //const [menssageVisible, setMenssageVisible] = useState(false);
  const [validations, setValidations] = useState({
    name: false,
    lastName: false,
    email: false,
    roles: false,
    clients: false,
    client: false,

    //...(!currentUser._id && { password: false, confirm: false }),
    ...(!currentUser._id && { password: false }),
  });
  const [clientsOpts, setClientsOpts] = useState([]);
  const [clientsActiveOpts, setClientsActiveOpts] = useState([]);
  const [roleOpts, setRoleOpts] = useState([]);

  // Refs
  const refBtnSubmit = React.useRef(null);

  useImperativeHandle(innerRef, () => ({
    submit: () => {
      refBtnSubmit.current.click();
    },
  }));

  // Handles
  const handleChange = (_, { name, value }) => {
    // console.debug('handleChange', name, value);
    setUser({ ...user, [name]: value });
    if (validations.hasOwnProperty(name)) validateField(name, value);
  };

  const handleBlur = ({ target }) => {
    // console.debug('handleBlur', target);
    if (target && validations.hasOwnProperty(target.name))
      validateField(target.name, target.value);
  };

  const validateField = (name, value) => {
    setValidations({
      ...validations,
      [name]: value && value.length ? false : true,
    });

    // if (name === 'confirm' || name === 'password') {
    //   let password = name === 'password' ? value : user.password;
    //   let confirm = name === 'confirm' ? value : user.confirm;
    //   if ((password && password.length) && (confirm && confirm.length)) {
    //     if (password === confirm) {
    //       setMenssage('The password is not secure');
    //       //setMenssageVisible(true);
    //       setValidations({ ...validations, confirm: false });
    //     } else {
    //       setMenssageVisible(false);
    //       setValidations({ ...validations, confirm: true });
    //     }
    //   }else{
    //     setMenssageVisible(false);
    //   }
    // }
  };

  const handleSubmit = async (event) => {
    setLoading(true);

    try {
      event.preventDefault();

      let isValidForm = true;
      const newValidations = Object.keys(validations).reduce((prev, field) => {
        const ret = {
          ...prev,
          [field]: user[field] && user[field].length ? false : true,
        };

        isValidForm = isValidForm && !ret[field];

        return ret;
      }, {});

      setValidations(newValidations);

      if (isValidForm) {
        let result;
        if (!currentUser._id) {
          result = await service.create(user);
        } else {
          let tempUser = { ...user };
          if (!user.password) delete tempUser.password;
          result = await service.patch(currentUser._id, tempUser);
        }

        if (result) {
          if (
            auth &&
            auth.user &&
            result._id === auth.user._id &&
            result.client !== auth.user.client
          ) {
            window.location.reload();
            return;
          }

          onSave && onSave(result);
          return;
        }
      }
    } catch (err) {
      console.error('Error: ', err);
    }

    setLoading(false);
  };

  // Effects
  useEffect(() => {
    setIsValid(Object.values(validations).filter((val) => val).length >= 1);
  }, [validations]);

  // Load clients
  useEffect(() => {
    if (auth.user.isSupport) {
      let didCancel = false;

      const getClients = async () => {
        try {
          let result = await serviceClients.find({});

          if (!didCancel) {
            const clientsOpts = result.data.map(
              ({ _id: value, name: text }, index) => ({
                key: index,
                text,
                value,
              })
            );

            setClientsOpts(clientsOpts);
          }
        } catch (err) {
          console.error('Dropdown Clients', err);
        }
      };

      getClients();

      return () => {
        didCancel = true;
      };
    }
  }, []);

  // Active client Dropdown is loaded
  useEffect(() => {
    // console.debug('useEffect user.clients', JSON.stringify(user, null, 2));
    if (
      auth.user.isSupport &&
      user &&
      Array.isArray(user.clients) &&
      clientsOpts.length
    ) {
      let clients = [],
        client = null;

      clients = clientsOpts.filter(({ value }) => {
        const exists = user.clients.includes(value);
        if (value === user.client && exists) {
          client = value;
        }

        return exists;
      });

      setClientsActiveOpts(clients);
      setUser({ ...user, client });
    }
  }, [user.clients, clientsOpts]);

  // Loading roles without Support for Admin
  useEffect(() => {
    // console.count('isSupport');
    const roleOpts = [...RolesOptions];

    if (!auth.user.isSupport) {
      const index = roleOpts.findIndex(({ value }) => value === support);
      roleOpts.splice(index, 1);
    }

    setRoleOpts(roleOpts);
  }, [auth.user]);

  return (
    <WrapContent>
      <Form
        onSubmit={handleSubmit}
        loading={loading}
        style={{ marginRight: '15px' }}
      >
        <Form.Group widths={'equal'}>
          <Form.Input
            placeholder="Name"
            label="Name *"
            name="name"
            value={user.name || ''}
            autoFocus
            onChange={handleChange}
            onBlur={handleBlur}
            error={validations.name && { content: 'Required Name' }}
          />

          <Form.Input
            placeholder="Last Name"
            label="Last Name *"
            name="lastName"
            value={user.lastName || ''}
            onChange={handleChange}
            onBlur={handleBlur}
            error={validations.lastName && { content: 'Required Last Name' }}
          />
        </Form.Group>

        <Form.Input
          placeholder="Email"
          label="Email *"
          name="email"
          type="email"
          value={user.email || ''}
          onChange={handleChange}
          onBlur={handleBlur}
          error={
            validations.email
              ? { content: 'Required Email' }
              : user.email &&
                !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(
                  user.email
                ) && { content: 'Invalid Email Address' }
          }
        />

        <Form.Group widths={'equal'}>
          <Form.Input
            placeholder={!currentUser._id ? 'Password' : 'New Password'}
            label={`Password ${!currentUser._id ? '*' : ''}`}
            name="password"
            type="password"
            value={user.password || ''}
            onChange={handleChange}
            onBlur={handleBlur}
            error={validations.password && { content: 'Required Password' }}
          />

          {/* <Form.Input
            placeholder={'Confirm'}
            label="Confirm"
            name="confirm"
            type="password"
            value={user.confirm || ''}
            onChange={handleChange}
            onBlur={handleBlur}
            error={validations.confirm && { content: user.confirm && user.confirm.length ? 'The password is not the same' : 'Required Confirm' }}
          /> */}
        </Form.Group>

        {/* <Message warning visible={menssageVisible}>
          <Icon name='warning' />
          {menssage}
        </Message> */}

        <Form.Group widths={'equal'}>
          <Form.Dropdown
            placeholder="Roles"
            name="roles"
            label="Roles *"
            value={user.roles || roleDefault || []}
            fluid
            selection
            search
            multiple
            onChange={handleChange}
            onBlur={(_, data) => handleBlur({ target: { ...data } })}
            options={roleOpts}
            width={8}
            error={validations.roles && { content: 'Required Roles' }}
          />

          {auth && auth.user && auth.user.isSupport ? (
            <>
              <Form.Dropdown
                placeholder="Clients"
                name="clients"
                label="Clients *"
                value={user.clients || []}
                fluid
                selection
                search
                multiple
                onChange={handleChange}
                onBlur={(_, data) => handleBlur({ target: { ...data } })}
                options={clientsOpts}
                width={8}
                disabled={!auth.user.allowShippers}
                error={validations.clients && { content: 'Required Clients' }}
              />

              <Form.Dropdown
                placeholder="Client"
                name="client"
                label="Client Active *"
                value={user.client || null}
                fluid
                selection
                search
                onChange={handleChange}
                onBlur={(_, data) => handleBlur({ target: { ...data } })}
                options={clientsActiveOpts}
                width={8}
                disabled={!auth.user.allowShippers}
                error={validations.client && { content: 'Required Client' }}
              />
            </>
          ) : (
            <></>
          )}
        </Form.Group>

        <input type="submit" style={{ display: 'none' }} ref={refBtnSubmit} />
      </Form>

      {/* <Segment>
        <pre>{JSON.stringify(user, null, 2)}</pre>
      </Segment> */}
    </WrapContent>
  );
};

const WrapContent = styled.div``;

export default forwardRef((props, ref) => (
  <FormAddUser {...props} innerRef={ref} />
));
