import React from 'react';

import DropdownSearchFetch from '../../../components/Formik/DropdownSearchFetch';

function DropdownPais({ formik, values, names, errors }) {
  let { setFieldValue } = formik;
  return (
    <DropdownSearchFetch
      fluid
      selection
      label="País"
      value={values.Pais}
      onChange={(e, { value }) => {
        setFieldValue(names.Pais, value);
        setFieldValue(names.Colonia, null);
        setFieldValue(names.Estado, null);
        
      }}
      placeholder="País"
      featherService="catalogo-pais"
      columName="Descripcion"
      columValue="CPaisID"
      mapValues={(value) => {
        return {
          key: Math.random(),
          text: `${value['c_Pais']} - ${value['Descripcion']}`,
          value: value['CPaisID'],
        };
      }}
      error={
        errors.Pais && {
          content: errors.Pais,
        }
      }
    />
  );
}

export default DropdownPais;
