import React from 'react';
import styled from 'styled-components';
import { Button, Icon, Segment } from 'semantic-ui-react';
import swal from 'sweetalert';

const Actions = ({ handledOnClose, formik }) => {
  let { validateForm } = formik;

  const validateBeforeSubmit = () => {
    //console.log('formik.values: ', formik.values);
    validateForm().then((errors) => {
      if (Object.keys(errors).length > 0) {
        swal('Error', '¡Revisa la información faltante!', 'error');
      }
    });
  };

  return (
    <Segment>
      <ActionContainer>
        <Button primary compact type="submit" onClick={validateBeforeSubmit}>
          <Icon name="bell" /> Guardar
        </Button>

        <Button compact onMouseDown={handledOnClose}>
          <Icon name="reply" /> Back
        </Button>
      </ActionContainer>
    </Segment>
  );
};

const ActionContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(6, 1fr);
  grid-template-rows: auto;
  grid-template-areas: '. . . . . .';
`;

export default Actions;
