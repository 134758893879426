import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import TableContainer from '../../../components/TableContainer';
import { AgGridReact } from '@ag-grid-community/react';
import { AllModules } from '@ag-grid-enterprise/all-modules';
import { Button, Header, Image } from 'semantic-ui-react';

const Table = ({
  data,
  onPackingClick,
  onUnpackingClick,
  selection,
  setSelection,
}) => {
  const [gridApi, setGridApi] = useState(null);
  const [gridColumnApi, setGridColumnApi] = useState(null);
  const [defaultColDef] = useState({
    // flex: 1,
    filter: true,
    sortable: true,
    resizable: true,
  });
  const [columnDefs] = useState([
    {
      field: 'item',
      headerName: 'Item',
      cellRenderer: 'itemCellRenderer',
      suppressSizeToFit: true,
    },
    { field: 'location', headerName: 'Location' },
    { field: 'quantity', headerName: 'Quantity' },
    {
      field: 'button',
      headerName: '',
      pinned: 'right',
      cellStyle: { textAlign: 'center' },
      width: 150,
      cellRenderer: 'buttonCellRenderer',
    },
  ]);
  const [context, setContext] = useState({
    title: onPackingClick
      ? 'Packing'
      : onUnpackingClick
      ? 'Unpacking'
      : 'Untitled',
    onClick: null,
  });

  const handleClick = (product) => {
    // console.debug('handleClick', product, data.length);

    if (onPackingClick) onPackingClick({ ...product });
    else if (onUnpackingClick) onUnpackingClick({ ...product });
  };

  useEffect(() => {
    // console.debug(
    //   'useEffect data, onPackingClick, onUnpackingClick',
    //   data.length,
    //   context
    // );
    if (data && data.length) {
      setContext((context) => ({
        title: context.title,
        onClick: null,
        force: true,
      }));
    }
  }, [data, onPackingClick, onUnpackingClick]);

  useEffect(() => {
    // console.debug('useEffect context, gridApi', context);
    if (gridApi && context && gridColumnApi) {
      if (context.force) {
        setContext((context) => ({
          title: context.title,
          onClick: handleClick,
        }));
      } else {
        gridColumnApi.autoSizeColumns &&
          gridColumnApi.autoSizeColumn('item', false);
        gridApi.sizeColumnsToFit && gridApi.sizeColumnsToFit();

        gridApi.refreshCells({
          columns: ['button'],
          force: true,
          suppressFlash: true,
        });
        // gridApi.refreshHeader();
      }
    }
  }, [context, gridApi, gridColumnApi, handleClick]);

  const handleGridReady = ({ api, columnApi }) => {
    setGridApi(api);
    setGridColumnApi(columnApi);
  };

  return (
    <>
      <TableContainer className="ag-theme-material" rowFilters={3}>
        <AgGridReact
          modules={AllModules}
          defaultColDef={defaultColDef}
          columnDefs={columnDefs}
          suppressRowClickSelection={true}
          rowData={data}
          pagination={true}
          paginationPageSize={100}
          onGridReady={handleGridReady}
          context={context}
          components={{ itemCellRenderer: getItemCellRenderer() }}
          frameworkComponents={{
            itemCellRenderer2: ItemCellRenderer,
            buttonCellRenderer: ButtonCellRenderer,
          }}
        />
      </TableContainer>
    </>
  );
};

Table.propTypes = {
  data: PropTypes.array,
  onPackingClick: PropTypes.func,
  onUnpackingClick: PropTypes.func,
  selection: PropTypes.array,
  setSelection: PropTypes.func,
};

export default Table;

function getItemCellRenderer() {
  function ItemCellRenderer() {}
  ItemCellRenderer.prototype.init = function (params) {
    var tempDiv = document.createElement('div');
    tempDiv.innerHTML = `<h4 class="ui image header">
      <img
        src="${params.data.image}"
        class="ui small rounded image"
      />
      <div class="content">
        ${params.data.title}
        <div class="sub header">SKU&nbsp; ${params.data.sku}</div>
      </div>
    </h4>`;

    this.eGui = tempDiv.firstChild;
  };
  ItemCellRenderer.prototype.getGui = function () {
    return this.eGui;
  };
  return ItemCellRenderer;
}

const ItemCellRenderer = ({ data }) => (
  <Header as="h4" image>
    <Image src={data.image} rounded size="small" />
    <Header.Content>
      {data.title}
      <Header.Subheader>SKU&nbsp; {data.sku}</Header.Subheader>
    </Header.Content>
  </Header>
);

const ButtonCellRenderer = ({ data, context: { title, onClick } }) => {
  const handleClick = (event, _) => {
    onClick && onClick(data);
  };

  return (
    <>
      <Button compact primary onClick={handleClick}>
        {title}
      </Button>
    </>
  );
};
