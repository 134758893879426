import React, { useState } from 'react';
import { Form, Dropdown, Label } from 'semantic-ui-react';

/**
 * Basic DropDown
 * 
  <Dropdown
    fluid
    selection
    label="Tipo de Comprobante"
    value={values.idTipoComprobante}
    onChange={(e, { value }) =>
      setFieldValue('idTipoComprobante', value)
    }
    placeholder="Tipo de Comprobante"
    options={[{ key: 'T', value: 'T', text: 'Translado' }]}
  />
 */

const DropDown = ({
  value,
  label,
  onChange,
  options,
  error,
  clearable,
  isDisabled,
  handleBlur,
}) => {
  let [isFetching] = useState(false);
  return (
    <Form.Field>
      <label>{label}</label>
      <Dropdown
        fluid
        selection
        clearable
        search={true}
        options={options}
        value={value}
        onChange={onChange}
        disabled={isDisabled}
        loading={isFetching}
        error={error}
        onBlur={handleBlur}
      />
      {error && (
        <Label pointing prompt>
          {error.content}
        </Label>
      )}
    </Form.Field>
  );
};

export default DropDown;
