import React from 'react';
import ActivityIndicator from '../../components/ActivityIndicator';
import { Grid, GridRow, GridColumn, Dropdown, Button } from 'semantic-ui-react';
import styled from 'styled-components';

const TableNavigation = ({
  isBusy,
  hasSelection,
  messageAction,
  onDeleteClick,
}) => {
  return (
    <Grid columns={2} verticalAlign="middle" padded="vertically">
      <GridRow stretched>
        <GridColumn>
          {(isBusy && (
            <ActivityIndicator title={messageAction || 'Loading...'} />
          )) || (
            <Instructions>
              Select one or more Partnerships to do actions
            </Instructions>
          )}
        </GridColumn>
        <GridColumn textAlign="right">
          <div>
            <Dropdown
              text="Bulk Actions"
              as={Button}
              primary
              compact
              style={{ marginRight: 0 }}
              disabled={isBusy || !hasSelection}
            >
              <Dropdown.Menu>
                <Dropdown.Item
                  icon={{ name: 'delete', color: 'red' }}
                  text="Delete"
                  onClick={() => onDeleteClick()}
                />
              </Dropdown.Menu>
            </Dropdown>
          </div>
        </GridColumn>
      </GridRow>
    </Grid>
  );
};

const Instructions = styled.div`
  font-family: 'Roboto', sans-serif;
  font-size: 14px;
  color: rgba(0, 0, 0, 0.6);
  letter-spacing: 0.25px;
  text-align: left;
  line-height: 20px;
`;

export default TableNavigation;
