import React, { forwardRef } from 'react';
import { Form, Segment } from 'semantic-ui-react';

const FormHeader = ({ formik }) => {
  let {
    values,
    errors,
    touched,
    handleChange,
    handleBlur,
    handleSubmit,
    isSubmitting,
    /* and other goodies */
  } = formik;
  return (
    <>
      <Segment>
        
        <Form.Group widths={'equal'}>
        
          <Form.Input
            placeholder="Nombre"
            label="Nombre"
            name="Nombre"
            value={values.Nombre}
            onChange={handleChange}
            onBlur={handleBlur}
            // error={
            //   validations.name && { content: 'Required idTipoComprobante' }
            // }
          />
          <Form.Input
            placeholder="Codigo Postal"
            label="Codigo Psostal"
            name="c_CodigoPostal"
            value={values.c_CodigoPostal}
            onChange={handleChange}
            onBlur={handleBlur}
            // error={validations.lastName && { content: 'Required Last Name' }}
          />
          <Form.Input
            placeholder="Codigo Colonia"
            label="Codigo Colonia"
            name="c_Colonia"
            value={values.c_Colonia}
            onChange={handleChange}
            onBlur={handleBlur}
            // error={validations.lastName && { content: 'Required Last Name' }}
          />
          <Form.Input
            name="CcoloniaID"
            type="hidden"
            value={values.CcoloniaID}
          />
        </Form.Group>
      </Segment>
    </>
  );
};

export default forwardRef((props, ref) => (
  <FormHeader {...props} innerRef={ref} />
));
