import React from 'react';
import styled from 'styled-components';
import { Button, Icon } from 'semantic-ui-react';

const Actions = ({ isBusy, onBackClick }) => {
  const handledBackClick = (event) => {
    event.preventDefault();
    onBackClick && onBackClick(event);
  };

  return (
    <ActionContainer>
      {/*<UtilitiesGrid />*/}
      <Button primary compact disabled={isBusy}>
        <Icon name="save" /> Save
      </Button>
      <Button compact disabled={isBusy} onClick={handledBackClick}>
        <Icon name="reply" /> Back
      </Button>
    </ActionContainer>
  );
};

const ActionContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(7, 1fr);
  grid-template-rows: auto;
  grid-gap: 1em;
`;

export default Actions;
