import React, { useState } from 'react';
import PropTypes from 'prop-types';
import SlidingPanel from '@bit/softwareland.flex.sl-sliding-panel';
import swal from 'sweetalert';
import Actions from './Actions';
import Form from './Form';
import DrawerFiltersWrapper from '../../../components/DrawerFiltersWrapper';

const Drawer = ({
  openPanel,
  onClose,
  shipper: current
}) => {
  const [currentSection] = useState(current._id ? 'EDIT' : 'CREATE');
  const [isBusy, setIsBusy] = useState(false);

  let drawerTitles = {
    CREATE: {
      title: 'Create New Shipper',
      subtitle: '',
    },
    EDIT: {
      title: 'Edit Shipper',
      subtitle: '',
    },
  };

  let title = drawerTitles[currentSection].title;
  let subtitle = drawerTitles[currentSection].subtitle;

  const handledOnClose = async () => {
    let confirmation = await swal('Do you want close this form?', {
      buttons: ['No', 'Yes'],
    });

    if (confirmation) {
      onClose(false);
    }
  };

  const handleSuccess = (shipper) => {
    onClose(true);
  };

  return (
    <SlidingPanel
      isOpen={openPanel}
      size={50}
      title={title}
      subtitle={subtitle}
      onClose={handledOnClose}
    >
      <DrawerFiltersWrapper>
        <Form
          isBusy={isBusy}
          setIsBusy={setIsBusy}
          shipper={current}
          onSuccess={handleSuccess}
        >
          <Actions
            isBusy={isBusy}
            onBackClick={handledOnClose}
          />
        </Form>
      </DrawerFiltersWrapper>
    </SlidingPanel>
  );
};

Actions.propTypes = {
  openPanel: PropTypes.func,
  onClose: PropTypes.func,
  shipper: PropTypes.object
}

export default Drawer;