import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Button, Icon } from 'semantic-ui-react';

const ButtonsFilter = ({
  isBusy,
  isSearchBusy,
  isClearBusy,
  onSearchClick,
  onClearClick,
  style
}) => {
  return (
    <WrapperButtons style={style}>
      <Button
        primary
        compact
        disabled={typeof isSearchBusy === "boolean" ? isSearchBusy : isBusy}

        onClick={onSearchClick}
      >
        <Icon name="search" /> Search
        </Button>
      <Button
        compact
        disabled={typeof isClearBusy === "boolean" ? isClearBusy : isBusy}

        onClick={onClearClick}
      >
        <Icon name="erase" /> Clear
        </Button>
    </WrapperButtons>
  );
};

const WrapperButtons = styled.div`
  grid-area: buttons;
  display: flex;
  align-items: center;
  justify-content: flex-end;
`;

ButtonsFilter.propTypes = {
  isBusy: PropTypes.bool,
  isSearchBusy: PropTypes.bool,
  isClearBusy: PropTypes.bool,
  onSearchClick: PropTypes.func,
  onClearClick: PropTypes.func,
  style: PropTypes.object
};

export default ButtonsFilter;