import React, { useEffect, useState } from 'react';
import { Divider, Header, Icon, Table } from 'semantic-ui-react';
import feathers from '../../../../../services/feathersRest';
import feathersSocket from '../../../../../services/feathers';
import ActivityIndicator from '../../../../../components/ActivityIndicator';
import moment from 'moment';
import { constant, set } from 'lodash';
const PreviousImportInfo = ({ data, setData, state, setState }) => {
  const [lastRunData, setLastRunData] = useState(null);
  const [recentItemData, setRecentItemData] = useState(null);
  const [lastRushorderData, setLastRushorderData] = useState(null);
  const [isBusy, setIsBusy] = useState(false);
  const [diffRecentAndNewest, setDiffRecentAndNewest] = useState(false);
  const val = React.useRef();

  useEffect(() => {
    setState({ ...state, isValid: true });
    setData({
      ...data,
      info: {
        firstCargoItem: '',
        lastCargoItem: '',
      },
    });
  }, []);

  useEffect(() => {
    val.current = recentItemData;
    recentItemData &&
      setData({
        ...data,
        info: {
          firstCargoItem:
            recentItemData.result &&
            moment(recentItemData.result.firstCreatedDate).format(
              'MM/DD/YYYY hh:mm A'
            ),
          lastCargoItem:
            recentItemData.result &&
            moment(recentItemData.result.lastCreatedDate).format(
              'MM/DD/YYYY hh:mm A'
            ),
        },
      });
  }, [recentItemData]);

  useEffect(() => {
    const getData = async () => {
      let lastRunData = await feathers.service('import-shiphero').find({
        query: {
          $sort: {
            createdAt: -1,
          },
          $limit: 1,
        },
      });

      lastRunData.data.length && setLastRunData(lastRunData.data[0]);

      let recentItemData = await feathers.service('import-shiphero').find({
        query: {
          $sort: {
            'result.lastCreatedDate': -1,
          },
          $limit: 1,
        },
      });
      recentItemData.data.length && setRecentItemData(recentItemData.data[0]);

      lastRunData.data.length &&
        recentItemData.data.length &&
        lastRunData.data[0]._id !== recentItemData.data[0]._id &&
        setDiffRecentAndNewest(true);
      const service = feathersSocket.service('rush-orders');
      let rushorderData = await service.find({
        //{
        query: {
          // 'request.type': 'orders',
          $sort: {
            endRunAt: -1,
          },
          $limit: 1,
          // },
        },
      });

      rushorderData.data.length && setLastRushorderData(rushorderData.data[0]);
    };
    setIsBusy(true);
    getData();
    setIsBusy(false);
  }, []);

  const formatDate = (date) => {
    return moment(new Date(date)).format('MM/DD/YYYY hh:mm A');
  };

  const showData = (childData, isDate) => {
    console.log(data);
    return childData ? (
      isDate ? (
        formatDate(childData)
      ) : (
        childData
      )
    ) : isBusy ? (
      <ActivityIndicator title={'Loading...'} />
    ) : (
      '---'
    );
  };

  return (
    <>
      <Divider horizontal>
        <Header as="h4">
          <Icon name="bar chart" />
          Shiphero
        </Header>
      </Divider>

      <Table definition>
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell />
            <Table.HeaderCell>Run at</Table.HeaderCell>
            <Table.HeaderCell>From Date</Table.HeaderCell>
            <Table.HeaderCell>To Date</Table.HeaderCell>
            <Table.HeaderCell>Elements Imported</Table.HeaderCell>
            <Table.HeaderCell>First item date</Table.HeaderCell>
            <Table.HeaderCell>Last item date</Table.HeaderCell>
          </Table.Row>
        </Table.Header>
        <Table.Body>
          <Table.Row>
            <Table.Cell>Last import run</Table.Cell>
            <Table.Cell>
              {' '}
              {showData(lastRunData ? lastRunData.startRunAt : null, true)}
            </Table.Cell>
            <Table.Cell>
              {' '}
              {showData(
                lastRunData ? lastRunData.request.fromDate : null,
                true
              )}
            </Table.Cell>
            <Table.Cell>
              {' '}
              {showData(lastRunData ? lastRunData.request.toDate : null, true)}
            </Table.Cell>
            <Table.Cell>
              {' '}
              {showData(lastRunData ? lastRunData.result.created : null, false)}
            </Table.Cell>
            <Table.Cell>
              {' '}
              {showData(
                lastRunData ? lastRunData.result.firstCreatedDate : null,
                true
              )}
            </Table.Cell>
            <Table.Cell>
              {' '}
              {showData(
                lastRunData ? lastRunData.result.lastCreatedDate : null,
                true
              )}
            </Table.Cell>
          </Table.Row>
          {diffRecentAndNewest ? (
            <Table.Row>
              <Table.Cell>Newest items imported</Table.Cell>
              <Table.Cell>
                {' '}
                {showData(
                  recentItemData ? recentItemData.startRunAt : null,
                  true
                )}
              </Table.Cell>
              <Table.Cell>
                {' '}
                {showData(
                  recentItemData ? recentItemData.request.fromDate : null,
                  true
                )}
              </Table.Cell>
              <Table.Cell>
                {' '}
                {showData(
                  recentItemData ? recentItemData.request.toDate : null,
                  true
                )}
              </Table.Cell>
              <Table.Cell>
                {' '}
                {showData(
                  recentItemData ? recentItemData.result.created : null,
                  false
                )}
              </Table.Cell>
              <Table.Cell>
                {' '}
                {showData(
                  recentItemData
                    ? recentItemData.result.firstCreatedDate
                    : null,
                  true
                )}
              </Table.Cell>
              <Table.Cell>
                {' '}
                {showData(
                  recentItemData ? recentItemData.result.lastCreatedDate : null,
                  true
                )}
              </Table.Cell>
            </Table.Row>
          ) : (
            <></>
          )}
        </Table.Body>
      </Table>

      <Divider horizontal>
        <Header as="h4">
          <Icon name="bar chart" />
          Rush Order
        </Header>
      </Divider>
      <Table definition>
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell />
            <Table.HeaderCell>Run at</Table.HeaderCell>
            <Table.HeaderCell>Imported Orders</Table.HeaderCell>
            <Table.HeaderCell>Created Items</Table.HeaderCell>
          </Table.Row>
        </Table.Header>
        <Table.Body>
          <Table.Row>
            <Table.Cell>Last import</Table.Cell>
            <Table.Cell>
              {' '}
              {showData(
                lastRushorderData ? lastRushorderData.startRunAt : null,
                true
              )}
            </Table.Cell>
            <Table.Cell>
              {' '}
              {showData(
                lastRushorderData
                  ? lastRushorderData.result.orders.length
                  : null,
                false
              )}
            </Table.Cell>
            <Table.Cell>
              {' '}
              {showData(
                lastRushorderData ? lastRushorderData.result.created : null,
                false
              )}
            </Table.Cell>
          </Table.Row>
        </Table.Body>
      </Table>
    </>
  );
};

export default PreviousImportInfo;
