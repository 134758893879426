import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import TableContainer from '../../../../components/TableContainer';
import { AgGridReact } from '@ag-grid-community/react';
import { AllModules } from '@ag-grid-enterprise/all-modules';
import _ from 'lodash';
import { Label } from 'semantic-ui-react';
import {
  valueFormatter,
  number,
  price,
  dateTime,
} from '../../../../components/AgGridColumns';
import swal from 'sweetalert';
import { integratorOptions } from '../constants/filters';

const Table = ({
  data,
  selection,
  setSelection,
  getData,
  valueProductGetter,
  valueDailyLogGetter,
}) => {
  const [columnGroupDef] = useState({
    pinned: 'left',
    cellRenderer: 'agGroupCellRenderer',
    cellRendererParams: { checkbox: true },
  });
  const [defaultColDef] = useState({
    filter: true,
    sortable: true,
    enableRowGroup: true,
    resizable: true,
  });
  const [sideBar] = useState({
    toolPanels: ['columns'],
    defaultToolPanel: 'filters',
  });
  const [gridColumnApi, setGridColumnApi] = useState();
  const [gridApi, setGridApi] = useState();
  const [columnDefs, setColumnDefs] = useState([
    {
      headerName: 'BIN',
      valueGetter: valueDailyLogGetter('bin'),
      headerCheckboxSelection: true,
      headerCheckboxSelectionFilteredOnly: true,
      checkboxSelection: true,
      valueFormatter,
    },
    {
      headerName: 'Is 321',
      valueGetter: valueProductGetter('is321'),
      valueFormatter,
    },
    {
      headerName: 'Import Code',
      valueGetter: valueProductGetter('exportCode'),
      valueFormatter,
    },
    {
      headerName: 'Fiber',
      valueGetter: valueProductGetter('fiber'),
      valueFormatter,
    },
    {
      headerName: 'COO',
      valueGetter: valueProductGetter('coco'),
      valueFormatter,
    },
    {
      headerName: 'DESC',
      valueGetter: valueProductGetter('description'),
      valueFormatter,
    },
    {
      ...price,

      headerName: 'Cost',

      valueGetter: (params) => {
        if (
          params.data &&
          params.data.product &&
          params.data.product.length &&
          params.data.product[0]
        ) {
          return params.data.product[0].cost;
        }

        return null;
      },
    },
    {
      headerName: 'Tags',
      cellRendererFramework: (params) =>
        (params.data &&
          params.data.tags &&
          params.data.tags.length &&
          params.data.tags.map((item, idx) => (
            <Label key={idx} as="a" tag>
              {item}
            </Label>
          ))) ||
        '',
    },
    {
      ...dateTime,

      headerName: 'Created Date',
      field: 'createdDateTime',
      sort: 'asc',
    },
    {
      headerName: 'Product Name',
      field: 'productName',
      valueFormatter,
    },
    {
      headerName: 'SKU',
      field: 'SKU',
      valueFormatter,
    },
    {
      ...number,

      headerName: 'QTY Shipped',
      field: 'qtyShipped',
    },
    {
      ...price,

      headerName: 'Price',
      field: 'price',
    },
    {
      ...price,

      headerName: 'Shipped Subtotal',
      field: 'shippedSubtotal',
    },
    {
      headerName: '3PL Customer',
      field: 'customer3PL',
      valueFormatter,
    },
    {
      headerName: 'Order Number',
      field: 'orderNumber',
      valueFormatter,
    },
    {
      headerName: 'Tracking Number',
      field: 'trackingNumber',
      valueFormatter,
    },
    {
      headerName: 'Email',
      field: 'email',
      valueFormatter,
    },
    {
      headerName: 'Name',
      field: 'name',
      valueFormatter,
    },
    {
      headerName: 'Address',
      field: 'address',
      valueFormatter,
    },
    {
      headerName: 'City',
      field: 'city',
      valueFormatter,
    },
    {
      headerName: 'Zip',
      field: 'zip',
      valueFormatter,
    },
    {
      headerName: 'State',
      field: 'state',
      valueFormatter,
    },
    {
      headerName: 'Country',
      field: 'country',
      valueFormatter,
    },
    {
      headerName: 'Phone',
      field: 'phone',
      valueFormatter,
    },
    {
      headerName: 'Product Tariff Code',
      field: 'productTariffCode',
      valueFormatter,
    },
    {
      headerName: 'Integrator',
      field: 'integrator',
      valueFormatter: (params) => {
        const value =
          params &&
          params.value &&
          integratorOptions.find(({ value }) => value === params.value);

        return (value && value.text.length && value.text) || '-';
      },
    },
  ]);

  // Effects
  useEffect(() => {
    // console.debug('useEffect Table', selection, (gridApi && gridApi.getSelectedRows()));
    if (
      selection &&
      !selection.length &&
      gridApi &&
      gridApi.getSelectedRows().length
    ) {
      gridApi.deselectAll();
    }
  }, [selection, gridApi]);

  // Handles
  const handleSelectionChanged = (event) => {
    if (selection && setSelection) {
      let rows = [];

      try {
        if (event && event.api) {
          rows = event.api.getSelectedRows().map((row) => row._id);
        }
      } catch (err) {
        rows = [];

        swal({
          title: 'Error',
          text: 'Failed to select Shipped Itmes',
          icon: 'error',
          buttons: {
            cancel: 'Close',
          },
        });
      } finally {
        // console.debug('onSelectionChanged event rows', '[' + rows.map(item => `ObjectId('${item}')`).join(', ') + ']');
        setSelection(rows);
      }
    }
  };

  const onGridReady = async ({ columnApi, api }) => {
    getData && getData();
    setGridColumnApi(columnApi);
    setGridApi(api);
  };

  const settingColumngGroupDefinition = (valuesRowGroups) => {
    let changedDefs = _.cloneDeep(columnDefs);
    if (valuesRowGroups.columns.length > 0) {
      changedDefs = changedDefs.map((col) =>
        col.headerName === valuesRowGroups.columns[0].colDef.headerName
          ? {
              ...col,
              checkboxSelection: false,
              pinned: false,
              hide: true,
              headerCheckboxSelection: false,
              rowGroup: true,
            }
          : {
              ...col,
              checkboxSelection: false,
              headerCheckboxSelection: false,
              rowGroup: false,
            }
      );
    } else {
      changedDefs = changedDefs.map((col) =>
        col.headerName === 'BIN'
          ? {
              ...col,
              checkboxSelection: true,
              hide: false,
              headerCheckboxSelection: true,
              rowGroup: false,
            }
          : {
              ...col,
              checkboxSelection: false,
              hide: false,
              headerCheckboxSelection: false,
              rowGroup: false,
            }
      );
    }

    setColumnDefs(changedDefs);
  };

  return (
    <TableContainer className="ag-theme-material" rowFilters={'D3'}>
      <AgGridReact
        columnDefs={columnDefs}
        rowData={data}
        rowSelection="multiple"
        suppressRowClickSelection={true}
        onSelectionChanged={handleSelectionChanged}
        defaultColDef={defaultColDef}
        sideBar={sideBar}
        groupHideOpenParents={true}
        // rowGroupPanelShow={'always'}
        // floatingFilter={true}
        groupSelectsChildren={true}
        pagination={true}
        paginationPageSize={100}
        modules={AllModules}
        autoGroupColumnDef={columnGroupDef}
        onColumnRowGroupChanged={(valuesRowGroups) =>
          settingColumngGroupDefinition(valuesRowGroups)
        }
        suppressDragLeaveHidesColumns={true}
        suppressMakeColumnVisibleAfterUnGroup={true}
        onGridReady={onGridReady}
      />
    </TableContainer>
  );
};

Table.propTypes = {
  data: PropTypes.array,
  selection: PropTypes.array,
  setSelection: PropTypes.func,
  getData: PropTypes.func,
  valueProductGetter: PropTypes.func,
  valueDailyLogGetter: PropTypes.func,
};

export default Table;
