import React, { forwardRef } from 'react';
import { Form, Segment } from 'semantic-ui-react';
import Dropdown from '../../../components/Formik/Dropdown';
import DropdownSearchFetch from '../../../components/Formik/DropdownSearchFetch';
import DropdownSimpleFetch from '../../../components/Formik/DropdownSimpleFetch';

const FormHeader = ({ formik }) => {
  let {
    values,
    errors,
    touched,
    handleChange,
    handleBlur,
    handleSubmit,
    isSubmitting,
    setFieldValue
    /* and other goodies */
  } = formik;
  return (
    <>
      <Segment>
        
        <Form.Group widths={'equal'}>
          <Form.Input
            placeholder="Codigo"
            label="Codigo"
            name="CodigoChofer"
            value={values.CodigoChofer}
            onChange={handleChange}
            onBlur={handleBlur}
            error={
              errors.CodigoChofer && { content: errors.CodigoChofer }
            }
          />
          <Form.Input
            placeholder="Nombre"
            label="Nombre"
            name="Nombre"
            value={values.Nombre}
            onChange={handleChange}
            onBlur={handleBlur}
            error={
              errors.Nombre && { content: errors.Nombre }
            }
          />
          <DropdownSimpleFetch
            clearable
            fluid
            selection
            label="Tipo Figura Transortista"
            value={values.TipoFiguraTransportista}
            onChange={(e, { value }) =>
              setFieldValue('TipoFiguraTransportista', value)
            }
            placeholder="Tipo Figura Transortista"
            featherService="catalogo-figura-transporte"
            columName="Descripcion"
            columValue="CFtransporteID"
            error={errors.TipoFiguraTransportista && touched.TipoFiguraTransportista}
          />
          <Form.Input
            placeholder="Licencia"
            label="Licencia"
            name="NumeroLicencia"
            value={values.NumeroLicencia}
            onChange={handleChange}
            onBlur={handleBlur}
            error={
              errors.NumeroLicencia && { content: errors.NumeroLicencia }
            }
          />
        </Form.Group>
        <Form.Group widths={'equal'}>
          
          <Form.Input
            placeholder="RFC"
            label="RFC"
            name="RFC"
            value={values.RFC}
            onChange={handleChange}
            onBlur={handleBlur}
            error={
              errors.RFC && { content: errors.RFC }
            }
          />
          <DropdownSearchFetch
            fluid
            selection
            label="Residencia Fiscal"
            value={values.PaisResidenciaFiscal}
            onChange={(e, { value }) => setFieldValue('PaisResidenciaFiscal', value)}
            placeholder="Residencia Fiscal"
            featherService="catalogo-pais"
            columName="Descripcion"
            columValue="CPaisID"
            mapValues={(value) => {
              return {
                key: Math.random(),
                text: `${value['c_Pais']} - ${value['Descripcion']}`,
                value: value['CPaisID'],
              };
            }}
            error={
              errors.PaisResidenciaFiscal && { content: errors.PaisResidenciaFiscal }
            }
          />
          <Form.Input
            placeholder="Registro Tributario"
            label="Registro Tributario"
            name="NumRegistroTributario"
            value={values.NumRegistroTributario}
            onChange={handleChange}
            onBlur={handleBlur}
            error={
              errors.NumRegistroTributario && { content: errors.NumRegistroTributario }
            }
          />
        </Form.Group>
      </Segment>
    </>
  );
};

export default forwardRef((props, ref) => (
  <FormHeader {...props} innerRef={ref} />
));
