import React from 'react';
import { Button } from 'semantic-ui-react';

const TopNavigation = ({ openPanel, setOpenPanel }) => (
  <Button primary compact onClick={() => setOpenPanel(!openPanel)}>
    Create Client
  </Button>
);

export default TopNavigation;
