import React, { useState } from 'react';
import TableContainer from '../../components/TableContainer';
import { AgGridReact } from '@ag-grid-community/react';
import { AllModules } from '@ag-grid-enterprise/all-modules';
import _ from 'lodash';
import { Button } from 'semantic-ui-react';
import { number, dateTime } from '../../components/AgGridColumns';

const Table = ({
  data,
  selectedData,
  getData,
  setCurrentConsolidation,
  setOpenPanel,
  setDrawerTitle,
}) => {
  const [columnGroupDef] = useState({
    pinned: 'left',
    cellRenderer: 'agGroupCellRenderer',
    cellRendererParams: { checkbox: true },
  });
  const [defaultColDefs] = useState({
    filter: false,
    sortable: true,
    enableRowGroup: true,
    resizable: true,
    flex: 1,
    width: 250,
  });
  const [sideBar] = useState({
    toolPanels: ['columns'],
    defaultToolPanel: 'filters',
  });
  const [, setGridColumnApi] = useState({});
  const [, setGridApi] = useState({});

  // console.count(gridColumnApi, gridApi);

  const [columnDefs, setColumnDefs] = useState([
    {
      ...dateTime,

      headerName: 'Shipped Date',
      field: 'shipped_date',
      sort: 'asc',

      checkboxSelection: true,
      headerCheckboxSelection: true
    },
    {
      ...dateTime,

      headerName: 'Creation Date',
      field: 'createdAt'
    },
    {
      ...number,

      headerName: 'Shipped Items',
      field: 'total_items',
      valueFormatter: 'value || 0'
    },
    {
      headerName: 'Action',
      field: 'actions',
      cellRendererFramework: (params) =>
        (params && params.data && (
          <Button compact primary onClick={() => handledOnEdit(params.data)}>
            Edit
          </Button>
        )) ||
        '',
    },
  ]);

  const handledOnEdit = (data) => {
    setCurrentConsolidation(data);
    setOpenPanel(true);
    setDrawerTitle('Edit');
  };

  const onGridReady = (params) => {
    setGridColumnApi(params.columnApi);
    setGridApi(params.api);
    getData();
  };

  const settingColumngGroupDefinition = (valuesRowGroups) => {
    let changedDefs = _.cloneDeep(columnDefs);

    if (valuesRowGroups.columns.length > 0) {
      changedDefs = changedDefs.map((col) =>
        col.field === valuesRowGroups.columns[0].colDef.field &&
          col.field !== 'actions'
          ? {
            ...col,
            checkboxSelection: false,
            pinned: false,
            hide: true,
            headerCheckboxSelection: false,
            rowGroup: true,
          }
          : col.field === 'actions'
            ? {
              ...col,
              checkboxSelection: false,
              hide: false,
              headerCheckboxSelection: false,
              rowGroup: false,
            }
            : {
              ...col,
              checkboxSelection: false,
              headerCheckboxSelection: false,
              rowGroup: false,
              hide: false,
            }
      );
    } else {
      changedDefs = changedDefs.map((col) =>
        col.field === 'shipped_date'
          ? {
            ...col,
            checkboxSelection: true,
            hide: false,
            headerCheckboxSelection: true,
            rowGroup: false,
          }
          : {
            ...col,
            checkboxSelection: false,
            hide: false,
            headerCheckboxSelection: false,
            rowGroup: false,
          }
      );
    }

    setColumnDefs(changedDefs);
  };

  return (
    <TableContainer className="ag-theme-material" rowFilters={1}>
      <AgGridReact
        autoGroupColumnDef={columnGroupDef}
        columnDefs={columnDefs}
        defaultColDef={defaultColDefs}
        groupHideOpenParents={true}
        groupSelectsChildren={true}
        modules={AllModules}
        onColumnRowGroupChanged={(valuesRowGroups) =>
          settingColumngGroupDefinition(valuesRowGroups)
        }
        onGridReady={onGridReady}
        onSelectionChanged={(checkedItem) =>
          selectedData(checkedItem.api.getSelectedRows())
        }
        pagination={true}
        paginationPageSize={100}
        rowData={data}
        rowGroupPanelShow="always"
        rowSelection="multiple"
        sideBar={sideBar}
        suppressDragLeaveHidesColumns={true}
        suppressMakeColumnVisibleAfterUnGroup={true}
        suppressRowClickSelection={true}
      />
    </TableContainer>
  );
};

export default Table;
