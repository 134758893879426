import { AgGridReact } from '@ag-grid-community/react';
import { AllModules } from '@ag-grid-enterprise/all-modules';
import 'lodash';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { Button, Icon } from 'semantic-ui-react';
import swal from 'sweetalert';
import { date } from '../../components/AgGridColumns';
import TableContainer from '../../components/TableContainer';
import feathers from '../../services/feathers';

const Table = ({
  data,
  onEditClick,
  onDeleteClick,
  selection,
  setSelection,
  setIsBusy,
  lookupDailyLog,
  suppressScroll,
  goToBottomOnChange,
  ...other
}) => {
  const service = feathers.service('daily-logs');

  // States
  const [gridApi, setGridApi] = useState();
  const [columnDefs] = useState([
    ...(selection && setSelection
      ? [
          {
            headerName: '',
            width: 70,
            checkboxSelection: true,
            headerCheckboxSelection: true,
            suppressMenu: true,
            pinned: 'left',
            enableRowGroup: false,
            resizable: false,
            sortable: false,
          },
        ]
      : []),

    {
      ...date,

      field: 'actualDate',
      headerName: 'Date',
    },
    { field: 'bin', headerName: 'Bin' },

    ...(onEditClick
      ? [
          {
            headerName: '',
            field: '',
            pinned: 'right',
            cellStyle: { textAlign: 'center' },

            cellRendererFramework: (params) => {
              if (params.data)
                return (
                  <Button
                    compact
                    primary
                    onClick={() => handleEditClick(params.data)}
                  >
                    Edit
                  </Button>
                );
            },
          },
        ]
      : []),
    ...(onDeleteClick
      ? [
          {
            headerName: '',
            field: '',
            pinned: 'right',
            cellStyle: { textAlign: 'center' },
            cellRendererFramework: (params) => {
              if (params.data)
                return (
                  <Icon
                    color="red"
                    name="delete"
                    size="large"
                    link
                    onClick={() => handleDeleteClick(params)}
                  />
                );
            },
          },
        ]
      : []),
  ]);

  const [noScrollOnNewData] = useState(suppressScroll ? suppressScroll : false);
  const [goToBottom] = useState(
    goToBottomOnChange ? goToBottomOnChange : false
  );
  const [defaultColDef] = useState({
    flex: 1,
    sortable: true,
  });

  // Effects
  useEffect(() => {
    // console.debug('useEffect Table', selection, (gridApi && gridApi.getSelectedRows()));
    if (
      selection &&
      !selection.length &&
      gridApi &&
      gridApi.getSelectedRows().length
    ) {
      gridApi.deselectAll();
    }
  }, [selection, gridApi]);

  useEffect(() => {
    // console.count('useEffect lookupDailyLog');
    // console.debug('columnDefs.length', columnDefs.length);
    if (lookupDailyLog && gridApi) {
      const end = columnDefs.length === 4 ? 2 : 1;
      const cols = columnDefs
        .slice(0, end)
        .concat({
          field: lookupDailyLog.key,
          headerName: lookupDailyLog.label,
          resizable: true,
        })
        .concat(columnDefs.slice(end))
        .map((col, index) => ({ ...col, colId: index, flex: 1 }));

      gridApi.setColumnDefs(cols);
      gridApi.sizeColumnsToFit();
    }
  }, [lookupDailyLog, gridApi, columnDefs]);

  // Handles
  const handleEditClick = (dailyLog) => {
    onEditClick && onEditClick({ ...dailyLog });
  };

  const handleDeleteClick = async (params /*, dailyLog*/) => {
    let success = false,
      result = null,
      dailyLog = params.data;
    let msg = `Do you want to delete this log?

      ${
        lookupDailyLog.key === 'trackingNumber'
          ? dailyLog.trackingNumber
          : dailyLog.orderNumber
      } - ${dailyLog.bin}`;
    let confirmation = await swal(msg, {
      buttons: ['No', 'Yes'],
    });
    if (confirmation) {
      try {
        setIsBusy(true);

        result = await (dailyLog._id ? service.remove(dailyLog._id, {}) : null);

        success = true;
        setIsBusy(false);
      } catch (error) {
        console.error('Error: ', error);
        await swal('Error', "Couldn't Remove Daily Log", 'error');
      } finally {
        if (success) {
          if (onDeleteClick && result.length) {
            var rowData = [];
            params.api.forEachNode(function (node) {
              if (node.data._id !== dailyLog._id) rowData.push(node.data);
            });
            onDeleteClick(rowData);
            params.api.ensureIndexVisible(rowData.length - 1, 'middle');
            await swal('Done', 'Finished', 'success');
          }
        }
      }
    }
  };

  const handleSelectionChanged = (event) => {
    if (selection && setSelection) {
      let rows = [];

      try {
        if (event && event.api) {
          rows = event.api.getSelectedRows().map((row) => row._id);
        }
      } catch (err) {
        rows = [];

        swal({
          title: 'Error',
          text: 'Failed to select daily logs',
          icon: 'error',
          buttons: {
            cancel: 'Close',
          },
        });
      } finally {
        // console.debug('onSelectionChanged event rows', '[' + rows.map(item => `ObjectId('${item}')`).join(', ') + ']');
        setSelection(rows);
      }
    }
  };

  const handleGridReady = (params) => setGridApi(params.api);
  const handleRowDataChanged = (event) => {
    const index = data.length - 1;
    if (event && event.api) {
      event.api.ensureIndexVisible(index, 'bottom');
    }
  };

  return (
    <TableContainer className="ag-theme-material" rowFilters={1}>
      <AgGridReact
        modules={AllModules}
        columnDefs={lookupDailyLog ? columnDefs : []}
        defaultColDef={defaultColDef}
        immutableColumns={true}
        {...(selection && setSelection
          ? {
              rowSelection: 'multiple',
              suppressRowClickSelection: true,
              onSelectionChanged: handleSelectionChanged,
            }
          : {})}
        rowData={data}
        pagination={true}
        suppressScrollOnNewData={noScrollOnNewData}
        paginationPageSize={100}
        onGridReady={handleGridReady}
        onRowDataChanged={goToBottom ? handleRowDataChanged : null}
        {...other}
      />
    </TableContainer>
  );
};

Table.propTypes = {
  data: PropTypes.array,
  onEditClick: PropTypes.func,
  selection: PropTypes.array,
  setSelection: PropTypes.func,
};

export default Table;
