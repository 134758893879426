import React, { useEffect } from 'react';
import styled from 'styled-components';
import permissions from './permissions.json';
import feathers from '../../../../../services/feathers';
import {
  Modal,
  Button,
  Icon,
  List,
  Loader,
  Image,
  Segment,
  Step,
  Card,
  Placeholder,
  Dimmer,
  Checkbox,
} from 'semantic-ui-react';

const PERMISSIONS_VALID = permissions.map(({ key }) => key);
const Permissions = ({ data, setData, state, setState }) => {
  useEffect(() => {
    // console.count('useEffect data');
    const isValid =
      (data &&
        Array.isArray(data.permissions) &&
        data.permissions.length > 0) ||
      false;

    setState({ ...state, isValid });
  }, [data]);

  useEffect(() => {
    // console.count('useEffect state');
    if (!state.permissions) {
      let didCancel = false;
      const dispatch = (func, data) => !didCancel && func(data);

      const getPermissions = async () => {
        const result = await feathers.service('configuration').find({
          query: {
            name: { $in: PERMISSIONS_VALID },
            value: true,
            $select: ['name'],
          },
        });

        if (!didCancel && Array.isArray(result) && result.length) {
          const perValid = permissions
            .map((perm) =>
              result.find(({ name }) => name === perm.key) ? perm : null
            )
            .filter((perm) => perm);

          dispatch(setState, { ...state, permissions: perValid });
        }
      };

      getPermissions();
      return () => (didCancel = true);
    }
  }, [state]);

  const handlePermissionChange = (_, { name, checked }) =>
    setData((prev) => {
      const newPerms = ((prev && prev.permissions) || []).filter(
        (key) => key !== name
      );

      checked && newPerms.push(name);

      return { ...prev, permissions: newPerms };
    });

  return (
    <Wrapper>
      {state && state.permissions ? (
        state.permissions.map(({ key, title }) => (
          <Segment compact key={key}>
            <Checkbox
              toggle
              name={key}
              checked={data.permissions.includes(key)}
              label={<label>{title}</label>}
              onChange={handlePermissionChange}
            />
          </Segment>
        ))
      ) : (
        <></>
      )}
    </Wrapper>
  );
};

const Wrapper = styled.div`
  width: '200%';
  height: 15vh;
  vertical-align: center;
  align-items: center;
  align-content: center;

  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-template-rows: auto;
  grid-gap: 1em;

  & > div {
    margin: 0 !important;
  }
`;

export default Permissions;
