import React, { useEffect, useReducer } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Checkbox } from 'semantic-ui-react';
import DrawerFiltersWrapper from '../../components/DrawerFiltersWrapper';
import SemanticInput from '../../components/SemanticInput';
import ButtonsFilter from '../../components/ButtonsFilter';

const queryInit = {
  text: '',
  incomplete: false
};

const reducerQuery = (prevState, { name, value, type, query }) => {
  if (type === 'init' && query) {
    return query;
  } else if (name) {
    return { ...prevState, [name]: value }
  }

  return prevState;
};

const Filters = ({
  isBusy,
  onSearch
}) => {
  // States
  const [query, dispatchQuery] = useReducer(reducerQuery, { ...queryInit });

  // Effects
  useEffect(() => {
    // console.count('Filters query');
    const sendQuery = () => {
      handleSearchClick()
    }

    sendQuery();
  }, []);

  // Handles
  const handleChangeInput = (_, { name, value }) => dispatchQuery({ name, value });

  const handleChangeCheckbox = (_, { name, checked }) => dispatchQuery({ name, value: checked });

  const handleSearchClick = (event, data) => {
    // Create query for feathers
    const queryMap = {
      fuzzy: true,

      ...(query.text && { $search: query.text }),
      ...(query.incomplete && { addFromValidation: { $exists: true, $in: [true] } }),
      // ...query.incomplete && {
      //   $or: [
      //     { sku: { $exists: true, $in: ['', null] } },
      //     { name: { $exists: true, $in: ['', null] } },
      //     { cost: { $exists: true, $in: ['', null] } },
      //     { addFromValidation: { $exists: true, $in: [true] } }
      //   ]
      // }
    };

    // Send query to Products
    onSearch && onSearch(queryMap);
  };

  const handleClearClick = (event, data) => {
    dispatchQuery({
      type: 'init',
      query: {
        ...queryInit
      }
    });
  };

  return (
    <>
      {/* <pre>query: {JSON.stringify(query, null, 2)}</pre> */}

      <DrawerFiltersWrapper>
        <FiltersGrid>
          <SemanticInput
            name="text"
            label="Product"
            placeholder="Search..."
            value={query.text}
            onChange={handleChangeInput}

            fluid
            style={{ gridArea: 'search' }}
            disabled={isBusy}
          />

          <WrapperCheckbox>
            <Checkbox
              name="incomplete"
              label="Only Incomplete"
              checked={query.incomplete}
              onChange={handleChangeCheckbox}
              // style={{ marginTop: '2em', gridArea: 'check' }}

              slider
              disabled={isBusy}
            />
          </WrapperCheckbox>

          <ButtonsFilter
            isBusy={isBusy}
            onSearchClick={handleSearchClick}
            onClearClick={handleClearClick}
          />
        </FiltersGrid>
      </DrawerFiltersWrapper>
    </>
  );
};

const FiltersGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-template-rows: auto;
  grid-gap: 1em;
  grid-template-areas: 'search incomplete . buttons';
`;

const WrapperCheckbox = styled.div`
  grid-area: incomplete;
  display: flex;
  align-items: center;
  justify-content: flex-start;
`;

Filters.propTypes = {
  isBusy: PropTypes.bool,
  query: PropTypes.object,
  onSearch: PropTypes.func
};

export default Filters;