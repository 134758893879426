import React, { useState, useEffect, Fragment } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Button } from 'semantic-ui-react';
import feathers from '../../../../../services/feathers';
import ActivityIndicator from '../../../../../components/ActivityIndicator';
import SemanticInput from '../../../../../components/SemanticInput';
import swal from 'sweetalert';

const AssociateForm = ({
  onAssociate,
  shippedItem
}) => {
  const service = feathers.service('product-data');

  // States
  const [isSearching, setIsSearching] = useState(false);
  const [products, setProducts] = useState(false);
  const [pattern, setPattern] = useState('');
  const [search, setSearch] = useState('');
  const [typingTimeout, setTypingTimeout] = useState(0);
  const refSearch = React.useRef(null);

  // Effects
  useEffect(() => {
    // console.count('AssociateForm shippedItem');
    const initPattern = () => {
      if (shippedItem) {
        setPattern(shippedItem.SKU);
      }
    }

    initPattern();
  }, [shippedItem]);

  const handleChangeInput = (_, { value }) => {
    setPattern(value);

    if (typingTimeout) {
      clearTimeout(typingTimeout);
    }

    setTypingTimeout(setTimeout(async function () {
      if (search !== value) {
        setSearch(value);

        let products = [],
          result = [];

        try {
          if (value.length > 3) {
            setIsSearching(true);
            const query = {
              $or: [
                { sku: { $search: value } },
                { name: { $search: value } },
                { description: { $search: value } },
              ],
            };

            do {
              result = await service.find({
                query: {
                  ...query,

                  $skip: products.length
                }
              });

              products = products.concat(result.data);

              // console.debug('result', result);
            } while (products.length !== result.total);
          }
        } catch (err) {
          // console.error('err', err);
          products = [];

          swal({
            title: 'Error',
            text: 'Failed get products',
            icon: 'error',
            buttons: {
              cancel: 'Close'
            }
          });
        } finally {
          setProducts(products);
          setIsSearching(false);

          refSearch && refSearch.current && refSearch.current.focus();
        }
      }
    }, 1000));
  };

  const getHighlightedText = text => {
    const parts = text.split(new RegExp(`(${search})`, 'gi'));
    const textSpan = parts.map((part, i) => (<Fragment key={i}>
      {part.toLowerCase() === search.toLowerCase()
        ? <HighlightedText>{part}</HighlightedText>
        : part
      }
    </Fragment>));

    return textSpan;
  }

  return (
    <FormContainer>
      <InputWrapper>
        <SemanticInput
          name="pattern"
          label="Pattern"
          value={pattern}
          onChange={handleChangeInput}

          fluid
          disabled={isSearching}
          style={{ gridArea: 'pattern' }}
          ref={refSearch}
        />
      </InputWrapper>

      {isSearching
        ? <ActivityIndicator title="Searching..." />

        : products.length
          ? (
            products.map((option, i) => (
              <ResultSearch key={i}>
                <Title>Product Name: {getHighlightedText(option.name)}</Title>
                <Subtitle>SKU: {getHighlightedText(option.sku)}</Subtitle>
                <Button primary compact onClick={() => onAssociate(option)}>
                  Associate
                </Button>
              </ResultSearch>
            ))
          )

          : (
            <ResultSearch>
              <Title>There's no product available or selected</Title>
              <Subtitle>
                Please search the product to associate by it's SKU, Name or part of it's Description
              </Subtitle>
            </ResultSearch>
          )
      }
    </FormContainer>
  );
};

const FormContainer = styled.div`
  display: grid;
  grid-template: repeat(1, 1fr);
  grid-auto-rows: auto;
  grid-template-areas: '.';
  grid-gap: 1em;
`;

const InputWrapper = styled.div`
  display: grid;
  grid-template: repeat(4, 1fr);
  grid-auto-rows: auto;
  grid-template-areas: 'pattern . . .';
  grid-gap: 1em;
`;

const ResultSearch = styled.div``;

const Title = styled.div`
  font-weight: bold;
`;

const Subtitle = styled.div`
  color: rgba(56, 56, 56, 0.6);
`;

const HighlightedText = styled.span`
  font-weight: bold;
  color: #4f9aa8;
`;

AssociateForm.propTypes = {
  onAssociate: PropTypes.func,
  shippedItem: PropTypes.object
};

export default AssociateForm;