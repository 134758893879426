import React, { useState, useEffect } from 'react';
import TableContainer from '../../../../components/TableContainer';
import { AgGridReact } from '@ag-grid-community/react';
import { AllModules } from '@ag-grid-enterprise/all-modules';
import _ from 'lodash';
import { options as StatusOptions } from '../../../../constants/Status';
import { Icon } from 'semantic-ui-react';
import { number, price, dateTime } from '../../../../components/AgGridColumns';
import swal from 'sweetalert';

const Table = ({ data, selection, setSelection }) => {
  // States
  const [gridApi, setGridApi] = useState();
  const [columnDefs] = useState([
    {
      ...dateTime,

      headerName: 'Created Date',
      field: 'createdDateTime',
      sort: 'asc',

      checkboxSelection: true,
      headerCheckboxSelection: true,
    },
    {
      headerName: 'Status',
      field: 'status',
      width: 150,
      cellRendererFramework: (params) => {
        if (params.data && params.data.status) {
          const status = StatusOptions.find(
            (s) => s.key === params.data.status
          );

          if (status) {
            return (
              <>
                <Icon name={status.icon.name} color={status.icon.color} />{' '}
                {status.text}
              </>
            );
          }
        } else return '-';
      },
    },
    {
      headerName: 'Product Name',
      field: 'productName',
    },
    {
      headerName: 'SKU',
      field: 'SKU',
      width: 150,
      minWidth: 50,
    },
    {
      ...number,

      headerName: 'QTY Shipped',
      field: 'qtyShipped',
      width: 150,
    },
    {
      ...price,

      headerName: 'Price',
      field: 'price',
      width: 100,
    },
    {
      ...price,

      headerName: 'Shipped Subtotal',
      field: 'shippedSubtotal',
      width: 180,
    },
    { headerName: '3PL Customer', field: 'customer3PL', width: 180 },
    { headerName: 'Order Number', field: 'orderNumber' },
    {
      headerName: 'Tracking Number',
      field: 'trackingNumber',
    },
    { headerName: 'Email', field: 'email' },
    { headerName: 'Name', field: 'name' },
    { headerName: 'Address', field: 'address' },
    { headerName: 'City', field: 'city' },
    { headerName: 'Zip', field: 'zip', width: 150 },
    { headerName: 'State', field: 'state', width: 100 },
    { headerName: 'Country', field: 'country', width: 150 },
    { headerName: 'Phone', field: 'phone' },
    { headerName: 'Product Tariff Code', field: 'productTariffCode' },
  ]);

  // Effects
  useEffect(() => {
    // console.debug('useEffect Table', selection, (gridApi && gridApi.getSelectedRows()));
    if (
      selection &&
      !selection.length &&
      gridApi &&
      gridApi.getSelectedRows().length
    ) {
      gridApi.deselectAll();
    }
  }, [selection, gridApi]);

  // Handles
  const handleSelectionChanged = (event) => {
    if (selection && setSelection) {
      let rows = [];

      try {
        if (event && event.api) {
          rows = event.api.getSelectedRows().map((row) => row._id);
        }
      } catch (err) {
        rows = [];

        swal({
          title: 'Error',
          text: 'Failed to select Shipped Items',
          icon: 'error',
          buttons: {
            cancel: 'Close',
          },
        });
      } finally {
        // console.debug('onSelectionChanged event rows', '[' + rows.map(item => `ObjectId('${item}')`).join(', ') + ']');
        setSelection(rows);
      }
    }
  };

  const handleGridReady = (params) => setGridApi(params.api);

  const settingColumngGroupDefinition = (valuesRowGroups) => {
    let changedDefs = _.cloneDeep(columnDefs);
    if (valuesRowGroups.columns.length > 0) {
      changedDefs = changedDefs.map((item) =>
        item.field === valuesRowGroups.columns[0].colDef.field
          ? {
              ...item,
              pinned: false,
              hide: true,
              rowGroup: true,
            }
          : { ...item, rowGroup: false }
      );
    }

    gridApi.setColumnDefs(changedDefs);
  };

  return (
    <TableContainer className="ag-theme-material" rowFilters={'D1'}>
      <AgGridReact
        columnDefs={columnDefs}
        rowData={data}
        rowSelection="multiple"
        defaultColDef={{
          filter: false,
          sortable: true,
          enableRowGroup: true,
          resizable: true,
          width: 250,
        }}
        onSelectionChanged={handleSelectionChanged}
        suppressRowClickSelection={true}
        sideBar={{
          toolPanels: ['columns'],
          defaultToolPanel: 'filters',
        }}
        groupHideOpenParents={true}
        // rowGroupPanelShow={'always'}
        groupSelectsChildren={true}
        pagination={true}
        paginationPageSize={100}
        modules={AllModules}
        onColumnRowGroupChanged={(valuesRowGroups) =>
          settingColumngGroupDefinition(valuesRowGroups)
        }
        suppressDragLeaveHidesColumns={true}
        suppressMakeColumnVisibleAfterUnGroup={true}
        onGridReady={handleGridReady}
        // isRowSelectable={params => !rowValidate(params.data)}
        // floatingFilter={true}
      />
    </TableContainer>
  );
};

export default Table;
