import React, { useReducer, useEffect } from 'react';
import { Tab as TabSemantic } from 'semantic-ui-react';
import reducerTabs from './reducerTabs';

const Tab = ({
  event,
  setEvent,
  isBusy,
  setIsBusy,
  onDeletePackageClick,
  onUnpackingClick,
}) => {
  // States
  const [tab, dispatchTab] = useReducer(reducerTabs, {
    activeIndex: 0,
    panes: [],
  });

  // Handles
  const handleTabChange = (_, { activeIndex }) => dispatchTab({ activeIndex });

  // Effects
  useEffect(() => {
    // console.count('Tabs useEffect event', event);
    if (event && event.type && tab) {
      switch (event.type) {
        case 'packing':
          const { panes, activeIndex } = tab;
          if (
            panes &&
            panes.length &&
            activeIndex >= 0 &&
            activeIndex < panes.length
          ) {
            dispatchTab({
              type: 'update',
              data: [...panes[activeIndex].data, event.product],
            });
          } else {
            dispatchTab({
              type: 'add',
              data: [event.product],
            });
          }
          break;

        case 'addPackage':
          dispatchTab({
            type: 'add',
            data: [],
          });
          break;
      }
      setEvent(null);
    }
  }, [event]);

  useEffect(() => {
    const handleDeletePackageClick = (index) => {
      const { panes } = tab;
      if (tab && panes && panes.length && index >= 0 && index < panes.length) {
        const dataRemoved = panes[index].data || [];
        dispatchTab({
          type: 'delete',
          activeIndex: index,
        });

        onDeletePackageClick && onDeletePackageClick(dataRemoved);
      }
    };

    const handleUnpackingClick = (product) => {
      const { panes, activeIndex } = tab;
      if (
        tab &&
        panes &&
        panes.length &&
        activeIndex >= 0 &&
        activeIndex < panes.length
      ) {
        setIsBusy(true);
        const indexProduct = panes[activeIndex].data.findIndex(
          ({ _id }) => _id === product._id
        );
        const newDataPacking = [
          ...panes[activeIndex].data.slice(0, indexProduct),
          ...panes[activeIndex].data.slice(indexProduct + 1),
        ];

        dispatchTab({
          type: 'update',
          data: newDataPacking,
        });

        onUnpackingClick && onUnpackingClick(product);
        setIsBusy(false);
      }
    };

    // console.count('useEffect tab, dispatchTab');
    const { panes, activeIndex } = tab;
    if (
      tab &&
      panes &&
      panes.length &&
      activeIndex >= 0 &&
      activeIndex < panes.length &&
      panes.some((pane) => pane.forceRender || pane.new)
    ) {
      dispatchTab({
        type: 'render',
        isBusy,
        handleDeletePackageClick,
        handleUnpackingClick,
      });
    }
  }, [
    tab,
    dispatchTab,
    isBusy,
    onDeletePackageClick,
    setIsBusy,
    onUnpackingClick,
  ]);

  return (
    <TabSemantic
      panes={tab.panes}
      activeIndex={tab.activeIndex}
      onTabChange={handleTabChange}
    />
  );
};

Tab.propTypes = {};

export default Tab;
