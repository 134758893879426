import React from 'react';
import styled from 'styled-components';

export default props => {
  return <Footer>©2020 FlexLogic</Footer>;
};

const Footer = styled.footer`
  text-align: center;
  background-color: #f5f5f5;
  font-size: 12px;
  padding: 5px;
`;
