import React, { useState, useEffect } from 'react';
import DefaultLayout from '../../layouts/DefaultLayout';
import TopNavigation from './TopNavigation';
import HeaderBG from '../../components/HeaderBG';
import SectionTitle from '../../components/SectionTitle';
import Filters from './Filters';
import TableNavigation from './TableNavigation';
import Table from './Table';
import Drawer from './Drawer';
import { Container, Breadcrumb } from 'semantic-ui-react';
import feathers, { useAuth } from '../../services/feathers';
import swal from 'sweetalert';
import { pluralize } from '../../functions/common';
import dataTest from './dataTest';
import ModalUpload from './ModalUpload';
import axios from 'axios';

const BillOfLadingMx = (props) => {
  const service = feathers.service('documentos');
  const auth = useAuth();
  // console.debug('auth', JSON.stringify(auth, null, 2));

  // States
  const [openModalUpload, setOpenModalUpload] = useState(false);

  const [currentDocumentId, setCurrentDocumentId] = useState([]);
  const [isBusy, setIsBusy] = useState(false);
  const [openPanel, setOpenPanel] = useState(false);
  const [data, setData] = useState([]);
  const [selection, setSelection] = useState([]);
  const [messageAction, setMessageAction] = useState();
  const [isSupport, setIsSupport] = useState(false);

  const [client, setClient] = useState(null);
  const [isValid, setIsValid] = useState(false);

  const [query, setQuery] = useState(null);
  const [timbrado, setTimbrado] = useState(false);

  // Effects
  useEffect(() => {
    const filter = () => {
      if (query) {
        getData();
      }
    };

    filter();
  }, [query, timbrado]);

  useEffect(() => {
    // console.debug('auth', JSON.stringify(auth, null, 2));
    const { isSupport } = (auth && auth.user) || {},
      client = (auth && auth.user && auth.user.client) || null,
      isInvalid = (!isSupport && !client && true) || false;

    setIsSupport(isSupport);
    setClient(client);
    setIsValid(!isInvalid);
  }, [auth.user]);

  // Functions
  const getData = async () => {
    if (query) {
      let data = [],
        result = [];

      try {
        setIsBusy(true);
        setSelection([]);

        if (auth.user) {
          query.Organizacion = auth.user.client._id;
        }

        console.debug('query', JSON.stringify(query, null, 2));
        do {
          result = await service.find({
            query: {
              ...query,
              $sort: { folio: -1 },
              $skip: data.length,
            },
          });

          data = data.concat(result.data);

          // console.debug('result', result.data);
        } while (data.length !== result.total);
      } catch (err) {
        console.error('Error', err);
        data = [];

        swal({
          title: 'Error',
          text: 'Failed get BillOfLadingMx',
          icon: 'error',
          buttons: {
            cancel: 'Close',
          },
        });
      } finally {
        setData(data);
        setIsBusy(false);
      }
    }
  };

  const setTestData = () => {
    setData(dataTest(true));
    setOpenModalUpload(false);

    setTimeout(() => {
      setData(dataTest(false));
    }, 5000);
  };

  const Timbrar = async (id, idPOS, idSucursal) => {
    let service = feathers.service('pos');

    let resultPos = await service.find({ query: { idPOS, idSucursal } });
    resultPos = (resultPos.data.length && resultPos.data[0]) || null;

    if (resultPos) {
      let idCertificado = resultPos.idCertificado;
      let idPAC = resultPos.idPAC;

      // await axios.get('https://api-app.flexlogic.co/timbrar-cfdi/' + id);
      let resultTimbrado = await axios.post(
        'https://api-app.flexlogic.co/timbrar-cfdi/',
        {
          idDocumento: id,
          idCertificado: idCertificado,
          idPAC: idPAC,
          sUser: auth.user._id,
        }
      );

      if (
        resultTimbrado.data.resultTimbrado.errors &&
        resultTimbrado.data.resultTimbrado.errors.length
      ) {
        // onClose(true);
        swal('Error', resultTimbrado.data.resultTimbrado.errors[0], 'error');
      } else {
        // updateFolio(values);
        // setCurrentSection('EDIT');
        // onClose(true);
        swal('Timbrado', '¡Se ha timbrado correctamente!', 'success');
        setTimbrado(true);
      }

      // alert(id);
    } else {
      swal('Error', 'No hay POS', 'error');
    }
  };

  const openDrawer = (documentId) => {
    setCurrentDocumentId(documentId);
    setOpenPanel(true);
  };

  // Handles
  const handleCreateClick = () => {
    openDrawer(null);
  };

  const handleEditClick = (documentId) => {
    openDrawer(documentId);
  };

  const handleDeleteClick = async () => {
    let success = false;

    try {
      const total = selection.length;
      let msg = `Do you want to delete ${total} ${pluralize(total, 'user')}?`;
      let confirmation = await swal(msg, {
        buttons: ['No', 'Yes'],
      });

      if (confirmation) {
        setIsBusy(true);
        setMessageAction('Deleting ...');
        await service.remove(selection);

        success = true;
      }
    } catch (error) {
      console.debug(error);
      await swal('Error', "Don't Delete selected BillOfLadingMx", 'error');
    } finally {
      setMessageAction();
      setIsBusy(false);

      if (success) {
        await swal('Done', 'Finished', 'success');

        setSelection([]);
        getData();
      }
    }
  };

  const handleClose = (isSuccess) => {
    setCurrentDocumentId(null);
    setOpenPanel(false);

    isSuccess && getData();
  };

  const getClientName = () =>
    (auth && auth.user && auth.user.client && auth.user.client.name) || null;

  return (
    <>
      <DefaultLayout
        currentPath={props.match.path}
        globalActions={
          <TopNavigation
            isBusy={isBusy}
            onCreateClick={handleCreateClick}
            isValid={isValid}
            setOpenModalUpload={setOpenModalUpload}
          />
        }
      >
        <HeaderBG />

        <Container className="fluid-95">
          <Breadcrumb
            icon="right angle"
            sections={[
              { key: getClientName(), content: getClientName(), link: false },
              // { key: 'Carta Porte', content: 'Carta Porte', link: false },
              {
                key: 'BillOfLadingMx',
                content: 'Carta Porte',
                active: true,
              },
            ]}
          />
          <Filters
            isBusy={isBusy}
            setQuery={setQuery}
            isValid={isValid}
            isSupport={isSupport}
            client={client}
          />
          {/* 
          <TableNavigation
            isBusy={isBusy}
            haveSelected={selection.length > 0}
            onDeleteClick={handleDeleteClick}
            messageAction={messageAction}
          /> */}
          <Table
            data={data}
            onTimbrar={Timbrar}
            onEditClick={handleEditClick}
            selection={selection}
            setSelection={setSelection}
            isSupport={isSupport}
          ></Table>
        </Container>
      </DefaultLayout>

      {openPanel ? (
        <Drawer
          openPanel={openPanel}
          onClose={handleClose}
          currentDocumentId={currentDocumentId}
        ></Drawer>
      ) : null}

      <ModalUpload
        open={openModalUpload}
        setOpen={setOpenModalUpload}
        importHandled={setTestData}
      ></ModalUpload>
    </>
  );
};

export default BillOfLadingMx;
