import React from 'react';
import styled from 'styled-components';
import Spinner from '../assets/graphics/load.gif';

export default props => (
  <ActivityIndicator>
    <Image src={Spinner} alt="" />
    <Title>{props.title}</Title>
  </ActivityIndicator>
);

const ActivityIndicator = styled.div`
  display: flex;
  align-items: center;
`;

const Image = styled.img`
  width: 24px;
  margin-right: 8px;
`;

const Title = styled.span`
  font-family: 'Roboto', sans-serif;
  font-size: 16px;
`;
