import React from 'react';
import styled from 'styled-components';

export default (props) => <HeaderBG />;

const HeaderBG = styled.div`
  position: absolute;
  width: 100%;
  height: 70px;
  background-color: #f5f5f5;
  z-index: -1;
`;
