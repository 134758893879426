import React, { useState } from 'react';
import { Container } from 'semantic-ui-react';
import HeaderBG from '../../components/HeaderBG';
import SectionTitle from '../../components/SectionTitle';
import DefaultLayout from '../../layouts/DefaultLayout';
import ImportBajaReport from './Drawer/Modals/ImportBajaReport';
import Filters from './Filters';

const BajaShipmentsReport = (props) => {
  // States
  const [isBusy] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const [query, setQuery] = useState(null);

  return (
    <DefaultLayout currentPath={props.match.path}>
      <HeaderBG />
      <Container>
        <SectionTitle>BAJA Shipments Report</SectionTitle>
        <Filters
          isBusy={isBusy}
          query={query}
          setQuery={setQuery}
          setIsOpen={setIsOpen}
        />
        {isOpen ? (
          <ImportBajaReport
            query={query}
            onClose={(isSuccess) => {
              setIsOpen(false);
              // isSuccess &&  TODO: generateReport
            }}
          />
        ) : (
          <></>
        )}
      </Container>
    </DefaultLayout>
  );
};
export default BajaShipmentsReport;
