import React, { useState, useEffect, useReducer } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import DrawerFiltersWrapper from '../../components/DrawerFiltersWrapper';
import SemanticInput from '../../components/SemanticInput';
import { options as RolesOptions, support } from '../../constants/Roles';
import SemanticDropdown from '../../components/SemanticDropdown';
import ButtonsFilter from '../../components/ButtonsFilter';
import feathers, { useAuth } from '../../services/feathers';

const queryInit = {
  Nombre:'',
  Organizacion:''
};

const reducerQuery = (prevState, { name, value, type, query }) => {
  if (type === 'init' && query) {
    return query;
  } else if (name) {
    return { ...prevState, [name]: value };
  }

  return prevState;
};

const Filters = ({ isBusy, setQuery, isValid,getData }) => {
  const auth = useAuth();

  // States
  const [query, dispatchQuery] = useReducer(reducerQuery, { ...queryInit });
  const [roleOpts, setRoleOpts] = useState([]);
  const [clientOpts, setClientOpts] = useState([]);

  // Effects
  useEffect(() => {
    // console.count('Filters query');
    const sendQuery = () => {
      isValid && handleSearchClick();
    };

    sendQuery();
  }, [isValid]);

  useEffect(() => {
    const roleOpts = [...RolesOptions];
    if (!auth.user.isSupport) {
      const index = roleOpts.findIndex(({ value }) => value === support);
      roleOpts.splice(index, 1);
    }

    setRoleOpts(roleOpts);
  }, [auth.user]);

  useEffect(() => {
    let didCancel = false;

    const getClients = async () => {
      let options = [];

      if (auth && auth.user && auth.user.isSupport) {
        let result = await feathers.service('clients').find({
          query: {},
        });

        if (!didCancel) {
          options = result.data.map(({ _id, name }) => ({
            key: _id,
            text: name,
            value: _id,
          }));
        }
      }

      setClientOpts(options);
    };

    getClients();

    return () => {
      didCancel = true;
    };
  }, [auth]);

  // Handles
  const handleChangeInput = (_, { name, value }) =>
    dispatchQuery({ name, value });

  const handleSearchClick = () => {
    // Create query for feathers
    const queryMap = {
      Nombre : {$like:'%'+ query.Nombre +'%'},
      Organizacion: auth.user.client._id
    };
    setQuery && setQuery(queryMap);
    getData()
  };

  const handleClearClick = (event, data) => {
    dispatchQuery({
      type: 'init',
      query: {
        ...queryInit,
      },
    });
  };

  return (
    <>
      {/* <pre>query: {JSON.stringify(query, null, 2)}</pre> */}
      <DrawerFiltersWrapper>
        <FiltersGrid>
          <SemanticInput
            name="Nombre"
            label="Nombre"
            placeholder="Search..."
            value={query.Nombre}
            onChange={handleChangeInput}
            style={{ gridArea: 'search' }}
            disabled={isBusy || !isValid}
          />
          <ButtonsFilter
            isBusy={isBusy || !isValid}
            onSearchClick={handleSearchClick}
            onClearClick={handleClearClick}
          />
        </FiltersGrid>
      </DrawerFiltersWrapper>
    </>
  );
};

const FiltersGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-template-rows: auto;
  grid-gap: 1em;
  grid-template-areas: 'search role . buttons';
`;

Filters.propTypes = {
  isBusy: PropTypes.bool,
  setQuery: PropTypes.func,
  defaultUser: PropTypes.object,
  isValid: PropTypes.bool,
};

export default Filters;
