import React, { useState } from 'react';
import { Form as SemanticForm, Label } from 'semantic-ui-react';
import styled from 'styled-components';
import SemanticInput from '../../../components/SemanticInput';
import swal from 'sweetalert';

const Form = ({
  isBusy,
  setIsBusy,
  bin: current,
  service,
  onSuccess,
  children,
}) => {
  const [bin, setBin] = useState({ ...current });
  const [validations, setValidations] = useState({
    name: false,
  });

  const handledOnSubmit = async (event) => {
    let success = false,
      result = null;
    try {
      setIsBusy(true);
      event.preventDefault();

      const firstInvalid = isInvalid();

      if (!firstInvalid) {
        let binDTO = { ...bin };
        result = await (!bin._id
          ? service.create(binDTO)
          : service.patch(bin._id, binDTO));

        success = true;
      }
    } catch (error) {
      console.error('error :>> ', error);
      await swal('Error', `Bin not added`, 'error');
    } finally {
      setIsBusy(false);

      if (success) {
        await swal('Done', 'Finished', 'success');
        onSuccess && onSuccess(result);
      }
    }
  };

  const isInvalid = () => {
    let firstInvalid = null;

    const newValidations = Object.keys(validations).reduce((prev, field) => {
      const ret = { ...prev, [field]: bin[field] ? false : true };

      firstInvalid = firstInvalid || (ret[field] && field);

      return ret;
    }, {});

    setValidations(newValidations);

    return firstInvalid;
  };

  const handledChange = (_, { name, value }) =>
    setBin({ ...bin, [name]: value });

  return (
    <SemanticForm onSubmit={handledOnSubmit} loading={isBusy}>
      <FormGrid>
        <div style={{ gridArea: 'name' }}>
          <Label>
            To register the name of a new BIN try not to use the folio or
            consecutive number that you will use to differentiate between
            different bins of the same type. For example just use "bin-ex" to
            reffer the bins: bin-ex-1, bin-ex-2, etc.
          </Label>

          <SemanticInput
            placeholder="Name"
            label="Name *"
            name="name"
            value={bin.name || ''}
            onChange={handledChange}
          />

          <SemanticInput
            placeholder="Capacity"
            label="Capacity *"
            name="capacity"
            value={bin.capacity || ''}
            onChange={handledChange}
          />
        </div>
      </FormGrid>
      {children}
    </SemanticForm>
  );
};

const FormGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-template-rows: auto auto;
  grid-gap: 1em;
  grid-template-areas: 'name . .' '. . .';
`;

export default Form;
