import React, { useState, useEffect, useRef } from 'react';
import ReactDOM from 'react-dom';
import swal from 'sweetalert';
import { Dropdown, Ref } from 'semantic-ui-react';

const ModalClient = ({ clients, client }) => {
  const [id, setId] = useState((client && client._id) || null);
  const [options] = useState(
    (Array.isArray(clients) &&
      clients.length &&
      clients.map(({ _id, name }, index) => ({
        key: index,
        value: _id,
        text: name,
      }))) ||
      []
  );
  const refClient = useRef(null);

  useEffect(() => {
    // console.count('ModalClient useEffect refClient');
    refClient && refClient.current && refClient.current.focus();
  }, [refClient]);

  const handleChange = (_, { value }) => {
    setId(value);
    swal.setActionValue(value);
  };

  return (
    <Ref innerRef={refClient}>
      <Dropdown
        onChange={handleChange}
        options={options}
        placeholder="Client"
        selection
        value={id}
        className="swal-content__input"
        openOnFocus={false}
      />
    </Ref>
  );
};

ModalClient.propTypes = {};

export default async (user) => {
  // console.debug('ModalClient', JSON.stringify(user, null, 2));
  let newClientId = null;
  try {
    // We want to retrieve ModalClient as a pure DOM node:
    const wrapper = document.createElement('div');
    ReactDOM.render(<ModalClient {...user} />, wrapper);
    const el = wrapper.firstChild;

    newClientId = await swal({
      text: 'Client',
      content: el,
      button: {
        text: 'Ok',
        closeModal: true,
      },
    });
  } catch (err) {
    console.error('Error ::>', err);
    newClientId = null;
  }

  return (
    (newClientId &&
      String(newClientId).length &&
      user.client._id !== String(newClientId) &&
      String(newClientId)) ||
    null
  );
};
