import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import QueryBuilderOri from 'react-querybuilder';
import ButtonAction from './ButtonAction';
import DropdownSelector from './DropdownSelector';
import ValueEditor from './ValueEditor';
import Checkbox from './Checkbox';

const QueryBuilder = ({ controlElements, isValid, ...othersProps }) => {
  const controlElementsProps = {
    addGroupAction: ButtonAction,
    removeGroupAction: ButtonAction,
    addRuleAction: ButtonAction,
    removeRuleAction: ButtonAction,
    combinatorSelector: DropdownSelector,
    fieldSelector: DropdownSelector,
    operatorSelector: DropdownSelector,
    valueEditor: ValueEditor,
    notToggle: Checkbox,
    ...controlElements,
  };

  return (
    <WrapperQueryBuilder isValid={isValid}>
      <QueryBuilderOri
        {...othersProps}
        controlElements={controlElementsProps}
      />
    </WrapperQueryBuilder>
  );
};

QueryBuilder.propTypes = {
  fields: PropTypes.array.isRequired,
};

const WrapperQueryBuilder = styled.div`
  & > .queryBuilder .ruleGroup {
    background: ${(props) =>
      props.isValid ? 'rgba(79, 154, 168, 0.2)' : 'rgba(226, 83, 83, 0.2)'};
  }
`;

export default QueryBuilder;
