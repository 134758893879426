import React, { useEffect, useState } from 'react';
import DefaultLayout from '../../layouts/DefaultLayout';
import TopNavigation from './TopNavigation';
import HeaderBG from '../../components/HeaderBG';
import SectionTitle from '../../components/SectionTitle';
import Filters from './Filters';
import TableNavigation from './TableNavigation';
import Table from './Table';
import Drawer from './Drawer';
import { Container } from 'semantic-ui-react';
import feathers from '../../services/feathers';
import swal from 'sweetalert';
import { pluralize } from '../../functions/common';

const Consolidation = (props) => {
  const service = feathers.service('consolidated-cargo');

  // States
  const [isBusy, setIsBusy] = useState(false);
  const [data, setData] = useState();
  const [selection, setSelection] = useState([]);
  const [openPanel, setOpenPanel] = useState(false);
  const [currentConsolidation, setCurrentConsolidation] = useState({});
  const [messageAction, setMessageAction] = useState();
  const [drawerTitle, setDrawerTitle] = useState('');
  const [query, setQuery] = useState(null);

  // Effects
  useEffect(() => {
    const filter = () => {
      if (query) {
        getData();
      }
    };

    filter();
  }, [query]);

  const getData = async () => {
    if (query) {
      let data = [],
        result = [];

      try {
        setIsBusy(true);
        setSelection([]);

        // console.debug('query', JSON.stringify(query, null, 2));
        do {
          result = await service.find({
            query: {
              ...query,

              $skip: data.length,
            },
          });

          data = data.concat(result.data);

          // console.debug('result', data, result);
        } while (data.length !== result.total);
      } catch (err) {
        console.error('Error', err);
        data = [];

        swal({
          title: 'Error',
          text: 'Failed get Consolidated Cargos',
          icon: 'error',
          buttons: {
            cancel: 'Close',
          },
        });
      } finally {
        setData(data);
        setIsBusy(false);

        // refFilters && refFilters.current && refFilters.current.focus();
      }
    }
  };

  const selectedData = (selected) => {
    const selectedIDs = selected.map((item) => item._id);
    setSelection(selectedIDs);
  };

  const handleDelete = async () => {
    const total = selection.length;

    try {
      let msg = `Do you want to delete ${total} consolidated ${pluralize(
        total,
        'cargo'
      )}?`;
      let confirmation = await swal(msg, {
        buttons: ['No', 'Yes'],
      });
      if (confirmation) {
        setIsBusy(true);
        setMessageAction('Deleting ...');
        await feathers.service('consolidated-cargo').remove(selection);
        setMessageAction('Importing ...');
        getData();
      }
    } catch (error) {
      // console.debug(error)
      await swal(
        'Error',
        `Don't Delete selected consolidated ${pluralize(total, 'cargo')}`,
        'error'
      );
    }
    setIsBusy(false);
  };

  return (
    <>
      <DefaultLayout
        currentPath={props.match.path}
        globalActions={
          <TopNavigation
            openPanel={openPanel}
            setOpenPanel={setOpenPanel}
            setDrawerTitle={setDrawerTitle}
          />
        }
      >
        <HeaderBG />
        <Container>
          <SectionTitle>Consolidation 321</SectionTitle>

          <Filters isBusy={isBusy} query={query} setQuery={setQuery} />

          <TableNavigation
            isBusy={isBusy}
            messageAction={messageAction}
            selection={selection}
            handleDelete={handleDelete}
          ></TableNavigation>
          <Table
            getData={getData}
            data={data}
            selectedData={selectedData}
            selection={selection}
            setCurrentConsolidation={setCurrentConsolidation}
            openPanel={openPanel}
            setOpenPanel={setOpenPanel}
            setDrawerTitle={setDrawerTitle}
          ></Table>
        </Container>
      </DefaultLayout>

      {openPanel ? (
        <Drawer
          openPanel={openPanel}
          setOpenPanel={setOpenPanel}
          currentConsolidation={currentConsolidation}
          setCurrentConsolidation={setCurrentConsolidation}
          getConsolidationData={getData}
          drawerTitle={drawerTitle}
          isBusy={isBusy}
        ></Drawer>
      ) : null}
    </>
  );
};

export default Consolidation;
