import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import TableContainer from '../../components/TableContainer';
import { AgGridReact } from '@ag-grid-community/react';
import { AllModules } from '@ag-grid-enterprise/all-modules';
import 'lodash';
import { Button, Icon } from 'semantic-ui-react';
import ReactLoading from 'react-loading';
import { options as RolesOptions } from '../../constants/Roles';
import swal from 'sweetalert';
import feathers, { useAuth } from '../../services/feathers';
import {
  valueFormatter,
  dateTimeCartaPorte,
  date,
} from '../../components/AgGridColumns';
import { saveAs } from 'file-saver';
import axios from 'axios';
import moment from 'moment';

const Table = ({ data, onEditClick, selection, setSelection, onTimbrar }) => {
  const auth = useAuth();
  const servicePDF = feathers.service('export-pdf-cartaporte');
  // States
  const [gridApi, setGridApi] = useState();
  const [gridColumnApi, setGridColumnApi] = useState(null);
  const [columnDefs] = useState([
    // {
    //   headerName: '',
    //   width: 70,
    //   checkboxSelection: true,
    //   headerCheckboxSelection: true,
    //   suppressMenu: true,
    //   pinned: 'left',
    //   enableRowGroup: false,
    //   resizable: false,
    //   sortable: false,
    // },
    // {
    //   headerName: 'Completado',
    //   field: 'cfd_Certificado',
    //   cellRendererFramework: ({ data }) => {
    //     if (data.Importando) {
    //       return (
    //         <ReactLoading
    //           type={'cylon'}
    //           color={'black'}
    //           height={30}
    //           width={30}
    //         />
    //       );
    //     } else {
    //       return <Icon name="check circle" color="green" />;
    //     }
    //   },
    // },
    {
      headerName: 'Timbrado',
      field: 'cfd_Certificado',
      cellRendererFramework: (data) => {
        if (data.value) {
          return <Icon name="check" />;
        } else {
          return '';
        }
      },
    },

    {
      headerName: 'Tipo de Comprobante',
      field: 'idTipoComprobante',
      valueFormatter,
    },
    {
      headerName: 'Serie',
      field: 'Serie',
      valueFormatter,
    },
    {
      headerName: 'Folio',
      field: 'Folio',
      valueFormatter,
    },
    {
      ...dateTimeCartaPorte,
      headerName: 'Fecha Documento',
      field: 'FechaDocumento',
      sort: true,
    },
    {
      headerName: 'Status',
      field: 'idStatus',
      valueFormatter,
    },
    {
      headerName: 'RFC Receptor',
      field: 'ReceptorRFC',
      valueFormatter,
    },
    {
      headerName: 'Receptor Nombre',
      field: 'ReceptorNombre',
      valueFormatter,
    },
    {
      headerName: 'UIID Timbre',
      field: 'timbre_uuid',
      valueFormatter,
    },
    {
      headerName: 'Fecha Timbrado',
      field: 'timbre_FechaTimbrado',
    },
    {
      headerName: 'Versión',
      field: 'timbre_version',
      valueFormatter,
    },
    {
      headerName: '',
      field: '',
      pinned: 'right',
      cellStyle: { textAlign: 'center' },

      cellRendererFramework: (params) => {
        if (params.data)
          if (params.data.Importando)
            return (
              <ReactLoading
                type={'cylon'}
                color={'black'}
                height={30}
                width={30}
              />
            );

        if (!params.data.cfd_Certificado) {
          return (
            <Button.Group>
              {/* <Button
                compact
                onClick={() =>
                  // handleEditClick({
                  //   ...params.data,
                  //   client: params.data.client && params.data.client._id,
                  // })
                  handleEditClick(params.data.id)
                }
              >
                Editar
              </Button> */}
              <Button
                compact
                primary
                onClick={() =>
                  // handleEditClick({
                  //   ...params.data,
                  //   client: params.data.client && params.data.client._id,
                  // })
                  onTimbrar(
                    params.data.id,
                    params.data.idPOS,
                    params.data.idSucursal
                  )
                }
              >
                Timbrar
              </Button>
            </Button.Group>
          );
        } else {
          return (
            <Button.Group>
              <Button
                compact
                primary
                // onClick={() =>
                //   handleEditClick({
                //     ...params.data,
                //     client: params.data.client && params.data.client._id,
                //   })
                // }
                onClick={() => saveFileXML()}
              >
                XML
              </Button>
              <Button
                compact
                primary
                // onClick={() =>
                //   handleEditClick({
                //     ...params.data,
                //     client: params.data.client && params.data.client._id,
                //   })
                // }

                onClick={() => saveFilePDF(params.data.id)}
              >
                PDF
              </Button>
            </Button.Group>
          );
        }

        return '';
      },
    },
  ]);
  const [defaultColDefs] = useState({
    filter: false,
    sortable: true,
    enableRowGroup: true,
    resizable: true,
    flex: 1,
    width: 250,
  });

  // Effect
  useEffect(() => {
    if (
      selection &&
      !selection.length &&
      gridApi &&
      gridApi.getSelectedRows().length
    ) {
      gridApi.deselectAll();
    }
  }, [selection, gridApi]);

  const saveFilePDF = async (id) => {
    let html = await servicePDF.get(id);
    console.log(html);
    const url = window.URL.createObjectURL(new Blob([html]));
    const link = document.createElement('a');
    link.href = url;
    link.setAttribute('download', `FileName.pdf`);

    // Append to html link element page
    document.body.appendChild(link);

    // Start download
    link.click();

    // Clean up and remove the link
    link.parentNode.removeChild(link);
  };
  const saveFileXML = () => {
    saveAs(
      'https://raw.githubusercontent.com/facturacionmoderna/Comprobantes/master/CFDI_33/ejemplo_basico33_XML.xml',
      'factura.xml'
    );
  };

  // useEffect(() => {
  //   if (auth && auth.user && auth.user.isSupport && gridApi) {
  //     // console.count('Table useEffect client, gridApi');

  //     const end = 4;
  //     const cols = columnDefs
  //       .slice(0, end)
  //       .concat(
  //         {
  //           headerName: 'Clients',
  //           field: 'clients',
  //           valueFormatter: (params) => {
  //             if (
  //               params &&
  //               Array.isArray(params.value) &&
  //               params.value.length
  //             ) {
  //               return params.value
  //                 .map(({ tripNumber }) => tripNumber)
  //                 .sort()
  //                 .join(', ');
  //             }

  //             return '-';
  //           },
  //         },
  //         {
  //           headerName: 'Client Active',
  //           field: 'client',
  //           valueGetter: 'data && data.client && data.client.name',
  //           valueFormatter,
  //         }
  //       )
  //       .concat(columnDefs.slice(end));

  //     gridApi.setColumnDefs(cols);
  //     gridApi.sizeColumnsToFit();
  //   }
  // }, [auth, gridApi]);

  useEffect(() => {
    if (gridColumnApi) {
      var allColumnIds = gridColumnApi
        .getAllColumns()
        .filter(({ colDef: { field } }) => field !== '')
        .map(({ colId }) => colId);

      gridColumnApi.autoSizeColumns &&
        gridColumnApi.autoSizeColumns(allColumnIds, false);
    }
  }, [data, gridColumnApi]);

  // Handles
  const handleEditClick = (documentId) => {
    onEditClick && onEditClick(documentId);
  };

  const handleSelectionChanged = (event) => {
    if (selection && setSelection) {
      let rows = [];

      try {
        if (event && event.api) {
          rows = event.api.getSelectedRows().map((row) => row._id);
        }
      } catch (err) {
        rows = [];

        swal({
          title: 'Error',
          text: 'Failed to select users',
          icon: 'error',
          buttons: {
            cancel: 'Close',
          },
        });
      } finally {
        setSelection(rows);
      }
    }
  };

  const handleGridReady = ({ api, columnApi }) => {
    setGridApi(api);
    setGridColumnApi(columnApi);
  };

  return (
    <TableContainer className="ag-theme-material" rowFilters={1}>
      <AgGridReact
        modules={AllModules}
        columnDefs={columnDefs}
        defaultColDef={defaultColDefs}
        rowSelection={'multiple'}
        suppressRowClickSelection={true}
        onSelectionChanged={handleSelectionChanged}
        rowData={data}
        pagination={true}
        paginationPageSize={100}
        onGridReady={handleGridReady}
        sortingOrder={['desc', 'asc', null]}
      />
    </TableContainer>
  );
};

Table.propTypes = {
  data: PropTypes.array,
  onEditClick: PropTypes.func,
  selection: PropTypes.array,
  setSelection: PropTypes.func,
};

export default Table;
