import React, { useState, useEffect } from 'react';
import DefaulLayout from '../../layouts/DefaultLayout';
import TopNavigation from './TopNavigation';
import HeaderBG from '../../components/HeaderBG';
import { Container } from 'semantic-ui-react';
import SectionTitle from '../../components/SectionTitle';
import Filters from './Filters';
import TableNavigation from './TableNavigation';
import Table from './Table';
import Drawer from './Drawer';
import feathers from '../../services/feathers';
import swal from 'sweetalert';

const Client = (props) => {
  const service = feathers.service('clients');

  // States
  const [openPanel, setOpenPanel] = useState(false);
  const [isBusy, setIsBusy] = useState(false);
  const [data, setData] = useState([]);
  const [currentClient, setCurrentClient] = useState({});
  const [selection, setSelection] = useState([]);
  const [messageAction, setMessageAction] = useState();
  const [query, setQuery] = useState(null);

  // Effects
  useEffect(() => {
    const filter = () => {
      if (query) {
        getData();
      }
    };

    filter();
  }, [query]);

  // Functions
  const getData = async () => {
    if (query) {
      let data = [],
        result = [];

      try {
        setIsBusy(true);
        setSelection([]);

        // console.debug('query', JSON.stringify(query, null, 2));
        do {
          result = await service.find({
            query: {
              ...query,

              $skip: data.length,
            },
          });

          data = data.concat(result.data);

          // console.debug('result', result);
        } while (data.length !== result.total);
      } catch (err) {
        console.error('Error', err);
        data = [];

        swal({
          title: 'Error',
          text: 'Failed get Clients',
          icon: 'error',
          buttons: {
            cancel: 'Close',
          },
        });
      } finally {
        setData(data);
        setIsBusy(false);

        // refFilters && refFilters.current && refFilters.current.focus();
      }
    }
  };

  const onClose = (isSuccess) => {
    setCurrentClient({});
    setOpenPanel(!openPanel);

    isSuccess && getData();
  };

  const openDrawer = (client) => {
    setCurrentClient(client);
    setOpenPanel(true);
  };

  // Handles
  const handledEditClick = (client) => {
    openDrawer(client);
  };

  const handledDeleteClick = async () => {
    let success = false;

    try {
      const total = selection.length;
      let msg = `Do you want to delete ${total} Client${
        (total > 1 && 's') || ''
      }?`;
      let confirm = await swal(msg, {
        buttons: ['No', 'Yes'],
      });

      if (confirm) {
        let ids = selection.map((item) => item._id);
        setIsBusy(true);
        setMessageAction('Deleting ...');
        await service.remove(ids);
        success = true;
      }
    } catch (error) {
      console.error(error);
      await swal('Error', `Clients not deleted`, 'error');
    } finally {
      setMessageAction();
      setIsBusy(false);

      if (success) {
        await swal('Done', 'Finished', 'success');
        setSelection([]);
        getData();
      }
    }
  };

  return (
    <DefaulLayout
      currentPath={props.match.path}
      globalActions={
        <TopNavigation openPanel={openPanel} setOpenPanel={setOpenPanel} />
      }
    >
      <HeaderBG />
      <Container>
        <SectionTitle>Clients</SectionTitle>

        <Filters isBusy={isBusy} query={query} setQuery={setQuery} />

        <TableNavigation
          isBusy={isBusy}
          hasSelection={selection.length}
          messageAction={messageAction}
          onDeleteClick={handledDeleteClick}
        />
        <Table
          data={data}
          onEditClick={handledEditClick}
          getData={getData}
          setSelection={setSelection}
        />
      </Container>

      {(openPanel && (
        <Drawer
          openPanel={openPanel}
          onClose={onClose}
          client={currentClient}
          service={service}
        />
      )) ||
        null}
    </DefaulLayout>
  );
};

export default Client;
