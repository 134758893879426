import React, { useEffect, useState } from 'react';
import { Dropdown, Form, Label } from 'semantic-ui-react';
import feathers from '../../services/feathers';

/**
 * Simple Fetch DropDown
 * 
  <DropdownSimpleFetch
    fluid
    selection
    label="Tipo de Comprobante"
    value={values.idTipoComprobante}
    onChange={(e, { value }) =>
      setFieldValue('idTipoComprobante', value)
    }
    placeholder="Tipo de Comprobante"
    featherService="catalogo-tipo-de-comprobante"
    columName="Descripcion"
    columValue="c_TipoDeComprobante"
  />
 */

const DropDown = ({
  value,
  label,
  onChange,
  featherService,
  columName,
  columValue,
  mapValues,
  error,
  disabled,
  clearable,
  query,
}) => {
  let service = feathers.service(featherService);
  let [isFetching, setIsFetching] = useState(true);
  let [options, setOptions] = useState([]);
  let [fetchOptions, setFetchOptions] = useState([]);

  const mapValuesDefault = (value) => {
    return {
      key: Math.random(),
      text: value[columName],
      value: value[columValue],
    };
  };

  useEffect(() => {
    const fetchInitdata = async () => {
      let resultOptions = await service.find({
        query: query ? query : {},
      });
      setFetchOptions(resultOptions.data);
      let options = resultOptions.data.map(mapValues || mapValuesDefault);
      setOptions(options);
      setIsFetching(false);
    };
    fetchInitdata();
  }, [columName, columValue, service]);

  return (
    <Form.Field>
      <label>{label}</label>
      <Dropdown
        clearable
        fluid
        selection
        search={true}
        options={options}
        value={value}
        onChange={(e, target) => {
          onChange(e, target, fetchOptions);
        }}
        disabled={disabled}
        loading={isFetching}
        error={error}
      />
      {error && (
        <Label pointing prompt>
          {error.content}
        </Label>
      )}
    </Form.Field>
  );
};

export default DropDown;
