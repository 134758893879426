import swal from 'sweetalert';
import downloadFile from '../../../../../functions/downloadFile';
import moment from 'moment';
import { number, date } from '../../../../../components/AgGridColumns';

const ExcelJS = require('exceljs');

export default async (name = '', exportData) => {
  if (exportData && exportData.length > 0) {
    try {
      let mappedData = exportData.map((item) => {
        const product =
          (item && item.product && item.product.length && item.product[0]) ||
          null;
        // const cost =
        //   (product && number.valueFormatter({ value: product.cost })) || 'N/A';

        const usefullZip = item.zip
          ? String(item.zip).length > 5
            ? String(item.zip).slice(0, 5)
            : String(item.zip)
          : item.zip;

        return {
          'Shipment Control Number': '',
          'Shipment Type': 'Section 321',
          'Shipper Name': 'Baja Screen Print International',
          'Shipper Address': 'Vinedos 3000 Parque Industrial',
          'Shipper City': 'Tecate',
          'Shipper Country': 'MX',
          'Shipper State': 'BCN',
          'Shipper Postal': '21430',
          'Shipper Port of Lading': 'Baja California',

          'Consignee Name': item.name ? item.name : '-',
          'Consignee Address': item.address ? item.address : '-',
          'Consignee City': item.city ? item.city : '-',
          'Consignee Country': item.country ? item.country : '-',
          'Consignee State': item.state ? item.state : '-',
          'Consignee Postal': usefullZip
            ? isNaN(Number(usefullZip))
              ? usefullZip
              : Number(usefullZip)
            : '-',

          'Product Description': item.customsDescription || '-',
          'Product Qty': Number(item.qtyShipped),
          'Product UOM': 'PCS',
          'Product Weight': item.weight,
          'Product Unit of Weight': item.weightUnit,
          'Product Value': item.price ? item.price : 0,
          'Origin Country': item.countryOfManufacturer || '-',
        };
      });

      let exportName = `${name.toUpperCase()} 321-.csv`;

      const wb = new ExcelJS.Workbook();

      const ws = wb.addWorksheet('321 Template');

      // ws.getCell('A1').value = 'Date';
      // ws.getCell('A2').value = 'Bultos';
      // ws.getCell('B1').value = moment().startOf('day').format('DD/MM/YYYY');
      // ws.getCell('B1').alignment = { horizontal: 'right' };
      // ws.getCell('B2').value = {
      //   formula: 'SUM(O4:O' + (mappedData.length + 3) + ')',
      // };

      let headers = [];
      ws.columns = Object.keys(mappedData[0]).map((field) => {
        headers.push(field);
        return { key: field, width: 30 };
      }, {});
      ws.getRow(1).values = headers;
      ws.addRows(mappedData);

      // ws.getCell('A3').font = {
      //   name: 'Courier New',
      //   size: 11,
      //   bold: true,
      // };
      // ws.getCell('B3').font = {
      //   bold: true,
      // };
      //   ws.getColumn('A').alignment = { horizontal: 'right' };
      //   ws.getColumn('C').numFmt = '###0';
      //   ws.getColumn('C').alignment = { horizontal: 'left' };
      //   ws.getColumn('D').numFmt = '###0';
      //   ws.getColumn('D').alignment = { horizontal: 'left' };

      // let cells = [
      //   'A3',
      //   'B3',
      //   'C3',
      //   'D3',
      //   'E3',
      //   'F3',
      //   'G3',
      //   'H3',
      //   'I3',
      //   'J3',
      //   'K3',
      //   'L3',
      //   'M3',
      //   'N3',
      //   'O3',
      //   'P3',
      // ];
      // cells.forEach((cell) => {
      //   ws.getCell(cell).fill = {
      //     type: 'pattern',
      //     pattern: 'solid',
      //     fgColor: { argb: 'A4C2F4' },
      //   };
      // });

      const buf = await wb.csv.writeBuffer();
      downloadFile(
        buf,
        exportName,
        'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
      );
    } catch (error) {
      console.error('error', error);
      swal(`Could Not Export`, error, 'error');
    }
  } else {
    swal(`Could Not Export`, `No ${name} records  were found`, 'error');
  }
};
