import React, { useState } from 'react';
import PropTypes from 'prop-types';
import swal from 'sweetalert';
import SlidingPanel from '@bit/softwareland.flex.sl-sliding-panel';
import DrawerFiltersWrapper from '../../../components/DrawerFiltersWrapper';
import Form from './Form';

const Drawer = ({ openPanel, onClose, rule }) => {
  const [currentSection] = useState(rule._id ? 'EDIT' : 'CREATE');
  let drawerTitles = {
    CREATE: {
      title: 'Create New Rule',
      subtitle: '',
    },
    EDIT: {
      title: 'Edit Rule',
      subtitle: '',
    },
  };

  let title = drawerTitles[currentSection].title;
  let subtitle = drawerTitles[currentSection].subtitle;

  const handleOnClose = async () => {
    let confirmation = await swal('Do you want close this form?', {
      buttons: ['No', 'Yes'],
    });

    if (confirmation) {
      onClose(false);
    }
  };

  const handleSuccess = (rule) => {
    onClose(true);
  };

  return (
    <>
      <SlidingPanel
        isOpen={openPanel}
        size={70}
        title={title}
        subtitle={subtitle}
        onClose={handleOnClose}
      >
        <DrawerFiltersWrapper>
          <Form
            currentRule={rule}
            onSave={handleSuccess}
            onClose={handleOnClose}
          />
        </DrawerFiltersWrapper>
      </SlidingPanel>
    </>
  );
};

Drawer.propTypes = {
  openPanel: PropTypes.bool,
  onClose: PropTypes.func,
  rule: PropTypes.object,
};

export default Drawer;
