import React from 'react';
import styled from 'styled-components';
import { Modal, Button, Icon, Grid, List, Image } from 'semantic-ui-react';
import SemanticInput from '../../../components/SemanticInput';

const CalculateShippingRates = ({ onClose }) => {
  const handleSubmitClick = () => onClose(true);
  const handleCloseClick = () => onClose(false);
  return (
    <Modal open={true} size="small">
      <Modal.Header>Calculate Shipping Rates</Modal.Header>

      <Modal.Content>
        <Grid>
          <Grid.Row stretched>
            <Grid.Column centered>
              <ButtonsContainer>
                <Button primary circular size="small">
                  Same for all packages
                </Button>
                <Button primary circular size="small">
                  Cheapest for each package
                </Button>
              </ButtonsContainer>
            </Grid.Column>
          </Grid.Row>

          <Grid.Row stretched>
            <Grid.Column>
              <SemanticInput
                name="search"
                label="Search"
                fluid
                disabled={false}
              />
            </Grid.Column>
          </Grid.Row>

          <Grid.Row stretched>
            <Grid.Column>
              <List celled>
                <List.Item>
                  <Image
                    avatar
                    src="https://react.semantic-ui.com/images/avatar/small/helen.jpg"
                  />
                  <List.Content>
                    <List.Header>Snickerdoodle</List.Header>
                    An excellent companion
                  </List.Content>
                </List.Item>
                <List.Item>
                  <Image
                    avatar
                    src="https://react.semantic-ui.com/images/avatar/small/daniel.jpg"
                  />
                  <List.Content>
                    <List.Header>Poodle</List.Header>A poodle, it's pretty basic
                  </List.Content>
                </List.Item>
                <List.Item>
                  <Image
                    avatar
                    src="https://react.semantic-ui.com/images/avatar/small/daniel.jpg"
                  />
                  <List.Content>
                    <List.Header>Paulo</List.Header>
                    He's also a dog
                  </List.Content>
                </List.Item>
              </List>
            </Grid.Column>
          </Grid.Row>
        </Grid>
      </Modal.Content>

      <ActionContainer>
        <Button primary compact disabled={false} onClick={handleSubmitClick}>
          <Icon name="save" /> Submit
        </Button>

        <Button compact disabled={false} onClick={handleCloseClick}>
          <Icon name="reply" /> Close
        </Button>
      </ActionContainer>
    </Modal>
  );
};

const ButtonsContainer = styled.div`
  text-align: center;
`;

const ActionContainer = styled(Modal.Actions)``;

export default CalculateShippingRates;
