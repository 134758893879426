import moment from 'moment';

const formatDate = 'MM/DD/YYYY';
const formatDateTime = `${formatDate} hh:mm a`;

const getNumber = (num) => {
  let value = String(num).replace('$', '').replace(' ', '').trim();

  return Number(value.length ? value : null);
};

const getDateColumn = (format) => {
  return {
    filter: 'agDateColumnFilter',

    valueFormatter: (params) => {
      if (params && params.value) {
        let { value } = params;

        return `${moment.utc(value).format(format)}`;
      }

      return null;
    },
  };
};
const getDateColumnCartaPorte = (format) => {
  return {
    filter: 'agDateColumnFilter',

    valueFormatter: (params) => {
      if (params && params.value) {
        let { value } = params;

        return `${moment(value).add(7, 'hours').format(format)}`;
      }

      return null;
    },
  };
};

export const valueFormatter = 'value || "-"';

export const number = {
  cellStyle: { textAlign: 'right' },
  filter: 'agNumberColumnFilter',

  valueFormatter: (params) => {
    if (params) {
      let value = getNumber(params.value);

      if (!isNaN(value)) {
        return value;
      }
    }

    return 'N/A';
  },

  comparator: (val1, val2) => {
    let num1 = getNumber(val1);
    let num2 = getNumber(val2);

    if (isNaN(num1) && isNaN(num2)) {
      return 0;
    }

    if (isNaN(num1)) {
      return -1;
    }

    if (isNaN(num2)) {
      return 1;
    }

    return num1 - num2;
  },
};

export const price = {
  ...number,

  valueFormatter: (params) => {
    if (params) {
      let value = getNumber(params.value);

      if (!isNaN(value)) {
        return `$ ${parseFloat(value).toFixed(2)}`;
      }
    }

    return 'N/A';
  },
};

export const date = getDateColumn(formatDate);
export const dateTime = getDateColumn(formatDateTime);
export const dateTimeCartaPorte = getDateColumnCartaPorte(formatDateTime);
