import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Label, Grid, Segment } from 'semantic-ui-react';
import ActivityIndicator from '../../../components/ActivityIndicator';
import moment from "moment";

const Status = ({
  isBusy,
  order
}) => {
  return (
    <Grid columns={1} divided>
      <Grid.Row stretched>
        <Grid.Column>
          <Segment>
            <Label as='a' basic size="large">
              {order.status}
            </Label>

            <Label as='a' basic color='red' pointing size="large">
              {moment(order.shipDate, 'MM/DD/YYYY').fromNow()}
            </Label>
          </Segment>
        </Grid.Column>
      </Grid.Row>
    </Grid>
  );
};

const Instructions = styled.div`
  font-family: 'Roboto', sans-serif;
  font-size: 14px;
  color: rgba(0, 0, 0, 0.6);
  letter-spacing: 0.25px;
  text-align: left;
  line-height: 20px;
`;

Status.propTypes = {
  isBusy: PropTypes.bool,
  order: PropTypes.object
};

export default Status;