import React, { useState } from 'react';
import { Button, Form, Grid, Header, Segment } from 'semantic-ui-react';
import Logo from '../assets/graphics/flexlogic.png';
import SinglePageLayout from '../layouts/SinglePageLayout';

export default props => {
  const [userMail, setUserMail] = useState('');
  return (
    <SinglePageLayout>
      {console.log('userMail :', userMail)}
      <Grid container centered>
        <Grid.Column>
          <Segment>
            <Grid container centered padded>
              <img src={Logo} width="240" alt="FlexLogic" />
            </Grid>
            <Header as="h2">Recover your Password</Header>
            <Form>
              <Form.Input
                label="E-Mail"
                type="email"
                placeholder="Type your e-mail"
                onChange={(event, { value }) => {
                  setUserMail(value);
                }}
              />
              <Button
                type="submit"
                primary
                onClick={() => props.history.push('/login')}
              >
                Recover Password
              </Button>
            </Form>
          </Segment>
        </Grid.Column>
      </Grid>
    </SinglePageLayout>
  );
};
