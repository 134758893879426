import React, { forwardRef } from 'react';
import { Form, Segment } from 'semantic-ui-react';
import DropdownSearchFetch from '../../../components/Formik/DropdownSearchFetch';
import FormGroupLocations from './FormGroupLocations';

const getErrorsAndTouchs = (touched, errors, index, name) => {
  
};
const FormHeader = ({ formik }) => {
  let {
    values,
    errors,
    touched,
    handleChange,
    handleBlur,
    handleSubmit,
    isSubmitting,
    setFieldValue,
    /* and other goodies */
  } = formik;
  return (
    <>
      <Segment>
      <Form.Group widths={'equal'}>
        <Form.Input
            placeholder="Tipo PAC"
            label="idTipoPAC"
            name="idTipoPAC"
            value={values.idTipoPAC}
            onChange={handleChange}
            onBlur={handleBlur}
            error={
              errors.idTipoPAC && { content: errors.idTipoPAC }
            }
          />
        <Form.Input
          placeholder="Descripcion"
          label="Descripcion"
          name="Descripcion"
          value={values.Descripcion}
          onChange={handleChange}
          onBlur={handleBlur}
          error={
            errors.Descripcion && {
              content: errors.Descripcion,
            }
          }
        />
        <Form.Input
          placeholder="Usuario"
          label="Usuario"
          name="Usuario"
          value={values.Usuario}
          onChange={handleChange}
          onBlur={handleBlur}
          error={
            errors.Usuario && {
              content: errors.Usuario,
            }
          }
        />
        <Form.Input
          placeholder="Contrasena"
          label="Contrasena"
          name="Contrasena"
          value={values.Contrasena}
          onChange={handleChange}
          onBlur={handleBlur}
          error={
            errors.Contrasena && {
              content: errors.Contrasena,
            }
          }
        />
      </Form.Group>
      <Form.Group widths={'equal'}>
        <Form.Input
          placeholder="Status"
          label="Status"
          name="Status"
          value={values.Status}
          onChange={handleChange}
          onBlur={handleBlur}
          error={
            errors.Status && {
              content: errors.Status,
            }
          }
        />
        <Form.Input
          placeholder="Parametro1"
          label="Parametro1"
          name="Parametro1"
          value={values.Parametro1}
          onChange={handleChange}
          onBlur={handleBlur}
          error={
            errors.Parametro1 && {
              content: errors.Parametro1,
            }
          }
        />
        <Form.Input
          placeholder="Parametro2"
          label="Parametro2"
          name="Parametro2"
          value={values.Parametro2}
          onChange={handleChange}
          onBlur={handleBlur}
          error={
            errors.Parametro2 && {
              content: errors.Parametro2,
            }
          }
        />
        <Form.Input
          placeholder="Parametro3"
          label="Parametro3"
          name="Parametro3"
          value={values.Parametro3}
          onChange={handleChange}
          onBlur={handleBlur}
          error={
            errors.Parametro3 && {
              content: errors.Parametro3,
            }
          }
        />
      </Form.Group>
      <Form.Group widths={'equal'}>
        <Form.Input
          placeholder="Parametro4"
          label="Parametro4"
          name="Parametro4"
          value={values.Parametro4}
          onChange={handleChange}
          onBlur={handleBlur}
          error={
            errors.Parametro4 && {
              content: errors.Parametro4,
            }
          }
        />
      </Form.Group>
      </Segment>
    </>
  );
};

export default forwardRef((props, ref) => (
  <FormHeader {...props} innerRef={ref} />
));
