import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Form, Segment, Grid, Divider, Label } from 'semantic-ui-react';
import QueryBuilder, { formatQuery } from '../../../components/QueryBuilder';
import { fields, operators, toQuery, toRule } from './QueryBuilderConfig';
import ValueEditor from './ValueEditor';

const QueryBuilderComponent = ({
  query: sourceQuery,
  onQueryChange,
  error,
}) => {
  // console.debug('QueryBuilderComponent', sourceQuery, error);
  // States
  const [query, setQuery] = useState(toQuery(sourceQuery));

  // Effects
  useEffect(() => {
    const newQuery = toQuery(sourceQuery);
    const oldValue = query && formatQuery(query, 'sql');
    const newValue = sourceQuery && formatQuery(newQuery, 'sql');

    if (oldValue !== newValue && !oldValue.includes(', ""') && newValue) {
      setQuery(newQuery);
    }
  }, [sourceQuery]);

  // Handles
  const handleQueryChange = (query) => {
    setQuery(query);

    onQueryChange && onQueryChange(toRule(query));
  };

  const handleGetOperators = (fieldName) => {
    const field = fields.find(({ name }) => name === fieldName);
    if (field) {
      if (field.type === 'text' || field.type === 'select') {
        const optValid = ['in', 'notIn'];
        if (
          fieldName === 'line_items.sku' ||
          fieldName === 'line_items.product_name'
        ) {
          optValid.unshift('some', 'none');
        } else {
          optValid.unshift('==', '!=');
        }
        if (field.type === 'text') {
          optValid.push('contains');
        }

        return operators.filter(({ name }) => optValid.includes(name));
      } else if (field.type === 'number') {
        return operators.filter(
          ({ name }) => !['some', 'none', 'contains'].includes(name)
        );
      }
    }

    return [];
  };

  const handleGetValueEditorType = (fieldName, operator) => {
    const field = fields.find(({ name }) => name === fieldName);
    if (field) {
      return field.type === 'select' ? 'select' : 'text';
    }

    return 'text';
  };

  const handleGetInputType = (fieldName, operator) => {
    const field = fields.find(({ name }) => name === fieldName);
    if (field) {
      return field.type;
    }

    return 'text';
  };

  const handleGetValues = (fieldName, operator) => {
    switch (fieldName) {
      case 'to_address.typeOfAddress':
        return [
          { name: 'POBox', label: 'PO Box' },
          { name: 'USTerritories', label: 'US Territories' },
          { name: 'Military', label: 'Military' },
          { name: 'Domestic', label: 'Domestic' },
          { name: 'International', label: 'International' },
        ];

      default:
        return [];
    }
  };

  return (
    <>
      <Form.Field>
        <QueryBuilder
          query={query}
          fields={fields}
          onQueryChange={handleQueryChange}
          isValid={error === false}
          controlElements={{
            valueEditor: ValueEditor,
          }}
          getOperators={handleGetOperators}
          getValueEditorType={handleGetValueEditorType}
          getInputType={handleGetInputType}
          getValues={handleGetValues}
          resetOnOperatorChange={true}
        />
        {error ? (
          <Label pointing prompt>
            {error.content}
          </Label>
        ) : (
          <></>
        )}
      </Form.Field>

      {/* <Segment>
        <Grid columns={2} relaxed="very">
          <Grid.Column>
            <pre>Query: {JSON.stringify(query, null, 2)}</pre>
          </Grid.Column>
          <Grid.Column>
            <pre>Rule: {JSON.stringify(toRule(query), null, 2)}</pre>
          </Grid.Column>
        </Grid>

        <Divider vertical>Equals</Divider>
      </Segment> */}
    </>
  );
};

QueryBuilderComponent.propTypes = {
  query: PropTypes.object,
  onQueryChange: PropTypes.func.isRequired,
  error: PropTypes.oneOfType([PropTypes.object, PropTypes.bool]).isRequired,
};

export default QueryBuilderComponent;
