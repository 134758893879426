import React from 'react';
import styled from 'styled-components';
import NavigationBar from '../components/NavigationBar';

export default (props) => {
  return (
    <Container>
      <NavigationBar
        globalActions={props.globalActions}
        currentPath={props.currentPath}
      />
      <MainContent>{props.children}</MainContent>
      <Footer>©2020 FlexLogic</Footer>
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  flex-direction: column;
  min-height: 100vh;
`;

const MainContent = styled.main`
  display: flex;
  flex-grow: 1;
  padding-top: 84px;
  flex-direction: column;
`;

const Footer = styled.footer`
  text-align: center;
  background-color: #f5f5f5;
  font-size: 12px;
  padding: 5px;
`;
