import React, { useEffect, useState } from 'react';
import { AgGridReact } from '@ag-grid-community/react';
import { AllModules } from '@ag-grid-enterprise/all-modules';
import MainFiltersWrapper from '@bit/softwareland.flex.main-filters-wrapper';
import _ from 'lodash';
import Lottie from 'react-lottie';
import {
  Button,
  Dropdown,
  Grid,
  GridColumn,
  GridRow,
  Icon,
  Modal,
} from 'semantic-ui-react';
import styled from 'styled-components';
import swal from 'sweetalert';
import GeneratingLabels from '../../assets/animations/generating-layout.json';
import OrdersFilters from './OrdersFilters';
import feathers from '../../services/feathers';
import '../../styles/styles.scss';
import ActivityIndicator from '../ActivityIndicator';
import PricesPanelContent from '../PricesPanelContent';
import TableContainer from '../TableContainer';
import SlidingPanel from '@bit/softwareland.flex.sl-sliding-panel';
import { number, price, date } from '../AgGridColumns';

const OrdersImport = (props) => {
  const service = feathers.service('orders');

  // States
  const [data, setData] = useState([]);
  const [send, setSend] = useState(false);
  const [isBusy, setIsBusy] = useState(false);
  const [selection, setSelection] = useState([]);
  const [columnDefs, setColumnDefs] = useState([]);
  const [openPanel, setOpenPanel] = useState(false);
  const [importBusy, setImportBusy] = useState(false);
  const [columnDetails, setColumnDetails] = useState([]);
  const [quotesPanelData, setQuotesPanelData] = useState({});
  const [quoteDialogVisible, setQuoteDialogVisible] = useState(false);
  const [query, setQuery] = useState(null);

  // Effects
  useEffect(() => {
    const filter = () => {
      if (query) {
        getData();
      }
    };

    filter();
  }, [query]);

  const getData = async () => {
    if (query) {
      let data = [],
        result = [];

      try {
        setIsBusy(true);
        setSelection([]);

        // console.debug('query', JSON.stringify(query, null, 2));
        do {
          result = await service.find({
            query: {
              ...query,

              $skip: data.length,
            },
          });

          data = data.concat(result.data);

          // console.debug('result', result);
        } while (data.length !== result.total);
      } catch (err) {
        console.error('Error', err);
        data = [];

        swal({
          title: 'Error',
          text: 'Failed get Orders',
          icon: 'error',
          buttons: {
            cancel: 'Close',
          },
        });
      } finally {
        setData(data);
        setIsBusy(false);

        // refFilters && refFilters.current && refFilters.current.focus();
      }
    }
  };

  useEffect(() => {
    setColumnDefs([
      {
        headerName: 'Order #',
        field: 'order_number',
        pinned: 'left',
        width: 212,
        enableRowGroup: true,
        checkboxSelection: true,
        headerCheckboxSelection: true,
        filter: 'agTextColumnFilter',
        resizable: true,
        cellRenderer: 'agGroupCellRenderer',
      },
      {
        headerName: 'Status',
        field: 'integrator_fulfillment_status',
        valueGetter: (params) => {
          return (
            (params &&
              params.data &&
              params.data.integrator_fulfillment_status &&
              params.data.integrator_fulfillment_status.toUpperCase()) ||
            'N/A'
          );
        },
        enableRowGroup: true,
        filter: 'agTextColumnFilter',
        resizable: true,
      },
      {
        ...date,

        headerName: 'Order Date',
        field: 'order_date',

        enableRowGroup: true,
        resizable: true,
      },
      {
        headerName: 'Store',
        enableRowGroup: true,
        field: 'shop_name',
        resizable: true,
        filter: 'agTextColumnFilter',
      },
      {
        headerName: 'Tags',
        enableRowGroup: true,
        field: 'tags',
        resizable: true,
        filter: 'agTextColumnFilter',
      },
      {
        ...price,

        headerName: 'Total Price',
        enableRowGroup: true,
        field: 'total_price',
        resizable: true,
      },
      {
        ...price,

        headerName: 'Shipping Cost',
        enableRowGroup: true,
        field: 'shipping_cost',

        cellRendererFramework: (params) => {
          if (params && params.data) {
            const bestQuote = price.valueFormatter({
              value: params.data.bestQuote,
            });

            if (bestQuote !== 'N/A' && bestQuote !== '$ 0.00') {
              return (
                <ShippingCostGroup>
                  <ShippingPrice>{bestQuote}</ShippingPrice>

                  <ShippingOptions
                    onClick={() => {
                      setQuotesPanelData(params.data);
                      setOpenPanel(true);
                    }}
                  >
                    View Options
                  </ShippingOptions>
                </ShippingCostGroup>
              );
            }
          }

          return 'N/A';
        },
      },
      {
        headerName: 'Carrier',
        field: 'carrier',
        enableRowGroup: true,
        valueGetter: (params) => {
          return params.data
            ? params.data.carrier && params.data.carrier !== ''
              ? params.data.carrier === 'lightWeight'
                ? 'USPS - Parcel Select Lightweight®'
                : params.data.carrier
              : 'N/A'
            : null;
        },
        resizable: true,
      },
      {
        ...number,

        headerName: 'Item Count',
        field: 'line_items',
        enableRowGroup: true,

        valueGetter:
          '(data && data.line_items && data.line_items.length) || ""',
        resizable: true,
      },
      {
        headerName: 'Shipper Name',
        enableRowGroup: true,
        field: 'shipper_name',
        resizable: true,
        filter: 'agTextColumnFilter',
        valueGetter: (params) => {
          let company = params
              ? params.data
                ? params.data.from_address
                  ? params.data.from_address.company
                  : false
                : false
              : false,
            fullName = params
              ? params.data
                ? params.data.from_address
                  ? `${
                      params.data.from_address.first_name
                        ? params.data.from_address.first_name
                        : ''
                    } ${
                      params.data.from_address.last_name
                        ? params.data.from_address.last_name
                        : ''
                    }`
                  : false
                : false
              : false;
          return company ? company : fullName ? fullName : 'N/A';
        },
      },
      {
        headerName: 'Consignee Name',
        enableRowGroup: true,
        field: 'consignee_name',
        resizable: true,
        filter: 'agTextColumnFilter',
        valueGetter: (params) => {
          // let company = params
          //   ? params.data
          //     ? params.data.shipping_address
          //       ? params.data.shipping_address.company
          //       : false
          //     : false
          //   : false;
          let fullName = params
            ? params.data
              ? params.data.shipping_address
                ? `${
                    params.data.shipping_address.first_name
                      ? params.data.shipping_address.first_name
                      : ''
                  }
                      `
                : // ${
                  //   params.data.shipping_address.last_name
                  //     ? params.data.shipping_address.last_name
                  //     : ''
                  // }
                  false
              : false
            : false;
          return fullName ? fullName : 'N/A';
          // return company ? company : fullName ? fullName : 'N/A';
        },
      },
      {
        headerName: 'Consignee Address',
        enableRowGroup: true,
        field: 'consignee_address',
        resizable: true,
        filter: 'agTextColumnFilter',
        valueGetter: (params) => {
          let fullAddress = params
            ? params.data
              ? params.data.shipping_address
                ? `${
                    params.data.shipping_address.address1
                      ? params.data.shipping_address.address1
                      : ''
                  } ${
                    params.data.shipping_address.address2
                      ? params.data.shipping_address.address2
                      : ''
                  }`
                : false
              : false
            : false;
          return fullAddress ? fullAddress : 'N/A';
        },
      },
      {
        headerName: 'Consignee City',
        enableRowGroup: true,
        field: 'consignee_city',
        resizable: true,
        filter: 'agTextColumnFilter',
        valueGetter: (params) => {
          let city = params
            ? params.data
              ? params.data.shipping_address
                ? params.data.shipping_address.city
                : false
              : false
            : false;
          return city ? city : 'N/A';
        },
      },
      {
        headerName: 'Consignee State',
        enableRowGroup: true,
        field: 'consignee_state',
        resizable: true,
        filter: 'agTextColumnFilter',
        valueGetter: (params) => {
          let state = params
            ? params.data
              ? params.data.shipping_address
                ? params.data.shipping_address.state
                : params.data.shipping_address.state_code
                ? params.data.shipping_address.state_code
                : false
              : false
            : false;
          return state ? state : 'N/A';
        },
      },
      {
        headerName: 'Consignee ZIP',
        enableRowGroup: true,
        field: 'consignee_postal',
        resizable: true,
        filter: 'agTextColumnFilter',
        valueGetter: (params) => {
          let zip = params
            ? params.data
              ? params.data.shipping_address
                ? params.data.shipping_address.zip
                : false
              : false
            : false;
          return zip ? zip : 'N/A';
        },
      },
      {
        headerName: 'Consignee Country',
        enableRowGroup: true,
        field: 'consignee_country',
        resizable: true,
        filter: 'agTextColumnFilter',
        valueGetter: (params) => {
          let country = params
            ? params.data
              ? params.data.shipping_address
                ? params.data.shipping_address.country
                : params.data.shipping_address.country_code
                ? params.data.shipping_address.country_code
                : false
              : false
            : false;
          return country ? country : 'N/A';
        },
      },
    ]);

    setColumnDetails([
      {
        headerName: 'SKU',
        pinned: 'left',
        valueGetter: (params) =>
          (params && params.data && params.data.sku) || 'N/A',
      },
      {
        headerName: 'Product',
        width: 350,
        resizable: true,
        valueGetter: (params) =>
          (params && params.data && params.data.name) || 'N/A',
      },
      {
        ...price,

        headerName: 'Price',
        field: 'price',
        width: 100,
      },
      {
        ...number,

        headerName: 'Qty',
        field: 'quantity',
        width: 100,
      },
      {
        ...price,

        headerName: 'Total',
        width: 100,
        valueGetter: (params) => {
          if (
            params.data &&
            !isNaN(params.data.price) &&
            !isNaN(params.data.quantity)
          ) {
            return params.data.quantity * params.data.price;
          }

          return null;
        },
      },
    ]);

    getData();
  }, []);

  const infoToSend = async () => {
    try {
      setImportBusy(true);
      let response;

      let logiship_orders = selection.filter((item) => {
        return (
          item.integrator_fulfillment_status === 'AWAITING_PROCESSING' &&
          (item.shop_name === 'La tiendita' || item.shop_name === 'Store Wars')
        );
      });

      // console.log('logiship_orders :>> ', logiship_orders);

      let stores = [...new Set(logiship_orders.map((item) => item.shop_name))];

      // console.log('stores :>> ', stores);

      if (false)
        await swal(
          'Warning',
          `You have to select orders from only one store at the time, the stores selected are:${stores.map(
            (store) => ` ${store}`
          )}`,
          'warning'
        );
      else {
        if (logiship_orders.length === 0)
          await swal(
            'Warning',
            `The orders selected are not from "La tiendita", "Store Wars" or it were converted into parcels before`,
            'warning'
          );
        else {
          response = await feathers.service('parcels').create(logiship_orders, {
            query: { $createLabel: true, madeFromOrders: true },
          });
        }
      }

      setImportBusy(false);
      setSend(false);

      if (response) props.props.history.push('/parcels');
    } catch (error) {
      console.error('Error: ', error);
    }
  };

  const deleteSelect = () => {
    swal({
      title: 'Are you sure?',
      text:
        'Once deleted, you will have to recover the Order(s) from Ship Hero',
      icon: 'warning',
      buttons: true,
      dangerMode: true,
    }).then(async (willDelete) => {
      if (willDelete) {
        let uniqsOrders = _.unionBy(selection, '_id');

        for (const order of uniqsOrders) {
          await service.remove(order._id);
        }

        getData();
        swal('Your Order(s) has been deleted', {
          icon: 'success',
        });
      }
    });
  };

  const getQuotes = async () => {
    setQuoteDialogVisible(true);
    try {
      let ordertToCreate = selection.map(({ _id }) => _id);
      // Request quotes
      // let resultQuotation =
      await feathers.service('usps-quotation').create(ordertToCreate);
      // Close request dialog
      setQuoteDialogVisible(false);
      // Show confirmation alert
      swal('Your quotes are ready!', {
        icon: 'success',
      });
      // Reload data
      getData();
    } catch (error) {
      console.error('Error', error);
    }
  };

  const cancelQuotesRequest = async () => {
    setQuoteDialogVisible(false);
    try {
      // cancel request
    } catch (error) {
      console.error('Error', error);
    }
  };

  const quoteUpdateHandler = async (data) => {
    let quoteFind = data.item.quotes.find((x) => x.service === data.service);
    await service.patch(data.item._id, {
      bestQuote: quoteFind.pricing,
      carrier: data.service,
    });
    setOpenPanel(false);
    getData();
  };

  const setColumnGroupDefinition = async (params) => {
    let changedDefs = _.cloneDeep(columnDefs);
    if (params.columns.length) {
      changedDefs = changedDefs.map((item) =>
        item.field === params.columns[0].colDef.field
          ? {
              ...item,
              checkboxSelection: false,
              hide: true,
              pinned: false,
              headerCheckboxSelection: false,
              rowGroup: true,
            }
          : {
              ...item,
              pinned: false,
              checkboxSelection: false,
              headerCheckboxSelection: false,
              rowGroup: false,
            }
      );
    } else {
      changedDefs = changedDefs.map((item) =>
        item.field === 'order_number'
          ? {
              ...item,
              pinned: 'left',
              checkboxSelection: true,
              hide: false,
              headerCheckboxSelection: true,
              rowGroup: false,
            }
          : {
              ...item,
              checkboxSelection: false,
              headerCheckboxSelection: false,
              rowGroup: false,
            }
      );
    }
    setColumnDefs(changedDefs);
  };

  return (
    <>
      <MainFiltersWrapper>
        <OrdersFilters isBusy={isBusy} query={query} setQuery={setQuery} />
      </MainFiltersWrapper>

      <Grid columns={2} verticalAlign="middle" padded="vertically">
        <GridRow stretched>
          <GridColumn>
            {isBusy ? (
              <ActivityIndicator title="Importing..." />
            ) : (
              <RecordsSummary>
                <Instructions>
                  Select one or more orders to do actions
                </Instructions>
              </RecordsSummary>
            )}
          </GridColumn>
          <GridColumn textAlign="right">
            <div>
              <Dropdown
                text="Bulk Actions"
                as={Button}
                primary
                compact
                disabled={selection.length !== 0 ? false : true}
                style={{ marginRight: 0 }}
                direction="left"
              >
                <Dropdown.Menu>
                  <Dropdown.Item
                    text="Quote Orders"
                    onClick={() => getQuotes()}
                  ></Dropdown.Item>
                  <Dropdown.Item
                    text="Create Parcels"
                    onClick={() => setSend(true)}
                  ></Dropdown.Item>

                  <Dropdown.Divider />

                  <Dropdown.Item
                    text="Delete"
                    style={{ color: 'red' }}
                    onClick={() => deleteSelect()}
                  ></Dropdown.Item>
                </Dropdown.Menu>
              </Dropdown>
            </div>
          </GridColumn>
        </GridRow>
      </Grid>

      <TableContainer className="ag-theme-material" rowFilters={2}>
        <AgGridReact
          modules={AllModules}
          columnDefs={columnDefs}
          masterDetail={true}
          detailCellRendererParams={{
            detailGridOptions: {
              columnDefs: columnDetails,
            },
            getDetailRowData: (params) => {
              params.successCallback(params.data.line_items);
            },
            template:
              '<div style="height: 100%; background-color: #f5f5f5; padding: 10px; box-sizing: border-box;">' +
              '  <div style="height: 10%;">Item Details</div>' +
              '  <div ref="eDetailGrid" style="height: 90%;"></div>' +
              '</div>',
          }}
          rowData={data}
          rowSelection="multiple"
          defaultColDef={{ filter: true, sortable: true }}
          onColumnRowGroupChanged={(params) => setColumnGroupDefinition(params)}
          onSelectionChanged={({ api }) => setSelection(api.getSelectedRows())}
          rowGroupPanelShow={'always'}
          animateRows={true}
          pagination={true}
          paginationPageSize={100}
          suppressRowClickSelection={true}
          sideBar={{
            toolPanels: ['columns'],
            defaultToolPanel: 'filters',
          }}
          groupSelectsChildren={true}
          autoGroupColumnDef={{
            field: 'order_number',
            width: 212,
            cellRenderer: 'agGroupCellRenderer',
            cellRendererParams: { checkbox: true },
          }}
        />
      </TableContainer>

      <Modal open={send} size="small">
        <Modal.Header icon="archive" content="Create parcels from selection" />
        <Modal.Content>
          {importBusy && (
            <Lottie
              options={{
                loop: true,
                autoplay: true,
                animationData: GeneratingLabels,
                rendererSettings: {
                  preserveAspectRatio: 'xMidYMid slice',
                },
              }}
              width={400}
              height={240}
            />
          )}
          <Grid container centered padded>
            <p>
              {importBusy
                ? 'Generating labels, please wait...'
                : `You will create ${selection.length} parcel${
                    selection.length > 1 ? '(s)' : ''
                  }. Do you want to proceed?`}
            </p>
          </Grid>
        </Modal.Content>
        <Modal.Actions>
          <Button basic onClick={() => setSend(false)}>
            {importBusy ? 'Cancel' : 'No'}
          </Button>
          {!importBusy && (
            <Button primary onClick={() => infoToSend()}>
              <Icon name="checkmark" /> Yes
            </Button>
          )}
        </Modal.Actions>
      </Modal>

      <Modal open={quoteDialogVisible} size="small">
        <Modal.Header icon="archive" content="Get quotes for selected orders" />
        <Modal.Content>
          <Lottie
            options={{
              loop: true,
              autoplay: true,
              animationData: GeneratingLabels,
              rendererSettings: {
                preserveAspectRatio: 'xMidYMid slice',
              },
            }}
            width={400}
            height={240}
          />
          <Grid container centered padded>
            <p>Requesting quotes, please wait...</p>
          </Grid>
        </Modal.Content>
        <Modal.Actions>
          <Button basic onClick={() => cancelQuotesRequest()}>
            Cancel
          </Button>
        </Modal.Actions>
      </Modal>

      <SlidingPanel
        isOpen={openPanel}
        size={35}
        title="Shipping Price"
        subtitle="Select one"
        onClose={() => setOpenPanel(false)}
      >
        <PricesPanelContent
          data={quotesPanelData}
          onSave={quoteUpdateHandler}
        />
      </SlidingPanel>
    </>
  );
};

const RecordsSummary = styled.div``;

const Instructions = styled.div`
  font-family: 'Roboto', sans-serif;
  font-size: 14px;
  color: rgba(0, 0, 0, 0.6);
  letter-spacing: 0.25px;
  text-align: left;
  line-height: 20px;
`;

const ShippingCostGroup = styled.div`
  display: flex;
  justify-content: flex-end;
`;

const ShippingPrice = styled.div``;

const ShippingOptions = styled.a`
  display: inline-block;
  margin-left: 8px;
  font-size: 10px;
  color: #4f9aa8;
  transition-duration: 0.25s;
  cursor: pointer;

  &:hover {
    color: #3b737e;
  }
`;

export default OrdersImport;
