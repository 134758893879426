import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { Input, Checkbox } from 'semantic-ui-react';

const OrderList = ({ data, setData, state, setState }) => {
  const [getAutomatic, setGetAutomatic] = useState(
    !data ||
      !data.orders ||
      (Array.isArray(data.orders) &&
        data.orders.filter((str) => String(str).trim()).length === 0)
  );
  useEffect(() => {
    // console.count('useEffect data');
    const isValid =
      (data &&
        Array.isArray(data.orders) &&
        data.orders.filter((str) => String(str).trim()).length > 0) ||
      getAutomatic;

    setState({ ...state, isValid });
  }, [data, getAutomatic, setState, state]);

  const handleChange = (_, { value }) => {
    setData({
      ...data,
      orders: value.split(','),
    });
  };

  const handleListSwitch = (_, { name, checked }) => {
    setGetAutomatic(checked);
    const isValid = checked;

    if (checked)
      setData({
        ...data,
        orders: [],
      });

    setState({ ...state, isValid });
  };
  return (
    <Wrapper>
      <Checkbox
        toggle
        name="getList"
        checked={getAutomatic}
        label={<label>{'Get order list from Rush Order'}</label>}
        onChange={handleListSwitch}
      />
      {!getAutomatic ? (
        <Input
          style={{ gridArea: 'list' }}
          fluid
          label={<label>{'Type an order Id list'}</label>}
          placeholder="1, 2, 3..."
          onChange={handleChange}
          value={(data.orders || []).join(',')}
        />
      ) : (
        <></>
      )}
    </Wrapper>
  );
};

const Wrapper = styled.div`
  width: '200%';
  height: 15vh;
  vertical-align: center;
  align-items: center;
  align-content: center;

  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-template-rows: auto;
  grid-gap: 1em;
  grid-template-areas: '. list list .';
`;

export default OrderList;
