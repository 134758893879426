import moment from 'moment';
import _ from 'lodash';
import { Tags } from '../../../../constants/Tags';

const fixString = (str) => (str && str.trim() && str.toLowerCase()) || '';
const includes = (item, filter) =>
  _.includes(fixString(item), fixString(filter));

export const generalFilter = (item, filters) => {
  const createdDateTime = moment.utc(item.createdDateTime).local();

  return (
    (createdDateTime.isBetween(
      filters.createdDateTime.$gte,
      filters.createdDateTime.$lte,
      undefined,
      '[]'
    ) &&
      includes(item.orderNumber, filters.orderNumber) &&
      includes(item.trackingNumber, filters.trackingNumber) &&
      includes(item.SKU, filters.SKU) &&
      includes(item.productName, filters.productName) &&
      includes(item.customer3PL, filters.customer3PL) &&
      includes(item.email, filters.email) &&
      filterByTag(item, filters.tags) &&
      filterByCountry(item, filters.country) &&
      filterByIntegrator(item, filters.integrator) &&
      true) ||
    false
  );
};

export const filterByTag = (item, tags) => {
  const itemTags = (item && item.tags && item.tags.length && item.tags) || [];
  const filterTags = (tags && tags.length && tags) || [];

  return (
    (filterTags.includes(Tags.external) || !itemTags.includes(Tags.external)) &&
    (!filterTags.length ||
      (filterTags.includes(Tags.noTags) && !itemTags.length) ||
      itemTags.some((tag) => filterTags.includes(tag)))
  );
};

export const filterByCountry = (item, countries) => {
  const filterCountries = (countries && countries.length && countries) || [];

  return (
    !filterCountries.length ||
    (filterCountries.includes('INTERNATIONAL') &&
      !includes(item.country, 'US')) ||
    filterCountries.includes(item.country)
  );
};

export const filterByDailyLog = (item, { bin, showBin }) => {
  const dailylog =
    (item && item.dailylog && item.dailylog.length && item.dailylog[0]) || null;
  const itemBin = (dailylog && dailylog.bin) || '';

  if (showBin === 'MIX') {
    return bin && bin.length ? itemBin.length && includes(itemBin, bin) : true;
  } else if (showBin === 'NO BIN') {
    return dailylog === null;
  } else if (showBin === 'BIN') {
    return itemBin.length && includes(itemBin, bin);
  } else {
    return false;
  }
};

export const filterByProduct = (item, { coco, exportCode, is321 }) => {
  const product =
    (item && item.product && item.product.length && item.product[0]) || null;
  const filterIs321 = (is321 && is321.length && is321) || [];

  return (
    (product
      ? includes(product.coco, coco) &&
        (Array.isArray(product.exportCode)
          ? product.exportCode.reduce((acc, ec) => {
              if (!acc) {
                return includes(ec, exportCode);
              }

              return acc;
            }, false)
          : includes(product.exportCode, exportCode)) &&
        (!filterIs321.length ||
          filterIs321.includes(fixString(product.is321).toUpperCase()) ||
          (filterIs321.includes('NO PRODUCT') &&
            fixString(product.is321) === ''))
      : (!filterIs321.length || filterIs321.includes('NO PRODUCT')) && true) ||
    false
  );
};

export const filterByIntegrator = (item, integrators) => {
  const filterIntegrators =
    (integrators && integrators.length && integrators) || [];

  return (
    !filterIntegrators.length || filterIntegrators.includes(item.integrator)
  );
};

export default (data, filters) => {
  if (Array.isArray(data) && data.length) {
    return data.filter((item) => {
      try {
        return (
          (item &&
            generalFilter(item, filters) &&
            filterByDailyLog(item, filters) &&
            filterByProduct(item, filters) &&
            true) ||
          false
        );
      } catch (error) {
        console.error('error :>> ', error);
        return false;
      }
    });
  }

  return [];
};
