import React, { useState, useEffect } from 'react';
import { Form } from 'semantic-ui-react';
import swal from 'sweetalert';
import { Formik } from 'formik';
import Actions from './Actions';
import FormEmisor from './FormEmisor';
import axios from 'axios';
import feathers from '../../../services/feathers';
import SlidingPanel from '@bit/softwareland.flex.sl-sliding-panel';
import * as Yup from 'yup';

const Drawer = ({ openPanel, onClose, currentEmisor }) => {
  const service = feathers.service('pos');
  const serviceTaxCertificado = feathers.service('tax-certificados');
  const [currentSection, setCurrentSection] = useState(
    (currentEmisor && currentEmisor.idPOS && 'EDIT') || 'CREATE'
  );
  useEffect(() => {});
  const SignupSchema = Yup.object().shape({
	  
	  idSucursal: Yup.number()
    .required('Required'),
	  idAlmacen: Yup.number()
    .required('Required'),
	  idModulo: Yup.number()
    .required('Required'),
	  idTipoPOS: Yup.string()
    .required('Required'),
	  Nombre: Yup.string()
    .max(100, 'Too Long!')
    .required('Required'),
	  Descripcion: Yup.string()
    .max(250, 'Too Long!'),
	  Telefono1: Yup.string()
    .max(50, 'Too Long!'),
	  Telefono2: Yup.string()
    .max(50, 'Too Long!'),
	  Celular: Yup.string()
    .max(50, 'Too Long!'),
	  Correo: Yup.string()
    .max(150, 'Too Long!'),
	  SitioWeb: Yup.string()
    .max(150, 'Too Long!'),
	  GoogleMaps: Yup.string()
    .max(250, 'Too Long!'),
	  LogoRuta: Yup.string()
    .max(255, 'Too Long!'),
	  Status: Yup.number()
    .nullable(true),
	  TaxID: Yup.string()
    .max(50, 'Too Long!'),
	  RazonSocial: Yup.string()
    .max(250, 'Too Long!'),
	  RegimenFiscal: Yup.string()
    .max(50, 'Too Long!'),
	  idPAC: Yup.number()
    .nullable(true),
	  idCertificado: Yup.number()
    .nullable(true),
	  version_cfd: Yup.number()
    .nullable(true)
  });

  const handleSave = async (values, { setSubmitting }) => {
    console.log("saving values")
    if (values) {
      console.log('values', values);
      let success = true;
      try{
        if(values.idPOS){
          let result = await service.patch(values.idPOS, values);
          console.log('resultCreate', result);
          swal('Actualizado', '¡Se ha actualizado correctamente!', 'success');
        }else{
          let resultCreate = await service.create(values);
          console.log('resultCreate', resultCreate);
          swal('Guardado', '¡Se ha guardado correctamente!', 'success');
        }
        if(values.idCertificado){
          let certificado = await serviceTaxCertificado.get(values.idCertificado)
          if(values.Cer && certificado){
            
            console.log(typeof  values.Cer)
            certificado.CertificadoByte = values.Cer
            let result = await serviceTaxCertificado.patch(certificado.idCertificado, certificado);
            console.log('gurdar certificado', result);
          }
          if(values.Key){
            var reader = new FileReader();
            certificado.LlaveByte = await reader.readAsBinaryString(values.Key)
            let result = await serviceTaxCertificado.patch(certificado.idCertificado, certificado);
            console.log('guardar llave', result);
          }
        }
      }catch(exception){
        console.log(exception)
        swal('Error', 'Dont Save emisor', 'error');
        success = false;
      }
      if(success){
        setCurrentSection('CREATE');
        onClose(true);
      }
    } else swal('Error', 'Dont Save emisor', 'error');
  };

  const handledOnClose = async () => {
    let confirmation = await swal('Do you want close this form?', {
      buttons: ['No', 'Yes'],
    });

    if (confirmation) {
      setCurrentSection('CREATE');
      onClose(false);
    }
  };

  let drawerTitles = {
    CREATE: {
      title: 'Nuevo Emisor',
      subtitle: '',
    },
    EDIT: {
      title: 'Editar Emisor',
      subtitle: '',
    },
  };

  let title = drawerTitles[currentSection].title;
  let subtitle = drawerTitles[currentSection].subtitle;


  return (
    <SlidingPanel
      isOpen={openPanel}
      size={100}
      title={title}
      subtitle={subtitle}
      onClose={handledOnClose}
    >
      <Formik
        initialValues={currentEmisor}
        onSubmit={handleSave}
        validationSchema={SignupSchema}
      >
        {(formikProps) => (
          
          <Form
            onSubmit={formikProps.handleSubmit}
            style={{ marginRight: '15px' }}
            
          >
            <FormEmisor
              onSave={handleSave}
              // ref={refForm}
              // isValid={isValid}
              // setIsValid={setIsValid}
              formik={formikProps}
            />

            <Actions formik={formikProps} 
            handledOnClose={handledOnClose}/>
          </Form>
        )}
      </Formik>
    </SlidingPanel>
  );
};

export default Drawer;
