import React, { forwardRef } from 'react';
import { Form, Segment } from 'semantic-ui-react';
import DropdownSearchFetch from '../../../components/Formik/DropdownSearchFetch';

const FormHeader = ({ formik }) => {
  let {
    values,
    errors,
    touched,
    handleChange,
    handleBlur,
    handleSubmit,
    isSubmitting,
    setFieldValue
    /* and other goodies */
  } = formik;
  return (
    <>
      <Segment>
        
        <Form.Group widths={'equal'}>
          <Form.Input
            placeholder="Placa"
            label="Placa"
            name="Placa"
            value={values.Placa}
            onChange={handleChange}
            onBlur={handleBlur}
            error={
              errors.Placa && { content: errors.Placa }
            }
          />
          <Form.Input
            placeholder="Alias"
            label="Alias"
            name="Alias"
            value={values.Alias}
            onChange={handleChange}
            onBlur={handleBlur}
            error={
              errors.Alias && { content: errors.Alias }
            }
          />
          <DropdownSearchFetch
            fluid
            selection
            label="Sub Tipo Remolque"
            value={values.SubTipoRemolque}
            onChange={(e, { value }) => setFieldValue('SubTipoRemolque', value)}
            placeholder="Sub Tipo Remolque"
            featherService="catalogo-sub-tipo-rem"
            columName="RemSemiRem"
            columValue="CSubTipoRemID"
            mapValues={(value) => {
              return {
                key: Math.random(),
                text: `${value['c_SubTipoRem']} - ${value['RemSemiRem']}`,
                value: value['CSubTipoRemID'],
              };
            }}
            error={
              errors.SubTipoRemolque && { content: errors.SubTipoRemolque }
            }
          />
        </Form.Group>
        
      </Segment>
    </>
  );
};

export default forwardRef((props, ref) => (
  <FormHeader {...props} innerRef={ref} />
));
