import React from 'react';
import styled from 'styled-components';
import { Button, Icon, Segment } from 'semantic-ui-react';

const Actions = ({ onSaveClick, handledOnClose, isValid, formik }) => {
  let {
    values,
    errors,
    touched,
    handleChange,
    handleBlur,
    handleSubmit,
    isSubmitting,
    /* and other goodies */
  } = formik;

  return (
    <Segment>
      <ActionContainer>
        <Button primary compact disabled={isValid} type="submit">
          <Icon name="bell" /> Guardar
        </Button>

        <Button compact onMouseDown={handledOnClose} type="button">
          <Icon name="reply" /> Back
        </Button>
      </ActionContainer>
    </Segment>
  );
};

const ActionContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(6, 1fr);
  grid-template-rows: auto;
  grid-template-areas: '. . . . . .';
`;

export default Actions;
