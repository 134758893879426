import React, { useState, useEffect, useMemo } from 'react';
import styled from 'styled-components';
import { Button } from 'semantic-ui-react';
import feathers from '../../../../services/feathers';
import moment from 'moment';
import permissions from './Import/permissions.json';
import swal from 'sweetalert';

const PERMISSIONS_VALID = permissions.map(({ key }) => key);
const UpdateNavigation = ({ isOpen, setIsOpen, isBusy }) => {
  const [lastUpdate, setLastUpdate] = useState(null);

  useEffect(() => {
    let didCancel = false;

    const getData = async () => {
      const lastUpdate = await feathers.service('shipped-items').find({
        query: {
          $lastUpdate: true,
        },
      });

      if (lastUpdate) {
        const local = moment.utc(lastUpdate).local();

        !didCancel && setLastUpdate(local);
      }
    };

    getData();
    return () => (didCancel = true);
  }, []);

  const handleImportClick = async () => {
    const result = await feathers.service('configuration').find({
      query: {
        name: { $in: PERMISSIONS_VALID },
        $select: ['value'],
        value: true,
      },
    });

    if (Array.isArray(result) && result.length) setIsOpen(true);
    else {
      await swal({
        title: 'Access denied',
        text: 'The current configuration does not allow you to update from Shiphero',
        icon: 'warning',

        buttons: {
          cancel: 'Close',
        },
      });
    }
  };

  const message = useMemo(() => {
    if (lastUpdate) {
      const date = lastUpdate.format('MM/DD/YYYY');
      const time = lastUpdate.format('HH:mm');

      return (
        <UpdateMessage>
          Last Update {date} at {time}
        </UpdateMessage>
      );
    }

    return <br />;
  }, [lastUpdate]);

  return (
    <MainContainer>
      <ButtonImport
        primary
        compact
        onClick={handleImportClick}
        disabled={isBusy}
      >
        Import
      </ButtonImport>

      {message}
    </MainContainer>
  );
};

const MainContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(6, 1fr);
  grid-gap: 1em;

  grid-template-rows: auto auto;
  grid-template-areas:
    '. . . . . button'
    '. . . . . label';
`;

const ButtonImport = styled(Button)`
  grid-area: button;
`;

const UpdateMessage = styled.span`
  grid-area: label;
  color: rgba(57, 57, 57, 0.6);
`;

export default UpdateNavigation;
