import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Button } from 'semantic-ui-react';
import { FlatfileButton } from '@flatfile/react'
import feathers from '../../services/feathers';

const TopNavigation = ({ isBusy, onCreateClick, isValid, onSaveBulkData }) => {

  const [tipoPermiso, setTipoPermiso] = useState();
  const [configAutotransporte, setConfigAutotransporte] = useState();

  useEffect(() => {
    async function cargarCatalogos() {
      const tipoPermisoService = await feathers.service('catalogo-tipo-permiso');
      let catalogoTipoPermiso =await tipoPermisoService.find()
      let catalogoCodigoTipoPermiso = catalogoTipoPermiso.data.map((tipo)=>{
        return { value: tipo.CTpermisoID, label: tipo.c_TipoPermiso }
      })
      
      setTipoPermiso(catalogoCodigoTipoPermiso)

      const configAutotransporteService = await feathers.service('catalogo-config-autotransporte');
      let catalogoConfigAutotransporte =await configAutotransporteService.find()
      let catalogoCodigoConfigAutotransporte = catalogoConfigAutotransporte.data.map((tipo)=>{
        return { value: tipo.CcAutoTransID, label: tipo.c_ConfigAutotransporte }
      })
      
      setConfigAutotransporte(catalogoCodigoConfigAutotransporte)
    }
    cargarCatalogos()
  },[]);

  return (
    <>
    {tipoPermiso && configAutotransporte && <FlatfileButton
      settings={{
          type: "remolques",
          fields: [
              { label: "Nombre", key: "Nombre", validators: [ { validate: "required" }] },
              { label: "Placa", key: "Placa" },
              { label: "Año Modelo", key: "AnioModelo" },
              { label: "ConfiguracionAutotransporte", key: "ConfiguracionAutotransporte" , type: "select", options: configAutotransporte  },
              { label: "AseguradoraResponsabilidadCivil", key: "AseguradoraResponsabilidadCivil" },
              { label: "PolizaResponsabilidadCivil", key: "PolizaResponsabilidadCivil" },
              { label: "AseguradoraMedioAmbiente", key: "AseguradoraMedioAmbiente" },
              { label: "PolizaMedioAmbiente", key: "PolizaMedioAmbiente" },
              { label: "AseguradoraCarga", key: "AseguradoraCarga" },
              { label: "PolizaCarga", key: "PolizaCarga" },
              { label: "PrimaSeguro", key: "PrimaSeguro" },
              { label: "TipoPermisoSCT", key: "TipoPermisoSCT", type: "select", options: tipoPermiso  },
              { label: "NumPermisoSCT", key: "NumPermisoSCT" }
          ]
      }}
      licenseKey={"8966b5ef-41eb-4d04-806b-f05b03741e6e"}
      customer={{
          companyId: "ABC-123",
          companyName: "ABC Corp.",
          email: "john@abc123.com",
          name: "John Smith",
          userId: "12345"
      }}
      onData={async (results) => {
        // do something with the results
        onSaveBulkData(results)
      }}
      render={(importer, launch) => {
        return <Button primary compact onClick={launch} disabled={isBusy || !isValid}>Upload file</Button>;
      }}
    />
}
    &nbsp;
    <Button
      primary
      compact
      onClick={onCreateClick}
      disabled={isBusy || !isValid}
    >
      Create Transporte
    </Button>
    </>
  );
};

TopNavigation.propTypes = {
  isBusy: PropTypes.bool,
  onCreateClick: PropTypes.func,
  isValid: PropTypes.bool,
};

export default TopNavigation;
