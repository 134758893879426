import React, { useReducer, useEffect } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import moment from 'moment';
import { statusOptions, countryOptions } from '../../constants/statusOptions';
import DateRangePicker from '../DateRangePicker';
import SemanticInput from '../SemanticInput';
import SemanticDropdown from '../SemanticDropdown';
import ButtonsFilter from '../ButtonsFilter';

const queryInit = {
  order_date: {
    $gte: moment().startOf('day'),
    $lte: moment().endOf('day'),
  },
  shop_name: '',
  shipping_address: {
    first_name: '',
    state: '',
    zip: '',
    country_code: [],
  },
  integrator_fulfillment_status: [],
};

const reducerQuery = (prevState, { name, value, type, query }) => {
  if (type === 'init' && query) {
    return query;
  } else if (name) {
    return { ...prevState, [name]: value };
  }

  return prevState;
};

const OrdersFilters = ({ isBusy, query: base, setQuery }) => {
  // States
  const [query, dispatchQuery] = useReducer(reducerQuery, { ...queryInit });

  // Effects
  useEffect(() => {
    // console.count('OrdersFilters query');
    const sendQuery = () => {
      handleSearchClick();
    };

    sendQuery();
  }, []);

  // Handles
  const handleChangeInput = (_, { name, value }) => {
    const names = name.split('.');
    const newQuery = {
      name: names.shift(),
      value,
    };

    if (names.length) {
      newQuery.value = {
        ...query[newQuery.name],

        [names.shift()]: value,
      };
    }

    dispatchQuery(newQuery);
  };

  const handleSearchClick = (event, data) => {
    const toSearch = ($search) => ({ $search });

    const {
      shipping_address,
      shop_name,
      integrator_fulfillment_status,
      tags,
      order_number,
    } = query;
    const queryShippingAddress = Object.keys(shipping_address).reduce(
      (prev, item) => {
        if (shipping_address[item] && shipping_address[item].length) {
          const value =
            item === 'country'
              ? shipping_address[item][0] !== 'NUS'
                ? { $in: [...shipping_address[item]] }
                : { $nin: ['US'] }
              : toSearch(shipping_address[item]);

          return {
            ...prev,

            [`shipping_address.${item}`]: value,
          };
        }

        return prev;
      },
      {}
    );

    const queryMap = {
      line_items: { $exists: true, $ne: [] },

      order_date: query.order_date,

      ...(shop_name && { shop_name: toSearch(shop_name) }),

      ...(tags && { tags: toSearch(tags) }),

      ...(order_number && { order_number: toSearch(order_number) }),

      ...queryShippingAddress,

      ...(integrator_fulfillment_status &&
        integrator_fulfillment_status.length && {
          integrator_fulfillment_status: {
            $in: integrator_fulfillment_status,
          },
        }),
    };

    // console.debug('query', JSON.stringify(queryMap, null, 2));
    setQuery && setQuery(queryMap);
  };

  const handleClearClick = (event, data) => {
    dispatchQuery({
      type: 'init',
      query: {
        ...queryInit,

        order_date: {
          $gte: moment().startOf('day'),
          $lte: moment().endOf('day'),
        },
      },
    });
  };

  return (
    <>
      {/* <pre>query: {JSON.stringify(query, null, 2)}</pre> */}

      <FiltersGrid>
        <DateRangePicker
          name="order_date"
          label="Order Date"
          timePicker={false}
          startDate={query.order_date.$gte}
          endDate={query.order_date.$lte}
          maxDate={moment()}
          onChange={([$gte, $lte]) => {
            dispatchQuery({
              name: 'order_date',
              value: { $gte, $lte },
            });
          }}
          style={{ gridArea: 'date' }}
          disabled={isBusy}
        />

        <SemanticInput
          name="shop_name"
          label="Store"
          value={query.shop_name}
          onChange={handleChangeInput}
          fluid
          style={{ gridArea: 'store' }}
          disabled={isBusy}
        />

        <SemanticInput
          name="tags"
          label="Tags"
          value={query.tags}
          onChange={handleChangeInput}
          fluid
          style={{ gridArea: 'tags' }}
          disabled={isBusy}
        />
        <SemanticInput
          name="shipping_address.first_name"
          label="Consignee Name"
          value={query.shipping_address.first_name}
          onChange={handleChangeInput}
          fluid
          style={{ gridArea: 'consignee' }}
          disabled={isBusy}
        />
      </FiltersGrid>
      <FiltersGrid>
        <SemanticInput
          name="shipping_address.state"
          label="Consignee State"
          value={query.shipping_address.state}
          onChange={handleChangeInput}
          fluid
          style={{ gridArea: 'province' }}
          disabled={isBusy}
        />

        <SemanticInput
          name="shipping_address.zip"
          label="Consignee ZIP"
          value={query.shipping_address.zip}
          onChange={handleChangeInput}
          fluid
          style={{ gridArea: 'zip' }}
          disabled={isBusy}
        />

        <SemanticDropdown
          name="shipping_address.country"
          label="Country"
          value={query.shipping_address.country}
          onChange={handleChangeInput}
          fluid
          multiple
          selection
          options={countryOptions}
          style={{ gridArea: 'country' }}
          disabled={isBusy}
        />

        <SemanticDropdown
          name="integrator_fulfillment_status"
          label="Status"
          value={query.integrator_fulfillment_status}
          onChange={handleChangeInput}
          fluid
          multiple
          selection
          options={statusOptions}
          style={{ gridArea: 'status' }}
          disabled={isBusy}
        />
      </FiltersGrid>
      <FiltersGrid>
        <SemanticInput
          name="order_number"
          label="Order Number"
          value={query.order_number}
          onChange={handleChangeInput}
          fluid
          style={{ gridArea: 'tags' }}
          disabled={isBusy}
        />
      </FiltersGrid>
      <ButtonsFilter
        isBusy={isBusy}
        onSearchClick={handleSearchClick}
        onClearClick={handleClearClick}
      />
    </>
  );
};

const FiltersGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-template-rows: auto auto;
  grid-gap: 1em;
  grid-template-areas:
    'date store consignee province'
    'zip country status tags';
`;

OrdersFilters.propTypes = {
  isBusy: PropTypes.bool,
  query: PropTypes.object,
  setQuery: PropTypes.func,
};

export default OrdersFilters;
