import React from 'react';
import styled from 'styled-components';
import { Button, Icon } from 'semantic-ui-react';

const Actions = ({ onSaveClick, isValid, handledOnClose }) => {
  return (
    <ActionContainer>
      <Button primary compact onClick={onSaveClick} disabled={isValid}>
        <Icon name="save" /> Save
      </Button>

      <Button
        compact
        onMouseDown={handledOnClose}
      >
        <Icon name="reply" /> Back
      </Button>
    </ActionContainer>
  );
};

const ActionContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(6, 1fr);
  grid-template-rows: auto;
  grid-template-areas: '. . . . . .';
`;

export default Actions;
