import React from 'react';
import TabMenuItemPackage from './TabMenuItemPackage';
import TabPanePackage from './TabPanePackage';

const createTab = (key, data, isBusy, onDeleteClick, onUnpackingClick) => {
  return {
    data: [...data],
    menuItem: TabMenuItemPackage(key, onDeleteClick, data.length),

    render: (props) => (
      <TabPanePackage
        {...props}
        isBusy={isBusy}
        onUnpackingClick={onUnpackingClick}
      />
    ),
  };
};

export default createTab;
