import React from 'react';
import PropTypes from 'prop-types';
import { Button, Dropdown, Grid, GridColumn, GridRow } from 'semantic-ui-react';
import styled from 'styled-components';
import ActivityIndicator from '../../components/ActivityIndicator';

const TableNavigation = ({
  isBusy,
  haveSelected,
  onDeleteClick,
  messageAction,
}) => {
  return (
    <Grid columns={2} verticalAlign="middle" padded="vertically">
      <GridRow stretched columns={2}>
        <GridColumn>
          {isBusy ? (
            <ActivityIndicator title={messageAction || 'Loading...'} />
          ) : (
            <Instructions>Select one or more Rules to do actions</Instructions>
          )}
        </GridColumn>
        <GridColumn textAlign="right">
          <div>
            <Dropdown
              text="Bulk Actions"
              as={Button}
              primary
              compact
              style={{ marginRight: 0 }}
              disabled={isBusy || !haveSelected}
            >
              <Dropdown.Menu>
                <Dropdown.Item
                  icon={{ name: 'delete', color: 'red' }}
                  text="Delete"
                  onClick={onDeleteClick}
                  disabled={false}
                />
              </Dropdown.Menu>
            </Dropdown>
          </div>
        </GridColumn>
      </GridRow>

      <GridRow stretched columns={1}>
        <GridColumn>
          <Instructions>
            The sequence number and order of the list represents the priority of
            the rules. A rule with a higher priority (a lower sequence number)
            will be queried before a rule with a lower priority (a higher
            sequence number). As a result, a parcel that matches more than one
            rule will be processed through the one with the highest priority. To
            change the priority of a rule, drag the "=" symbol (located in the
            "name" column of each row) up or down depending on whether you want
            to lower or raise the priority.
          </Instructions>
        </GridColumn>
      </GridRow>
    </Grid>
  );
};

TableNavigation.propTypes = {
  isBusy: PropTypes.bool,
  haveSelected: PropTypes.bool,
  onDeleteClick: PropTypes.func,
  messageAction: PropTypes.string,
};

const Instructions = styled.div`
  font-family: 'Roboto', sans-serif;
  font-size: 14px;
  color: rgba(0, 0, 0, 0.6);
  letter-spacing: 0.25px;
  text-align: left;
  line-height: 20px;
`;

export default TableNavigation;
