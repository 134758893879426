import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { AgGridReact } from '@ag-grid-community/react';
import { AllModules } from '@ag-grid-enterprise/all-modules';
import TableContainer from '../../components/TableContainer';
import { Button } from 'semantic-ui-react';
import services from './Drawer/services';
import carriers from './Drawer/carriers';
import swal from 'sweetalert';
import feathers from '../../services/feathers';

const Table = ({
  data: sourceData,
  setData: setSourceData,
  onEditClick,
  selection,
  setSelection,
  ...other
}) => {
  const service = feathers.service('automation-rule');

  // States
  const [data, setData] = useState([...sourceData]);
  const [gridApi, setGridApi] = useState(null);
  const [gridColumnApi, setGridColumnApi] = useState(null);
  const [columnDefs] = useState([
    ...(selection && setSelection
      ? [
          {
            headerName: '',
            width: 70,
            checkboxSelection: true,
            headerCheckboxSelection: true,
            suppressMenu: true,
            pinned: 'left',
            enableRowGroup: false,
            resizable: false,
            sortable: false,
          },
        ]
      : []),
    {
      field: 'priority',
      headerName: 'Sequence',
    },
    {
      field: 'name',
      headerName: 'Name',
      rowDrag: true,
    },
    { field: 'description', headerName: 'Description' },
    {
      headerName: 'Triggered Carrier',
      valueGetter: '(data && data.action && data.action.carrier) || "-"',
      valueFormatter: ({ value }) => {
        const service = carriers.find(({ name }) => name === value);
        return (service && service.label) || '-';
      },
    },
    {
      headerName: 'Triggered Service',
      valueGetter: '(data && data.action && data.action.service) || "-"',
      valueFormatter: ({ value }) => {
        const service = services.find(({ name }) => name === value);
        return (service && service.label) || '-';
      },
    },

    ...(onEditClick
      ? [
          {
            field: 'button',
            headerName: '',
            pinned: 'right',
            cellStyle: { textAlign: 'center' },
            width: 150,
            cellRenderer: 'buttonCellRenderer',
          },
        ]
      : []),
  ]);
  const [defaultColDef] = useState({
    flex: 1,
    sortable: false,
    filter: false,
    menuTabs: [],
  });
  const [context, setContext] = useState({
    title: 'Edit',
    onClick: null,
  });

  // Effects
  useEffect(() => {
    const data = [...sourceData];
    setData(data);
    gridApi && gridApi.setRowData(data);
  }, [sourceData, gridApi]);

  useEffect(() => {
    // console.debug('useEffect Table', selection, (gridApi && gridApi.getSelectedRows()));
    if (
      selection &&
      !selection.length &&
      gridApi &&
      gridApi.getSelectedRows().length
    ) {
      gridApi.deselectAll();
    }
  }, [selection, gridApi]);

  useEffect(() => {
    if (data && data.length) {
      setContext((context) => ({
        title: context.title,
        onClick: null,
        force: true,
      }));
    }
  }, [data, setContext, sourceData, onEditClick]);

  useEffect(() => {
    // console.debug('useEffect context, gridApi', context);
    if (gridApi && context) {
      if (context.force) {
        setContext((context) => ({
          title: context.title,
          onClick: handleEditClick,
        }));
      } else {
        gridApi.refreshCells({
          columns: ['button'],
          force: true,
          suppressFlash: true,
        });
      }
    }
  }, [context, gridApi]);

  // Handles
  const handleGridReady = ({ api, columnApi }) => {
    setGridApi(api);
    setGridColumnApi(columnApi);
  };

  const handleEditClick = (rule) => {
    onEditClick && onEditClick({ ...rule });
  };

  const handleSelectionChanged = (event) => {
    if (selection && setSelection) {
      let rows = [];

      try {
        if (event && event.api) {
          rows = event.api.getSelectedRows().map((row) => row._id);
        }
      } catch (err) {
        rows = [];

        swal({
          title: 'Error',
          text: 'Failed to select Rules',
          icon: 'error',
          buttons: {
            cancel: 'Close',
          },
        });
      } finally {
        // console.debug('onSelectionChanged event rows', '[' + rows.map(item => `ObjectId('${item}')`).join(', ') + ']');
        setSelection(rows);
      }
    }
  };

  const handleRowDragMove = ({ node: movingNode, overNode }) => {
    if (movingNode && overNode && movingNode !== overNode) {
      const movingData = movingNode.data;
      const overData = overNode.data;
      const fromIndex = data.indexOf(movingData);
      const toIndex = data.indexOf(overData);
      const newStore = [...data];

      // Move in Array
      const elm = newStore.splice(fromIndex, 1)[0];
      newStore.splice(toIndex, 0, elm);

      // console.clear();
      const newData = newStore.map((rule, index) => {
        const priority = index + 1;

        return {
          ...rule,
          priority,
        };
      });
      setData(newData);
      gridApi.setRowData(newData);
      gridApi.clearFocusedCell();
    }
  };

  const handleRowDragEnd = async (e) => {
    // console.clear();
    const queuedTasksAsync = await Promise.all(
      data.map(async (rule) => {
        const sourceRule = sourceData.find(
          ({ _id, priority }) => _id === rule._id && priority === rule.priority
        );

        if (!sourceRule) {
          return await service.patch(rule._id, {
            _id: rule._id,
            priority: rule.priority,
          });
        } else {
          return rule;
        }
      })
    );

    setSourceData(queuedTasksAsync);
  };

  const handleGetRowNodeId = ({ _id }) => {
    return _id;
  };

  return (
    <>
      {/* <pre>
        {JSON.stringify(
          data.map((rule) => ({
            ...rule,
            condition: null,
            action: rule.action.service,
          })),
          null,
          2
        )}
      </pre> */}
      <TableContainer className="ag-theme-material" rowFilters={1}>
        <AgGridReact
          modules={AllModules}
          columnDefs={columnDefs}
          defaultColDef={defaultColDef}
          rowSelection={'multiple'}
          suppressRowClickSelection={true}
          onSelectionChanged={handleSelectionChanged}
          deltaRowDataMode={true}
          animateRows={true}
          getRowNodeId={handleGetRowNodeId}
          onGridReady={handleGridReady}
          onRowDragMove={handleRowDragMove}
          onRowDragEnd={handleRowDragEnd}
          context={context}
          {...other}
          frameworkComponents={{
            buttonCellRenderer: ButtonCellRenderer,
          }}
        />
      </TableContainer>
    </>
  );
};

const ButtonCellRenderer = ({ data, context: { title, onClick } }) => {
  const handleClick = (event, _) => {
    onClick && onClick(data);
  };

  return (
    <>
      <Button compact primary onClick={handleClick}>
        {title}
      </Button>
    </>
  );
};

Table.propTypes = {
  data: PropTypes.array,
  onEditClick: PropTypes.func,
  selection: PropTypes.array,
  setSelection: PropTypes.func,
};

export default Table;
