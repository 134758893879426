import React, { useState, useEffect } from 'react';
import swal from 'sweetalert';
import { Container } from 'semantic-ui-react';
import DefaultLayout from '../../layouts/DefaultLayout';
import HeaderBG from '../../components/HeaderBG';
import SectionTitle from '../../components/SectionTitle';
import TopNavigation from './TopNavigation';
import Filters from './Filters';
import TableNavigation from './TableNavigation';
import Table from './Table';
import sample from './sample';

const Packing = ({ match: { path } }) => {
  // States
  const [isBusy, setIsBusy] = useState(false);
  const [query, setQuery] = useState(null);
  const [data, setData] = useState([]);
  const [selection, setSelection] = useState([]);

  // Effects
  useEffect(() => {
    const filter = () => {
      if (query) {
        getData();
      }
    };

    filter();
  }, [query]);

  // Functions
  const getData = async () => {
    if (query) {
      let data = [];
      // result = [];

      try {
        setIsBusy(true);
        setSelection([]);

        // console.debug('query', JSON.stringify(query, null, 2));
        // do {
        //   result = await service.find({
        //     query: {
        //       ...query,

        //       $skip: data.length,
        //     },
        //   });

        //   data = data.concat(result.data);

        //   console.debug('result', result);
        // } while (data.length !== result.total);

        // await new Promise(resolve => setTimeout(resolve, 2000));
        data = [...sample];
      } catch (err) {
        console.error('Error', err);
        data = [];

        swal({
          title: 'Error',
          text: 'Failed get Orders',
          icon: 'error',
          buttons: {
            cancel: 'Close',
          },
        });
      } finally {
        setData(data);
        setIsBusy(false);

        // refFilters && refFilters.current && refFilters.current.focus();
      }
    }
  };

  return (
    <>
      <DefaultLayout
        currentPath={path}
        globalActions={<TopNavigation isBusy={isBusy} />}
      >
        <HeaderBG />

        <Container>
          <SectionTitle>Packing</SectionTitle>

          <Filters isBusy={isBusy} query={query} setQuery={setQuery} />

          <TableNavigation isBusy={isBusy} />

          <Table
            data={data}
            selection={selection}
            setSelection={setSelection}
          />
        </Container>
      </DefaultLayout>
    </>
  );
};

export default Packing;