import React, { useState } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import SlidingPanel from '@bit/softwareland.flex.sl-sliding-panel';
import swal from 'sweetalert';
import Table from '../Table';
import ProductModal from './ProductModal';
import Actions from './Actions';

const Drawer = ({ onClose, data: currentData }) => {
  const [data, setData] = useState(currentData || []);
  const [product, setProduct] = useState(null);
  const title = 'Validate Information';

  const handleClose = async () => {
    let confirmation = await swal('Do you want close this form?', {
      buttons: ['No', 'Yes'],
    });

    if (confirmation) {
      setProduct(null);
      onClose(data.length !== currentData.length);
    }
  };

  const handleEditClick = async (product) => {
    setProduct(product);
  };

  const handleCloseModal = async (isSuccess) => {
    if (isSuccess) {
      setData(data.filter(({ _id }) => _id !== product._id));
    }

    setProduct(null);
  };

  return (
    <>
      <SlidingPanel isOpen={true} size={95} title={title} onClose={handleClose}>
        <WrapContent>
          <Table data={data} onEditClick={handleEditClick} />

          <Actions
            // isBusy={isBusy}
            onBackClick={handleClose}
          />
        </WrapContent>
      </SlidingPanel>

      {product ? (
        <ProductModal product={product} onClose={handleCloseModal} />
      ) : (
        <></>
      )}
    </>
  );
};

const WrapContent = styled.div`
  padding-right: 1em;
`;

Drawer.propTypes = {
  onClose: PropTypes.func,
  data: PropTypes.array,
};

export default Drawer;
