import React, { forwardRef } from 'react';
import { Form, Segment, Header } from 'semantic-ui-react';
import DropdownSimpleFetch from '../../../components/Formik/DropdownSimpleFetch';
import DropdownSearchFetch from '../../../components/Formik/DropdownSearchFetch';
import DropdownReceptores from '../../../components/Formik/DropdownReceptores';

const FormReceptor = ({ formik }) => {
  let {
    values,
    errors,
    touched,
    handleChange,
    handleBlur,
    setFieldValue,
    /* and other goodies */
  } = formik;
  return (
    <>
      <Segment>
        <Header as="h3">Receptor</Header>
        <Form>
          <Form.Group widths={'equal'}>
            {values.idTipoComprobante === 'I' ? (
              <DropdownReceptores
                fluid
                selection
                label="Nombre"
                value={values.ReceptorNombre}
                onChange={(e, { value }) => {
                  console.log('value: ', value);
                  setFieldValue('ReceptorNombre', value);
                  setFieldValue('ReceptorRFC', value.RFC);
                  setFieldValue('ReceptorCodPostal', value.CodPos);
                }}
                placeholder="Nombre"
                featherService="receptores"
                columName="Nombre"
                columValue="Nombre"
                mapValues={(value) => {
                  return {
                    key: Math.random(),
                    text: `${value['Nombre']}`,
                    value: value,
                  };
                }}
                error={
                  errors.ReceptorNombre &&
                  touched.ReceptorNombre && {
                    content: errors.ReceptorNombre,
                  }
                }
              />
            ) : (
              <Form.Input
                placeholder="Nombre"
                label="Nombre"
                name="ReceptorNombre"
                value={values.ReceptorNombre}
                onChange={handleChange}
                onBlur={handleBlur}
                disabled={values.idTipoComprobante === 'T' ? true : false}
                error={
                  errors.ReceptorNombre &&
                  touched.ReceptorNombre && {
                    content: errors.ReceptorNombre,
                  }
                }
              />
            )}

            <Form.Input
              placeholder="RFC"
              label="RFC"
              name="ReceptorRFC"
              value={values.ReceptorRFC}
              onChange={handleChange}
              onBlur={handleBlur}
              disabled={values.idTipoComprobante === 'T' ? true : false}
              error={
                errors.ReceptorRFC &&
                touched.ReceptorRFC && { content: errors.ReceptorRFC }
              }
            />
          </Form.Group>
          <DropdownSearchFetch
            fluid
            selection
            label="Código Postal"
            value={
              values.LugarExpedicion && values.idTipoComprobante === 'T'
                ? (values.ReceptorCodPostal = values.LugarExpedicion)
                : values.ReceptorCodPostal
            }
            onChange={(e, { value }) =>
              setFieldValue('ReceptorCodPostal', value)
            }
            placeholder="Código Postal"
            featherService="catalogo-codigo-postal"
            columName="c_CodigoPostal"
            columValue="c_CodigoPostal"
            mapValues={(value) => {
              return {
                key: Math.random(),
                text: `${value['c_CodigoPostal']}`,
                value: value['c_CodigoPostal'],
              };
            }}
            disabled={values.idTipoComprobante === 'T' ? true : false}
            error={
              errors.ReceptorCodPostal &&
              touched.ReceptorCodPostal && {
                content: errors.ReceptorCodPostal,
              }
            }
          />
          {/* <Form.Group widths={'equal'}>
            <Form.Input
              placeholder="Calle"
              label="Calle"
              name="ReceptorCalle"
              value={values.ReceptorCalle}
              onChange={handleChange}
              onBlur={handleBlur}
              error={
                errors.ReceptorCalle &&
                touched.ReceptorCalle && { content: errors.ReceptorCalle }
              }
            />
          </Form.Group>
          <Form.Group widths={'equal'}>
            <Form.Input
              placeholder="Número Exterior"
              label="Número Exterior"
              name="ReceptorNumExterior"
              value={values.ReceptorNumExterior}
              onChange={handleChange}
              onBlur={handleBlur}
              error={
                errors.ReceptorNumExterior &&
                touched.ReceptorNumExterior && {
                  content: errors.ReceptorNumExterior,
                }
              }
            />
            <Form.Input
              placeholder="Numero Interior"
              label="Numero Interior"
              name="ReceptorNumInterior"
              value={values.ReceptorNumInterior}
              onChange={handleChange}
              onBlur={handleBlur}
              // error={
              //   validations.name && { content: 'Required idTipoComprobante' }
              // }
            />
            <Form.Input
              placeholder="Referencia"
              label="Referencia"
              name="ReceptorReferencia"
              value={values.ReceptorReferencia}
              onChange={handleChange}
              onBlur={handleBlur}
              // error={validations.lastName && { content: 'Required Last Name' }}
            />
          </Form.Group>
          <Form.Group widths={'equal'}>
            <DropdownSearchFetch
              fluid
              selection
              label="País"
              value={values.ReceptorPais}
              onChange={(e, { value }) => setFieldValue('ReceptorPais', value)}
              placeholder="País"
              featherService="catalogo-pais"
              columName="Descripcion"
              columValue="c_Pais"
              mapValues={(value) => {
                return {
                  key: Math.random(),
                  text: `${value['c_Pais']} - ${value['Descripcion']}`,
                  value: value['c_Pais'],
                };
              }}
            />
            <DropdownSearchFetch
              fluid
              selection
              label="Estado"
              value={values.ReceptorEstado}
              onChange={(e, { value }) =>
                setFieldValue('ReceptorEstado', value)
              }
              placeholder="Estado"
              featherService="catalogo-estado"
              columName="Nombre"
              columValue="c_Estado"
              mapValues={(value) => {
                return {
                  key: Math.random(),
                  text: `${value['c_Estado']} - ${value['Nombre']}`,
                  value: value['c_Estado'],
                };
              }}
            />
            <DropdownSearchFetch
              fluid
              selection
              label="Municipio"
              value={values.ReceptorMunicipio}
              onChange={(e, { value }) =>
                setFieldValue('ReceptorMunicipio', value)
              }
              placeholder="Municipio"
              featherService="catalogo-municipio"
              columName="Descripcion"
              columValue="CmunicipioID"
              mapValues={(value) => {
                return {
                  key: Math.random(),
                  text: `${value['Descripcion']}`,
                  value: value['CmunicipioID'],
                };
              }}
            />
          </Form.Group>
          <Form.Group widths={'equal'}>
            <DropdownSearchFetch
              fluid
              selection
              label="Localidad"
              value={values.ReceptorLocalidad}
              onChange={(e, { value }) =>
                setFieldValue('ReceptorLocalidad', value)
              }
              placeholder="Localidad"
              featherService="catalogo-localidad"
              columName="Descripcion"
              columValue="ClocalidadID"
              mapValues={(value) => {
                return {
                  key: Math.random(),
                  text: `${value['Descripcion']}`,
                  value: value['ClocalidadID'],
                };
              }}
            />
            <DropdownSearchFetch
              fluid
              selection
              label="Código Postal"
              value={values.ReceptorCodPostal}
              onChange={(e, { value }) =>
                setFieldValue('ReceptorCodPostal', value)
              }
              placeholder="Código Postal"
              featherService="catalogo-codigo-postal"
              columName="c_CodigoPostal"
              columValue="c_CodigoPostal"
              mapValues={(value) => {
                return {
                  key: Math.random(),
                  text: `${value['c_CodigoPostal']}`,
                  value: value['c_CodigoPostal'],
                };
              }}
            />
            <DropdownSearchFetch
              fluid
              selection
              label="Colonia"
              value={values.ReceptorColonia}
              onChange={(e, { value }) =>
                setFieldValue('ReceptorColonia', value)
              }
              placeholder="Colonia"
              featherService="catalogo-colonia"
              columName="Nombre"
              columValue="CcoloniaID"
              mapValues={(value) => {
                return {
                  key: Math.random(),
                  text: `${value['Nombre']}`,
                  value: value['CcoloniaID'],
                };
              }}
            />
          </Form.Group> */}
        </Form>
      </Segment>
    </>
  );
};

export default forwardRef((props, ref) => (
  <FormReceptor {...props} innerRef={ref} />
));
