import React, { useState, useEffect } from 'react';
import { Form, Dimmer, Loader } from 'semantic-ui-react';
import SlidingPanel from '../../../components/SLSlidingPanel';
import swal from 'sweetalert';
import { Formik } from 'formik';
import Actions from './Actions';
import FormDocument from './FormDocument';
import axios from 'axios';
import feathers from '../../../services/feathers';
import moment from 'moment';
import { indexOf, values } from 'lodash';
import validationScheme from './validationSchema';
import initialValues from './initialValuesBlanck';
import ModalTrip from './ModalTrip';
import { useAuth } from '../../../services/feathers';

const Drawer = ({ openPanel, onClose, currentDocumentId }) => {
  const auth = useAuth();
  const [isValid, setIsValid] = useState(false);
  const [isCartaPorte, setIsCartaPorte] = useState(true);
  const [isMaterialPeligroso, setIsMaterialPeligroso] = useState(false);
  const [loading, setLoading] = useState(true);
  const [lastDocument, setLastDocument] = useState(null);
  const [userPOS, setUserPOS] = useState(null);
  const refForm = React.useRef(null);
  const serviceTrip = feathers.service('optima-trips');
  const serviceDocumentos = feathers.service('documentos');
  const servicePOS = feathers.service('pos');
  const serviceCodigoPostal = feathers.service('catalogo-codigo-postal');
  const serviceDocumentosConceptos = feathers.service('documentos-conceptos');
  const serviceDocumentosConceptosImpuestos = feathers.service(
    'documentos-conceptos-impuestos'
  );
  const serviceDocumentosCartaPorte = feathers.service(
    'documentos-carta-porte'
  );
  const serviceDocumentosCartaPorteDomicilios = feathers.service(
    'documentos-carta-porte-domicilios'
  );
  const serviceDocumentosCartaPorteAutos = feathers.service(
    'documentos-carta-porte-autos'
  );
  const serviceDocumentosCartaPorteFigurasTransp = feathers.service(
    'documentos-carta-porte-figuras-transp'
  );
  const serviceDocumentosCartaPorteCantTransp = feathers.service(
    'documentos-carta-porte-cant-transp'
  );
  const serviceDocumentosCartaPorteAutosRemolques = feathers.service(
    'documentos-carta-porte-autos-remolques'
  );
  const servicePOSFolios = feathers.service('pos-folios');
  const serviceReceptores = feathers.service('receptores');
  const [currentSection, setCurrentSection] = useState(
    (currentDocumentId && 'EDIT') || 'CREATE'
  );

  let drawerTitles = {
    CREATE: {
      title: 'Documentos',
      subtitle: '',
    },
    EDIT: {
      title: 'Edit Document',
      subtitle: '',
    },
  };

  let title = drawerTitles[currentSection].title;
  let subtitle = drawerTitles[currentSection].subtitle;

  const getLastDocument = async () => {
    let lastDocument = await serviceDocumentos.find({
      query: {
        Organizacion: auth.user.client._id,
        $sort: { id: -1 },
        $limit: 1,
      },
    });

    lastDocument =
      (lastDocument &&
        lastDocument.data &&
        lastDocument.data.length &&
        lastDocument.data[0]) ||
      {};
    setLastDocument(lastDocument);
    setLoading(false);
    // console.log('lastDocument', lastDocument);
  };

  const isObjEmpty = (obj) => {
    for (var prop in obj) {
      if (obj.hasOwnProperty(prop)) return false;
    }
    return true;
  };

  const getUserPOS = async () => {
    let userPOS = await servicePOS.get(auth.user.client._id);
    userPOS =
      userPOS && userPOS.length && userPOS[0] && !isObjEmpty(userPOS[0])
        ? userPOS[0]
        : null;
    // console.log('userPOS', userPOS);
    setUserPOS(userPOS);
    setLoading(false);
  };

  useEffect(() => {
    getLastDocument();
    getUserPOS();
  }, []);

  const handledOnClose = async () => {
    let confirmation = await swal('Do you want close this form?', {
      buttons: ['No', 'Yes'],
    });

    if (confirmation) {
      setCurrentSection('CREATE');
      onClose(false);
    }
  };

  const getCodigoPostal = async (cp) => {
    let findResult = await serviceCodigoPostal.find({
      query: { c_CodigoPostal: cp },
    });

    findResult =
      (findResult.data && findResult.data.length > 0 && findResult.data[0]) ||
      null;

    return findResult;
  };

  const setDocumento = async (values) => {
    values.idUser = auth.user._id;
    values.Organizacion = auth.user.client._id;
    if (values.ReceptorNombre && values.ReceptorNombre.Nombre) {
      values.ReceptorNombre = values.ReceptorNombre.Nombre;
    }
    let resultCreateDocumento = await serviceDocumentos.create(values);
    console.log('resultCreateDocumento', resultCreateDocumento);
    return resultCreateDocumento;
  };

  let origenId = '';
  let destinoId = '';
  let distancia = 0;
  const setDocumentoUbicaciones = async (values, id) => {
    for (let [index, ubicacion] of values.ubicaciones.entries()) {
      ubicacion.id = id;
      ubicacion.idRow = index + 1;
      ubicacion.FechaHoraSalidaLlegada = moment(
        ubicacion.FechaHoraSalidaLlegada
      ).format('YYYY-MM-DD HH:mm:ss.SSS');

      //TODO esto no será fijo, hay que seleccionarlo en el front end.
      if (ubicacion.TipoUbicacion === 'Origen') {
        origenId = ubicacion.IDUbicacion;
      }
      if (ubicacion.TipoUbicacion === 'Destino') {
        destinoId = ubicacion.IDUbicacion;
      }

      distancia = distancia + ubicacion.DistanciaRecorrida;

      let resultCreateDocumentosCartaPorteDomicilios =
        await serviceDocumentosCartaPorteDomicilios.create(ubicacion);

      console.log(
        'resultCreateDocumentosCartaPorteDomicilios',
        resultCreateDocumentosCartaPorteDomicilios
      );
    }
  };

  const setDocumentoCantTransp = async (
    idConcepto,
    idRowConcepto,
    cantidad
  ) => {
    let resultDocumentosCartaPorteCantTransp =
      await serviceDocumentosCartaPorteCantTransp.create({
        id: idConcepto,
        idRowConcepto: idRowConcepto,
        idRow: 1,
        IDOrigen: origenId,
        IDDestino: destinoId,
        Cantidad: cantidad,
        CveTransporte: '01', //TODO esto a que se refier
      });

    console.log(
      'resultDocumentosCartaPorteCantTransp',
      resultDocumentosCartaPorteCantTransp
    );
  };

  const setDocumentoConceptos = async (values, id) => {
    for (let [index, concepto] of values.conceptos.entries()) {
      concepto.id = id;
      concepto.idRow = index + 1;
      //console.log('concepto: ', concepto);
      if (concepto.TipoConcepto === 0) {
        concepto.ValorMercancia = 0.0;
        concepto.PesoEnKg = 0.0;
        concepto.MonedaMercancia = '';
        concepto.FraccionArancelariaComercio = '';
      }
      let resultCreateDocumentoConcepto =
        await serviceDocumentosConceptos.create(concepto);
      console.log(
        'resultCreateDocumentoConcepto',
        resultCreateDocumentoConcepto
      );

      if (isCartaPorte) {
        //Agregar las cantidades de los figuraTransportes
        //Cantidad a transportar en que envío. //TODO se necesita hacer para cada envío, ahorita se hara en uno solo.
        if (concepto.TipoConcepto !== 0) {
          await setDocumentoCantTransp(
            resultCreateDocumentoConcepto.id,
            concepto.idRow,
            concepto.Cantidad
          );
        }
      }
    }
  };

  const setDocumentoConceptosImpuestos = async (values, id) => {
    for (let [index, concepto] of values.conceptos.entries()) {
      concepto.id = id;
      concepto.idRow = index + 1;
      if (concepto.TipoConcepto === 0 && concepto.PerfilImpuestos !== 3) {
        concepto.TasaOCuota = 'Tasa';
        let resultCreateDocumentoConceptoImpuesto =
          await serviceDocumentosConceptosImpuestos.create(concepto);
        console.log(
          'resultCreateDocumentoConceptoImpuesto',
          resultCreateDocumentoConceptoImpuesto
        );
      }

      // if (isCartaPorte) {
      //   //Agregar las cantidades de los figuraTransportes
      //   //Cantidad a transportar en que envío. //TODO se necesita hacer para cada envío, ahorita se hara en uno solo.
      //   await setDocumentoCantTransp(
      //     resultCreateDocumentoConcepto.id,
      //     concepto.idRow,
      //     concepto.Cantidad
      //   );
      // }
    }
  };

  const setDocumentoAutoTransportes = async (values, id) => {
    let resultCreateDocumentosCartaPorteAutos =
      await serviceDocumentosCartaPorteAutos.create({
        id: id,
        PermSCT: values.PermSCT,
        AutoConfigVehicular: values.AutoConfigVehicular,
        NumPermitoSCT: values.NumPermitoSCT,
        AutoPlacaVM: values.AutoPlacaVM,
        AutoAnioModeloVM: values.AutoAnioModeloVM,
        AseguraRespCivil: values.AseguraRespCivil,
        PolizaRespCivil: values.PolizaRespCivil,
        AseguraMedAmbiente: values.AseguraMedAmbiente,
        PolizaMedAmbiente: values.PolizaMedAmbiente,
        AseguraCarga: values.AseguraCarga,
        PolizaCarga: values.PolizaCarga,
        PrimaSeguro: values.PrimaSeguro,
      });
    console.log(
      'resultCreateDocumentosCartaPorteAutos',
      resultCreateDocumentosCartaPorteAutos
    );
  };

  const setDocumentoAutosRemolques = async (values, id) => {
    if (values.PlacaR1) {
      let resultCreateDocumentosCartaPorteAutosRemolques =
        await serviceDocumentosCartaPorteAutosRemolques.create({
          id: id,
          idRow: 1,
          Placa: values.PlacaR1,
          SubtipoRem: values.Remolque1,
        });
      console.log(
        'resultCreateDocumentosCartaPorteAutosRemolques1',
        resultCreateDocumentosCartaPorteAutosRemolques
      );
    }
    if (values.PlacaR2) {
      let resultCreateDocumentosCartaPorteAutosRemolques =
        await serviceDocumentosCartaPorteAutosRemolques.create({
          id: id,
          idRow: 2,
          Placa: values.PlacaR2,
          SubtipoRem: values.Remolque2,
        });
      console.log(
        'resultCreateDocumentosCartaPorteAutosRemolques2',
        resultCreateDocumentosCartaPorteAutosRemolques
      );
    }
  };

  const setDocumentoFiguraTransporte = async (values, id) => {
    for (let [index, figura] of values.figuraTransportes.entries()) {
      figura.id = id;
      figura.idRow = index + 1;
      let resultCreateDocumentosCartaPorteFigurasTransp =
        await serviceDocumentosCartaPorteFigurasTransp.create(figura);
      console.log(
        'resultCreateDocumentosCartaPorteFigurasTransp',
        resultCreateDocumentosCartaPorteFigurasTransp
      );
    }
  };

  const setDocumentoCartaPorte = async (id) => {
    let resultDocumentosCartaPorte = await serviceDocumentosCartaPorte.create({
      id: id,
      Version: '2.0',
      TransporInternac: 1, //Para decir que va a méxico
      TotalDistRec: distancia,
      EntradaSalidaMerc: 1,
      ViaEntradaSalidas: '01', //Autotransporte
      PaisOrigenDestino: 'USA',
    });

    console.log('resultDocumentosCartaPorte', resultDocumentosCartaPorte);
  };

  const saveReceptorOnCatalog = async (values) => {
    let receptorResult = await serviceReceptores.find({
      query: {
        RFC: values.ReceptorRFC,
        Organizacion: values.Organizacion,
      },
    });
    let receptor = {};
    if (receptorResult.total > 0) {
      receptor = receptorResult.data[0];
    }

    receptor.CodPos = values.ReceptorCodPos;
    receptor.Nombre = values.ReceptorNombre;
    receptor.Organizacion = values.Organizacion;
    receptor.Pais = values.ReceptorPais;
    receptor.RFC = values.ReceptorRFC;
    receptor.RegistroTributarioExterior =
      values.ReceptorRegistroTributarioExterior;

    if (receptor.id) {
      let result = await serviceReceptores.patch(receptor.id, receptor);
      console.log('resultPatch', result);
    } else {
      let resultCreate = await serviceReceptores.create(receptor);
      console.log('resultCreate', resultCreate);
    }
    console.log('receptor', receptor);
  };

  const handleSave = async (values, data) => {
    // Cuando es tipo Ingreso y solamente tiene un concepto
    if (values.idTipoComprobante === 'I' && values.conceptos.length <= 1) {
      swal('Error', '¡Mínimo debe de tener un ingreso y un traslado!', 'error');
      return true;
    }

    // Valida que cuando es Ingreso, tenga por lo menos un ingreso y un traslado
    if (values.idTipoComprobante === 'I' && values.conceptos.length >= 1) {
      var conceptos0 = 0;
      var conceptos1 = 0;
      var conceptos2 = 0;
      for (let concepto of values.conceptos) {
        if (concepto.TipoConcepto === 0) {
          values.ImpuestosTraslados =
            values.ImpuestosTraslados + concepto.Importe;
          values.Subtotal = values.Subtotal + concepto.Subtotal;
          values.Total = values.ImpuestosTraslados + values.Subtotal;
          conceptos0 = conceptos0 + 1;
        }
        if (concepto.TipoConcepto === 1) {
          conceptos1 = conceptos1 + 1;
        }
        if (concepto.TipoConcepto === 2) {
          conceptos2 = conceptos2 + 1;
        }
      }

      if (conceptos0 === 0) {
        swal(
          'Error',
          '¡Debe de haber por lo menos 1 concepto de tipo: Concepto!',
          'error'
        );
        return true;
      }
      if (conceptos1 === 0 && conceptos2 === 0) {
        swal(
          'Error',
          '¡Debe de haber por lo menos 1 concepto de tipo: Traslado!',
          'error'
        );
        return true;
      }
    }

    if (values) {
      console.log('valores', values);
      setLoading(true);
      try {
        let cpResult = await getCodigoPostal(values.LugarExpedicion);

        let fechaDocumento = moment(values.FechaDocumento);
        let isDTS = fechaDocumento.clone().isDST();

        if (isDTS) {
          values.FechaDocumento = fechaDocumento
            .clone()
            .utcOffset(cpResult.DiffHorrarioVerano * 60)
            .format('YYYY-MM-DD HH:mm:ss.SSS');
        } else {
          values.FechaDocumento = fechaDocumento
            .clone()
            .utcOffset(cpResult.DiffHorrarioInvierno * 60)
            .format('YYYY-MM-DD HH:mm:ss.SSS');
        }

        //Documento
        let resultCreateDocumento = await setDocumento(values);

        if (isCartaPorte) {
          // Ubicaciones
          await setDocumentoUbicaciones(values, resultCreateDocumento.id);

          //Autotransporte;
          await setDocumentoAutoTransportes(values, resultCreateDocumento.id);

          //Autotransporte Remolques;
          await setDocumentoAutosRemolques(values, resultCreateDocumento.id);

          //TODO Agregar el Remolque 1 y 2 a la Placa.

          // FiguraTransporte;
          await setDocumentoFiguraTransporte(values, resultCreateDocumento.id);

          //Carta Porte
          await setDocumentoCartaPorte(resultCreateDocumento.id);
        }

        // Conceptos;
        await setDocumentoConceptos(values, resultCreateDocumento.id);

        // ConceptosImpuestos;
        await setDocumentoConceptosImpuestos(values, resultCreateDocumento.id);

        // alert(resultCreateDocumento.id);

        await saveReceptorOnCatalog(values);

        setCurrentSection('EDIT');
        onClose(true);

        //Axios comentado
        // let resultTimbrado = await axios.post(
        //   'https://api-app.flexlogic.co/timbrar-cfdi/',
        //   {
        //     idDocumento: resultCreateDocumento.id,
        //     idCertificado: 2,
        //     idPAC: 2,
        //     sUser: auth.user._id,
        //   }
        // );

        // let resultTimbrado = await axios.post(
        //   'https://flexlogic-cartaporte.azurewebsites.net/api/Documentos/GeneraCFDI',
        //   {
        //     idDocumento: resultCreateDocumento.id,
        //     idCertificado: 1,
        //     idPAC: 1,
        //     sUser: 'irving',
        //   }
        // );

        // console.log('resultTimbrado', resultTimbrado);

        //Axios Comentado
        // if (
        //   resultTimbrado.data.resultTimbrado.errors &&
        //   resultTimbrado.data.resultTimbrado.errors.length
        // ) {
        //   onClose(true);
        //   swal('Error', resultTimbrado.data.resultTimbrado.errors[0], 'error');
        // } else {
        //   updateFolio(values);
        //   setCurrentSection('EDIT');
        //   onClose(true);
        //   swal('Timbrado', '¡Se ha timbrado correctamente!', 'success');
        // }
      } catch (error) {
        console.log('error', error.stack);
        swal('Error', error.message, 'error');
        setLoading(false);
      }
    } else swal('Error', 'Dont Save User', 'error');
  };

  const posFoliosService = feathers.service('pos-folios');

  const getPOSFolios = async (values) => {
    let response = await posFoliosService.find({
      query: {
        idPOS: values.idPOS,
        idTipoComprobante: values.idTipoComprobante,
        Serie: values.Serie,
      },
    });

    let data =
      (response && response.data && response.data.length && response.data[0]) ||
      null;
    return data;
  };

  async function updateFolio(values) {
    let data = await getPOSFolios(values);
    console.log(data);
    if (data) {
      let response = await servicePOSFolios.patch(values.idPOS, {
        idPOS: data.idPOS,
        idTipoComprobante: data.idTipoComprobante,
        Serie: data.Serie,
        Folio: ++data.Folio,
      });
    }
  }

  /**
   * Stop enter submitting the form.
   * @param keyEvent Event triggered when the user presses a key.
   */
  function onKeyDown(keyEvent) {
    if ((keyEvent.charCode || keyEvent.keyCode) === 13) {
      keyEvent.preventDefault();
    }
  }
  async function verViaje(TripID, formik, dataConceptos) {
    if (TripID) {
      //setLoading(true);
      let viaje = await serviceTrip.get(TripID);

      //console.log(viaje);
      formik.values.PlacaR1 =
        // viaje.Remolques.length > 0 ? viaje.Remolques[0].PrimaryPlate : '';
        '2342342';
      formik.values.Remolque1 =
        //viaje.Remolques.length > 0 ? viaje.Remolques[0].SubTipoRemolque : '';
        'CTR001';
      formik.values.PlacaR2 =
        viaje.Remolques.length > 1 ? viaje.Remolques[1].PrimaryPlate : '';
      formik.values.Remolque2 =
        viaje.Remolques.length > 1 ? viaje.Remolques[1].SubTipoRemolque : '';
      formik.values.figuraTransportes = [];
      for (let i = 0; i < viaje.Choferes.length; i++) {
        let chofer = {
          TipoFigura: '01',
          NombreFigura:
            viaje.Choferes[i].FirstName + ' ' + viaje.Choferes[i].LastName,
          RFC: viaje.Choferes[i].RFC ? viaje.Choferes[i].RFC : '',
          NumLicencia: viaje.Choferes[i].LicenseNumber,
          ResidenciaFiscal: 'MEX',
          NumRegTributario: viaje.Choferes[i].NumRegistroTributario
            ? viaje.Choferes[i].NumRegistroTributario
            : '',
          Calle: viaje.Choferes[i].PhysicalAddress1
            ? viaje.Choferes[i].PhysicalAddress1
            : '',
          NumExterior: '',
          NumInterior: '',
          Referencia: '',
          Pais: '',
          Estado: '',
          Municipio: '',
          Localidad: '',
          CodigoPostal: '',
          Colonia: '',
          ParteTransporte: '',
        };
        formik.values.figuraTransportes.push(chofer);
      }

      if (viaje.Shipment.length > 0) {
        formik.values.ubicaciones = [
          {
            TipoUbicacion: 'Origen',
            TipoEstacion: '',
            NumEstacion: '',
            NombreEstacion: '',
            NavegacionTrafijo: '',
            RFCRemitenteDestinatario: '',
            ResidenciaFiscal: '',
            NumRegTributario: '',
            IDUbicacion: '',
            FechaHoraSalidaLlegada: '',
            DistanciaRecorrida: 0,
            Calle:
              viaje.Shipment[0].Shipper.Address1 +
              ' ' +
              viaje.Shipment[0].Shipper.Address2
                ? viaje.Shipment[0].Shipper.Address2
                : '',
            NumExterior: '',
            NumInterior: '',
            Referencia: '',
            Pais:
              viaje.Shipment[0].Shipper.Country == 'MX'
                ? 'MEX'
                : viaje.Shipment[0].Shipper.Country == 'US'
                ? 'USA'
                : '',
            Estado: viaje.Shipment[0].Shipper.StateProvince,
            Municipio: '',
            Localidad: '',
            CodigoPostal: viaje.Shipment[0].Shipper.Postal,
            Colonia: '',
          },
          {
            TipoUbicacion: 'Destino',
            TipoEstacion: '',
            NumEstacion: '',
            NombreEstacion: '',
            NavegacionTrafijo: '',
            RFCRemitenteDestinatario: '',
            ResidenciaFiscal: '',
            NumRegTributario: '',
            IDUbicacion: '',
            FechaHoraSalidaLlegada: viaje.ArrivalDateTime,
            DistanciaRecorrida: 0,
            Calle:
              viaje.Shipment[0].Consignee.Address1 +
              ' ' +
              viaje.Shipment[0].Consignee.Address2
                ? viaje.Shipment[0].Consignee.Address2
                : '',
            NumExterior: '',
            NumInterior: '',
            Referencia: '',
            Pais:
              viaje.Shipment[0].Consignee.Country == 'MX'
                ? 'MEX'
                : viaje.Shipment[0].Consignee.Country == 'US'
                ? 'USA'
                : '',
            Estado: viaje.Shipment[0].Consignee.StateProvince,
            Municipio: '',
            Localidad: '',
            CodigoPostal: viaje.Shipment[0].Consignee.Postal,
            Colonia: '',
          },
        ];
      }

      //Agregar conceptos
      //console.log('dataConceptos: ', dataConceptos);
      let newConceptos = [];
      if (dataConceptos) {
        formik.values.conceptos = [];
        dataConceptos.map((data, index) => {
          if (data.NP !== 'Grand Total') {
            newConceptos.push({
              Cantidad: data['Cantidad']
                ? data['Cantidad']
                : data['Cant Piezas ']
                ? data['Cant Piezas ']
                : 1, //DONE
              idProducto: 0, //DONE Después
              Descripcion: data['Descripcion']
                ? data['Descripcion']
                : data['DESCRIPCION']
                ? data['DESCRIPCION']
                : '', //DONE
              Valor: 16.0,
              PrecioUnitario: 0.0, //DONE Disable cuando es Traslado
              Subtotal: 0.0, //DONE Disable cuando es Traslado
              Descuento: 0.0, //DONE Disable cuando es Traslado
              ImpuestosTraslados: 0.0, //DONEPara ingresos
              ImpuestosRetenciones: 0.0, //DONEPara ingresos
              Total: 0.0, //DONE Disable cuando es Traslado
              idReferencia: 0, //DONE Sin uso
              idReferenciaModulo: 0, //DONE Sin uso
              TipoConcepto: 1, //DONE //IDEA 0 - Ingresos, 1 - Translados, 2  - Translados
              Valor: 16.0,
              ClaveProdServ: data['Clave ProdServ SAT']
                ? data['Clave ProdServ SAT'].toString()
                : data['CLAVE SAT']
                ? data['CLAVE SAT'].toString()
                : '', //DONE //IDEA es Sat código - TipoConcepto 2 -> cat_ClaveProdServCP
              CuentaPredial: '', //IDEA después
              NumeroIdentificacion: '', //IDEA después
              ClaveUnidad: data['Unidad Medida']
                ? data['Unidad Medida']
                : 'H87', //DONE
              UnidadMedida: 'Pieza', //DONE
              ValorMercancia: data['Valor Mercancia']
                ? data['Valor Mercancia']
                : data['Valor Dolares']
                ? data['Valor Dolares']
                : 0.0, //DONE Carta Porte
              PesoEnKg: data['Peso en KG']
                ? data['Peso en KG']
                : data['Peso Bruto']
                ? data['Peso Bruto']
                : 0.0, //DONE Carta Porte
              MonedaMercancia: data['Moneda Valor Mercancia']
                ? data['Moneda Valor Mercancia']
                : data['Moneda']
                ? data['Moneda']
                : '', //DONE Carta Porte
              cfdi_tipoRelacion: '', //IDEA Después
              cfdi_tipoRelacionuuid: '', //IDEA Después
              NumeroIdentificacionComercio: '', //IDEA Después - comercio exterior.
              FraccionArancelariaComercio: data['Fraccion Arancelaria MX']
                ? data['Fraccion Arancelaria MX'].toString()
                : data['Fracción MX']
                ? data['Fracción MX'].toString()
                : '', //DONE Carta Porte
              CantidadAduanaComercio: 0.0, //IDEA Después - comercio exterior.
              UnidadAduanaComercio: '', //IDEA Después - comercio exterior.
              ValorUnitarioAduanaComercio: 0.0, //IDEA Después - comercio exterior.
              ValorDolaresComercio: 0.0, //IDEA Después - comercio exterior.
              DescripcionIngles: '', //IDEA Después - comercio exterior.
              cfdi_RelacionIdDoc: 0, //IDEA Después - complemento de pagos.
              cfdi_RelacionNumParcialidad: 0, //IDEA Después - complemento de pagos.
              cfdi_RelacionReferencia: '', //IDEA Después - complemento de pagos.
              idDoc_Ref: 0, //IDEA Después - complemento de pagos.
              CveMaterialPeligroso: data['Clave Material Peligroso']
                ? data['Clave Material Peligroso']
                : '', //TODO Carta Porte - catalogo - requerido si es material peligroso en ClaveProdServ
              Embalaje: data['Embalaje'] ? data['Embalaje'] : '', //TODO Carta Porte - catalogo - requerido si es material peligroso en ClaveProdServ
              EmbalajeDescripcion: data['Embalaje Descripcion']
                ? data['Embalaje Descripcion']
                : '', //TODO Carta Porte - requerido si es material peligroso en ClaveProdServ
              UUIDComercioExterior: data['UUID Comercio Exterior']
                ? data['UUID Comercio Exterior']
                : '', //TODO Carta Porte - opcional
              Pedimento: data['Pedimento'] ? data['Pedimento'] : '', //TODO Carta Porte - opcional con formato validado, si espacio deben ser 15, y si no con espacios lo que den.
              Dimensiones: '', //TODO Carta Porte - opcional cuando es material peligroso.
            });
          }
        });
        formik.values.conceptos = newConceptos;

        formik.setValues(formik.values);
        console.log('formik: ', formik.values);
      }
    }
  }

  return (
    <SlidingPanel
      isOpen={openPanel}
      size={100}
      title={title}
      subtitle={subtitle}
      onClose={handledOnClose}
      style={{ backgroundColor: '#f5f5f5' }}
    >
      {(!loading && lastDocument && (
        <Formik
          initialValues={initialValues(lastDocument, userPOS)}
          validateOnChange={false}
          validationSchema={validationScheme}
          onSubmit={handleSave}
        >
          {(formikProps) => (
            <Form
              onKeyDown={onKeyDown}
              onSubmit={formikProps.handleSubmit}
              loading={loading}
              style={{ marginRight: '15px' }}
            >
              <ModalTrip formik={formikProps} verViaje={verViaje} />
              <FormDocument
                onSave={handleSave}
                ref={refForm}
                isValid={isValid}
                setIsValid={setIsValid}
                isCartaPorte={isCartaPorte}
                setIsCartaPorte={setIsCartaPorte}
                isMaterialPeligroso={isMaterialPeligroso}
                setIsMaterialPeligroso={setIsMaterialPeligroso}
                formik={formikProps}
              />

              <Actions
                handledOnClose={handledOnClose}
                isValid={isValid}
                formik={formikProps}
              />
            </Form>
          )}
        </Formik>
      )) || (
        <Dimmer active inverted>
          <Loader inverted>Cargando</Loader>
        </Dimmer>
      )}
    </SlidingPanel>
  );
};

export default Drawer;
