export default [
  {
    name: 'FCM',
    label: 'First Class Mail',
    availableFor: ['USPS', 'NEWGISTICS'],
  },
  { name: 'PM', label: 'Priority Mail', availableFor: ['USPS', 'NEWGISTICS'] },
  { name: 'EM', label: 'Priority Mail Express', availableFor: ['USPS'] },
  { name: 'STDPOST', label: 'Ground Retail', availableFor: ['USPS'] },
  { name: 'BPM', label: 'Bound Printed Matter', availableFor: ['NEWGISTICS'] },
  {
    name: 'PRCLSEL',
    label: 'Parcel Select (ground)',
    availableFor: ['USPS', 'NEWGISTICS'],
  },
  {
    name: 'PSLW',
    label: 'Parcel Select Lightweight',
    availableFor: ['NEWGISTICS'],
  },
];
