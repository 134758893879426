import React, { useState, useEffect, forwardRef } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Form as SemanticForm, Label, Icon } from 'semantic-ui-react';
import SemanticInput from '../../../components/SemanticInput';
import feathers from '../../../services/feathers';
import swal from 'sweetalert';
import moment from 'moment';
import DatePicker from '../../../components/DatePicker';

const Form = ({
  isBusy,
  setIsBusy,
  dailyLog: current,
  binCounter: counter,
  setBinCounter,
  binData,
  children,
  onSuccess,
  onTrySave,
  data,
  lookupDailyLog = { key: 'number', label: 'Number' },
  innerRef,
}) => {
  const service = feathers.service('daily-logs');

  // States
  const [defaultValue] = useState({ actualDate: moment().startOf('day') });
  const [dailyLog, setDailyLog] = useState({
    ...current,
    actualDate: moment(current.actualDate).startOf('day'),
  });
  const [validations, setValidations] = useState({
    actualDate: false,
    [lookupDailyLog.key]: false,
    bin: false,
  });
  const [binCapacity, setBinCapacity] = useState(0);

  // Refs
  const refDate = React.useRef(null);
  const refLookupDailyLog = React.useRef(null);
  const refBin = React.useRef(null);

  // Effects
  useEffect(() => {
    if (refLookupDailyLog.current) refLookupDailyLog.current.focus();
  }, []);

  // Handles
  const handleChange = (_, { name, value }) => {
    setDailyLog({
      ...dailyLog,
      [name]: ['bin', lookupDailyLog.key].includes(name)
        ? value.toLowerCase()
        : value,
    });

    if (name == 'bin') {
      let exists = binData.find(
        (item) =>
          String(item.name.toLowerCase()) === String(value.toLowerCase()) ||
          String(value.toLowerCase()).startsWith(item.name.toLowerCase())
      );
      if (exists) setBinCapacity(exists.capacity);
      else setBinCapacity(0);
    }
  };
  const handleKeyDown = (event) => {
    const {
      target: { name },
      keyCode,
    } = event;

    if (keyCode === 13 && (name === lookupDailyLog.key || name === 'bin')) {
      const hasLookupDailyLog =
        dailyLog[lookupDailyLog.key] &&
        dailyLog[lookupDailyLog.key].trim().length;
      const hasBin = dailyLog.bin && dailyLog.bin.trim().length;

      if (name === lookupDailyLog.key && !hasBin) {
        refBin.current.focus();
        event.preventDefault();
      } else if (name === 'bin' && !hasLookupDailyLog) {
        refLookupDailyLog.current.focus();
        event.preventDefault();
      }
    }
  };

  const handleSubmit = async (event, data) => {
    let success = false,
      result = null;

    try {
      setIsBusy(true);
      event.preventDefault();

      const firstInvalid = isInvalid();
      if (!firstInvalid) {
        const actualDate = moment(dailyLog.actualDate).utc();

        let dailyLogDTO = {
          ...dailyLog,

          date: moment(dailyLog.actualDate).format(),
          dateOrigin: actualDate.format('M/D/YYYY'),
          actualDate: actualDate.toDate(),
        };

        // console.debug('dailyLogDTO', JSON.stringify(dailyLogDTO, null, 4), dailyLogDTO);
        if (!onTrySave || !(await onTrySave(dailyLog))) {
          result = await (!dailyLog._id
            ? service.create(dailyLogDTO)
            : service.patch(dailyLog._id, dailyLogDTO));
          success = true;

          // console.debug('result', result);
        } else {
          success = false;
          await swal('Error', 'Daily Log already exists', 'error');
        }
      } else {
        if (firstInvalid === 'actualDate') {
          refDate.current.focus();
        } else if (firstInvalid === lookupDailyLog.key) {
          refLookupDailyLog.current.focus();
        } else if (firstInvalid === 'bin') {
          refBin.current.focus();
        }
      }
    } catch (err) {
      console.error('Error: ', err);
      await swal('Error', "Don't Add Daily Log", 'error');
    } finally {
      setIsBusy(false);

      if (success) {
        if (dailyLog._id) {
          await swal('Done', 'Finished', 'success');
        } else {
          setDailyLog({
            ...defaultValue,
            ...(dailyLog.bin && { bin: dailyLog.bin }),
          });
          refLookupDailyLog.current.focus();
          let temp = counter;

          if (temp[dailyLog.bin]) temp[dailyLog.bin]++;
          else temp[dailyLog.bin] = 1;

          setBinCounter(temp);
        }

        onSuccess && onSuccess(result);
      }
    }
  };

  // Functions
  const isInvalid = () => {
    let firstInvalid = null;

    const newValidations = Object.keys({
      actualDate: validations.actualDate,
      [lookupDailyLog.key]: validations[lookupDailyLog.key],
      bin: validations.bin,
    }).reduce((prev, field) => {
      const ret = { ...prev, [field]: dailyLog[field] ? false : true };

      firstInvalid = firstInvalid || (ret[field] && field);

      return ret;
    }, {});

    setValidations(newValidations);

    return firstInvalid;
  };

  return (
    <>
      {/* dailyLog: <pre>{JSON.stringify(dailyLog, null, 2)}</pre> */}

      <SemanticForm onSubmit={handleSubmit} loading={isBusy}>
        <FormGrid>
          <div></div>
          <div></div>
          <div style={{ textAlign: 'center' }}>
            {dailyLog.bin ? (
              <Label size="huge" circular color="teal">
                {dailyLog.bin}
              </Label>
            ) : (
              <></>
            )}

            {dailyLog.bin ? (
              <Label size="huge">
                <Icon name="boxes" />
                {counter[dailyLog.bin]}
                {' of '}
                {binCapacity ? binCapacity : 'no data on the bin limit'}
              </Label>
            ) : (
              <></>
            )}
          </div>
        </FormGrid>
        <FormGrid>
          <DatePicker
            selected={dailyLog.actualDate}
            onChange={(date) =>
              handleChange(undefined, { name: 'actualDate', value: date })
            }
            style={{ gridArea: 'date' }}
            InputProps={{
              placeholder: 'Date',
              label: 'Date *',
              name: 'actualDate',
              disabled: isBusy,
              fluid: true,

              ...(validations.actualDate && {
                error: { content: 'Required Date' },
              }),
            }}
            ref={refDate}
          />

          <div style={{ gridArea: 'lookupDailyLog' }}>
            <SemanticInput
              placeholder={lookupDailyLog.label}
              label={`${lookupDailyLog.label} *`}
              name={lookupDailyLog.key}
              value={dailyLog[lookupDailyLog.key] || ''}
              onChange={handleChange}
              error={
                validations[lookupDailyLog.key] && {
                  content: `Required ${lookupDailyLog.label}`,
                }
              }
              disabled={isBusy}
              fluid
              onKeyDown={handleKeyDown}
              ref={refLookupDailyLog}
            />
          </div>

          <div style={{ gridArea: 'bin' }}>
            <SemanticInput
              placeholder="BIN"
              label="BIN *"
              name="bin"
              value={dailyLog.bin || ''}
              onChange={handleChange}
              error={validations.bin && { content: 'Required BIN' }}
              disabled={isBusy}
              fluid
              onKeyDown={handleKeyDown}
              ref={refBin}
            />
          </div>
        </FormGrid>

        {children}
      </SemanticForm>
    </>
  );
};

Form.propTypes = {
  isBusy: PropTypes.bool,
  setIsBusy: PropTypes.func,
  dailyLog: PropTypes.object,
  children: PropTypes.array,
  onSuccess: PropTypes.func,
  lookupDailyLog: PropTypes.object,
};

const FormGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-template-rows: auto auto;
  grid-gap: 1em;
  grid-template-areas: 'date lookupDailyLog bin' '. . .';
`;

export default forwardRef((props, ref) => <Form {...props} innerRef={ref} />);
