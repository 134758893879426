import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import TableContainer from '../../../../components/TableContainer';
import { AgGridReact } from '@ag-grid-community/react';
import { AllModules } from '@ag-grid-enterprise/all-modules';
import { Button } from 'semantic-ui-react';
import _ from 'lodash';
import swal from 'sweetalert';
import { valueFormatter, number, price, dateTime } from '../../../../components/AgGridColumns';

const Table = ({
  data,
  onShippedItemClick,
  selection,
  setSelection,
  valueProductGetter
}) => {
  // States
  const [columnGroupDef] = useState({
    pinned: 'left',
    cellRenderer: 'agGroupCellRenderer',
    cellRendererParams: { checkbox: true },
  });
  const [defaultColDefs] = useState({
    filter: false,
    sortable: true,
    enableRowGroup: true,
    resizable: true
  });
  const [sideBar] = useState({
    toolPanels: ['columns'],
    defaultToolPanel: 'filters',
  });
  const [gridApi, setGridApi] = useState();
  const [gridColumnApi, setGridColumnApi] = useState(null);
  const [columnDefs, setColumnDefs] = useState([
    ...(selection && setSelection
      ? [
        {
          headerName: '',
          field: '',
          width: 70,
          checkboxSelection: true,
          headerCheckboxSelection: true,
          suppressMenu: true,
          pinned: 'left',
          enableRowGroup: false,
          resizable: false,
          sortable: false,
        },
      ]
      : []),
    {
      headerName: 'SKU',
      field: 'SKU',
      valueFormatter
    },
    {
      headerName: 'Is 321',
      valueGetter: valueProductGetter('is321'),
      valueFormatter,
    },
    {
      headerName: 'Import Code',
      valueGetter: valueProductGetter('exportCode'),
      valueFormatter,
    },
    {
      headerName: 'Fiber',
      valueGetter: valueProductGetter('fiber'),
      valueFormatter
    },
    {
      headerName: 'COO',
      valueGetter: valueProductGetter('coco'),
      valueFormatter
    },
    {
      headerName: 'DESC',
      valueGetter: valueProductGetter('description'),
      valueFormatter
    },
    {
      ...price,

      headerName: 'Cost',

      valueGetter: params => {
        if (params.data && params.data.product && params.data.product.length && params.data.product[0]) {
          return params.data.product[0].cost;
        }

        return null;
      }
    },
    {
      ...dateTime,

      headerName: 'Created Date',
      field: 'createdDateTime',
      sort: 'asc',
    },
    {
      headerName: 'Product Name',
      field: 'productName',
      valueFormatter,
    },
    {
      ...number,

      headerName: 'QTY Shipped',
      field: 'qtyShipped',
    },
    {
      ...price,

      headerName: 'Price',
      field: 'price',
    },
    {
      ...price,

      headerName: 'Shipped Subtotal',
      field: 'shippedSubtotal',
    },
    {
      headerName: '3PL Customer',
      field: 'customer3PL',
      valueFormatter,
    },
    {
      headerName: 'Order Number',
      field: 'orderNumber',
      valueFormatter
    },
    {
      headerName: 'Tracking Number',
      field: 'trackingNumber',
      valueFormatter
    },
    {
      headerName: 'Email',
      field: 'email',
      valueFormatter
    },
    {
      headerName: 'Name',
      field: 'name',
      valueFormatter
    },
    {
      headerName: 'Address',
      field: 'address',
      valueFormatter
    },
    {
      headerName: 'City',
      field: 'city',
      valueFormatter
    },
    {
      headerName: 'Zip',
      field: 'zip',
      valueFormatter
    },
    {
      headerName: 'State',
      field: 'state',
      valueFormatter
    },
    {
      headerName: 'Country',
      field: 'country',
      valueFormatter
    },
    {
      headerName: 'Phone',
      field: 'phone',
      valueFormatter
    },
    {
      headerName: 'Product Tariff Code',
      field: 'productTariffCode',
      valueFormatter
    },
    {
      headerName: '',
      field: '',
      width: 300,
      pinned: 'right',
      cellStyle: { textAlign: 'center' },

      cellRendererFramework: (params) => {
        if (params.data)
          return (
            <Button.Group>
              <Button
                compact
                primary
                onClick={() => handleAddProductClick(params.data)}
              >
                {params.data.product && params.data.product.length && params.data.product[0] ? 'Edit product' : 'Add product'}
              </Button>

              <Button.Or />

              <Button
                compact
                primary
                onClick={() => handleAssociateProductClick(params.data)}
              >
                Associate product
              </Button>
            </Button.Group>
          );

        return '';
      }
    },
  ]);

  // Effects
  useEffect(() => {
    // console.debug('useEffect Table', selection, (gridApi && gridApi.getSelectedRows()));
    if (
      selection &&
      !selection.length &&
      gridApi &&
      gridApi.getSelectedRows().length
    ) {
      gridApi.deselectAll();
    }
  }, [selection, gridApi]);

  useEffect(() => {
    if (gridColumnApi) {
      var allColumnIds = gridColumnApi
        .getAllColumns()
        .filter((column) => column.colDef.field !== '')
        .map((column) => column.colId);

      gridColumnApi.autoSizeColumns &&
        gridColumnApi.autoSizeColumns(allColumnIds, false);
    }
  }, [data, gridColumnApi]);

  // Handles
  const handleAddProductClick = shippedItem => {
    onShippedItemClick && onShippedItemClick({ ...shippedItem, _id: null });
  };

  const handleAssociateProductClick = shippedItem => {
    onShippedItemClick && onShippedItemClick({ ...shippedItem });
  };

  const handleSelectionChanged = (event) => {
    let rows = [];

    try {
      if (event && event.api) {
        rows = event.api.getSelectedRows().map(row => row._id);
      }
    } catch (err) {
      rows = [];

      swal({
        title: 'Error',
        text: 'Failed to select daily logs',
        icon: 'error',
        buttons: {
          cancel: 'Close',
        },
      });
    } finally {
      // console.debug('onSelectionChanged event rows', '[' + rows.map(item => `ObjectId('${item}')`).join(', ') + ']');
      setSelection(rows);
    }
  };

  const handleGridReady = ({ api, columnApi }) => {
    setGridApi(api);
    setGridColumnApi(columnApi);
  };

  const settingColumngGroupDefinition = (valuesRowGroups) => {
    let changedDefs = _.cloneDeep(columnDefs);

    if (valuesRowGroups.columns.length > 0) {
      changedDefs = changedDefs.map((col) =>
        col.headerName === valuesRowGroups.columns[0].colDef.headerName &&
          col.headerName !== ''
          ? {
            ...col,
            checkboxSelection: false,
            pinned: false,
            hide: true,
            headerCheckboxSelection: false,
            rowGroup: true,
          }
          : col.headerName === '' && col.pinned === 'right'
            ? {
              ...col,
              checkboxSelection: false,
              hide: false,
              headerCheckboxSelection: false,
              rowGroup: false,
            }
            : {
              ...col,
              checkboxSelection: false,
              headerCheckboxSelection: false,
              rowGroup: false,
              hide: false,
            }
      );
    } else {
      changedDefs = changedDefs.map((col) =>
        col.headerName === 'SKU'
          ? {
            ...col,
            checkboxSelection: true,
            hide: false,
            headerCheckboxSelection: true,
            rowGroup: false,
          }
          : {
            ...col,
            checkboxSelection: false,
            hide: false,
            headerCheckboxSelection: false,
            rowGroup: false,
          }
      );
    }

    setColumnDefs(changedDefs);
  };

  return (
    <TableContainer className="ag-theme-material" rowFilters={'D0'}>
      <AgGridReact
        modules={AllModules}
        columnDefs={columnDefs}
        defaultColDef={defaultColDefs}
        immutableColumns={true}
        rowSelection="multiple"
        suppressRowClickSelection={true}
        onSelectionChanged={handleSelectionChanged}
        rowData={data}
        pagination={true}
        paginationPageSize={100}
        onGridReady={handleGridReady}

        sideBar={sideBar}
        groupHideOpenParents={true}
        // rowGroupPanelShow={'always'}
        groupSelectsChildren={true}
        autoGroupColumnDef={columnGroupDef}
        suppressDragLeaveHidesColumns={true}
        suppressMakeColumnVisibleAfterUnGroup={true}
        onColumnRowGroupChanged={(valuesRowGroups) =>
          settingColumngGroupDefinition(valuesRowGroups)
        }
      />
    </TableContainer>
  );
};

Table.propTypes = {
  data: PropTypes.array,
  onShippedItemClick: PropTypes.func,
  selection: PropTypes.array,
  setSelection: PropTypes.func,
  valueProductGetter: PropTypes.func
};

export default Table;