import React, { useState } from 'react';
import SlidingPanel from '@bit/softwareland.flex.sl-sliding-panel';
import swal from 'sweetalert';
import Actions from './Actions';
import FormAddUser from '../Drawer/FormAddUser';
import DrawerFiltersWrapper from '../../../components/DrawerFiltersWrapper';

const Drawer = ({ openPanel, onClose, currentUser }) => {
  const [currentSection, setCurrentSection] = useState(
    (currentUser && currentUser._id && 'EDIT') || 'CREATE'
  );
  const [isValid, setIsValid] = useState(false);
  const refForm = React.useRef(null);

  let drawerTitles = {
    CREATE: {
      title: 'Create New User',
      subtitle: '',
    },
    EDIT: {
      title: 'Edit User',
      subtitle: '',
    },
  };

  let title = drawerTitles[currentSection].title;
  let subtitle = drawerTitles[currentSection].subtitle;

  const handledOnClose = async () => {
    let confirmation = await swal('Do you want close this form?', {
      buttons: ['No', 'Yes'],
    });

    if (confirmation) {
      setCurrentSection('CREATE');
      onClose(false);
    }
  };

  const handledSaveClick = () => {
    refForm.current.submit();
  };

  const handleSave = (user) => {
    if (user) {
      setCurrentSection('EDIT');
      onClose(true);
      swal('Done', 'Finished', 'success');
    } else swal('Error', 'Dont Save User', 'error');
  };

  return (
    <SlidingPanel
      isOpen={openPanel}
      size={50}
      title={title}
      subtitle={subtitle}
      onClose={handledOnClose}
    >
      <DrawerFiltersWrapper>
        <FormAddUser
          currentUser={currentUser}
          onSave={handleSave}
          ref={refForm}
          isValid={isValid}
          setIsValid={setIsValid}
        />

        <Actions
          onSaveClick={handledSaveClick}
          handledOnClose={handledOnClose}
          isValid={isValid}
        />
      </DrawerFiltersWrapper>
    </SlidingPanel>
  );
};

export default Drawer;
