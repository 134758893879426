import React, { forwardRef } from 'react';
import { Grid } from 'semantic-ui-react';
import styled from 'styled-components';
import FormHeader from './FormHeader';
import FormEmisor from './FormEmisor';
import FormReceptor from './FormReceptor';
import FormConcepts from './FormConcepts';
import FormUbicaciones from './FormUbicaciones';
import FormAutotransporte from './FormAutotransporte';
import FormFiguraTransporte from './FormFiguraTransporte';

const FormDocument = ({
  currentUser,
  onSave,
  setIsValid,
  innerRef,
  formik,
  isCartaPorte,
  isMaterialPeligroso,
  setIsCartaPorte,
  setIsMaterialPeligroso,
}) => {
  return (
    <WrapContent>
      <FormHeader
        formik={formik}
        isCartaPorte={isCartaPorte}
        setIsCartaPorte={setIsCartaPorte}
      />
      {/* Upload to wrapper */}
      <Grid>
        <Grid.Row>
          <Grid.Column width={8}>
            <FormEmisor formik={formik} />
          </Grid.Column>
          <Grid.Column width={8}>
            <FormReceptor formik={formik} />
          </Grid.Column>
        </Grid.Row>
      </Grid>
      {isCartaPorte && (
        <>
          <FormUbicaciones formik={formik} />
          <FormFiguraTransporte formik={formik} />
          <FormAutotransporte
            formik={formik}
            isMaterialPeligroso={isMaterialPeligroso}
          />
        </>
      )}
      <FormConcepts
        formik={formik}
        isCartaPorte={isCartaPorte}
        isMaterialPeligroso={isMaterialPeligroso}
        setIsMaterialPeligroso={setIsMaterialPeligroso}
      />
    </WrapContent>
  );
};

const WrapContent = styled.div``;

export default forwardRef((props, ref) => (
  <FormDocument {...props} innerRef={ref} />
));
