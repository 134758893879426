import React, { forwardRef } from 'react';
import { Form, Segment, Header, Grid, Divider } from 'semantic-ui-react';
import DropdownSimpleFetch from '../../../components/Formik/DropdownSimpleFetch';
import DropdownPos from '../../../components/Formik/DropdownPos';
import feathers from '../../../services/feathers';

const FormEmisor = ({ formik }) => {
  let {
    values,
    errors,
    touched,
    handleChange,
    handleBlur,
    setFieldValue,
    /* and other goodies */
  } = formik;

  const posFoliosService = feathers.service('pos-folios');

  const getPOSFolios = async (idPOS, tipoComprobante) => {
    let response = await posFoliosService.find({
      query: {
        idPOS: idPOS,
        idTipoComprobante: tipoComprobante,
      },
    });

    let data =
      (response && response.data && response.data.length && response.data[0]) ||
      null;
    return data;
  };

  return (
    <>
      <Segment>
        <Header as="h3">Emisor</Header>
        <Form>
          <Form.Group widths={'equal'}>
            <DropdownPos
              fluid
              selection
              label="Nombre"
              value={values.idPOS}
              onChange={(e, { value }, posData) => {
                setFieldValue('idPOS', value);
                setFieldValue('idSucursal', posData[0].idSucursal);
                setFieldValue('EmisorNombre', posData[0].NombreSucursal);
                setFieldValue('EmisorRFC', posData[0].TaxID);
                setFieldValue('idRegimen', posData[0].RegimenFiscal);
                setFieldValue('LugarExpedicion', posData[0].CodigoPostal);
                setFieldValue('ReceptorNombre', posData[0].NombreSucursal);
                setFieldValue('ReceptorRFC', posData[0].TaxID);
                setFieldValue('ReceptorCodPos', posData[0].CodigoPostal);
                setFieldValue('idPAC', posData[0].idPAC);
                setFieldValue('idCertificado', posData[0].idCertificado);
              }}
              placeholder="Nombre"
              featherService="pos"
              columName="NombreSucursal"
              columValue="idPOS"
              mapValues={(value) => {
                return {
                  key: Math.random(),
                  text: `${value['NombreSucursal']}`,
                  value: value['idPOS'],
                };
              }}
              error={
                errors.EmisorNombre &&
                touched.EmisorNombre && { content: errors.EmisorNombre }
              }
            />

            <Form.Input
              placeholder="RFC"
              label="RFC"
              name="EmisorRFC"
              value={values.EmisorRFC}
              onChange={handleChange}
              onBlur={handleBlur}
              error={
                errors.EmisorRFC &&
                touched.EmisorRFC && { content: errors.EmisorRFC }
              }
            />
          </Form.Group>
          <Form.Group widths={'equal'}>
            <DropdownSimpleFetch
              fluid
              selection
              label="Regimen"
              value={values.idRegimen}
              onChange={(e, { value }) => setFieldValue('idRegimen', value)}
              placeholder="Regimen"
              featherService="catalogo-regimen-fiscal"
              columName="Descripcion"
              columValue="c_RegimenFiscal"
              mapValues={(value) => {
                return {
                  key: Math.random(),
                  text: `${value['c_RegimenFiscal']} - ${value['Descripcion']}`,
                  value: value['c_RegimenFiscal'],
                };
              }}
              error={
                errors.idRegimen &&
                touched.idRegimen && { content: errors.idRegimen }
              }
            />

            <Form.Input
              placeholder="Lugar de expedición"
              label="Lugar de expedición"
              name="LugarExpedicion"
              value={values.LugarExpedicion}
              onChange={handleChange}
              onBlur={handleBlur}
              error={
                errors.LugarExpedicion &&
                touched.LugarExpedicion && {
                  content: errors.LugarExpedicion,
                }
              }
            />
          </Form.Group>
        </Form>
        {/* <Form.Group widths={'equal'}>
        <Form.Input
        placeholder="Calle"
          label="Calle"
          name="EmisorCalle"
          value={values.EmisorCalle}
          onChange={handleChange}
          onBlur={handleBlur}
          error={
            errors.EmisorCalle &&
            touched.EmisorCalle && { content: errors.EmisorCalle }
          }
        />
      </Form.Group>

      <Form.Group widths={'equal'}>
        <Form.Input
          placeholder="Número Exterior"
          label="Número Exterior"
          name="EmisorNumExterior"
          value={values.EmisorNumExterior}
          onChange={handleChange}
          onBlur={handleBlur}
          error={
            errors.EmisorNumExterior &&
            touched.EmisorNumExterior && { content: errors.EmisorNumExterior }
          }
        />
        <Form.Input
          placeholder="Numero Interior"
          label="Numero Interior"
          name="EmisorNumInterior"
          value={values.EmisorNumInterior}
          onChange={handleChange}
          onBlur={handleBlur}
          // error={
          //   validations.name && { content: 'Required idTipoComprobante' }
          // }
        />
        <Form.Input
          placeholder="Referencia"
          label="Referencia"
          name="EmisorReferencia"
          value={values.EmisorReferencia}
          onChange={handleChange}
          onBlur={handleBlur}
          // error={validations.lastName && { content: 'Required Last Name' }}
        />
      </Form.Group>
      <Form.Group widths={'equal'}>
        <DropdownSearchFetch
          fluid
          selection
          label="País"
          value={values.EmisorPais}
          onChange={(e, { value }) => setFieldValue('EmisorPais', value)}
          placeholder="País"
          featherService="catalogo-pais"
          columName="Descripcion"
          columValue="c_Pais"
          mapValues={(value) => {
            return {
              key: Math.random(),
              text: `${value['c_Pais']} - ${value['Descripcion']}`,
              value: value['c_Pais'],
            };
          }}
        />

        <DropdownSearchFetch
          fluid
          selection
          label="Estado"
          value={values.EmisorEstado}
          onChange={(e, { value }) => setFieldValue('EmisorEstado', value)}
          placeholder="Estado"
          featherService="catalogo-estado"
          columName="Nombre"
          columValue="c_Estado"
          mapValues={(value) => {
            return {
              key: Math.random(),
              text: `${value['c_Estado']} - ${value['Nombre']}`,
              value: value['c_Estado'],
            };
          }}
        />
        <DropdownSearchFetch
          fluid
          selection
          label="Municipio"
          value={values.EmisorMunicipio}
          onChange={(e, { value }) => setFieldValue('EmisorMunicipio', value)}
          placeholder="Municipio"
          featherService="catalogo-municipio"
          columName="Descripcion"
          columValue="CmunicipioID"
          mapValues={(value) => {
            return {
              key: Math.random(),
              text: `${value['Descripcion']}`,
              value: value['CmunicipioID'],
            };
          }}
        />
      </Form.Group>
      <Form.Group widths={'equal'}>
        <DropdownSearchFetch
          fluid
          selection
          label="Localidad"
          value={values.EmisorLocalidad}
          onChange={(e, { value }) => setFieldValue('EmisorLocalidad', value)}
          placeholder="Localidad"
          featherService="catalogo-localidad"
          columName="Descripcion"
          columValue="ClocalidadID"
          mapValues={(value) => {
            return {
              key: Math.random(),
              text: `${value['Descripcion']}`,
              value: value['ClocalidadID'],
            };
          }}
        />
        <DropdownSearchFetch
          fluid
          selection
          label="Código Postal"
          value={values.EmisorCodPos}
          onChange={(e, { value }) => setFieldValue('EmisorCodPos', value)}
          placeholder="Código Postal"
          featherService="catalogo-codigo-postal"
          columName="c_CodigoPostal"
          columValue="c_CodigoPostal"
          mapValues={(value) => {
            return {
              key: Math.random(),
              text: `${value['c_CodigoPostal']}`,
              value: value['c_CodigoPostal'],
            };
          }}
        />
        <DropdownSearchFetch
          fluid
          selection
          label="Colonia"
          value={values.EmisorColonia}
          onChange={(e, { value }) => setFieldValue('EmisorColonia', value)}
          placeholder="Colonia"
          featherService="catalogo-colonia"
          columName="Nombre"
          columValue="CcoloniaID"
          mapValues={(value) => {
            return {
              key: Math.random(),
              text: `${value['Nombre']}`,
              value: value['CcoloniaID'],
            };
          }}
        />
      </Form.Group> */}
      </Segment>
    </>
  );
};

export default forwardRef((props, ref) => (
  <FormEmisor {...props} innerRef={ref} />
));
