import React, { forwardRef, useState } from 'react';
import { Button, Modal } from 'semantic-ui-react';
import DropdownSearchTripFetch from '../../../components/Formik/DropdownSearchTripFetch';
import { Dropzone, FileItem } from '@dropzone-ui/react';
import * as xlsx from 'xlsx';

function exampleReducer(state, action) {
  switch (action.type) {
    case 'OPEN_MODAL':
      return { open: true, dimmer: action.dimmer };
    case 'CLOSE_MODAL':
      return { open: false };
    default:
      throw new Error();
  }
}

const ModalTrip = ({ formik, verViaje }) => {
  let { values, errors, touched, handleChange, handleBlur, setFieldValue } =
    formik;

  let [state, dispatch] = React.useReducer(exampleReducer, {
    open: false,
    dimmer: undefined,
  });

  let { open, dimmer } = state;

  const [files, setFiles] = useState([]);
  const [dataFiles, setDataFiles] = useState();
  const updateFiles = (incommingFiles) => {
    //console.log('incomming files', incommingFiles);
    if (incommingFiles) {
      const reader = new FileReader();
      reader.onload = (e) => {
        const data = e.target.result;
        const workbook = xlsx.read(data, { type: 'array' });
        const sheetName = workbook.SheetNames[0];
        const worksheet = workbook.Sheets[sheetName];
        setDataFiles(xlsx.utils.sheet_to_json(worksheet));
      };
      reader.readAsArrayBuffer(incommingFiles[0].file);
    }
    setFiles(incommingFiles);
  };
  const handleDelete = (id) => {
    setFiles(files.filter((x) => x.id !== id));
  };

  return (
    <div>
      <div style={{ marginBottom: 10 }}>
        <Button onClick={() => dispatch({ type: 'OPEN_MODAL' })} type="button">
          Cargar Viaje
        </Button>
      </div>

      <Modal
        dimmer={dimmer}
        open={open}
        onClose={() => dispatch({ type: 'CLOSE_MODAL' })}
      >
        <Modal.Header>Importar viaje de Optima</Modal.Header>
        <Modal.Content>
          <div style={{ marginBottom: 10 }}>
            <DropdownSearchTripFetch
              fluid
              selection
              label="Número de viaje"
              value={values.TripID}
              onChange={(e, { value }) => setFieldValue('TripID', value)}
              placeholder="Número de viaje"
              featherService="optima-trips"
              columName="TripNumber"
              columValue="TripID"
              mapValues={(value) => {
                return {
                  key: Math.random(),
                  text: `${value['TripNumber']}`,
                  value: value['TripID'],
                };
              }}
              error={errors.TripID && { content: errors.TripID }}
            />
          </div>
          <Dropzone
            onChange={updateFiles}
            value={files}
            onClean
            // accept={'image/jpeg,.ts, video/*'}
            label={'Arrastrar aquí o dar click para seleccionar. '}
            minHeight={'195px'}
            maxHeight={'500px'}
            disableScroll
          >
            {files.map((file) => (
              <FileItem
                {...file}
                key={file.id}
                onDelete={handleDelete}
                alwaysActive
                preview
                info
                resultOnTooltip
              />
            ))}
          </Dropzone>
        </Modal.Content>
        <Modal.Actions>
          <Button negative onClick={() => dispatch({ type: 'CLOSE_MODAL' })}>
            Cancelar
          </Button>
          <Button
            positive
            onClick={() => {
              dispatch({ type: 'CLOSE_MODAL' });
              verViaje(values.TripID, formik, dataFiles);
            }}
          >
            Importar
          </Button>
        </Modal.Actions>
      </Modal>
    </div>
  );
};

export default forwardRef((props, ref) => (
  <ModalTrip {...props} innerRef={ref} />
));
