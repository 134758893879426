import React from 'react';
import PropTypes from 'prop-types';
import { Grid, GridColumn, GridRow, Button, Dropdown } from 'semantic-ui-react';
import styled from 'styled-components';
import ActivityIndicator from '../../../../components/ActivityIndicator';

const TableNavigation = ({
  isDissociating,
  isBusy,
  selection,
  onOnBulk
}) => {
  const handleUnassociateClick = event => onOnBulk('unassociate', null);

  return (
    <Grid columns={2} verticalAlign="middle" padded="vertically">
      <GridRow stretched>
        <GridColumn>
          {(isDissociating && (
            <ActivityIndicator title="Applying bulk action..." />
          )) ||
            (isBusy && <ActivityIndicator title="Loading data..." />) || (
              <Instructions>
                Select one or more Shipped Items to do actions
              </Instructions>
            )}
        </GridColumn>

        <GridColumn textAlign="right">
          <div>
            <Dropdown
              text="Bulk Actions"
              as={Button}
              primary
              compact
              style={{ marginRight: 0 }}
              disabled={(selection && selection.length === 0) || isDissociating || isBusy}
            >
              <Dropdown.Menu>
                <Dropdown.Item
                  text="Unassociate"
                  style={{ color: 'red' }}
                  onClick={handleUnassociateClick}
                />
              </Dropdown.Menu>
            </Dropdown>
          </div>
        </GridColumn>
      </GridRow>
    </Grid>
  );
};

const Instructions = styled.div`
  font-family: 'Roboto', sans-serif;
  font-size: 14px;
  color: rgba(0, 0, 0, 0.6);
  letter-spacing: 0.25px;
  text-align: left;
  line-height: 20px;
`;

TableNavigation.propTypes = {
  isDissociating: PropTypes.bool,
  isBusy: PropTypes.bool,
  selection: PropTypes.array,
  onOnBulk: PropTypes.func
};

export default TableNavigation;