import swal from 'sweetalert';
import _ from 'lodash';
import downloadFile from '../../../../../functions/downloadFile';
import { number } from '../../../../../components/AgGridColumns';

const ExcelJS = require('exceljs');

export default async (exportData) => {
  console.debug('exportData', exportData.some(item => item.tags.includes('EXTERNAL')));
  if (exportData && exportData.length > 0) {
    try {
      let mappedData = exportData.map((item) => {
        const product = (item && item.product && item.product.length && item.product[0]) || null;
        const dailylog = (item && item.dailylog && item.dailylog.length && item.dailylog[0]) || null;
        const cost = (product && number.valueFormatter({ value: product.cost })) || 'N/A';
        const usefullZip = item.zip
          ? String(item.zip).length > 5
            ? String(item.zip).slice(0, 5)
            : String(item.zip)
          : item.zip;

        return {
          'Create Date': item.createdDate ? new Date(item.createdDate) : 'N/A',
          BIN: ((dailylog && dailylog.bin) || '-').toLowerCase(),
          'Order Number': item.orderNumber
            ? isNaN(Number(item.orderNumber))
              ? item.orderNumber
              : Number(item.orderNumber)
            : '-',
          'Tracking Number': item.trackingNumber
            ? isNaN(Number(item.trackingNumber))
              ? item.trackingNumber
              : Number(item.trackingNumber)
            : '-',
          Name: (product && product.name) || '-',
          Address: item.address ? item.address : '-',
          City: item.city ? item.city : '-',
          State: item.state ? item.state : '-',
          Zip: usefullZip ? usefullZip : '-',
          DESC: (product && product.description) || '-',
          'sum Cost': cost === 'N/A' ? 0 : cost,
          Country: item.country ? item.country : '-',
          Fiber: (product && product.fiber) || '-',
          '3PL Customer': item.customer3PL ? item.customer3PL : '-',
        };
      });

      const columns = Object.keys(mappedData[0]).map((field) => {
        return { header: field, key: field, width: 30 };
      }, {});

      const wb = new ExcelJS.Workbook();
      const ws = wb.addWorksheet('dATAbASE');
      ws.columns = columns;
      ws.addRows(mappedData);
      ws.getColumn('C').numFmt = '###0';
      ws.getColumn('C').alignment = { horizontal: 'left' };
      ws.getColumn('D').numFmt = '###0';
      ws.getColumn('D').alignment = { horizontal: 'left' };

      const groupData = _.chain(mappedData)
        .groupBy('BIN')
        .map((value, key) => ({ BIN: key, Items: value }))
        .value();

      groupData.forEach(item => {
        const wsBin = wb.addWorksheet(item.BIN);
        wsBin.columns = columns;
        wsBin.addRows(item.Items);
        wsBin.getColumn('C').numFmt = '###0';
        wsBin.getColumn('C').alignment = { horizontal: 'left' };
        wsBin.getColumn('D').numFmt = '###0';
        wsBin.getColumn('D').alignment = { horizontal: 'left' };
      });

      const buf = await wb.xlsx.writeBuffer();
      downloadFile(
        buf,
        'Bins.xlsx',
        'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
      );
    } catch (error) {
      console.error('error', error);
      swal(`Could Not Export`, error, 'error');
    }
  } else {
    swal(`Could Not Export`, `No records were the Driver Guide`, 'error');
  }
};
