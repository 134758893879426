import swal from 'sweetalert';
import _ from 'lodash';
import moment from 'moment';
import downloadFile from '../../../../../functions/downloadFile';
import { number } from '../../../../../components/AgGridColumns';

const ExcelJS = require('exceljs');

export default async (exportData) => {
  if (exportData && exportData.length > 0) {
    try {
      let mappedData = await exportData.map((item) => {
        const product =
          (item && item.product && item.product.length && item.product[0]) ||
          null;
        const cost =
          (product && number.valueFormatter({ value: product.cost })) || 'N/A';
        const usefullZip = item.zip
          ? String(item.zip).length > 5
            ? String(item.zip).slice(0, 5)
            : String(item.zip)
          : item.zip;

        return {
          'Is 321': (product && product.is321) || '-',
          'EXPORT CODE': (product && product.exportCode) || '-',
          'FIBER/CONTENT': (product && product.fiber) || '-',
          COO: (product && product.coco) || '-',
          DESCRIPTION: (product && product.description) || '-',
          COST: cost === 'N/A' ? 0 : cost,
          'Create Date': item.createdDateTime
            ? moment
                .utc(item.createdDateTime)
                .local()
                .format('DD/MM/YYYY HH:mm:ss')
            : 'N/A',
          'Product Name': (product && product.name) || '-',
          SKU:
            product && product.sku
              ? isNaN(Number(product.sku))
                ? product.sku
                : Number(product.sku)
              : '-',
          'QTY Shipped': item.qtyShipped ? item.qtyShipped : '-',
          Price: item.price ? item.price : '-',
          'Shipped Subtotal': item.shippedSubtotal ? item.shippedSubtotal : '-',
          '3PL Customer': item.customer3PL ? item.customer3PL : '-',
          'Order Number': item.orderNumber
            ? isNaN(Number(item.orderNumber))
              ? item.orderNumber
              : Number(item.orderNumber)
            : '-',
          'Tracking Number': item.trackingNumber
            ? isNaN(Number(item.trackingNumber))
              ? item.trackingNumber
              : Number(item.trackingNumber)
            : '-',
          Email: item.email ? item.email : '-',
          Name: (product && product.name) || '-',
          Address: item.address ? item.address : '-',
          City: item.city ? item.city : '-',
          Zip: usefullZip
            ? isNaN(Number(usefullZip))
              ? usefullZip
              : Number(usefullZip)
            : '-',
          State: item.state ? item.state : '-',
          Country: item.country ? item.country : '-',
          Phone: item.phone
            ? isNaN(Number(item.phone))
              ? item.phone
              : Number(item.phone)
            : '-',
          'Product Tariff Code': item.productTariffCode
            ? item.productTariffCode
            : '-',
          'Customs Value': item.customsValues ? item.customsValues : '-',
        };
      });

      const columns = Object.keys(mappedData[0]).map((field) => {
        return { header: field, key: field, width: 30 };
      }, {});

      //Internacional y not 321 5-18-20
      const exportName =
        'Internacional ' + moment().startOf('day').format('M-D-YY') + '.xlsx';

      const wb = new ExcelJS.Workbook();
      const ws = wb.addWorksheet('All international');
      ws.columns = Object.keys(mappedData[0]).map((field) => {
        return { header: field, key: field, width: 30 };
      }, {});
      ws.addRows(mappedData);
      ws.autoFilter = {
        from: 'G1',
        to: 'Y1',
      };
      ws.getColumn('F').numFmt = '$#,##0.00;[Red]-$#,##0.00';
      ws.getColumn('G').alignment = { horizontal: 'right' };
      ws.getColumn('I').numFmt = '###0';
      ws.getColumn('N').numFmt = '###0';
      ws.getColumn('N').alignment = { horizontal: 'left' };
      ws.getColumn('O').numFmt = '###0';
      ws.getColumn('O').alignment = { horizontal: 'left' };
      ws.getColumn('T').numFmt = '###0';
      ws.getColumn('W').numFmt = '###0';

      const groupData = _.chain(mappedData)
        .groupBy('Is 321')
        .map((value, key) => ({ Is321: key, Items: value }))
        .value();
      groupData.forEach((item) => {
        const wsBin = wb.addWorksheet('is321 ' + item.Is321);
        wsBin.columns = columns;
        wsBin.addRows(item.Items);
        wsBin.getColumn('F').numFmt = '$#,##0.00;[Red]-$#,##0.00';
        wsBin.getColumn('G').alignment = { horizontal: 'right' };
        wsBin.getColumn('I').numFmt = '###0';
        wsBin.getColumn('N').numFmt = '###0';
        wsBin.getColumn('N').alignment = { horizontal: 'left' };
        wsBin.getColumn('O').numFmt = '###0';
        wsBin.getColumn('O').alignment = { horizontal: 'left' };
        wsBin.getColumn('T').numFmt = '###0';
        wsBin.getColumn('W').numFmt = '###0';
      });

      const buf = await wb.xlsx.writeBuffer();
      downloadFile(
        buf,
        exportName,
        'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
      );
    } catch (error) {
      console.error('error', error);
      swal(`Could Not Export`, error, 'error');
    }
  } else {
    swal(`Could Not Export`, `No records were the Internacional`, 'error');
  }
};
