import React from 'react';
import DropdownSearchFetch from '../../../components/Formik/DropdownSearchFetch';
import feathers, { useAuth } from '../../../services/feathers';

function DropdownAutoTransporte({ formik, index, autoTransporte }) {
  let { setFieldValue } = formik;
  const auth = useAuth();

  const loadTransporte = async (id) => {
    const service = feathers.service('transportes');
    let result = await service.find({
      query: {
        id: id,
      },
    });
    if (result.data.length) return result.data[0];
  };

  const getTipoPermiso = async (id) => {
    const service = feathers.service('catalogo-tipo-permiso');
    let result = await service.find({
      query: {
        CTpermisoID: id,
      },
    });

    if (result.data.length) return result.data[0].c_TipoPermiso;
  };

  const getConfigAuto = async (id) => {
    const service = feathers.service('catalogo-config-autotransporte');
    let result = await service.find({
      query: {
        CcAutoTransID: id,
      },
    });
    if (result.data.length) return result.data[0].c_ConfigAutotransporte;
  };

  //console.log("Auto transports",autoTransporte)
  let query= (columName, columValue, searchQuery) => {
    let newQuery = {
      $or: [
        { [columName]: { $like: '%' + searchQuery + '%' } },
        { [columValue]: { $like: '%' + searchQuery + '%' } },
      ],
      $limit: 10,
      idOrganizacion: auth.user.client._id
    };
  
    return newQuery;
  };

  let queryInit = (columName, columValue, value) => {
    let newQuery;
    if (value) {
      newQuery = {
        [columValue]: { $like: value },
        $limit: 10,
        idOrganizacion: auth.user.client._id
      };
    } else {
      newQuery = {
        $limit: 10,
        idOrganizacion: auth.user.client._id
      };
    }
  
    return newQuery;
  };
  return (
    <DropdownSearchFetch
      fluid
      selection
      label="Transporte"
      onChange={async (e, { value }) => {
        setFieldValue(`autoTransportes.${index}.id`, value);
        let transporte = await loadTransporte(value);
        if (transporte) {
          // console.log(transporte);
          setFieldValue(
            `PermSCT`,
            await getTipoPermiso(transporte.TipoPermisoSCT)
          );
          setFieldValue(
            `AutoConfigVehicular`,
            await getConfigAuto(transporte.ConfiguracionAutotransporte)
          );
          setFieldValue(`NumPermitoSCT`, transporte.NumPermisoSCT);
          setFieldValue(`AutoPlacaVM`, transporte.Placa);
          setFieldValue(`AutoAnioModeloVM`, transporte.AnioModelo);
          setFieldValue(
            `AseguraRespCivil`,
            transporte.AseguradoraResponsabilidadCivil
              ? transporte.AseguradoraResponsabilidadCivil
              : ''
          );
          setFieldValue(
            `PolizaRespCivil`,
            transporte.PolizaResponsabilidadCivil
              ? transporte.PolizaResponsabilidadCivil
              : ''
          );
          setFieldValue(
            `AseguraMedAmbiente`,
            transporte.AseguradoraMedioAmbiente
              ? transporte.AseguradoraMedioAmbiente
              : ''
          );
          setFieldValue(
            `PolizaMedAmbiente`,
            transporte.PolizaMedioAmbiente ? transporte.PolizaMedioAmbiente : ''
          );
          setFieldValue(
            `AseguraCarga`,
            transporte.AseguradoraCarga ? transporte.AseguradoraCarga : ''
          );
          setFieldValue(
            `PolizaCarga`,
            transporte.PolizaCarga ? transporte.PolizaCarga : ''
          );
          setFieldValue(
            `PrimaSeguro`,
            transporte.PrimaSeguro ? transporte.PrimaSeguro : 0.0
          );
        } else {
          setFieldValue(`PermSCT`, '');
          setFieldValue(`AutoConfigVehicular`, '');
          setFieldValue(`NumPermitoSCT`, '');
          setFieldValue(`AutoPlacaVM`, '');
          setFieldValue(`AutoAnioModeloVM`, '');
          setFieldValue(`AseguraRespCivil`, '');
          setFieldValue(`PolizaRespCivil`, '');
          setFieldValue(`AseguraMedAmbiente`, '');
          setFieldValue(`PolizaMedAmbiente`, '');
          setFieldValue(`AseguraCarga`, '');
          setFieldValue(`PolizaCarga`, '');
          setFieldValue(`PrimaSeguro`, 0.0);
        }
      }}
      placeholder="Placa"
      featherService="transportes"
      columName="PlacaNumeroLicencia"
      columValue="id"
      mapValues={(value) => {
        return {
          key: Math.random(),
          text: `${value['Nombre']} - ${value['Placa']}`,
          value: value['id'],
        };
      }}
      queryWithSearch = {query}
      queryInit ={queryInit}
    />
  );
}

export default DropdownAutoTransporte;
