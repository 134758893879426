import React from 'react';
import styled from 'styled-components';
import Footer from '../components/FooterDefault';

export default props => {
  return (
    <Container>
      <MainContent>{props.children}</MainContent>
      <Footer />
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  flex-direction: column;
  min-height: 100vh;
`;

const MainContent = styled.main``;
