import React from 'react';
import PropTypes from 'prop-types';
import { Input } from 'semantic-ui-react';
import Popup from './Popup';

const ValueEditor = ({
  fieldData,
  handleOnChange,
  operator,
  title,
  value,
  inputType,
}) => {
  const handleChange = (_, { value }) => {
    handleOnChange && handleOnChange(value);
  };

  return (
    <>
      {['null', 'notNull'].includes(operator) ? (
        <></>
      ) : (
        <Popup content={title}>
          <Input
            placeholder={fieldData.label}
            onChange={handleChange}
            value={value}
            type={inputType}
          />
        </Popup>
      )}
    </>
  );
};

ValueEditor.propTypes = {
  fieldData: PropTypes.object.isRequired,
  handleOnChange: PropTypes.func.isRequired,
  operator: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired,
  inputType: PropTypes.string.isRequired,
};

export default ValueEditor;
