import React, { useReducer, useEffect } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import DrawerFiltersWrapper from '../../components/DrawerFiltersWrapper';
import SemanticInput from '../../components/SemanticInput';
import ButtonsFilter from '../../components/ButtonsFilter';
import SemanticDropdown from '../../components/SemanticDropdown';
import statusOptions from './status';

const queryInit = {
  search: '',
  status: null,
  tags: ''
};

const reducerQuery = (prevState, { name, value, type, query }) => {
  if (type === 'init' && query) {
    return query;
  } else if (name) {
    return { ...prevState, [name]: value }
  }

  return prevState;
};

const Filters = ({
  isBusy,
  query: base,
  setQuery
}) => {
  // States
  const [query, dispatchQuery] = useReducer(reducerQuery, { ...queryInit });

  // Effects
  useEffect(() => {
    // console.count('Filters query');
    const sendQuery = () => {
      handleSearchClick()
    }

    sendQuery();
  }, []);

  // Handles
  const handleChangeInput = (_, { name, value }) => dispatchQuery({ name, value });

  const handleSearchClick = () => {
    // Create query for feathers
    const queryMap = {
      ...query
    };
    // console.debug('queryMap', JSON.stringify(queryMap, null, 2));

    // Send query to Packing
    setQuery && setQuery(queryMap);
  };

  const handleClearClick = (event, data) => {
    dispatchQuery({
      type: 'init',
      query: {
        ...queryInit
      }
    });
  };

  return (
    <>
      {/* <pre>query: {JSON.stringify(query, null, 2)}</pre> */}

      <DrawerFiltersWrapper>
        <FiltersGrid>
          <SemanticInput
            name="search"
            label="Search"
            value={query.search}
            onChange={handleChangeInput}

            fluid
            style={{ gridArea: 'search' }}
            disabled={isBusy}
          />

          <SemanticDropdown
            name="status"
            label="Status"
            value={query.status}
            onChange={handleChangeInput}

            fluid
            selection
            options={statusOptions}
            renderLabel={(item) => item && item.text}
            style={{ gridArea: 'status' }}
            disabled={isBusy}
          />

          <SemanticInput
            name="tags"
            label="Tags"
            value={query.tags}
            onChange={handleChangeInput}

            fluid
            style={{ gridArea: 'tags' }}
            disabled={isBusy}
          />

          <ButtonsFilter
            isBusy={isBusy}
            onSearchClick={handleSearchClick}
            onClearClick={handleClearClick}
          />
        </FiltersGrid>
      </DrawerFiltersWrapper>
    </>
  );
};

const FiltersGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-template-rows: auto;
  grid-gap: 1em;
  grid-template-areas: 'search . . buttons';
`;

Filters.propTypes = {
  isBusy: PropTypes.bool,
  query: PropTypes.object,
  setQuery: PropTypes.func
};

export default Filters;