export const fields = [
  { type: 'text', name: 'shipping_name', label: 'Shipping name' },
  { type: 'text', name: 'profile', label: 'Profile' },
  { type: 'text', name: 'shop_name', label: 'Shop name' },
  {
    type: 'select',
    name: 'to_address.typeOfAddress',
    label: 'Type of Address',
  },
  { type: 'text', name: 'to_address.name', label: 'Address' },
  { type: 'text', name: 'to_address.company_name', label: 'Company name' },
  { type: 'text', name: 'to_address.address_1', label: 'Address 1' },
  { type: 'text', name: 'to_address.address_2', label: 'Address 2' },
  { type: 'text', name: 'to_address.email', label: 'Email' },
  { type: 'text', name: 'to_address.city', label: 'City' },
  { type: 'text', name: 'to_address.state', label: 'State' },
  { type: 'text', name: 'to_address.zip', label: 'Zip' },
  { type: 'text', name: 'to_address.country', label: 'Country' },
  { type: 'text', name: 'to_address.phone', label: 'Phone' },
  { type: 'number', name: 'weight', label: 'Weight (oz)' },
  { type: 'number', name: 'width', label: 'Width (inch)' },
  { type: 'number', name: 'length', label: 'Length (inch)' },
  { type: 'number', name: 'height', label: 'Height (inch)' },
  { type: 'number', name: 'line_items.quantity', label: 'Quantity' },
  { type: 'text', name: 'line_items.sku', label: 'Sku' },
  { type: 'number', name: 'line_items.price', label: 'Price' },
  { type: 'text', name: 'line_items.product_name', label: 'Product name' },
  { type: 'text', name: 'box_code', label: 'Box code' },
  {
    type: 'number',
    name: 'number_of_different_products',
    label: 'Number of different products',
  },
];

export const operators = [
  { name: '==', label: 'Same' },
  { name: '!=', label: 'Different' },
  { name: 'some', label: 'Some' },
  { name: 'none', label: 'None' },
  { name: '>', label: 'Greater than' },
  { name: '>=', label: 'Greater than or equal' },
  { name: '<', label: 'Smaller than' },
  { name: '<=', label: 'Less than or equal to' },
  { name: 'in', label: 'In' },
  { name: 'notIn', label: 'Not in' },
  { name: 'contains', label: 'Contains' },
];

export const defaultQuery = {
  combinator: 'and',
  not: false,
  rules: [],
};

const tryArrayToString = (value) => {
  if (Array.isArray(value)) {
    return value.join(', ');
  }

  return value;
};

const tryStringToArray = (value) => {
  if (typeof value === 'string' && value.length) {
    return value
      .split(',')
      .map((val) => val.trim())
      .filter((val) => val.length);
  }

  return value;
};

const getObjVar = (field) => ({ var: field || '' });

// Formato que se guarda en MongoDB
export const toRule = (query, level) => {
  if (query) {
    if (query.field && query.value && query.operator) {
      const { field: fieldName, value, operator } = query;
      let rule = {
        [operator]: [getObjVar(fieldName)],
      };

      switch (operator) {
        case 'in':
        case 'notIn':
          if (
            ['line_items.sku', 'line_items.product_name'].includes(fieldName)
          ) {
            const optRule = operator === 'in' ? 'some' : 'none';
            rule = {
              [optRule]: [
                ...rule[operator],
                {
                  in: [getObjVar(), tryStringToArray(value)],
                },
              ],
            };
          } else {
            rule[operator].push(tryStringToArray(value));
            if (operator === 'notIn') {
              rule = {
                '!': [
                  {
                    in: [...rule[operator]],
                  },
                ],
              };
            }
          }
          break;

        case 'some':
        case 'none':
          rule[operator].push({
            '==': [getObjVar(), value],
          });
          break;

        case 'contains':
          const field = fields.find(({ name }) => name === fieldName);
          if (field && field.type === 'text') {
            if (
              ['line_items.sku', 'line_items.product_name'].includes(fieldName)
            ) {
              rule = {
                some: [
                  getObjVar(fieldName),
                  {
                    in: [value, getObjVar()],
                  },
                ],
              };
            } else {
              rule = {
                in: [value, getObjVar(fieldName)],
              };
            }
          }
          break;

        default:
          rule[operator].push(value);
          break;
      }

      return rule;
    } else if (
      query.combinator &&
      Array.isArray(query.rules) &&
      query.rules.length
    ) {
      // Es una combinador
      const { combinator, rules: rulesQuery } = query;
      const rules = rulesQuery.map((rule) => toRule(rule, (level || 0) + 1));

      if (rules.some((rule) => rule === null)) {
        return null;
      } else if (!level && rules.length === 1) {
        return rules[0];
      } else {
        return {
          [combinator]: rules,
        };
      }
    }
  }

  return null;
};

// Formato que usa Query Builder
export const toQuery = (rule, level) => {
  if (rule) {
    if (Array.isArray(rule.and) || Array.isArray(rule.or)) {
      // Regla de agrupamiento
      const combinator = Array.isArray(rule.and) ? 'and' : 'or';
      const { [combinator]: rules } = rule;

      return {
        combinator,
        not: false,
        rules:
          rules && rules.length
            ? rules.map((rule) => toQuery(rule, (level || 0) + 1))
            : [],
      };
    } else {
      // Regla sencilla
      const operator = Object.getOwnPropertyNames(rule)[0];
      if (operator) {
        const { [operator]: ruleByOperator } = rule;
        const ruleQuery = {
          field: null,
          operator,
          value: null,
        };

        switch (operator) {
          case 'in': // Este caso es para diferenciar el operador in de contains
            if (ruleByOperator.length === 2) {
              if (ruleByOperator[0].var) {
                ruleQuery.field = ruleByOperator[0].var;
                ruleQuery.value = tryArrayToString(ruleByOperator[1]);
              } else if (ruleByOperator[1].var) {
                ruleQuery.field = ruleByOperator[1].var;
                ruleQuery.operator = 'contains';
                ruleQuery.value = tryArrayToString(ruleByOperator[0]);
              }
            }
            break;

          case '!': // Este caso es para obtener la regla con operador notIn
            if (
              ruleByOperator.length === 1 &&
              ruleByOperator[0].in &&
              ruleByOperator[0].in.length === 2
            ) {
              ruleQuery.field = ruleByOperator[0].in[0].var;
              ruleQuery.operator = 'notIn';
              ruleQuery.value = tryArrayToString(ruleByOperator[0].in[1]);
            }
            break;

          case 'some':
          case 'none': // Este caso es para diferenciar los operadores some y none de contains
            if (ruleByOperator.length === 2) {
              ruleQuery.field = ruleByOperator[0].var;
              const subOperator = ruleByOperator[1];
              if (subOperator) {
                if (subOperator['=='] && subOperator['=='].length === 2) {
                  ruleQuery.value = subOperator['=='][1];
                } else if (subOperator.in && subOperator.in.length === 2) {
                  if (
                    typeof subOperator.in[0] === 'string' &&
                    subOperator.in[1] &&
                    typeof subOperator.in[1].var === 'string'
                  ) {
                    ruleQuery.value = subOperator.in[0];
                    ruleQuery.operator = 'contains';
                  } else if (
                    subOperator.in[0] &&
                    typeof subOperator.in[0].var === 'string' &&
                    Array.isArray(subOperator.in[1])
                  ) {
                    ruleQuery.value = tryArrayToString(subOperator.in[1]);
                    ruleQuery.operator = operator === 'some' ? 'in' : 'notIn';
                  }
                }
              }
            }
            break;

          default:
            ruleQuery.field = ruleByOperator[0].var;
            ruleQuery.value = tryArrayToString(ruleByOperator[1]);
            break;
        }

        if (!level) {
          return { ...defaultQuery, rules: [ruleQuery] };
        } else {
          return ruleQuery;
        }
      }
    }
  }

  return defaultQuery;
};
