import React, { useState, useEffect } from 'react';
import SlidingPanel from '@bit/softwareland.flex.sl-sliding-panel';
import SectionSwitch from './SectionSwitch';
import swal from 'sweetalert';
import feathers from '../../../services/feathers';
import feathersRest from '../../../services/feathersRest';
import moment from 'moment';
import { pluralize } from '../../../functions/common';
import filterShippedItems from './functions/FilterShippedItems';
import ModalBin from './functions/ModalBin';
import permissions from './ImportShippedItems/Import/permissions.json';

const valueGetter = (parent, field) =>
  `data && data.${parent} && data.${parent}.length && data.${parent}[0] && data.${parent}[0].${field}`;
const valueProductGetter = (field) => valueGetter('product', field);
const valueDailyLogGetter = (field) => valueGetter('dailylog', field);

const today = moment().format('YYYY-MM-DDTHH:mm:ss');

const Drawer = ({
  openPanel,
  setOpenPanel,
  currentConsolidation,
  setCurrentConsolidation,
  getConsolidationData,
  drawerTitle,
  handleBackClick,
}) => {
  const [isBusy, setIsBusy] = useState(false);
  const [isDissociating, setIsDissociating] = useState(false);
  const [currentSection, setCurrentSection] = useState(
    'FORM_CONSOLIDATED_CARGO'
  );

  const [openImportModal, setOpenImportModal] = useState(false);
  const [filter, setFilter] = useState(null);
  const [inputs, setInputs] = useState({
    date:
      (currentConsolidation &&
        currentConsolidation.shipped_date &&
        moment(currentConsolidation.shipped_date).format(
          'YYYY-MM-DDTHH:mm:ss'
        )) ||
      today,
  });

  const [selection, setSelection] = useState([]);

  let drawerTitles = {
    FORM_CONSOLIDATED_CARGO: {
      title: `${drawerTitle} Consolidated Cargo`,
      subtitle: '',
    },
    IMPORT_SHIPPED_ITEMS: {
      title: 'Import Shipped Items from Shiphero',
      subtitle: '',
    },
    VALIDATE_SHIPPED_ITEMS: {
      title: 'Validate Information',
      subtitle: '',
    },
  };

  let { title, subtitle } = drawerTitles[currentSection];

  let getData = async () => {
    if (currentConsolidation._id) {
      setIsBusy(true);

      let resultShippedItems = await feathersRest
        .service('shipped-items')
        .find({
          query: { consolidationId: currentConsolidation._id, extended: true },
        });

      setCurrentConsolidation({
        ...currentConsolidation,
        shippedItems: resultShippedItems,
        total_items: resultShippedItems.length,
      });
      setIsBusy(false);
    }
  };

  const handleChangeFilter = (query) => {
    // console.debug('handleChangeFilter', JSON.stringify(query, null, 2));
    setFilter(query);
  };

  useEffect(() => {
    const filterData = () => {
      if (filter && currentConsolidation && currentConsolidation.shippedItems) {
        setIsBusy(true);
        setSelection([]);

        // import to count the timeout when the filter finishes
        console.time('filter');

        let filteredData = filterShippedItems(
          currentConsolidation.shippedItems,
          filter
        );
        setCurrentConsolidation({
          ...currentConsolidation,
          shippedItems_filtered: filteredData,
        });

        // here the setIsBusy(false) gets a forzed timeout
        sleep(console.timeEnd('filter'));
      }
    };

    filterData();
  }, [currentConsolidation, filter, setCurrentConsolidation]);

  const sleep = (ms) =>
    setTimeout(() => {
      setIsBusy(false);
    }, ms);

  const handledOnClose = async () => {
    let confirmation = await swal('Do you want close this form?', {
      buttons: ['No', 'Yes'],
    });

    if (confirmation) {
      if (currentConsolidation._id) {
        let patch_total_items = await feathers
          .service('consolidated-cargo')
          .patch(currentConsolidation._id, {
            total_items: currentConsolidation.shippedItems.length,
          });
        if (patch_total_items) closeDrawer();
      } else closeDrawer();
    }
  };

  const closeDrawer = () => {
    setSelection([]);
    setCurrentConsolidation({});
    setCurrentSection('FORM_CONSOLIDATED_CARGO');
    setOpenPanel(false);
    getConsolidationData();
  };

  let handledOnSave = async (close = true) => {
    try {
      let date = moment(inputs.date).format('YYYY-MM-DDTHH:mm:ss');
      let msg = 'Do you want to IMPORT INFO NOW?';
      let passToImportconfirmation = false;

      if (!currentConsolidation._id) {
        let resultCreateConsolidation = await feathers
          .service('consolidated-cargo')
          .create({
            name: `consolidation-cargo-${inputs.date}-${inputs.time}`,
            shipped_date: new Date(date),
            status: 'DRAFT',
            total_items: 0,
            last_start_date: null,
            last_end_date: null,
          });
        if (resultCreateConsolidation) {
          setSelection([]);

          passToImportconfirmation = await swal(msg, {
            buttons: ['No', 'Yes'],
          });

          setCurrentConsolidation({
            ...resultCreateConsolidation,
            shippedItems: [],
          });
        }
      } else {
        const { shippedItems, total_items, last_start_date, last_end_date } =
          currentConsolidation;

        let resultCreateConsolidation = await feathers
          .service('consolidated-cargo')
          .patch(currentConsolidation._id, {
            name: `consolidation-cargo-${inputs.date}-${inputs.time}`,
            shipped_date: new Date(date),
            status: 'DRAFT',
            total_items: (shippedItems && shippedItems.length) || total_items,
            last_start_date,
            last_end_date,
          });

        if (resultCreateConsolidation && close) {
          setSelection([]);
          setCurrentConsolidation({});
          setCurrentSection('FORM_CONSOLIDATED_CARGO');
          setOpenPanel(false);
        }
      }
      getConsolidationData();

      passToImportconfirmation && redirectToImport();
    } catch (error) {
      console.error('error :>> ', error);
    }
  };

  const bulkAction = async (patchData) => {
    try {
      const result = await feathers
        .service('shipped-items')
        .patch({}, patchData);
      console.log(result);
      return true;
    } catch (error) {
      await swal('Error', 'Occurrence an error', 'error');
      console.debug('bulkAction :', error);
      return false;
    }
  };

  const handleOnBulk = async (action, subAction) => {
    let patchData = {
      shippedItems: selection,
    };
    let response = false;
    if (action === 'associate') {
      setIsDissociating(true);
      patchData.consolidationId = currentConsolidation._id;
      patchData.shippedItems = selection;
      patchData.status = 'ASSOCIATED';
      response = await bulkAction(patchData);
      await getData();
      // handledOnSave(false);
    } else if (action === 'unassociate') {
      const total = selection.length;
      let msg = `You are going to unassociate ${total} shipped ${pluralize(
        total,
        'item'
      )}`;
      let confirmation = await swal(msg, {
        buttons: ['No', 'Yes'],
      });
      if (confirmation) {
        setIsDissociating(true);
        patchData.unassociated = true;
        response = await bulkAction(patchData);

        setIsBusy(true);
        const newShippedItems = currentConsolidation.shippedItems.filter(
          (item) => !selection.includes(item._id)
        );

        // No es necesario llamar el filtrado, se hace automaticamente al actualizar los shippedItems
        setCurrentConsolidation({
          ...currentConsolidation,

          shippedItems: newShippedItems,
          shippedItems_filtered: [],
        });
        setIsBusy(false);
        // handledOnSave(false);
      }
    } else if (action === 'tag') {
      setIsDissociating(true);
      patchData.assign = true;
      patchData.tag = subAction;
      response = await bulkAction(patchData);

      setIsBusy(true);
      const newShippedItems = currentConsolidation.shippedItems.map((item) => {
        if (selection.includes(item._id)) {
          if (subAction) {
            if (subAction === '321' || subAction === 'No321') {
              if (subAction === '321' && !item.tags.includes('No321'))
                item.tags.push(subAction);
              else if (subAction === 'No321' && !item.tags.includes('321'))
                item.tags.push(subAction);
            } else item.tags.push(subAction);
          } else {
            item.tags = [];
          }
        }

        return item;
      });

      // Se reinician los shipped-items, para que se vuelvan a filtrar
      setCurrentConsolidation({
        ...currentConsolidation,

        shippedItems: newShippedItems,
        shippedItems_filtered: [],
      });
      setIsBusy(false);
    } else if (action === 'createBin' && selection.length) {
      try {
        const bin = await ModalBin();
        if (bin) {
          setIsDissociating(true);
          setIsBusy(true);

          const result = await feathers.service('daily-logs').create({
            shippedItems: selection,
            bin,
          });

          console.debug('result', result);

          if (result) {
            await swal('Done', 'Finished', 'success');

            const newShippedItems = currentConsolidation.shippedItems.map(
              (item) => {
                if (
                  selection.includes(item._id) &&
                  (!item.dailylog || !item.dailylog.length)
                ) {
                  item.dailylog = [{ bin }];
                }

                return item;
              }
            );

            // Se reinician los shipped-items, para que se vuelvan a filtrar
            setCurrentConsolidation({
              ...currentConsolidation,

              shippedItems: newShippedItems,
              shippedItems_filtered: [],
            });
          } else {
            throw new Error('Fail save Daily Log');
          }
        }
      } catch (err) {
        console.error('Error', err);

        swal({
          title: 'Error',
          text: 'Could not create Daily Logs',
          icon: 'error',
          buttons: {
            cancel: 'Close',
          },
        });
      } finally {
        setIsBusy(false);
      }
    }

    setIsDissociating(false);
    return response;
  };

  const redirectToImport = async () => {
    const PERMISSIONS_VALID = permissions.map(({ key }) => key);
    const result = await feathers.service('configuration').find({
      query: {
        name: { $in: PERMISSIONS_VALID },
        $select: ['value'],
        value: true,
      },
    });

    if (Array.isArray(result) && result.length) {
      setOpenImportModal(true);
      setCurrentSection('IMPORT_SHIPPED_ITEMS');
    } else {
      await swal({
        title: 'Access denied',
        text: 'The current configuration does not allow you to import, ask to the Admin',
        icon: 'warning',

        buttons: {
          cancel: 'Close',
        },
      });
    }
  };

  return (
    <SlidingPanel
      isOpen={openPanel}
      size={95}
      title={title}
      subtitle={subtitle}
      onClose={handledOnClose}
    >
      <SectionSwitch
        currentSection={currentSection}
        setCurrentSection={setCurrentSection}
        getConsolidationData={getConsolidationData}
        currentConsolidation={currentConsolidation}
        onChangeFilter={handleChangeFilter}
        handledOnSave={handledOnSave}
        setOpenPanel={setOpenPanel}
        getData={getData}
        isBusy={isBusy}
        inputs={inputs}
        setInputs={setInputs}
        handleOnBulk={handleOnBulk}
        selection={selection}
        setSelection={setSelection}
        isDissociating={isDissociating}
        handledOnClose={handledOnClose}
        valueProductGetter={valueProductGetter}
        valueDailyLogGetter={valueDailyLogGetter}
        openImportModal={openImportModal}
      />
    </SlidingPanel>
  );
};

export default Drawer;
