import React from 'react';
import { Grid, GridColumn, GridRow } from 'semantic-ui-react';
import styled from 'styled-components';

import ActivityIndicator from '../../../../components/ActivityIndicator';

const TableNavigation = ({ isDissociating, isBusy }) => {
  return (
    <Grid columns={2} verticalAlign="middle" padded="vertically">
      <GridRow stretched>
        <GridColumn>
          {isDissociating ? (
            <ActivityIndicator title="Importing..." />
          ) : isBusy ? (
              <ActivityIndicator title="Loading data please wait..." />
            ) : (
            <Instructions>
              Select one or more Shipped Items to do actions
            </Instructions>
          )}
        </GridColumn>
        <GridColumn textAlign="right">
          {/* <div>
            <Dropdown
              text="Bulk Actions"
              as={Button}
              primary
              compact
              style={{ marginRight: 0 }}
            >
              <Dropdown.Menu>
                <Dropdown.Item
                  text="Action 1"
                  onClick={() => alert('Action 1')}
                  disabled={false}
                />

                <Dropdown.Divider />

                <Dropdown.Item
                  text="Action 2"
                  onClick={() => alert('Action 2')}
                  disabled={false}
                />
              </Dropdown.Menu>
            </Dropdown>
          </div> */}
        </GridColumn>
      </GridRow>
    </Grid>
  );
};

const Instructions = styled.div`
  font-family: 'Roboto', sans-serif;
  font-size: 14px;
  color: rgba(0, 0, 0, 0.6);
  letter-spacing: 0.25px;
  text-align: left;
  line-height: 20px;
`;

export default TableNavigation;
