import React from 'react';
import PropTypes from 'prop-types';
import {
  Button,
  Dropdown,
  Grid,
  GridColumn,
  GridRow
} from 'semantic-ui-react';
import styled from 'styled-components';
import ActivityIndicator from '../../components/ActivityIndicator';

const TableNavigation = ({
  isBusy,
  haveSelected,
  onDeleteClick,
  messageAction
}) => {
  return (
    <Grid columns={2} verticalAlign="middle" padded="vertically">
      <GridRow stretched>
        <GridColumn>
          {isBusy ? (
            <ActivityIndicator title={messageAction || 'Loading...'} />
          ) : (
              <Instructions>
                Select one or more Daily Logs to do actions
              </Instructions>
            )}
        </GridColumn>
        <GridColumn textAlign="right">
          <div>
            <Dropdown
              text="Bulk Actions"
              as={Button}
              primary
              compact
              style={{ marginRight: 0 }}
              disabled={isBusy || !haveSelected}
            >
              <Dropdown.Menu>
                <Dropdown.Item
                  icon={{ name: 'delete', color: 'red' }}
                  text="Delete"
                  onClick={onDeleteClick}
                  disabled={false}
                />
              </Dropdown.Menu>
            </Dropdown>
          </div>
        </GridColumn>
      </GridRow>
    </Grid>
  );
};

TableNavigation.propTypes = {
  isBusy: PropTypes.bool,
  haveSelected: PropTypes.bool,
  onDeleteClick: PropTypes.func,
  messageAction: PropTypes.string
};

const Instructions = styled.div`
  font-family: 'Roboto', sans-serif;
  font-size: 14px;
  color: rgba(0, 0, 0, 0.6);
  letter-spacing: 0.25px;
  text-align: left;
  line-height: 20px;
`;

export default TableNavigation;