import React, { useEffect, useState } from 'react';
import { AgGridReact } from '@ag-grid-community/react';
import { AllModules } from '@ag-grid-enterprise/all-modules';
import MainFiltersWrapper from '@bit/softwareland.flex.main-filters-wrapper';
import _ from 'lodash';
import ParcelsFilters from './ParcelsFilters';
import { useDispatch, useSelector } from 'react-redux';
import {
  Button,
  Dropdown,
  Grid,
  GridColumn,
  GridRow,
  Modal,
} from 'semantic-ui-react';
import styled from 'styled-components';
import swal from 'sweetalert';
import {
  PortInternational,
  UnitOfMeasure,
} from '../../constants/ModalValidations';
import feathers from '../../services/feathers';
import {
  dataToSend,
  toggleValidationModal,
} from '../../stores/Parcels/ParcelsActions';
import '../../styles/styles.scss';
import ActivityIndicator from '../ActivityIndicator';
import ValidationsModal from '../ValidationsModal';
import TableContainer from '../TableContainer';
import Lottie from 'react-lottie';
import BagAnimation from '../../assets/animations/bag';
import { errorSwal, warningSwal } from './functions/alerts';
import {
  allowedCities,
  allowedData,
  allowedStores,
  getOnlyIds,
} from './functions/validations-creating-offers';
import { valueFormatter, number, price, date } from '../AgGridColumns';

const ParcelsImport = (props) => {
  const service = feathers.service('parcels');

  const dispatch = useDispatch();
  const [data, setData] = useState([]);
  const [isBusy, setIsBusy] = useState(false);
  const [creatingOffer, setCreatingOffer] = useState(false);
  const [selection, setSelection] = useState([]);
  const [columnDefs, setColumnDefs] = useState([]);
  const [columnGroupDef, setColumnGroupDef] = useState({});
  const { validationModalVisible } = useSelector(
    (state) => state.ParcelReducer
  );
  const [query, setQuery] = useState(null);

  // Effects
  useEffect(() => {
    const filter = () => {
      if (query) {
        getData();
      }
    };

    filter();
  }, [query]);

  const getData = async () => {
    if (query) {
      let data = [],
        result = [];

      try {
        setIsBusy(true);
        setSelection([]);

        // console.debug('query', JSON.stringify(query, null, 2));
        do {
          result = await service.find({
            query: {
              ...query,

              $skip: data.length,
            },
          });

          data = data.concat(result.data);

          // console.debug('result', result);
        } while (data.length !== result.total);

        data = data.map(mapParcel);
      } catch (err) {
        console.error('Error', err);
        data = [];

        swal({
          title: 'Error',
          text: 'Failed get Parcels',
          icon: 'error',
          buttons: {
            cancel: 'Close',
          },
        });
      } finally {
        setData(data);
        setIsBusy(false);

        // refFilters && refFilters.current && refFilters.current.focus();
      }
    }
  };

  useEffect(() => {
    setColumnDefs([
      {
        headerName: 'Tracking #',
        enableRowGroup: true,
        field: 'tracking_number',
        width: 250,
        checkboxSelection: true,
        headerCheckboxSelection: true,
        valueGetter: (params) => {
          return params.data
            ? params.data.tracking_number && params.data.tracking_number !== ''
              ? params.data.tracking_number
              : 'N/A'
            : 'N/A';
        },
        filter: 'agTextColumnFilter',
        resizable: true,
        pinned: 'left',
      },
      {
        headerName: 'Flexlogic Order #',
        enableRowGroup: true,
        field: 'order_number',
        valueGetter: (params) => {
          return params.data
            ? params.data.order_number && params.data.order_number !== ''
              ? params.data.order_number
              : 'N/A'
            : 'N/A';
        },
        filter: 'agTextColumnFilter',
        resizable: true,
      },
      {
        headerName: 'Intergrator Order #',
        enableRowGroup: true,
        field: 'integrator_order_number',
        valueGetter: (params) => {
          return params.data
            ? params.data.integrator_order_number &&
              params.data.integrator_order_number !== ''
              ? params.data.integrator_order_number
              : 'N/A'
            : 'N/A';
        },
        filter: 'agTextColumnFilter',
        resizable: true,
      },
      {
        ...number,

        headerName: 'Qty',
        enableRowGroup: true,
        width: 100,
        valueGetter:
          '(data && data.line_items && data.line_items.length) || ""',
        resizable: true,
      },
      {
        headerName: 'Status',
        enableRowGroup: true,
        field: 'status',
        valueGetter: (params) =>
          (params &&
            params.data &&
            params.data.status &&
            params.data.status.toUpperCase()) ||
          'N/A',
        filter: 'agTextColumnFilter',
        resizable: true,
      },
      {
        headerName: 'Shipper Name',
        field: 'from_name',
        resizable: true,
        valueGetter: (params) => {
          return params.data
            ? params.data.from_name
              ? params.data.from_name
              : 'N/A'
            : 'N/A';
        },
      },
      {
        headerName: 'Shipper City',
        field: 'city',
        resizable: true,
        valueGetter: 'data && data.to_address && data.to_address.city',
        valueFormatter,
      },
      {
        headerName: 'Consignee Name',
        field: 'to_name',
        resizable: true,
        valueGetter: (params) => {
          return params.data
            ? params.data.to_address
              ? params.data.to_address.name
              : 'N/A'
            : 'N/A';
        },
      },
      {
        ...date,

        headerName: 'Parcel Date',
        field: 'integrator_created_date',
        resizable: true,
      },
      {
        headerName: 'Method',
        enableRowGroup: true,
        field: 'shipping_method',
        valueGetter: (params) => {
          return params.data
            ? params.data.shipping_method && params.data.shipping_method !== ''
              ? params.data.shipping_method
              : 'N/A'
            : 'N/A';
        },
        filter: 'agTextColumnFilter',
        resizable: true,
      },
      {
        headerName: 'Label',
        enableRowGroup: true,
        field: 'shipping_label_location',
        cellRendererFramework: (params) =>
          (params && params.data && params.data.status === 'SENT_TO_OFFER' && (
            <Button
              compact
              primary
              onClick={() => handleDownloadLabel(params.data)}
            >
              Download
            </Button>
          )) ||
          (params &&
            params.data &&
            params.data.status === 'SHIPPED' &&
            params.data.shipping_label_location && (
              <Button
                compact
                primary
                onClick={(event) => {
                  event.preventDefault();
                  window.open(params.data.shipping_label_location);
                }}
              >
                View Label
              </Button>
            )) ||
          'N/A',
        filter: 'agTextColumnFilter',
      },
      {
        headerName: 'Service',
        enableRowGroup: true,
        valueGetter: (params) => {
          return params.data
            ? params.data.integrator && params.data.integrator !== ''
              ? params.data.integrator
              : null
            : null;
        },
      },
      {
        headerName: 'Carrier',
        enableRowGroup: true,
        field: 'carrier',
        valueGetter: (params) => {
          return params.data
            ? params.data.carrier && params.data.carrier !== ''
              ? params.data.carrier
              : 'N/A'
            : 'N/A';
        },
        filter: 'agTextColumnFilter',
      },
      {
        ...price,

        headerName: 'Label Cost',
        enableRowGroup: true,
        field: 'shipping_cost',
        aggFunc: 'sum',
      },
    ]);
    setColumnGroupDef({
      pinned: 'left',
      cellRenderer: 'agGroupCellRenderer',
      cellRendererParams: { checkbox: true },
    });
    getData();
  }, []);

  const mapParcel = (item) => ({
    shipment_type: 'Section 321',
    product_uom: `${UnitOfMeasure[36].UnitOfMeasureCode} (${UnitOfMeasure[36].unitOfMeasureName})`,
    ...item,
    from_address: {
      ...item.from_address,
      port_of_lading: `${PortInternational[1].PortName}`,
    },
    dimensions: {
      ...item.dimensions,
      weight_unit: item.dimensions.weight_unit
        ? item.dimensions.weight_unit !== '' &&
          item.dimensions.weight_unit === 'lb'
          ? 'L'
          : 'K'
        : null,
    },
  });

  const settingColumngGroupDefinition = (valuesRowGroups) => {
    let changedDefs = _.cloneDeep(columnDefs);
    if (valuesRowGroups.columns.length > 0) {
      changedDefs = changedDefs.map((item) =>
        item.field === valuesRowGroups.columns[0].colDef.field
          ? {
              ...item,
              checkboxSelection: false,
              pinned: false,
              hide: true,
              headerCheckboxSelection: false,
              rowGroup: true,
            }
          : {
              ...item,
              checkboxSelection: false,
              headerCheckboxSelection: false,
              rowGroup: false,
            }
      );
    } else {
      changedDefs = changedDefs.map((item) =>
        item.field === 'tracking_number'
          ? {
              ...item,
              pinned: 'left',
              checkboxSelection: true,
              hide: false,
              headerCheckboxSelection: true,
              rowGroup: false,
            }
          : {
              ...item,
              checkboxSelection: false,
              headerCheckboxSelection: false,
              rowGroup: false,
            }
      );
    }
    setColumnDefs(changedDefs);
  };

  const deleteSelect = async () => {
    swal({
      title: 'Are you sure?',
      text:
        'Once deleted, you will have to recover the Parcel(s) from Ship Hero',
      icon: 'warning',
      buttons: true,
      dangerMode: true,
    }).then(async (willDelete) => {
      if (willDelete) {
        let unicParcels = _.unionBy(selection, '_id');

        for (const order of unicParcels) {
          await service.remove(order._id);
        }

        getData();
        swal('Your Parcel(s) has been deleted', {
          icon: 'success',
        });
      }
    });
  };

  const addDataForValidations = (selected) => {
    let parcelDetailPerItem = [];
    selected.forEach((parcel) => {
      parcel.line_items.forEach((item) => {
        parcelDetailPerItem.push({
          tracking_number: parcel.tracking_number,
          shipment_type: parcel.shipment_type,
          from_address: {
            company: parcel.from_name,
            address1: parcel.from_address.address1,
            city: parcel.from_address.city,
            country_code: parcel.from_address.country_code,
            state_code: parcel.from_address.state_code,
            zip: parcel.from_address.zip,
            port_of_lading: parcel.from_address.port_of_lading,
          },
          to_address: {
            name: parcel.to_address.first_name,
            address1: parcel.to_address.address1,
            city: parcel.to_address.city,
            country: parcel.to_address.country,
            state: parcel.to_address.state,
            zip: parcel.to_address.zip,
          },
          product_description: item.description,
          quantity: item.quantity,
          product_uom: parcel.product_uom,
          product_weight: item.dimensions.weight,
          weight_unit: item.dimensions.weight_unit === 'lb' ? 'L' : 'K',
          shipping_cost: item.value,
          origin_country: item.country_of_manufacture,
        });
      });
    });
    // console.debug('parcelDetailPerItem :', parcelDetailPerItem);
    setSelection(selected);
    dispatch(dataToSend(parcelDetailPerItem));
  };

  const createOffers = async () => {
    setCreatingOffer(true);
    try {
      let parcels = allowedData(selection);
      let cities = allowedCities(parcels);
      let stores = allowedStores(parcels);
      let valid = await warnings(parcels, cities, stores);

      if (valid) {
        await warningSwal(valid);
      } else {
        let order_ids = getOnlyIds(parcels);

        let res = await feathers.service('offers').create(order_ids);
        // console.debug('res :>> ', res);
      }
    } catch (error) {
      console.error('Error creating Offers at: ', error);
      errorSwal(`Error creating offers at: ${error.message}`);
    } finally {
      getData();
      setCreatingOffer(false);
    }
  };

  const warnings = async (parcels, cities, stores) => {
    if (stores.length > 1)
      return `The user can't select parcels from differents shippers to create a logiship offer, the shippers selected are:${stores.map(
        (s) => ` ${s}`
      )}`;
    else if (cities.length > 1)
      return `The user can't select parcels from differents cities to create a logiship offer, the cities selected are:${cities.map(
        (c) => ` ${c.toUpperCase()}`
      )}`;
    else if (parcels.length === 0)
      return 'The parcels selected are not from "La Tiendida", "Store Wars" or it were sent as logiship offers before';

    return false;
  };

  const handleDownloadLabel = async (shipment) => {
    try {
      let res = await feathers
        .service(`parcel-label`)
        .get(shipment.order_number);
      if (res && res.length > 0) {
        downloadPDF(res, shipment.order_number);
      } else {
        await swal('Done', 'Labels not found', 'success');
      }
    } catch (error) {
      console.error('Error', error);
    }
  };

  const downloadPDF = (data, order) => {
    const linkSource = `data:image/jpg;base64,${data}`;
    const downloadLink = document.createElement('a');
    const fileName = `${order}.jpg`;
    downloadLink.href = linkSource;
    downloadLink.download = fileName;
    downloadLink.click();
  };

  return (
    <>
      <MainFiltersWrapper>
        <ParcelsFilters isBusy={isBusy} query={query} setQuery={setQuery} />
      </MainFiltersWrapper>

      <Grid columns={2} verticalAlign="middle" padded="vertically">
        <GridRow stretched>
          <GridColumn>
            {isBusy ? (
              <ActivityIndicator title="Importing..." />
            ) : (
              <RecordsSummary>
                <Instructions>
                  Select one or more parcels to do actions
                </Instructions>
              </RecordsSummary>
            )}
          </GridColumn>
          <GridColumn textAlign="right">
            <div>
              <Dropdown
                text="Bulk Actions"
                as={Button}
                primary
                compact
                disabled={!selection.length || isBusy}
                style={{ marginRight: 0 }}
              >
                <Dropdown.Menu>
                  <Dropdown.Item
                    text="Create E-Manifest"
                    onClick={() => dispatch(toggleValidationModal())}
                    disabled={!selection.length}
                  />
                  <Dropdown.Item
                    text="Create Offers Logiship"
                    onClick={() => createOffers()}
                    disabled={!selection.length}
                  />
                  <Dropdown.Item text="Other Service" disabled />
                  <Dropdown.Divider />
                  <Dropdown.Item
                    text="Delete"
                    style={{ color: 'red' }}
                    onClick={() => deleteSelect()}
                    disabled={!selection.length}
                  />
                </Dropdown.Menu>
              </Dropdown>
            </div>
          </GridColumn>
        </GridRow>
      </Grid>

      <TableContainer className="ag-theme-material" rowFilters={3}>
        <AgGridReact
          columnDefs={columnDefs}
          rowData={data}
          rowSelection="multiple"
          defaultColDef={{ filter: true, sortable: true }}
          onSelectionChanged={(checkedItem) =>
            addDataForValidations(checkedItem.api.getSelectedRows())
          }
          suppressRowClickSelection={true}
          sideBar={{
            toolPanels: ['columns'],
            defaultToolPanel: 'filters',
          }}
          groupHideOpenParents={true}
          rowGroupPanelShow={'always'}
          groupSelectsChildren={true}
          pagination={true}
          paginationPageSize={100}
          modules={AllModules}
          autoGroupColumnDef={columnGroupDef}
          onColumnRowGroupChanged={(valuesRowGroups) =>
            settingColumngGroupDefinition(valuesRowGroups)
          }
          suppressDragLeaveHidesColumns={true}
          suppressMakeColumnVisibleAfterUnGroup={true}
        />
      </TableContainer>
      {validationModalVisible ? (
        <ValidationsModal openModal={validationModalVisible} />
      ) : null}
      {creatingOffer ? (
        <Modal open={creatingOffer} size={'small'}>
          <Modal.Header>Creating offers...</Modal.Header>
          <Lottie
            options={{
              loop: true,
              autoplay: true,
              animationData: BagAnimation,
              rendererSettings: {
                preserveAspectRatio: 'xMidYMid slice',
              },
            }}
            width={480}
            height={480}
          />
        </Modal>
      ) : null}
    </>
  );
};

const RecordsSummary = styled.div``;

const Instructions = styled.div`
  font-family: 'Roboto', sans-serif;
  font-size: 14px;
  color: rgba(0, 0, 0, 0.6);
  letter-spacing: 0.25px;
  text-align: left;
  line-height: 20px;
`;

export default ParcelsImport;
