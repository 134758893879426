export const errors = {
  special: 'Special Characters',
  length: 'Data Length',
  undefinedData: 'Undefined Data',
  empty: 'Empty Cell',
  emptyItems: 'Quantity Items',
  type: 'Invalid Data Type',
  quantity: 'Invalid Quantity',
  summatory: 'Invalid Quantity Total',
  direction:  'Invalid Directions'
};
export const UnitOfMeasure = [
  {
    UnitOfMeasureCode: 'BAG',
    unitOfMeasureName: 'Bag'
  },
  {
    UnitOfMeasureCode: 'BBL',
    unitOfMeasureName: 'Barrel'
  },
  {
    UnitOfMeasureCode: 'BDL',
    unitOfMeasureName: 'Bundle'
  },
  {
    UnitOfMeasureCode: 'BIC',
    unitOfMeasureName: 'Bing Chest'
  },
  {
    UnitOfMeasureCode: 'BIN',
    unitOfMeasureName: 'Bin'
  },
  {
    UnitOfMeasureCode: 'BKT',
    unitOfMeasureName: 'Bucket'
  },
  {
    UnitOfMeasureCode: 'BLE',
    unitOfMeasureName: 'Bale'
  },
  {
    UnitOfMeasureCode: 'BOX',
    unitOfMeasureName: 'Box'
  },
  {
    UnitOfMeasureCode: 'BSK',
    unitOfMeasureName: 'Basket or hamper'
  },
  {
    UnitOfMeasureCode: 'CAN',
    unitOfMeasureName: 'Can'
  },
  {
    UnitOfMeasureCode: 'CAR',
    unitOfMeasureName: 'Carrier'
  },
  {
    UnitOfMeasureCode: 'CAS',
    unitOfMeasureName: 'Case'
  },
  {
    UnitOfMeasureCode: 'CBC',
    unitOfMeasureName: 'Containers of Bulk Cargo'
  },
  {
    UnitOfMeasureCode: 'CBY',
    unitOfMeasureName: 'Carboy'
  },
  {
    UnitOfMeasureCode: 'CCS',
    unitOfMeasureName: 'Can Case'
  },
  {
    UnitOfMeasureCode: 'CHS',
    unitOfMeasureName: 'Chest'
  },
  {
    UnitOfMeasureCode: 'COL',
    unitOfMeasureName: 'Coil'
  },
  {
    UnitOfMeasureCode: 'COR',
    unitOfMeasureName: 'Core'
  },
  {
    UnitOfMeasureCode: 'CRT',
    unitOfMeasureName: 'Crate'
  },
  {
    UnitOfMeasureCode: 'CSK',
    unitOfMeasureName: 'Cask'
  },
  {
    UnitOfMeasureCode: 'CTN',
    unitOfMeasureName: 'Carton'
  },
  {
    UnitOfMeasureCode: 'CYL',
    unitOfMeasureName: 'Cylinder'
  },
  {
    UnitOfMeasureCode: 'DBK',
    unitOfMeasureName: 'Dry Bulk'
  },
  {
    UnitOfMeasureCode: 'DRM',
    unitOfMeasureName: 'Drum'
  },
  {
    UnitOfMeasureCode: 'DZ',
    unitOfMeasureName: 'Dozen'
  },
  {
    UnitOfMeasureCode: 'FT',
    unitOfMeasureName: 'Feet'
  },
  {
    UnitOfMeasureCode: 'HED',
    unitOfMeasureName: 'Heads of Beef'
  },
  {
    UnitOfMeasureCode: 'KEG',
    unitOfMeasureName: 'Keg'
  },
  {
    UnitOfMeasureCode: 'L',
    unitOfMeasureName: 'Liter'
  },
  {
    UnitOfMeasureCode: 'LBK',
    unitOfMeasureName: 'Liquid Bulk'
  },
  {
    UnitOfMeasureCode: 'LOG',
    unitOfMeasureName: 'Log'
  },
  {
    UnitOfMeasureCode: 'LUG',
    unitOfMeasureName: 'Lug'
  },
  {
    UnitOfMeasureCode: 'LVN',
    unitOfMeasureName: 'Lift Van'
  },
  {
    UnitOfMeasureCode: 'M',
    unitOfMeasureName: 'Meter'
  },
  {
    UnitOfMeasureCode: 'PAL',
    unitOfMeasureName: 'Pail'
  },
  {
    UnitOfMeasureCode: 'PCS',
    unitOfMeasureName: 'Pieces'
  },
  {
    UnitOfMeasureCode: 'PKG',
    unitOfMeasureName: 'Package'
  },
  {
    UnitOfMeasureCode: 'POV',
    unitOfMeasureName: 'Private Vehicle'
  },
  {
    UnitOfMeasureCode: 'QTR',
    unitOfMeasureName: 'Quarter of Beef'
  },
  {
    UnitOfMeasureCode: 'REL',
    unitOfMeasureName: 'Reel'
  },
  {
    UnitOfMeasureCode: 'ROL',
    unitOfMeasureName: 'Roll'
  },
  {
    UnitOfMeasureCode: 'SAK',
    unitOfMeasureName: 'Sack'
  },
  {
    UnitOfMeasureCode: 'SHT',
    unitOfMeasureName: 'Sheet'
  },
  {
    UnitOfMeasureCode: 'SID',
    unitOfMeasureName: 'Side of Beef'
  },
  {
    UnitOfMeasureCode: 'SKD',
    unitOfMeasureName: 'Skid'
  },
  {
    UnitOfMeasureCode: 'TBE',
    unitOfMeasureName: 'Tube'
  },
  {
    UnitOfMeasureCode: 'TBN',
    unitOfMeasureName: 'Tote Bin'
  },
  {
    UnitOfMeasureCode: 'TNK',
    unitOfMeasureName: 'Tank'
  },
  {
    UnitOfMeasureCode: 'UNT',
    unitOfMeasureName: 'Unit'
  }
];
export const CountryStateCode = [
  {
    CountryCode: 'CA',
    StateCode: 'AB',
    StateName: 'Alberta'
  },
  {
    CountryCode: 'CA',
    StateCode: 'BC',
    StateName: 'British Columbia'
  },
  {
    CountryCode: 'CA',
    StateCode: 'MB',
    StateName: 'Manitoba'
  },
  {
    CountryCode: 'CA',
    StateCode: 'NB',
    StateName: 'New Brunswick'
  },
  {
    CountryCode: 'CA',
    StateCode: 'NL',
    StateName: 'Newfoundland Labrador'
  },
  {
    CountryCode: 'CA',
    StateCode: 'NS',
    StateName: 'Nova Scotia'
  },
  {
    CountryCode: 'CA',
    StateCode: 'NT',
    StateName: 'Northwest Territories'
  },
  {
    CountryCode: 'CA',
    StateCode: 'NU',
    StateName: 'Nunavut'
  },
  {
    CountryCode: 'CA',
    StateCode: 'ON',
    StateName: 'Ontario'
  },
  {
    CountryCode: 'CA',
    StateCode: 'PE',
    StateName: 'Prince Edward Island'
  },
  {
    CountryCode: 'CA',
    StateCode: 'QC',
    StateName: 'Quebec'
  },
  {
    CountryCode: 'CA',
    StateCode: 'SK',
    StateName: 'Saskatchewan'
  },
  {
    CountryCode: 'CA',
    StateCode: 'YT',
    StateName: 'Yukon Territory'
  },
  {
    CountryCode: 'MX',
    StateCode: 'AGU',
    StateName: 'Aguascalientes'
  },
  {
    CountryCode: 'MX',
    StateCode: 'BCN',
    StateName: 'Baja California'
  },
  {
    CountryCode: 'MX',
    StateCode: 'BCS',
    StateName: 'Baja California Sur'
  },
  {
    CountryCode: 'MX',
    StateCode: 'CAM',
    StateName: 'Campeche'
  },
  {
    CountryCode: 'MX',
    StateCode: 'CHH',
    StateName: 'Chihuahua'
  },
  {
    CountryCode: 'MX',
    StateCode: 'CHP',
    StateName: 'Chiapas'
  },
  {
    CountryCode: 'MX',
    StateCode: 'COA',
    StateName: 'Coahuila'
  },
  {
    CountryCode: 'MX',
    StateCode: 'COL',
    StateName: 'Colima'
  },
  {
    CountryCode: 'MX',
    StateCode: 'DIF',
    StateName: 'Distrito Federal'
  },
  {
    CountryCode: 'MX',
    StateCode: 'DUR',
    StateName: 'Durango'
  },
  {
    CountryCode: 'MX',
    StateCode: 'GRO',
    StateName: 'Guerrero'
  },
  {
    CountryCode: 'MX',
    StateCode: 'GUA',
    StateName: 'Guanajuato'
  },
  {
    CountryCode: 'MX',
    StateCode: 'HID',
    StateName: 'Hidalgo'
  },
  {
    CountryCode: 'MX',
    StateCode: 'JAL',
    StateName: 'Jalisco'
  },
  {
    CountryCode: 'MX',
    StateCode: 'MEX',
    StateName: 'Estado de Mexico'
  },
  {
    CountryCode: 'MX',
    StateCode: 'MIC',
    StateName: 'Michoacan'
  },
  {
    CountryCode: 'MX',
    StateCode: 'MOR',
    StateName: 'Morelos'
  },
  {
    CountryCode: 'MX',
    StateCode: 'NAY',
    StateName: 'Nayarit'
  },
  {
    CountryCode: 'MX',
    StateCode: 'NLE',
    StateName: 'Nuevo Leon'
  },
  {
    CountryCode: 'MX',
    StateCode: 'OAX',
    StateName: 'Oaxaca'
  },
  {
    CountryCode: 'MX',
    StateCode: 'PUE',
    StateName: 'Puebla'
  },
  {
    CountryCode: 'MX',
    StateCode: 'QUE',
    StateName: 'Queretaro'
  },
  {
    CountryCode: 'MX',
    StateCode: 'ROO',
    StateName: 'Quintana Roo'
  },
  {
    CountryCode: 'MX',
    StateCode: 'SIN',
    StateName: 'Sinaloa'
  },
  {
    CountryCode: 'MX',
    StateCode: 'SLP',
    StateName: 'San Luis Potosi'
  },
  {
    CountryCode: 'MX',
    StateCode: 'SON',
    StateName: 'Sonora'
  },
  {
    CountryCode: 'MX',
    StateCode: 'TAB',
    StateName: 'Tabasco'
  },
  {
    CountryCode: 'MX',
    StateCode: 'TAM',
    StateName: 'Tamaulipas'
  },
  {
    CountryCode: 'MX',
    StateCode: 'TLA',
    StateName: 'Tlaxcala'
  },
  {
    CountryCode: 'MX',
    StateCode: 'VER',
    StateName: 'Veracruz'
  },
  {
    CountryCode: 'MX',
    StateCode: 'YUC',
    StateName: 'Yucatan'
  },
  {
    CountryCode: 'MX',
    StateCode: 'ZAC',
    StateName: 'Zacatecas'
  },
  {
    CountryCode: 'US',
    StateCode: 'AA',
    StateName: 'Armed forces'
  },
  {
    CountryCode: 'US',
    StateCode: 'AK',
    StateName: 'Alaska'
  },
  {
    CountryCode: 'US',
    StateCode: 'AL',
    StateName: 'Alabama'
  },
  {
    CountryCode: 'US',
    StateCode: 'AP',
    StateName: 'Armed forces'
  },
  {
    CountryCode: 'US',
    StateCode: 'AR',
    StateName: 'Arkansas'
  },
  {
    CountryCode: 'US',
    StateCode: 'AZ',
    StateName: 'Arizona'
  },
  {
    CountryCode: 'US',
    StateCode: 'CA',
    StateName: 'California'
  },
  {
    CountryCode: 'US',
    StateCode: 'CO',
    StateName: 'Colorado'
  },
  {
    CountryCode: 'US',
    StateCode: 'CT',
    StateName: 'Connecticut'
  },
  {
    CountryCode: 'US',
    StateCode: 'DC',
    StateName: 'Columbia (District of)'
  },
  {
    CountryCode: 'US',
    StateCode: 'DE',
    StateName: 'Delaware'
  },
  {
    CountryCode: 'US',
    StateCode: 'FL',
    StateName: 'Florida'
  },
  {
    CountryCode: 'US',
    StateCode: 'GA',
    StateName: 'Georgia'
  },
  {
    CountryCode: 'US',
    StateCode: 'GU',
    StateName: 'Guam'
  },
  {
    CountryCode: 'US',
    StateCode: 'HI',
    StateName: 'Hawaii'
  },
  {
    CountryCode: 'US',
    StateCode: 'IA',
    StateName: 'Iowa'
  },
  {
    CountryCode: 'US',
    StateCode: 'ID',
    StateName: 'Idaho'
  },
  {
    CountryCode: 'US',
    StateCode: 'IL',
    StateName: 'Illinois'
  },
  {
    CountryCode: 'US',
    StateCode: 'IN',
    StateName: 'Indiana'
  },
  {
    CountryCode: 'US',
    StateCode: 'KS',
    StateName: 'Kansas'
  },
  {
    CountryCode: 'US',
    StateCode: 'KY',
    StateName: 'Kentucky'
  },
  {
    CountryCode: 'US',
    StateCode: 'LA',
    StateName: 'Louisiana'
  },
  {
    CountryCode: 'US',
    StateCode: 'MA',
    StateName: 'Massachusetts'
  },
  {
    CountryCode: 'US',
    StateCode: 'MD',
    StateName: 'Maryland'
  },
  {
    CountryCode: 'US',
    StateCode: 'ME',
    StateName: 'Maine'
  },
  {
    CountryCode: 'US',
    StateCode: 'MI',
    StateName: 'Michigan'
  },
  {
    CountryCode: 'US',
    StateCode: 'MN',
    StateName: 'Minnesota'
  },
  {
    CountryCode: 'US',
    StateCode: 'MO',
    StateName: 'Missouri'
  },
  {
    CountryCode: 'US',
    StateCode: 'MS',
    StateName: 'Mississippi'
  },
  {
    CountryCode: 'US',
    StateCode: 'MT',
    StateName: 'Montana'
  },
  {
    CountryCode: 'US',
    StateCode: 'NC',
    StateName: 'North Carolina'
  },
  {
    CountryCode: 'US',
    StateCode: 'ND',
    StateName: 'North Dakota'
  },
  {
    CountryCode: 'US',
    StateCode: 'NE',
    StateName: 'Nebraska'
  },
  {
    CountryCode: 'US',
    StateCode: 'NH',
    StateName: 'New Hampshire'
  },
  {
    CountryCode: 'US',
    StateCode: 'NJ',
    StateName: 'New Jersey'
  },
  {
    CountryCode: 'US',
    StateCode: 'NM',
    StateName: 'New Mexico'
  },
  {
    CountryCode: 'US',
    StateCode: 'NV',
    StateName: 'Nevada'
  },
  {
    CountryCode: 'US',
    StateCode: 'NY',
    StateName: 'New York'
  },
  {
    CountryCode: 'US',
    StateCode: 'OH',
    StateName: 'Ohio'
  },
  {
    CountryCode: 'US',
    StateCode: 'OK',
    StateName: 'Oklahoma'
  },
  {
    CountryCode: 'US',
    StateCode: 'OR',
    StateName: 'Oregon'
  },
  {
    CountryCode: 'US',
    StateCode: 'PA',
    StateName: 'Pennsylvania'
  },
  {
    CountryCode: 'US',
    StateCode: 'PR',
    StateName: 'Puerto Rico'
  },
  {
    CountryCode: 'US',
    StateCode: 'RI',
    StateName: 'Rhode Island'
  },
  {
    CountryCode: 'US',
    StateCode: 'SC',
    StateName: 'South Carolina'
  },
  {
    CountryCode: 'US',
    StateCode: 'SD',
    StateName: 'South Dakota'
  },
  {
    CountryCode: 'US',
    StateCode: 'TN',
    StateName: 'Tennessee'
  },
  {
    CountryCode: 'US',
    StateCode: 'TX',
    StateName: 'Texas'
  },
  {
    CountryCode: 'US',
    StateCode: 'UT',
    StateName: 'Utah'
  },
  {
    CountryCode: 'US',
    StateCode: 'VA',
    StateName: 'Virginia'
  },
  {
    CountryCode: 'US',
    StateCode: 'VT',
    StateName: 'Vermont'
  },
  {
    CountryCode: 'US',
    StateCode: 'WA',
    StateName: 'Washington (State of)'
  },
  {
    CountryCode: 'US',
    StateCode: 'WI',
    StateName: 'Wisconsin'
  },
  {
    CountryCode: 'US',
    StateCode: 'WV',
    StateName: 'West Virginia'
  },
  {
    CountryCode: 'US',
    StateCode: 'WY',
    StateName: 'Wyoming'
  }
];
export const PortInternational = [
    {
        PortCode: "90200",
        PortCountryCode: "US",
        PortName: "US Port of Lading",
        StateCode: ""
    },
    {
        PortCode: "20100",
        PortCountryCode: "MX",
        PortName: "MX West Coast Region Ports",
        StateCode: ""
    },
    {
        PortCode: "20195",
        PortCountryCode: "MX",
        PortName: "MX East Coast Region Ports",
        StateCode: ""
    },
    {
        PortCode: "80101",
        PortCountryCode: "CA",
        PortName: "Alberta",
        StateCode: "AB"
    },
    {
        PortCode: "80102",
        PortCountryCode: "CA",
        PortName: "Manitoba",
        StateCode: "MB"
    },
    {
        PortCode: "80103",
        PortCountryCode: "CA",
        PortName: "Saskatchewan",
        StateCode: "SK"
    },
    {
        PortCode: "80105",
        PortCountryCode: "CA",
        PortName: "Yukon",
        StateCode: "YT"
    },
    {
        PortCode: "80106",
        PortCountryCode: "CA",
        PortName: "British Columbia",
        StateCode: "BC"
    },
    {
        PortCode: "80107",
        PortCountryCode: "CA",
        PortName: "Ontario",
        StateCode: "ON"
    },
    {
        PortCode: "80108",
        PortCountryCode: "CA",
        PortName: "Quebec",
        StateCode: "QC"
    },
    {
        PortCode: "80109",
        PortCountryCode: "CA",
        PortName: "Nova Scotia",
        StateCode: "NS"
    },
    {
        PortCode: "80110",
        PortCountryCode: "CA",
        PortName: "New Brunswick",
        StateCode: "NB"
    },
    {
        PortCode: "80111",
        PortCountryCode: "CA",
        PortName: "Prince Edward Island",
        StateCode: "PE"
    },
    {
        PortCode: "80112",
        PortCountryCode: "CA",
        PortName: "Newfoundland",
        StateCode: "NL"
    },
    {
        PortCode: "80113",
        PortCountryCode: "CA",
        PortName: "Nunavut",
        StateCode: "NU"
    },
    {
        PortCode: "97101",
        PortCountryCode: "MX",
        PortName: "Aguascalientes",
        StateCode: "AGU"
    },
    {
        PortCode: "97102",
        PortCountryCode: "MX",
        PortName: "Baja California",
        StateCode: "BCN"
    },
    {
        PortCode: "97103",
        PortCountryCode: "MX",
        PortName: "Baja California Sur",
        StateCode: "BCS"
    },
    {
        PortCode: "97104",
        PortCountryCode: "MX",
        PortName: "Chihuahua",
        StateCode: "CHH"
    },
    {
        PortCode: "97105",
        PortCountryCode: "MX",
        PortName: "Colima",
        StateCode: "COL"
    },
    {
        PortCode: "97106",
        PortCountryCode: "MX",
        PortName: "Campeche",
        StateCode: "CAM"
    },
    {
        PortCode: "97107",
        PortCountryCode: "MX",
        PortName: "Coahuila",
        StateCode: "COA"
    },
    {
        PortCode: "97108",
        PortCountryCode: "MX",
        PortName: "Chiapas",
        StateCode: "CHP"
    },
    {
        PortCode: "97109",
        PortCountryCode: "MX",
        PortName: "Distrito Federal",
        StateCode: "DIF"
    },
    {
        PortCode: "97110",
        PortCountryCode: "MX",
        PortName: "Durango",
        StateCode: "DUR"
    },
    {
        PortCode: "97111",
        PortCountryCode: "MX",
        PortName: "Guerrero",
        StateCode: "GRO"
    },
    {
        PortCode: "97112",
        PortCountryCode: "MX",
        PortName: "Guanajuato",
        StateCode: "GUA"
    },
    {
        PortCode: "97113",
        PortCountryCode: "MX",
        PortName: "Hidalgo",
        StateCode: "HID"
    },
    {
        PortCode: "97114",
        PortCountryCode: "MX",
        PortName: "Jalisco",
        StateCode: "JAL"
    },
    {
        PortCode: "97115",
        PortCountryCode: "MX",
        PortName: "Michoacan",
        StateCode: "MIC"
    },
    {
        PortCode: "97116",
        PortCountryCode: "MX",
        PortName: "Morelos",
        StateCode: "MOR"
    },
    {
        PortCode: "97117",
        PortCountryCode: "MX",
        PortName: "Estado de Mexico",
        StateCode: "MEX"
    },
    {
        PortCode: "97118",
        PortCountryCode: "MX",
        PortName: "Nayarit",
        StateCode: "NAY"
    },
    {
        PortCode: "97119",
        PortCountryCode: "MX",
        PortName: "Nuevo Leon",
        StateCode: "NLE"
    },
    {
        PortCode: "97120",
        PortCountryCode: "MX",
        PortName: "Oaxaca",
        StateCode: "OAX"
    },
    {
        PortCode: "97121",
        PortCountryCode: "MX",
        PortName: "Puebla",
        StateCode: "PUE"
    },
    {
        PortCode: "97122",
        PortCountryCode: "MX",
        PortName: "Quintana Roo",
        StateCode: "ROO"
    },
    {
        PortCode: "97123",
        PortCountryCode: "MX",
        PortName: "Queretaro",
        StateCode: "QUE"
    },
    {
        PortCode: "97124",
        PortCountryCode: "MX",
        PortName: "Sinaloa",
        StateCode: "SIN"
    },
    {
        PortCode: "97125",
        PortCountryCode: "MX",
        PortName: "San Luis Potosi",
        StateCode: "SLP"
    },
    {
        PortCode: "97126",
        PortCountryCode: "MX",
        PortName: "Sonora",
        StateCode: "SON"
    },
    {
        PortCode: "97127",
        PortCountryCode: "MX",
        PortName: "Tabasco",
        StateCode: "TAB"
    },
    {
        PortCode: "97128",
        PortCountryCode: "MX",
        PortName: "Tlaxcala",
        StateCode: "TLA"
    },
    {
        PortCode: "97129",
        PortCountryCode: "MX",
        PortName: "Tamaulipas",
        StateCode: "TAM"
    },
    {
        PortCode: "97130",
        PortCountryCode: "MX",
        PortName: "Veracruz",
        StateCode: "VER"
    },
    {
        PortCode: "97131",
        PortCountryCode: "MX",
        PortName: "Yucatan",
        StateCode: "YUC"
    },
    {
        PortCode: "97132",
        PortCountryCode: "MX",
        PortName: "Zacatecas",
        StateCode: "ZAC"
    },
    {
        PortCode: "80104",
        PortCountryCode: "CA",
        PortName: "Northwest Territories",
        StateCode: "NT"
    },
    {
        PortCode: "12203",
        PortCountryCode: "CA",
        PortName: "Alert Bay, BC",
        StateCode: ""
    },
    {
        PortCode: "08525",
        PortCountryCode: "CA",
        PortName: "Algoma, ONT",
        StateCode: ""
    },
    {
        PortCode: "13400",
        PortCountryCode: "CA",
        PortName: "All Other Canada Atlantic Region Ports",
        StateCode: ""
    },
    {
        PortCode: "09000",
        PortCountryCode: "CA",
        PortName: "All Other Canada Great Lakes Region Ports",
        StateCode: ""
    },
    {
        PortCode: "12200",
        PortCountryCode: "CA",
        PortName: "All Other Canada Pacific Region Ports",
        StateCode: ""
    },
    {
        PortCode: "15298",
        PortCountryCode: "CA",
        PortName: "All Other Labrador Ports",
        StateCode: ""
    },
    {
        PortCode: "15200",
        PortCountryCode: "CA",
        PortName: "All Other Newfoundland Ports",
        StateCode: ""
    },
    {
        PortCode: "03504",
        PortCountryCode: "CA",
        PortName: "Amherstburg, ONT",
        StateCode: ""
    },
    {
        PortCode: "15213",
        PortCountryCode: "CA",
        PortName: "Argentia, NFLD",
        StateCode: ""
    },
    {
        PortCode: "13846",
        PortCountryCode: "CA",
        PortName: "Auld’s Cove, NS",
        StateCode: ""
    },
    {
        PortCode: "13400",
        PortCountryCode: "CA",
        PortName: "Back Bay, NB",
        StateCode: ""
    },
    {
        PortCode: "13855",
        PortCountryCode: "CA",
        PortName: "Baddeck, CBI",
        StateCode: ""
    },
    {
        PortCode: "15298",
        PortCountryCode: "CA",
        PortName: "Baffin Island, NFLD",
        StateCode: ""
    },
    {
        PortCode: "13481",
        PortCountryCode: "CA",
        PortName: "Baie Comeau, QUE",
        StateCode: ""
    },
    {
        PortCode: "01653",
        PortCountryCode: "CA",
        PortName: "Bath, ONT",
        StateCode: ""
    },
    {
        PortCode: "13458",
        PortCountryCode: "CA",
        PortName: "Bathurst, NB",
        StateCode: ""
    },
    {
        PortCode: "15205",
        PortCountryCode: "CA",
        PortName: "Bay Roberts, NFLD",
        StateCode: ""
    },
    {
        PortCode: "13459",
        PortCountryCode: "CA",
        PortName: "Bayside, NB",
        StateCode: ""
    },
    {
        PortCode: "12215",
        PortCountryCode: "CA",
        PortName: "Beale Cove, BC",
        StateCode: ""
    },
    {
        PortCode: "01806",
        PortCountryCode: "CA",
        PortName: "Beauharnoi, QUE",
        StateCode: ""
    },
    {
        PortCode: "13435",
        PortCountryCode: "CA",
        PortName: "Beaver Harbour, NB",
        StateCode: ""
    },
    {
        PortCode: "13441",
        PortCountryCode: "CA",
        PortName: "Becancour, QUE",
        StateCode: ""
    },
    {
        PortCode: "12216",
        PortCountryCode: "CA",
        PortName: "Bella Coola, BC",
        StateCode: ""
    },
    {
        PortCode: "13442",
        PortCountryCode: "CA",
        PortName: "Belledune, NB",
        StateCode: ""
    },
    {
        PortCode: "13450",
        PortCountryCode: "CA",
        PortName: "Blacks Harbor, NB",
        StateCode: ""
    },
    {
        PortCode: "13400",
        PortCountryCode: "CA",
        PortName: "Bliss Harbor, NB",
        StateCode: ""
    },
    {
        PortCode: "12214",
        PortCountryCode: "CA",
        PortName: "Blubber Bay, BC",
        StateCode: ""
    },
    {
        PortCode: "13829",
        PortCountryCode: "CA",
        PortName: "Boca Sec, NB",
        StateCode: ""
    },
    {
        PortCode: "15200",
        PortCountryCode: "CA",
        PortName: "Bonavista, NFLD",
        StateCode: ""
    },
    {
        PortCode: "15214",
        PortCountryCode: "CA",
        PortName: "Botwood, NFLD",
        StateCode: ""
    },
    {
        PortCode: "09665",
        PortCountryCode: "CA",
        PortName: "Bowmanville, ONT",
        StateCode: ""
    },
    {
        PortCode: "09000",
        PortCountryCode: "CA",
        PortName: "Bridgeburg, ONT",
        StateCode: ""
    },
    {
        PortCode: "13400",
        PortCountryCode: "CA",
        PortName: "Bridgetown, NS",
        StateCode: ""
    },
    {
        PortCode: "13850",
        PortCountryCode: "CA",
        PortName: "Bridgewater, NS",
        StateCode: ""
    },
    {
        PortCode: "01528",
        PortCountryCode: "CA",
        PortName: "Britt, ONT",
        StateCode: ""
    },
    {
        PortCode: "01694",
        PortCountryCode: "CA",
        PortName: "Brockville, ONT",
        StateCode: ""
    },
    {
        PortCode: "08526",
        PortCountryCode: "CA",
        PortName: "Bruce Mine, ONT",
        StateCode: ""
    },
    {
        PortCode: "15201",
        PortCountryCode: "CA",
        PortName: "Bull Arm, NFLD",
        StateCode: ""
    },
    {
        PortCode: "15200",
        PortCountryCode: "CA",
        PortName: "Burgeo, NFLD",
        StateCode: ""
    },
    {
        PortCode: "12495",
        PortCountryCode: "CA",
        PortName: "Burnaby, BC",
        StateCode: ""
    },
    {
        PortCode: "12200",
        PortCountryCode: "CA",
        PortName: "Butedale, BC",
        StateCode: ""
    },
    {
        PortCode: "13410",
        PortCountryCode: "CA",
        PortName: "Cacouna, QUE",
        StateCode: ""
    },
    {
        PortCode: "12207",
        PortCountryCode: "CA",
        PortName: "Campbell River, BC",
        StateCode: ""
    },
    {
        PortCode: "13444",
        PortCountryCode: "CA",
        PortName: "Campbellton, NB",
        StateCode: ""
    },
    {
        PortCode: "13445",
        PortCountryCode: "CA",
        PortName: "Campobello, NB",
        StateCode: ""
    },
    {
        PortCode: "12213",
        PortCountryCode: "CA",
        PortName: "Cape Beale, BC",
        StateCode: ""
    },
    {
        PortCode: "15206",
        PortCountryCode: "CA",
        PortName: "Carbonear, NFLD",
        StateCode: ""
    },
    {
        PortCode: "01705",
        PortCountryCode: "CA",
        PortName: "Cardinal, ONT",
        StateCode: ""
    },
    {
        PortCode: "02641",
        PortCountryCode: "CA",
        PortName: "Cayuga, ONT",
        StateCode: ""
    },
    {
        PortCode: "13445",
        PortCountryCode: "CA",
        PortName: "Chance Harbor, NB",
        StateCode: ""
    },
    {
        PortCode: "13466",
        PortCountryCode: "CA",
        PortName: "Chandler, QUE",
        StateCode: ""
    },
    {
        PortCode: "14471",
        PortCountryCode: "CA",
        PortName: "Charlottetown, PEI",
        StateCode: ""
    },
    {
        PortCode: "13460",
        PortCountryCode: "CA",
        PortName: "Chatham, NB",
        StateCode: ""
    },
    {
        PortCode: "12229",
        PortCountryCode: "CA",
        PortName: "Chemainus, BC",
        StateCode: ""
    },
    {
        PortCode: "13473",
        PortCountryCode: "CA",
        PortName: "Chicoutimi, QUE",
        StateCode: ""
    },
    {
        PortCode: "14001",
        PortCountryCode: "CA",
        PortName: "Churchill, MAN",
        StateCode: ""
    },
    {
        PortCode: "15224",
        PortCountryCode: "CA",
        PortName: "Clarenville, NFLD",
        StateCode: ""
    },
    {
        PortCode: "13476",
        PortCountryCode: "CA",
        PortName: "Pointe Noire, QUE",
        StateCode: ""
    },
    {
        PortCode: "13451",
        PortCountryCode: "CA",
        PortName: "Clarke Harbour, NS",
        StateCode: ""
    },
    {
        PortCode: "13451",
        PortCountryCode: "CA",
        PortName: "Clarkes Harbour, NS",
        StateCode: ""
    },
    {
        PortCode: "01527",
        PortCountryCode: "CA",
        PortName: "Clarkson, ONT",
        StateCode: ""
    },
    {
        PortCode: "15226",
        PortCountryCode: "CA",
        PortName: "Coley’s Point, NFLD",
        StateCode: ""
    },
    {
        PortCode: "13481",
        PortCountryCode: "CA",
        PortName: "Comeau Bay, QUE",
        StateCode: ""
    },
    {
        PortCode: "15229",
        PortCountryCode: "CA",
        PortName: "Come-by-Chance, NFLD",
        StateCode: ""
    },
    {
        PortCode: "15220",
        PortCountryCode: "CA",
        PortName: "Conception Bay, NFLD",
        StateCode: ""
    },
    {
        PortCode: "13482",
        PortCountryCode: "CA",
        PortName: "Contrecoeur, QUE",
        StateCode: ""
    },
    {
        PortCode: "15231",
        PortCountryCode: "CA",
        PortName: "Corner Brook, NFLD",
        StateCode: ""
    },
    {
        PortCode: "01784",
        PortCountryCode: "CA",
        PortName: "Cornwall, ONT",
        StateCode: ""
    },
    {
        PortCode: "05517",
        PortCountryCode: "CA",
        PortName: "Corunna, ONT",
        StateCode: ""
    },
    {
        PortCode: "05515",
        PortCountryCode: "CA",
        PortName: "Courtright, ONT",
        StateCode: ""
    },
    {
        PortCode: "12231",
        PortCountryCode: "CA",
        PortName: "Cowichan Bay, BC",
        StateCode: ""
    },
    {
        PortCode: "12234",
        PortCountryCode: "CA",
        PortName: "Crofton, BC",
        StateCode: ""
    },
    {
        PortCode: "13484",
        PortCountryCode: "CA",
        PortName: "Dalhousie, NB",
        StateCode: ""
    },
    {
        PortCode: "13485",
        PortCountryCode: "CA",
        PortName: "Dartmouth, NS",
        StateCode: ""
    },
    {
        PortCode: "13400",
        PortCountryCode: "CA",
        PortName: "Deadmans Harbour, NB",
        StateCode: ""
    },
    {
        PortCode: "13475",
        PortCountryCode: "CA",
        PortName: "Deer Island, BC",
        StateCode: ""
    },
    {
        PortCode: "12496",
        PortCountryCode: "CA",
        PortName: "Delta, BC",
        StateCode: ""
    },
    {
        PortCode: "06695",
        PortCountryCode: "CA",
        PortName: "Depot Harbor, ONT",
        StateCode: ""
    },
    {
        PortCode: "13420",
        PortCountryCode: "CA",
        PortName: "Digby, NS",
        StateCode: ""
    },
    {
        PortCode: "13400",
        PortCountryCode: "CA",
        PortName: "Dipper Harbor, NB",
        StateCode: ""
    },
    {
        PortCode: "13493",
        PortCountryCode: "CA",
        PortName: "Donnacona, QUE",
        StateCode: ""
    },
    {
        PortCode: "12246",
        PortCountryCode: "CA",
        PortName: "Duncan Bay, BC",
        StateCode: ""
    },
    {
        PortCode: "12200",
        PortCountryCode: "CA",
        PortName: "Dundas Island, BC",
        StateCode: ""
    },
    {
        PortCode: "02645",
        PortCountryCode: "CA",
        PortName: "Dunnville, ONT",
        StateCode: ""
    },
    {
        PortCode: "15223",
        PortCountryCode: "CA",
        PortName: "Earls Island, NFLD",
        StateCode: ""
    },
    {
        PortCode: "12237",
        PortCountryCode: "CA",
        PortName: "Elk Falls, BC",
        StateCode: ""
    },
    {
        PortCode: "15207",
        PortCountryCode: "CA",
        PortName: "Englee Harbor, NFLD",
        StateCode: ""
    },
    {
        PortCode: "12200",
        PortCountryCode: "CA",
        PortName: "Esquimalt, BC",
        StateCode: ""
    },
    {
        PortCode: "08528",
        PortCountryCode: "CA",
        PortName: "Fisher Harbour, BC",
        StateCode: ""
    },
    {
        PortCode: "09000",
        PortCountryCode: "CA",
        PortName: "Fort Frances, ONT",
        StateCode: ""
    },
    {
        PortCode: "09662",
        PortCountryCode: "CA",
        PortName: "Fort Williams, ONT",
        StateCode: ""
    },
    {
        PortCode: "15208",
        PortCountryCode: "CA",
        PortName: "Fortune, NFLD",
        StateCode: ""
    },
    {
        PortCode: "13400",
        PortCountryCode: "CA",
        PortName: "Fox River, QUE",
        StateCode: ""
    },
    {
        PortCode: "12261",
        PortCountryCode: "CA",
        PortName: "Fraser River, BC",
        StateCode: ""
    },
    {
        PortCode: "15298",
        PortCountryCode: "CA",
        PortName: "Frobisher Bay, NFLD",
        StateCode: ""
    },
    {
        PortCode: "12494",
        PortCountryCode: "CA",
        PortName: "Ganges, BC",
        StateCode: ""
    },
    {
        PortCode: "13819",
        PortCountryCode: "CA",
        PortName: "Gaspe, QUE",
        StateCode: ""
    },
    {
        PortCode: "06533",
        PortCountryCode: "CA",
        PortName: "Goderich, ONT",
        StateCode: ""
    },
    {
        PortCode: "12275",
        PortCountryCode: "CA",
        PortName: "Gold River, BC",
        StateCode: ""
    },
    {
        PortCode: "13452",
        PortCountryCode: "CA",
        PortName: "Gold River, NS",
        StateCode: ""
    },
    {
        PortCode: "12493",
        PortCountryCode: "CA",
        PortName: "Gooch Island, BC",
        StateCode: ""
    },
    {
        PortCode: "15209",
        PortCountryCode: "CA",
        PortName: "Grand Bank, NFLD",
        StateCode: ""
    },
    {
        PortCode: "13829",
        PortCountryCode: "CA",
        PortName: "Grand Harbor, NB",
        StateCode: ""
    },
    {
        PortCode: "13829",
        PortCountryCode: "CA",
        PortName: "Grand Manan Island, NB",
        StateCode: ""
    },
    {
        PortCode: "09000",
        PortCountryCode: "CA",
        PortName: "Greenville, ONT",
        StateCode: ""
    },
    {
        PortCode: "13820",
        PortCountryCode: "CA",
        PortName: "Gros Cacouna, QUE",
        StateCode: ""
    },
    {
        PortCode: "13820",
        PortCountryCode: "CA",
        PortName: "Groscacouna, QUE",
        StateCode: ""
    },
    {
        PortCode: "13841",
        PortCountryCode: "CA",
        PortName: "Halifax, NS",
        StateCode: ""
    },
    {
        PortCode: "01520",
        PortCountryCode: "CA",
        PortName: "Hamilton, ONT",
        StateCode: ""
    },
    {
        PortCode: "13848",
        PortCountryCode: "CA",
        PortName: "Hantsport, NS",
        StateCode: ""
    },
    {
        PortCode: "15210",
        PortCountryCode: "CA",
        PortName: "Harbour Breton, NFLD",
        StateCode: ""
    },
    {
        PortCode: "15232",
        PortCountryCode: "CA",
        PortName: "Harbour Grace, NFLD",
        StateCode: ""
    },
    {
        PortCode: "12407",
        PortCountryCode: "CA",
        PortName: "Harmac, BC",
        StateCode: ""
    },
    {
        PortCode: "12200",
        PortCountryCode: "CA",
        PortName: "Hartley Bay, BC",
        StateCode: ""
    },
    {
        PortCode: "12200",
        PortCountryCode: "CA",
        PortName: "Hartley, BC",
        StateCode: ""
    },
    {
        PortCode: "09669",
        PortCountryCode: "CA",
        PortName: "Heron Bay, ONT",
        StateCode: ""
    },
    {
        PortCode: "15201",
        PortCountryCode: "CA",
        PortName: "Hibernia, NFLD",
        StateCode: ""
    },
    {
        PortCode: "12200",
        PortCountryCode: "CA",
        PortName: "Holsteinborg, BC",
        StateCode: ""
    },
    {
        PortCode: "15245",
        PortCountryCode: "CA",
        PortName: "Holyrood, NFLD",
        StateCode: ""
    },
    {
        PortCode: "12208",
        PortCountryCode: "CA",
        PortName: "Howie Sound, BC",
        StateCode: ""
    },
    {
        PortCode: "12200",
        PortCountryCode: "CA",
        PortName: "Jackson Bay, BC",
        StateCode: ""
    },
    {
        PortCode: "12217",
        PortCountryCode: "CA",
        PortName: "Jervis Inlet, BC",
        StateCode: ""
    },
    {
        PortCode: "01670",
        PortCountryCode: "CA",
        PortName: "Kingston, ONT",
        StateCode: ""
    },
    {
        PortCode: "03506",
        PortCountryCode: "CA",
        PortName: "Kingsville, ONT",
        StateCode: ""
    },
    {
        PortCode: "12287",
        PortCountryCode: "CA",
        PortName: "Kitimat, BC",
        StateCode: ""
    },
    {
        PortCode: "12209",
        PortCountryCode: "CA",
        PortName: "Ladysmith, BC",
        StateCode: ""
    },
    {
        PortCode: "01530",
        PortCountryCode: "CA",
        PortName: "Lakeview, ONT",
        StateCode: ""
    },
    {
        PortCode: "05515",
        PortCountryCode: "CA",
        PortName: "Lambton, ONT",
        StateCode: ""
    },
    {
        PortCode: "05515",
        PortCountryCode: "CA",
        PortName: "Lampton, ONT",
        StateCode: ""
    },
    {
        PortCode: "03508",
        PortCountryCode: "CA",
        PortName: "Leamington, ONT",
        StateCode: ""
    },
    {
        PortCode: "01832",
        PortCountryCode: "CA",
        PortName: "Levis, QUE",
        StateCode: ""
    },
    {
        PortCode: "06748",
        PortCountryCode: "CA",
        PortName: "Little Current, ONT",
        StateCode: ""
    },
    {
        PortCode: "13884",
        PortCountryCode: "CA",
        PortName: "Little Narrows, CBI",
        StateCode: ""
    },
    {
        PortCode: "13887",
        PortCountryCode: "CA",
        PortName: "Liverpool, NS",
        StateCode: ""
    },
    {
        PortCode: "15255",
        PortCountryCode: "CA",
        PortName: "Long Harbor, NFLD",
        StateCode: ""
    },
    {
        PortCode: "15260",
        PortCountryCode: "CA",
        PortName: "Long Pond, NFLD",
        StateCode: ""
    },
    {
        PortCode: "13437",
        PortCountryCode: "CA",
        PortName: "Louisburg, NS",
        StateCode: ""
    },
    {
        PortCode: "13851",
        PortCountryCode: "CA",
        PortName: "Lunenburg, NS",
        StateCode: ""
    },
    {
        PortCode: "13400",
        PortCountryCode: "CA",
        PortName: "Maces Bay, NB",
        StateCode: ""
    },
    {
        PortCode: "12200",
        PortCountryCode: "CA",
        PortName: "Maple Bay, BC",
        StateCode: ""
    },
    {
        PortCode: "09601",
        PortCountryCode: "CA",
        PortName: "Marathon, ONT",
        StateCode: ""
    },
    {
        PortCode: "15233",
        PortCountryCode: "CA",
        PortName: "Marystown, NFLD",
        StateCode: ""
    },
    {
        PortCode: "12210",
        PortCountryCode: "CA",
        PortName: "Masset, BC",
        StateCode: ""
    },
    {
        PortCode: "14070",
        PortCountryCode: "CA",
        PortName: "Matane, QUE",
        StateCode: ""
    },
    {
        PortCode: "12200",
        PortCountryCode: "CA",
        PortName: "McBride Bay, BC",
        StateCode: ""
    },
    {
        PortCode: "06766",
        PortCountryCode: "CA",
        PortName: "Meldrum Bay, ONT",
        StateCode: ""
    },
    {
        PortCode: "13870",
        PortCountryCode: "CA",
        PortName: "Meteghan, NS",
        StateCode: ""
    },
    {
        PortCode: "09555",
        PortCountryCode: "CA",
        PortName: "Michipicoten Harbor, ONT",
        StateCode: ""
    },
    {
        PortCode: "09555",
        PortCountryCode: "CA",
        PortName: "Michipicoten, ONT",
        StateCode: ""
    },
    {
        PortCode: "12490",
        PortCountryCode: "CA",
        PortName: "Mission, BC",
        StateCode: ""
    },
    {
        PortCode: "01530",
        PortCountryCode: "CA",
        PortName: "Mississauga, ONT",
        StateCode: ""
    },
    {
        PortCode: "08530",
        PortCountryCode: "CA",
        PortName: "Mitchell Bay, ONT",
        StateCode: ""
    },
    {
        PortCode: "14021",
        PortCountryCode: "CA",
        PortName: "Mont Louis, QUE",
        StateCode: ""
    },
    {
        PortCode: "14472",
        PortCountryCode: "CA",
        PortName: "Montaque, PEI",
        StateCode: ""
    },
    {
        PortCode: "01822",
        PortCountryCode: "CA",
        PortName: "Montreal, QUE",
        StateCode: ""
    },
    {
        PortCode: "01696",
        PortCountryCode: "CA",
        PortName: "Morrisburg, ONT",
        StateCode: ""
    },
    {
        PortCode: "13436",
        PortCountryCode: "CA",
        PortName: "Mulgrave, NS",
        StateCode: ""
    },
    {
        PortCode: "12406",
        PortCountryCode: "CA",
        PortName: "Nanaimo, BC",
        StateCode: ""
    },
    {
        PortCode: "06645",
        PortCountryCode: "CA",
        PortName: "Nanticoke, ONT",
        StateCode: ""
    },
    {
        PortCode: "12408",
        PortCountryCode: "CA",
        PortName: "New Westminster, BC",
        StateCode: ""
    },
    {
        PortCode: "14037",
        PortCountryCode: "CA",
        PortName: "Newcastle, NB",
        StateCode: ""
    },
    {
        PortCode: "12200",
        PortCountryCode: "CA",
        PortName: "Nootka, BC",
        StateCode: ""
    },
    {
        PortCode: "13853",
        PortCountryCode: "CA",
        PortName: "North Sydney, NS",
        StateCode: ""
    },
    {
        PortCode: "12498",
        PortCountryCode: "CA",
        PortName: "Oak Bay, BC",
        StateCode: ""
    },
    {
        PortCode: "06770",
        PortCountryCode: "CA",
        PortName: "Oakville, ONT",
        StateCode: ""
    },
    {
        PortCode: "03510",
        PortCountryCode: "CA",
        PortName: "Ojibway, ONT",
        StateCode: ""
    },
    {
        PortCode: "09670",
        PortCountryCode: "CA",
        PortName: "Oshawa, ONT",
        StateCode: ""
    },
    {
        PortCode: "07238",
        PortCountryCode: "CA",
        PortName: "Ottawa, ONT",
        StateCode: ""
    },
    {
        PortCode: "09554",
        PortCountryCode: "CA",
        PortName: "Otter Island, ONT",
        StateCode: ""
    },
    {
        PortCode: "06768",
        PortCountryCode: "CA",
        PortName: "Owen Sound, ONT",
        StateCode: ""
    },
    {
        PortCode: "06697",
        PortCountryCode: "CA",
        PortName: "Parry Sound, ONT",
        StateCode: ""
    },
    {
        PortCode: "03507",
        PortCountryCode: "CA",
        PortName: "Pelee Island, ONT",
        StateCode: ""
    },
    {
        PortCode: "01652",
        PortCountryCode: "CA",
        PortName: "Picton, ONT",
        StateCode: ""
    },
    {
        PortCode: "13854",
        PortCountryCode: "CA",
        PortName: "Pictou, NS",
        StateCode: ""
    },
    {
        PortCode: "14065",
        PortCountryCode: "CA",
        PortName: "Point Tupper, CBI",
        StateCode: ""
    },
    {
        PortCode: "14066",
        PortCountryCode: "CA",
        PortName: "Pointe Au Pic, QUE",
        StateCode: ""
    },
    {
        PortCode: "01823",
        PortCountryCode: "CA",
        PortName: "Pointe aux Trembles, ONT",
        StateCode: ""
    },
    {
        PortCode: "12200",
        PortCountryCode: "CA",
        PortName: "Porcher Island, BC",
        StateCode: ""
    },
    {
        PortCode: "12202",
        PortCountryCode: "CA",
        PortName: "Port Alberni, BC",
        StateCode: ""
    },
    {
        PortCode: "14069",
        PortCountryCode: "CA",
        PortName: "Port Alfred, QUE",
        StateCode: ""
    },
    {
        PortCode: "12422",
        PortCountryCode: "CA",
        PortName: "Port Alice, BC",
        StateCode: ""
    },
    {
        PortCode: "09662",
        PortCountryCode: "CA",
        PortName: "Port Arthur, ONT",
        StateCode: ""
    },
    {
        PortCode: "14073",
        PortCountryCode: "CA",
        PortName: "Port Cartier, QUE",
        StateCode: ""
    },
    {
        PortCode: "12204",
        PortCountryCode: "CA",
        PortName: "Port Clements, BC",
        StateCode: ""
    },
    {
        PortCode: "02641",
        PortCountryCode: "CA",
        PortName: "Port Colborne, ONT",
        StateCode: ""
    },
    {
        PortCode: "01530",
        PortCountryCode: "CA",
        PortName: "Port Credit, ONT",
        StateCode: ""
    },
    {
        PortCode: "12420",
        PortCountryCode: "CA",
        PortName: "Port Edward, BC",
        StateCode: ""
    },
    {
        PortCode: "12211",
        PortCountryCode: "CA",
        PortName: "Port Hardy, BC",
        StateCode: ""
    },
    {
        PortCode: "13849",
        PortCountryCode: "CA",
        PortName: "Port Hawkesbury, NS",
        StateCode: ""
    },
    {
        PortCode: "01700",
        PortCountryCode: "CA",
        PortName: "Port Johnstown, ONT",
        StateCode: ""
    },
    {
        PortCode: "13430",
        PortCountryCode: "CA",
        PortName: "Port Maitland, NS",
        StateCode: ""
    },
    {
        PortCode: "12426",
        PortCountryCode: "CA",
        PortName: "Port Mann, BC",
        StateCode: ""
    },
    {
        PortCode: "12428",
        PortCountryCode: "CA",
        PortName: "Port Mellon, BC",
        StateCode: ""
    },
    {
        PortCode: "12430",
        PortCountryCode: "CA",
        PortName: "Port Moody, BC",
        StateCode: ""
    },
    {
        PortCode: "13420",
        PortCountryCode: "CA",
        PortName: "Port of Digby, NS",
        StateCode: ""
    },
    {
        PortCode: "12444",
        PortCountryCode: "CA",
        PortName: "Port Simpson, BC",
        StateCode: ""
    },
    {
        PortCode: "02566",
        PortCountryCode: "CA",
        PortName: "Port Stanley, ONT",
        StateCode: ""
    },
    {
        PortCode: "09663",
        PortCountryCode: "CA",
        PortName: "Port Weller, ONT",
        StateCode: ""
    },
    {
        PortCode: "09671",
        PortCountryCode: "CA",
        PortName: "Port Whitby, ONT",
        StateCode: ""
    },
    {
        PortCode: "12441",
        PortCountryCode: "CA",
        PortName: "Powell River, BC",
        StateCode: ""
    },
    {
        PortCode: "01700",
        PortCountryCode: "CA",
        PortName: "Prescott, ONT",
        StateCode: ""
    },
    {
        PortCode: "12442",
        PortCountryCode: "CA",
        PortName: "Prince Rupert, BC",
        StateCode: ""
    },
    {
        PortCode: "14094",
        PortCountryCode: "CA",
        PortName: "Quebec, QUE",
        StateCode: ""
    },
    {
        PortCode: "15211",
        PortCountryCode: "CA",
        PortName: "Ramea, NFLD",
        StateCode: ""
    },
    {
        PortCode: "13400",
        PortCountryCode: "CA",
        PortName: "Red Head, NB",
        StateCode: ""
    },
    {
        PortCode: "13454",
        PortCountryCode: "CA",
        PortName: "Richardson Island, NB",
        StateCode: ""
    },
    {
        PortCode: "13454",
        PortCountryCode: "CA",
        PortName: "Richardson, NB",
        StateCode: ""
    },
    {
        PortCode: "12497",
        PortCountryCode: "CA",
        PortName: "Richmond, BC",
        StateCode: ""
    },
    {
        PortCode: "14068",
        PortCountryCode: "CA",
        PortName: "Rimouski, QUE",
        StateCode: ""
    },
    {
        PortCode: "12489",
        PortCountryCode: "CA",
        PortName: "Robert’s Bank, BC",
        StateCode: ""
    },
    {
        PortCode: "02643",
        PortCountryCode: "CA",
        PortName: "Rockport, ONT",
        StateCode: ""
    },
    {
        PortCode: "01697",
        PortCountryCode: "CA",
        PortName: "Rocky Point, ONT",
        StateCode: ""
    },
    {
        PortCode: "14095",
        PortCountryCode: "CA",
        PortName: "Rupert Inlet, QUE",
        StateCode: ""
    },
    {
        PortCode: "14426",
        PortCountryCode: "CA",
        PortName: "Saint Andrews, NB",
        StateCode: ""
    },
    {
        PortCode: "15212",
        PortCountryCode: "CA",
        PortName: "Saint Anthony, NFLD",
        StateCode: ""
    },
    {
        PortCode: "01690",
        PortCountryCode: "CA",
        PortName: "Saint Catharines, ONT",
        StateCode: ""
    },
    {
        PortCode: "14427",
        PortCountryCode: "CA",
        PortName: "Saint George, NB",
        StateCode: ""
    },
    {
        PortCode: "14429",
        PortCountryCode: "CA",
        PortName: "Saint Jean, QUE",
        StateCode: ""
    },
    {
        PortCode: "14428",
        PortCountryCode: "CA",
        PortName: "Saint John, NB",
        StateCode: ""
    },
    {
        PortCode: "15282",
        PortCountryCode: "CA",
        PortName: "Saint Johns, NFLD",
        StateCode: ""
    },
    {
        PortCode: "14429",
        PortCountryCode: "CA",
        PortName: "Saint Johns, QUE",
        StateCode: ""
    },
    {
        PortCode: "15283",
        PortCountryCode: "CA",
        PortName: "Saint Lawrence, NFLD",
        StateCode: ""
    },
    {
        PortCode: "14438",
        PortCountryCode: "CA",
        PortName: "Saint Stephen, NB",
        StateCode: ""
    },
    {
        PortCode: "12200",
        PortCountryCode: "CA",
        PortName: "San Mateo, BC",
        StateCode: ""
    },
    {
        PortCode: "13400",
        PortCountryCode: "CA",
        PortName: "Sandy Cove, NS",
        StateCode: ""
    },
    {
        PortCode: "05520",
        PortCountryCode: "CA",
        PortName: "Sarnia, ONT",
        StateCode: ""
    },
    {
        PortCode: "08525",
        PortCountryCode: "CA",
        PortName: "Sault Ste. Marie, ONT",
        StateCode: ""
    },
    {
        PortCode: "03507",
        PortCountryCode: "CA",
        PortName: "Scudder, ONT",
        StateCode: ""
    },
    {
        PortCode: "13829",
        PortCountryCode: "CA",
        PortName: "Seal Cove, NB",
        StateCode: ""
    },
    {
        PortCode: "12219",
        PortCountryCode: "CA",
        PortName: "Sechelt, BC",
        StateCode: ""
    },
    {
        PortCode: "13829",
        PortCountryCode: "CA",
        PortName: "Seelys Basin, NB",
        StateCode: ""
    },
    {
        PortCode: "13829",
        PortCountryCode: "CA",
        PortName: "Seelys Cove, NB",
        StateCode: ""
    },
    {
        PortCode: "13829",
        PortCountryCode: "CA",
        PortName: "Seelys Head, NB",
        StateCode: ""
    },
    {
        PortCode: "14444",
        PortCountryCode: "CA",
        PortName: "Sept Iles, QUE",
        StateCode: ""
    },
    {
        PortCode: "06767",
        PortCountryCode: "CA",
        PortName: "Serpent Harbor, ONT",
        StateCode: ""
    },
    {
        PortCode: "14444",
        PortCountryCode: "CA",
        PortName: "Seven Islands, QUE",
        StateCode: ""
    },
    {
        PortCode: "13888",
        PortCountryCode: "CA",
        PortName: "Sheet Harbour, NS",
        StateCode: ""
    },
    {
        PortCode: "13455",
        PortCountryCode: "CA",
        PortName: "Shelburne, NS",
        StateCode: ""
    },
    {
        PortCode: "12468",
        PortCountryCode: "CA",
        PortName: "Sidney, BC",
        StateCode: ""
    },
    {
        PortCode: "14092",
        PortCountryCode: "CA",
        PortName: "Sillery, QUE",
        StateCode: ""
    },
    {
        PortCode: "09000",
        PortCountryCode: "CA",
        PortName: "Slate Islands, ONT",
        StateCode: ""
    },
    {
        PortCode: "05512",
        PortCountryCode: "CA",
        PortName: "Sombra, ONT",
        StateCode: ""
    },
    {
        PortCode: "08525",
        PortCountryCode: "CA",
        PortName: "Soo, ONT",
        StateCode: ""
    },
    {
        PortCode: "12205",
        PortCountryCode: "CA",
        PortName: "Sooke, BC",
        StateCode: ""
    },
    {
        PortCode: "14466",
        PortCountryCode: "CA",
        PortName: "Sorel, QUE",
        StateCode: ""
    },
    {
        PortCode: "14467",
        PortCountryCode: "CA",
        PortName: "Souris, PEI",
        StateCode: ""
    },
    {
        PortCode: "12200",
        PortCountryCode: "CA",
        PortName: "South Pender Island, BC",
        StateCode: ""
    },
    {
        PortCode: "13400",
        PortCountryCode: "CA",
        PortName: "Spences Island, NS",
        StateCode: ""
    },
    {
        PortCode: "06767",
        PortCountryCode: "CA",
        PortName: "Spragge, ONT",
        StateCode: ""
    },
    {
        PortCode: "12208",
        PortCountryCode: "CA",
        PortName: "Squamish, BC",
        StateCode: ""
    },
    {
        PortCode: "14426",
        PortCountryCode: "CA",
        PortName: "St. Andrews, NB",
        StateCode: ""
    },
    {
        PortCode: "15212",
        PortCountryCode: "CA",
        PortName: "St. Anthony, NFLD",
        StateCode: ""
    },
    {
        PortCode: "01690",
        PortCountryCode: "CA",
        PortName: "St. Catharines, ONT",
        StateCode: ""
    },
    {
        PortCode: "15282",
        PortCountryCode: "CA",
        PortName: "St. Johns, NFLD",
        StateCode: ""
    },
    {
        PortCode: "15283",
        PortCountryCode: "CA",
        PortName: "St. Lawrence, NFLD",
        StateCode: ""
    },
    {
        PortCode: "14090",
        PortCountryCode: "CA",
        PortName: "St. Romuald, QUE",
        StateCode: ""
    },
    {
        PortCode: "12475",
        PortCountryCode: "CA",
        PortName: "Stanovan, BC",
        StateCode: ""
    },
    {
        PortCode: "15285",
        PortCountryCode: "CA",
        PortName: "Stephenville, NFLD",
        StateCode: ""
    },
    {
        PortCode: "12200",
        PortCountryCode: "CA",
        PortName: "Steveston, BC",
        StateCode: ""
    },
    {
        PortCode: "12478",
        PortCountryCode: "CA",
        PortName: "Stewart, BC",
        StateCode: ""
    },
    {
        PortCode: "14470",
        PortCountryCode: "CA",
        PortName: "Summerside, PEI",
        StateCode: ""
    },
    {
        PortCode: "13852",
        PortCountryCode: "CA",
        PortName: "Sydney, NS",
        StateCode: ""
    },
    {
        PortCode: "12482",
        PortCountryCode: "CA",
        PortName: "Tahsis, BC",
        StateCode: ""
    },
    {
        PortCode: "12206",
        PortCountryCode: "CA",
        PortName: "Texada, BC",
        StateCode: ""
    },
    {
        PortCode: "08527",
        PortCountryCode: "CA",
        PortName: "Thessalon, ONT",
        StateCode: ""
    },
    {
        PortCode: "02649",
        PortCountryCode: "CA",
        PortName: "Thorold, ONT",
        StateCode: ""
    },
    {
        PortCode: "14474",
        PortCountryCode: "CA",
        PortName: "Three Rivers, QUE",
        StateCode: ""
    },
    {
        PortCode: "09662",
        PortCountryCode: "CA",
        PortName: "Thunder Bay, ONT",
        StateCode: ""
    },
    {
        PortCode: "01535",
        PortCountryCode: "CA",
        PortName: "Toronto, ONT",
        StateCode: ""
    },
    {
        PortCode: "01807",
        PortCountryCode: "CA",
        PortName: "Tracy, QUE",
        StateCode: ""
    },
    {
        PortCode: "14474",
        PortCountryCode: "CA",
        PortName: "Trois Rivieres, QUE",
        StateCode: ""
    },
    {
        PortCode: "13470",
        PortCountryCode: "CA",
        PortName: "Tuktoyaktuk, NWT",
        StateCode: ""
    },
    {
        PortCode: "12208",
        PortCountryCode: "CA",
        PortName: "Twin Creeks, BC",
        StateCode: ""
    },
    {
        PortCode: "12218",
        PortCountryCode: "CA",
        PortName: "Ucuelet, BC",
        StateCode: ""
    },
    {
        PortCode: "12491",
        PortCountryCode: "CA",
        PortName: "Union Bay, BC",
        StateCode: ""
    },
    {
        PortCode: "14067",
        PortCountryCode: "CA",
        PortName: "Valleyfield, QUE",
        StateCode: ""
    },
    {
        PortCode: "12492",
        PortCountryCode: "CA",
        PortName: "Van Anda, BC",
        StateCode: ""
    },
    {
        PortCode: "12493",
        PortCountryCode: "CA",
        PortName: "Vancouver, BC",
        StateCode: ""
    },
    {
        PortCode: "06682",
        PortCountryCode: "CA",
        PortName: "Victoria Harbor, ONT",
        StateCode: ""
    },
    {
        PortCode: "12494",
        PortCountryCode: "CA",
        PortName: "Victoria, BC",
        StateCode: ""
    },
    {
        PortCode: "13456",
        PortCountryCode: "CA",
        PortName: "Victoria, PEI",
        StateCode: ""
    },
    {
        PortCode: "09605",
        PortCountryCode: "CA",
        PortName: "Walkerville, ONT",
        StateCode: ""
    },
    {
        PortCode: "12420",
        PortCountryCode: "CA",
        PortName: "Watson Island, BC",
        StateCode: ""
    },
    {
        PortCode: "02645",
        PortCountryCode: "CA",
        PortName: "Welland, ONT",
        StateCode: ""
    },
    {
        PortCode: "13457",
        PortCountryCode: "CA",
        PortName: "Welshpool, NB",
        StateCode: ""
    },
    {
        PortCode: "15230",
        PortCountryCode: "CA",
        PortName: "Whiffen Head, NF",
        StateCode: ""
    },
    {
        PortCode: "09671",
        PortCountryCode: "CA",
        PortName: "Whitby, ONT",
        StateCode: ""
    },
    {
        PortCode: "12200",
        PortCountryCode: "CA",
        PortName: "White Rock, BC",
        StateCode: ""
    },
    {
        PortCode: "09667",
        PortCountryCode: "CA",
        PortName: "Whitefish River, ONT",
        StateCode: ""
    },
    {
        PortCode: "12488",
        PortCountryCode: "CA",
        PortName: "Whonnock, BC",
        StateCode: ""
    },
    {
        PortCode: "12488",
        PortCountryCode: "CA",
        PortName: "Whonock, BC",
        StateCode: ""
    },
    {
        PortCode: "09000",
        PortCountryCode: "CA",
        PortName: "Wiarton, ONT",
        StateCode: ""
    },
    {
        PortCode: "13400",
        PortCountryCode: "CA",
        PortName: "Wilsons Beach, NB",
        StateCode: ""
    },
    {
        PortCode: "03512",
        PortCountryCode: "CA",
        PortName: "Windsor, ONT",
        StateCode: ""
    },
    {
        PortCode: "15200",
        PortCountryCode: "CA",
        PortName: "Witless Bay, NFLD",
        StateCode: ""
    },
    {
        PortCode: "12212",
        PortCountryCode: "CA",
        PortName: "Woodfibre, BC",
        StateCode: ""
    },
    {
        PortCode: "13829",
        PortCountryCode: "CA",
        PortName: "Woodwards Cove, NB",
        StateCode: ""
    },
    {
        PortCode: "13847",
        PortCountryCode: "CA",
        PortName: "Yarmouth, NS",
        StateCode: ""
    },
    {
        PortCode: "20101",
        PortCountryCode: "MX",
        PortName: "Acapulco",
        StateCode: ""
    },
    {
        PortCode: "20195",
        PortCountryCode: "MX",
        PortName: "All Other Mexico East Coast Region Ports",
        StateCode: ""
    },
    {
        PortCode: "20193",
        PortCountryCode: "MX",
        PortName: "Altamira",
        StateCode: ""
    },
    {
        PortCode: "20195",
        PortCountryCode: "MX",
        PortName: "Alvarado",
        StateCode: ""
    },
    {
        PortCode: "20153",
        PortCountryCode: "MX",
        PortName: "Alvaro Obregon",
        StateCode: ""
    },
    {
        PortCode: "20144",
        PortCountryCode: "MX",
        PortName: "Cabo San Lucas",
        StateCode: ""
    },
    {
        PortCode: "20161",
        PortCountryCode: "MX",
        PortName: "Calica",
        StateCode: ""
    },
    {
        PortCode: "20162",
        PortCountryCode: "MX",
        PortName: "Cancun",
        StateCode: ""
    },
    {
        PortCode: "20157",
        PortCountryCode: "MX",
        PortName: "Carmen",
        StateCode: ""
    },
    {
        PortCode: "20152",
        PortCountryCode: "MX",
        PortName: "Cayo Arcas",
        StateCode: ""
    },
    {
        PortCode: "20152",
        PortCountryCode: "MX",
        PortName: "Cayo Arcos Terminal",
        StateCode: ""
    },
    {
        PortCode: "20104",
        PortCountryCode: "MX",
        PortName: "Cerros Island",
        StateCode: ""
    },
    {
        PortCode: "20195",
        PortCountryCode: "MX",
        PortName: "Champoton",
        StateCode: ""
    },
    {
        PortCode: "20174",
        PortCountryCode: "MX",
        PortName: "Chetumal",
        StateCode: ""
    },
    {
        PortCode: "20195",
        PortCountryCode: "MX",
        PortName: "Ciudad Camargo",
        StateCode: ""
    },
    {
        PortCode: "20195",
        PortCountryCode: "MX",
        PortName: "Ciudad Chetumal",
        StateCode: ""
    },
    {
        PortCode: "20157",
        PortCountryCode: "MX",
        PortName: "Ciudad de Carmen",
        StateCode: ""
    },
    {
        PortCode: "20171",
        PortCountryCode: "MX",
        PortName: "Coatzacoalcos",
        StateCode: ""
    },
    {
        PortCode: "20173",
        PortCountryCode: "MX",
        PortName: "Cozumel Island",
        StateCode: ""
    },
    {
        PortCode: "20154",
        PortCountryCode: "MX",
        PortName: "Dos Bocas",
        StateCode: ""
    },
    {
        PortCode: "20195",
        PortCountryCode: "MX",
        PortName: "El Cuyo",
        StateCode: ""
    },
    {
        PortCode: "20142",
        PortCountryCode: "MX",
        PortName: "El Sauzal",
        StateCode: ""
    },
    {
        PortCode: "20108",
        PortCountryCode: "MX",
        PortName: "Ensenada",
        StateCode: ""
    },
    {
        PortCode: "20153",
        PortCountryCode: "MX",
        PortName: "Frontera",
        StateCode: ""
    },
    {
        PortCode: "20113",
        PortCountryCode: "MX",
        PortName: "Guaymas",
        StateCode: ""
    },
    {
        PortCode: "20195",
        PortCountryCode: "MX",
        PortName: "Gutierrez Zamorra",
        StateCode: ""
    },
    {
        PortCode: "20104",
        PortCountryCode: "MX",
        PortName: "Isla Cedros",
        StateCode: ""
    },
    {
        PortCode: "20195",
        PortCountryCode: "MX",
        PortName: "Isla de Lobos",
        StateCode: ""
    },
    {
        PortCode: "20179",
        PortCountryCode: "MX",
        PortName: "Isla Mujeres",
        StateCode: ""
    },
    {
        PortCode: "20128",
        PortCountryCode: "MX",
        PortName: "Islas Coronados",
        StateCode: ""
    },
    {
        PortCode: "20105",
        PortCountryCode: "MX",
        PortName: "La Paz",
        StateCode: ""
    },
    {
        PortCode: "20157",
        PortCountryCode: "MX",
        PortName: "Laguna de Terminos",
        StateCode: ""
    },
    {
        PortCode: "20107",
        PortCountryCode: "MX",
        PortName: "Lazaro Cardenas",
        StateCode: ""
    },
    {
        PortCode: "20174",
        PortCountryCode: "MX",
        PortName: "Mahahual",
        StateCode: ""
    },
    {
        PortCode: "20195",
        PortCountryCode: "MX",
        PortName: "Matamoros",
        StateCode: ""
    },
    {
        PortCode: "20122",
        PortCountryCode: "MX",
        PortName: "Mazatlan",
        StateCode: ""
    },
    {
        PortCode: "20181",
        PortCountryCode: "MX",
        PortName: "Minatitlan",
        StateCode: ""
    },
    {
        PortCode: "20104",
        PortCountryCode: "MX",
        PortName: "Morro Redondo",
        StateCode: ""
    },
    {
        PortCode: "20195",
        PortCountryCode: "MX",
        PortName: "Nautla",
        StateCode: ""
    },
    {
        PortCode: "20171",
        PortCountryCode: "MX",
        PortName: "Ostion",
        StateCode: ""
    },
    {
        PortCode: "20184",
        PortCountryCode: "MX",
        PortName: "Pajaritos",
        StateCode: ""
    },
    {
        PortCode: "20185",
        PortCountryCode: "MX",
        PortName: "Progreso",
        StateCode: ""
    },
    {
        PortCode: "20194",
        PortCountryCode: "MX",
        PortName: "Puerto de Morales",
        StateCode: ""
    },
    {
        PortCode: "20195",
        PortCountryCode: "MX",
        PortName: "Puerto Juarez",
        StateCode: ""
    },
    {
        PortCode: "20171",
        PortCountryCode: "MX",
        PortName: "Puerto Libre De Puerto Mexico",
        StateCode: ""
    },
    {
        PortCode: "20195",
        PortCountryCode: "MX",
        PortName: "Puerto Lobos",
        StateCode: ""
    },
    {
        PortCode: "20193",
        PortCountryCode: "MX",
        PortName: "Puerto Madero",
        StateCode: ""
    },
    {
        PortCode: "20171",
        PortCountryCode: "MX",
        PortName: "Puerto Mexico",
        StateCode: ""
    },
    {
        PortCode: "20194",
        PortCountryCode: "MX",
        PortName: "Puerto Morales",
        StateCode: ""
    },
    {
        PortCode: "20104",
        PortCountryCode: "MX",
        PortName: "Puerto Morro Redondo",
        StateCode: ""
    },
    {
        PortCode: "20143",
        PortCountryCode: "MX",
        PortName: "Puerto Vallarta",
        StateCode: ""
    },
    {
        PortCode: "20104",
        PortCountryCode: "MX",
        PortName: "Puerto Venustiano Carranza",
        StateCode: ""
    },
    {
        PortCode: "20173",
        PortCountryCode: "MX",
        PortName: "Punta Venado",
        StateCode: ""
    },
    {
        PortCode: "20125",
        PortCountryCode: "MX",
        PortName: "Rosarito",
        StateCode: ""
    },
    {
        PortCode: "20129",
        PortCountryCode: "MX",
        PortName: "Salina Cruz",
        StateCode: ""
    },
    {
        PortCode: "20144",
        PortCountryCode: "MX",
        PortName: "San Lucas",
        StateCode: ""
    },
    {
        PortCode: "20135",
        PortCountryCode: "MX",
        PortName: "San Marcos Island",
        StateCode: ""
    },
    {
        PortCode: "20141",
        PortCountryCode: "MX",
        PortName: "Santa Rosalia",
        StateCode: ""
    },
    {
        PortCode: "20142",
        PortCountryCode: "MX",
        PortName: "Sauzal",
        StateCode: ""
    },
    {
        PortCode: "20193",
        PortCountryCode: "MX",
        PortName: "Tampico",
        StateCode: ""
    },
    {
        PortCode: "20195",
        PortCountryCode: "MX",
        PortName: "Tecolutla",
        StateCode: ""
    },
    {
        PortCode: "20195",
        PortCountryCode: "MX",
        PortName: "Tlacotalpan",
        StateCode: ""
    },
    {
        PortCode: "20106",
        PortCountryCode: "MX",
        PortName: "Topolobampo",
        StateCode: ""
    },
    {
        PortCode: "20197",
        PortCountryCode: "MX",
        PortName: "Tuxpan",
        StateCode: ""
    },
    {
        PortCode: "20195",
        PortCountryCode: "MX",
        PortName: "Xcalac",
        StateCode: ""
    },
    {
        PortCode: "97117",
        PortCountryCode: "MX",
        PortName: "Mexico",
        StateCode: ""
    }
]

export const validationsStructure = [
    { col: 'Tracking Number', key: 'tracking_number', level: 'root', min: 3, max: 12 },
    { col: 'Shipment Type', key: 'shipment_type', level: 'root', min: 3, max: 100 },
    { col: 'Shipper Name', key: 'company', level: 'from', min: 3, max: 45 },
    { col: 'Shipper Address', key: 'address1', level: 'from', min: 3, max: 80 },
    { col: 'Shipper City', key: 'city', level: 'from', min: 3, max: 50 },
    { col: 'Shipper Country', key: 'country_code', level: 'from', min: 2, max: 50 },
    { col: 'Shipper State', key: 'state_code', level: 'from', min: 2, max: 50 },
    { col: 'Shipper ZIP', key: 'zip', level: 'from', min: 3, max: 50 },
    { col: 'Port of Lading', key: 'port_of_lading', level: 'from', min: 3, max: 100 },
    { col: 'Consignee Name', key: 'name', level: 'to', min: 3, max: 45 },
    { col: 'Consignee Address', key: 'address1', level: 'to', min: 3, max: 80 },
    { col: 'Consignee City', key: 'city', level: 'to', min: 3, max: 50 },
    { col: 'Consignee Country', key: 'country', level: 'to', min: 2, max: 50 },
    { col: 'Consignee State', key: 'state', level: 'to', min: 2, max: 50 },
    { col: 'Consignee ZIP', key: 'zip', level: 'to', min: 3, max: 50 },
    { col: 'Product Description', key: 'product_description', level: 'root', min: 3, max: 45 },
    { col: 'Qty', key: 'quantity', level: 'root', min: 1, max: 5 },
    { col: 'Product UOM', key: 'product_uom', level: 'root', min: 2, max: 50 },
    { col: 'Product Weight', key: 'product_weight', level: 'root', min: 1, max: 50 },
    { col: 'Unit Weight', key: 'weight_unit', level: 'root', min: 1, max: 50 },
    { col: 'Cost', key: 'shipping_cost', level: 'root', min: 1, max: 800 },
    { col: 'Origin Country', key: 'origin_country', level: 'root', min: 2, max: 50 },
]