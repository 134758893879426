import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Button } from 'semantic-ui-react';
import { FlatfileButton } from '@flatfile/react'
import feathers from '../../services/feathers';

const TopNavigation = ({ isBusy, onCreateClick, onSaveBulkData, isValid }) => {

  const [paises, setPaises] = useState();

  useEffect(() => {
    const funcAsync = async ()=>{
      const paisService = await feathers.service('catalogo-pais');
      let catalogoPais =await paisService.find()
      let catalogoCodigoPais = catalogoPais.data.map((pais)=>{
        return { value: pais.CPaisID, label: pais.c_Pais }
      })
      console.log(catalogoCodigoPais)
      setPaises(catalogoCodigoPais)
    }
    funcAsync()
  },[]);

  return (
    <>
    {paises && <FlatfileButton
        settings={{
            type: "prueba",
            fields: [
                { label: "Codigo", key: "CodigoChofer"},
                { label: "Nombre", key: "Nombre", validators: [ { validate: "required" }]},
                { label: "Tipo Figura Transportista", key: "TipoFiguraTransportista", validators: [ { validate: "required" }] },
                { label: "Licencia", key: "NumeroLicencia", validators: [ { validate: "required" }] },
                { label: "RFC", key: "RFC", validators: [ { validate: "required" }] },
                { label: "Pais Residencia Fiscal", key: "PaisResidenciaFiscal", type: "select", options: paises },
                { label: "Registro Tributario", key: "NumRegistroTributario" },
                { label: "Calle", key: "Calle" },
                { label: "Numero Exterior", key: "NumExterior" },
                { label: "Numero Interior", key: "NumInterior" },
                { label: "Referencia", key: "Referencia" },
                { label: "Pais", key: "Pais" },
                { label: "Estado", key: "Estado" },
                { label: "Municipio", key: "Municipio" },
                { label: "Localidad", key: "Localidad" },
                { label: "CodigoPostal", key: "CodigoPostal" },
                { label: "Colonia", key: "Colonia" }
            ]
        }}
        licenseKey={"8966b5ef-41eb-4d04-806b-f05b03741e6e"}
        customer={{
            companyId: "ABC-123",
            companyName: "ABC Corp.",
            email: "john@abc123.com",
            name: "John Smith",
            userId: "12345"
        }}
        onData={async (results) => {
          // do something with the results
          onSaveBulkData(results)
        }}
        render={(importer, launch) => {
          return <Button primary compact onClick={launch} disabled={isBusy || !isValid}>Upload file</Button>;
        }}
      />
}
      &nbsp;
    <Button
      primary
      compact
      onClick={onCreateClick}
      disabled={isBusy || !isValid}
    >
      Create Chofer
    </Button>
    </>
  );
};

TopNavigation.propTypes = {
  isBusy: PropTypes.bool,
  onCreateClick: PropTypes.func,
  isValid: PropTypes.bool,
};

export default TopNavigation;
