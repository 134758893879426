import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import TableContainer from '../../components/TableContainer';
import { AgGridReact } from '@ag-grid-community/react';
import { AllModules } from '@ag-grid-enterprise/all-modules';
import 'lodash';
import { Button } from 'semantic-ui-react';
import swal from 'sweetalert';
import { price } from '../../components/AgGridColumns';
import feathers from '../../services/feathers';

const Table = ({
  query,
  onEditClick,
  selection,
  setSelection,

  ...other
}) => {
  const service = feathers.service('product-data');

  // States
  const [gridApi, setGridApi] = useState();
  const [gridColumnApi, setGridColumnApi] = useState(null);
  const [columnDefs] = useState([
    ...(selection && setSelection
      ? [
          {
            headerName: '',
            field: '',
            width: 70,
            checkboxSelection: true,
            headerCheckboxSelection: true,
            suppressMenu: true,
            pinned: 'left',
            enableRowGroup: false,
            resizable: false,
            sortable: false,
          },
        ]
      : []),

    { field: 'barcode', headerName: 'BARCODE' },
    { field: 'sku', headerName: 'SKU' },
    { field: 'name', headerName: 'NAME' },
    {
      ...price,

      filter: false,
      headerName: 'Cost',
      field: 'cost',
    },
    { field: 'coco', headerName: 'COO' },
    { field: 'fiber', headerName: 'FIBER/CONTENT' },
    { field: 'hts', headerName: 'HTS CODE' },
    { field: 'exportCode', headerName: 'EXPORT CODE' },
    { field: 'rate', headerName: 'RATE' },
    { field: 'description', headerName: 'DESCRIPTION' },
    { field: 'customer', headerName: 'CUSTOMER' },
    { field: 'tariffAdditional', headerName: '7% Additional Tariff' },
    { field: 'tariff321', headerName: 'Section 301 Part 3 990388' },
    { field: 'is321', headerName: 'Is 321' },

    ...(onEditClick
      ? [
          {
            headerName: '',
            field: '',
            pinned: 'right',
            cellStyle: { textAlign: 'center' },

            cellRendererFramework: (params) => {
              if (params.data)
                return (
                  <Button
                    compact
                    primary
                    onClick={() => handleEditClick(params.data)}
                  >
                    Edit
                  </Button>
                );
            },
          },
        ]
      : []),
  ]);
  const [rowClassRules] = useState({
    'ag-row-warning': (params) => {
      if (params && params.data) {
        // const fields = ['barcode', 'sku', 'name', 'cost', 'coco', 'hts', 'rate', 'customer', 'fiber', 'exportCode', 'description', 'tariffAdditional', 'tariff321', 'addFromValidation'];
        // const fields = ['sku', 'name', 'cost', 'addFromValidation'];
        const fields = ['addFromValidation'];
        const incomplete = fields
          .slice(0) // Creamos una copia de los campos a validar
          .reduce((incomplete, field, _, arr) => {
            if (field === 'addFromValidation') {
              incomplete = params.data[field] === true || false;
            } else {
              incomplete = !(
                (params.data[field] && params.data[field].length && true) ||
                false
              );
            }

            if (incomplete) {
              arr.splice(1); // Detemos la validacion, para los demas campos
            }

            return incomplete;
          }, false);

        return incomplete;
      }

      return false;
    },
  });
  const [defaultColDef] = useState({ sortable: true, filter: false });

  // Effects
  useEffect(() => {
    // console.debug('useEffect Table', selection, (gridApi && gridApi.getSelectedRows()));
    if (
      selection &&
      !selection.length &&
      gridApi &&
      gridApi.getSelectedRows().length
    ) {
      gridApi.deselectAll();
    }
  }, [selection, gridApi]);

  const getSkip = ({ startRow }) => startRow || 0;

  const getLimit = ({ endRow, startRow }) => endRow - startRow || 0;

  const getSort = ({ sortModel }) => {
    const $sort =
      (Array.isArray(sortModel) &&
        sortModel.length &&
        sortModel.reduce((acc, { colId, sort }) => {
          return {
            ...acc,

            [colId]: sort === 'asc' ? 1 : -1,
          };
        }, {})) ||
      {};

    return $sort;
  };

  useEffect(() => {
    if (gridApi) {
      // console.count('Table useEffect gridApi, query');
      setSelection([]);

      gridApi.setServerSideDatasource({
        getRows: async (params) => {
          console.debug('params', params);

          try {
            const result = await service.find({
              query: {
                ...query,

                $skip: getSkip(params.request),
                $limit: getLimit(params.request),
                $sort: getSort(params.request),
              },
            });

            // console.debug('result', result);
            // Es necesario agregar uno, dado que el componente del grid elimina uno
            params.successCallback(
              result.total ? [...result.data, {}] : [],
              result.total
            );
          } catch (err) {
            console.error('Error', err);

            swal({
              title: 'Error',
              text: 'Failed get Products',
              icon: 'error',
              buttons: {
                cancel: 'Close',
              },
            });

            params.failCallback();
          }
        },

        destroy: () => console.debug('destroy'),
      });
    }
  }, [gridApi, query, service, setSelection]);

  // useEffect(() => {
  //   if (gridColumnApi) {
  //     var allColumnIds = gridColumnApi
  //       .getAllColumns()
  //       .filter((column) => column.colDef.field !== '')
  //       .map((column) => column.colId);

  //     gridColumnApi.autoSizeColumns
  //       && gridColumnApi.autoSizeColumns(allColumnIds, false);
  //   }
  // }, [data, gridColumnApi]);

  // Handles
  const handleEditClick = (product) => {
    onEditClick && onEditClick({ ...product });
  };

  const handleSelectionChanged = (event) => {
    if (selection && setSelection) {
      let rows = [];

      try {
        if (event && event.api) {
          rows = event.api.getSelectedRows().map((row) => row._id);
        }
      } catch (err) {
        rows = [];

        swal({
          title: 'Error',
          text: 'Failed to select products',
          icon: 'error',
          buttons: {
            cancel: 'Close',
          },
        });
      } finally {
        // console.debug('onSelectionChanged event rows', '[' + rows.map(item => `ObjectId('${item}')`).join(', ') + ']');
        setSelection(rows);
      }
    }
  };

  const handleGridReady = ({ api, columnApi }) => {
    setGridApi(api);
    setGridColumnApi(columnApi);
  };

  const handleModelUpdated = (event) => {
    if (gridColumnApi) {
      var allColumnIds = gridColumnApi
        .getAllColumns()
        .filter((column) => column.colDef.field !== '')
        .map((column) => column.colId);

      gridColumnApi.autoSizeColumns &&
        gridColumnApi.autoSizeColumns(allColumnIds, false);
    }
  };

  return (
    <TableContainer className="ag-theme-material" rowFilters={1}>
      <AgGridReact
        modules={AllModules}
        columnDefs={columnDefs}
        rowClassRules={rowClassRules}
        defaultColDef={defaultColDef}
        {...(selection && setSelection
          ? {
              rowSelection: 'multiple',
              suppressRowClickSelection: true,
              onSelectionChanged: handleSelectionChanged,
            }
          : {})}
        pagination={true}
        paginationPageSize={100}
        onGridReady={handleGridReady}
        rowModelType="serverSide"
        cacheBlockSize={100}
        animateRows={true}
        onModelUpdated={handleModelUpdated}
        {...other}
      />
    </TableContainer>
  );
};

Table.propTypes = {
  query: PropTypes.object,
  onEditClick: PropTypes.func,
  selection: PropTypes.array,
  setSelection: PropTypes.func,
};

export default Table;
