import React from 'react';
import { TabPane, Grid } from 'semantic-ui-react';
import SemanticInput from '../../../../components/SemanticInput';
import Table from '../Table';

const TabPanePackage = ({ onUnpackingClick, activeIndex, isBusy, panes }) => {
  const handleUnpackingClick = (product) => {
    onUnpackingClick && onUnpackingClick(product);
  };

  return (
    <>
      <TabPane key={activeIndex}>
        <Grid>
          <Grid.Row stretched>
            <Grid.Column>
              <SemanticInput
                name="search"
                label="Shipping Box"
                fluid
                disabled={isBusy}
              />
            </Grid.Column>
          </Grid.Row>

          <Grid.Row stretched>
            <Grid.Column>
              <Table
                data={panes[activeIndex].data}
                onUnpackingClick={handleUnpackingClick}
              />
            </Grid.Column>
          </Grid.Row>
        </Grid>
      </TabPane>
    </>
  );
};

export default TabPanePackage;
