export default (data, nameFile, format) => {
  let blob = new Blob([data], { type: format });
  if (navigator.msSaveBlob) {
    // IE 10+
    navigator.msSaveBlob(blob, nameFile);
  } else {
    let link = document.createElement('a');
    if (link.download !== undefined) {
      // feature detection
      // Browsers that support HTML5 download attribute
      let url = URL.createObjectURL(blob);
      link.setAttribute('href', url);
      link.setAttribute('download', nameFile);
      link.style.visibility = 'hidden';
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    }
  }
};
