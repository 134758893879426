import 'ag-grid-community/dist/styles/ag-grid.css';
import 'ag-grid-community/dist/styles/ag-theme-balham.css';
import 'ag-grid-community/dist/styles/ag-theme-material.css';
import _ from 'lodash';
import DropdownSearchSelection from './AutocompleteForOptima';
import ActivityIndicator from './ActivityIndicator';
import React, { useEffect, useState } from 'react';
import { AgGridReact } from '@ag-grid-community/react';
import { AllModules } from '@ag-grid-enterprise/all-modules';
import { useDispatch, useSelector } from 'react-redux';
import {
  validatingData,
  toggleValidationModal,
  setExternalTripId
} from '../stores/Parcels/ParcelsActions';
import { Button, Icon, Modal } from 'semantic-ui-react';
import {
  errors,
  validationsStructure,
  UnitOfMeasure,
  CountryStateCode,
  PortInternational
} from '../constants/ModalValidations';
import feathers from '../services/feathers';
import Lottie from 'react-lottie';
import TruckAnimation from '../assets/animations/truck_animation';
import swal from 'sweetalert';

export default ({ openModal }) => {
  const dispatch = useDispatch();
  const [columnHeaders, setColumnHeaders] = useState([]);
  const [errorCols, setErrorCols] = useState([]);
  const [loader, setLoader] = useState(true);
  const [isBusy, setIsBusy] = useState(false);
  const [errorDetail, setErrorDetail] = useState([]);
  const [validToSent, setValidToSent] = useState(true);
  const [data, setData] = useState([]);
  const { parcelsToSend } = useSelector(state => state.ParcelReducer);
  const { optimaTripSelected } = useSelector(state => state.ParcelReducer);
  const { tripId, tripNumber } = optimaTripSelected;

  useEffect(() => {
    setErrorCols([
      {
        headerName: 'Row',
        field: 'row',
        width: 70,
        resizable: true
      },
      {
        headerName: 'Error',
        field: 'error',
        width: 160,
        resizable: true
      },
      {
        headerName: 'Column',
        field: 'column',
        width: 200,
        resizable: true
      },
      {
        headerName: 'Detail',
        field: 'detail',
        width: 950,
        resizable: true
      }
    ]);
  }, []);

  const validateIncomingData = async () => {
    try {
      setLoader(true);

      let newparcelsToSend = _.cloneDeep(parcelsToSend);
      let errors = [];

      await newparcelsToSend.forEach((item, i) => {
        errors = getErrors(item, i, parcelsToSend);
        item.errors = errors;
      });

      let validatedData = newparcelsToSend.map(item => ({
        ...item,
        errors: item.errors ? item.errors : errors
      }));

      errors.length > 0 ? setValidToSent(true) : setValidToSent(false);
      dispatch(validatingData(validatedData));
      setData(validatedData);

      setColumnHeaders([
        {
          headerName: 'Tracking #',
          sortable: false,
          pinned: 'left',
          enableRowGroup: true,
          filter: true,
          valueGetter: params => {
            return params.data
              ? params.data.tracking_number &&
                params.data.tracking_number !== ''
                ? params.data.tracking_number
                : null
              : null;
          },
          editable: true,
          valueSetter: params => setNewValue(params, 'tracking_number'),
          suppressMovable: true
        },
        {
          headerName: 'Validated',
          filter: 'agSetColumnFilter',
          sortable: false,
          pinned: 'left',
          suppressMovable: true,
          cellStyle: params => {
            return params.data
              ? params.data.errors && params.data.errors.length
                ? { backgroundColor: '#f37171bd' }
                : { backgroundColor: '#bdecb8d6' }
              : null;
          },
          valueGetter: params => {
            return params.data
              ? params.data.errors && params.data.errors.length
                ? 'Failed'
                : 'Passed'
              : null;
          }
        },
        {
          headerName: 'Shipment Type',
          sortable: false,
          rowGroup: false,
          valueGetter: params => {
            return params.data
              ? params.data.shipment_type && params.data.shipment_type !== ''
                ? params.data.shipment_type
                : null
              : null;
          },
          editable: true,
          singleClickEdit: true,
          cellEditor: 'agRichSelectCellEditor',
          cellEditorParams: { values: ['Section 321'] },
          valueSetter: params => setNewValue(params, 'shipment_type')
        },
        {
          headerName: 'Shipper Name',
          sortable: false,
          valueGetter: params => {
            return params.data
              ? params.data.from_address
                ? params.data.from_address.company &&
                  params.data.from_address.company !== ''
                  ? params.data.from_address.company
                  : null
                : null
              : null;
          },
          editable: true,
          valueSetter: params => setNewValue(params, 'fcompany')
        },
        {
          headerName: 'Shipper Address',
          sortable: false,
          valueGetter: params => {
            return params.data
              ? params.data.from_address
                ? params.data.from_address.address1 &&
                  params.data.from_address.address1 !== ''
                  ? params.data.from_address.address1
                  : null
                : null
              : null;
          },
          editable: true,
          valueSetter: params => setNewValue(params, 'faddress1')
        },
        {
          headerName: 'Shipper City',
          sortable: false,
          valueGetter: params => {
            return params.data
              ? params.data.from_address
                ? params.data.from_address.city &&
                  params.data.from_address.city !== ''
                  ? params.data.from_address.city
                  : null
                : null
              : null;
          },
          editable: true,
          valueSetter: params => setNewValue(params, 'fcity')
        },
        {
          headerName: 'Shipper Country',
          sortable: false,
          valueGetter: params => {
            return params.data
              ? params.data.from_address
                ? params.data.from_address.country_code &&
                  params.data.from_address.country_code !== ''
                  ? params.data.from_address.country_code.slice(0, 2)
                  : null
                : null
              : null;
          },
          editable: true,
          singleClickEdit: true,
          cellEditor: 'agRichSelectCellEditor',
          cellEditorParams: { values: ['MX (Mexico)', 'CA (Canada)'] },
          valueSetter: params => setNewValue(params, 'fcountry_code')
        },
        {
          headerName: 'Shipper State',
          sortable: false,
          valueGetter: params => {
            return params.data
              ? params.data.from_address
                ? params.data.from_address.state_code &&
                  params.data.from_address.state_code !== ''
                  ? params.data.from_address.state_code
                  : null
                : null
              : null;
          },
          editable: true,
          singleClickEdit: true,
          cellEditor: 'agRichSelectCellEditor',
          cellEditorParams: params => {
            let values = CountryStateCode.filter(
              item => item.CountryCode === 'MX'
            );
            if (params.data.from_address.country_code.toLowerCase() === 'ca') {
              values = CountryStateCode.filter(
                item => item.CountryCode === 'CA'
              );
            } else if (
              params.data.from_address.country_code.toLowerCase() === 'us'
            ) {
              values = CountryStateCode.filter(
                item => item.CountryCode === 'US'
              );
            }
            return { values: values.map(item => item.StateCode) };
          },
          valueSetter: params => setNewValue(params, 'fstate_code')
        },
        {
          headerName: 'Shipper ZIP (Postal Code)',
          sortable: false,
          valueGetter: params => {
            return params.data
              ? params.data.from_address
                ? params.data.from_address.zip &&
                  params.data.from_address.zip !== ''
                  ? params.data.from_address.zip
                  : null
                : null
              : null;
          },
          editable: true,
          valueSetter: params => setNewValue(params, 'fzip')
        },
        {
          headerName: 'Port of Lading',
          sortable: false,
          rowGroup: false,
          valueGetter: params => {
            return params.data
              ? params.data.from_address
                ? params.data.from_address.port_of_lading &&
                  params.data.from_address.port_of_lading !== ''
                  ? params.data.from_address.port_of_lading
                  : null
                : null
              : null;
          },
          editable: true,
          singleClickEdit: true,
          cellEditor: 'agRichSelectCellEditor',
          cellEditorParams: params => {
            let values = PortInternational.filter(
              item => item.PortCountryCode === 'MX'
            );
            if (params.data.from_address.country_code.toLowerCase() === 'ca') {
              values = PortInternational.filter(
                item => item.PortCountryCode === 'CA'
              );
            } else if (
              params.data.from_address.country_code.toLowerCase() === 'us'
            ) {
              values = PortInternational.filter(
                item => item.PortCountryCode === 'US'
              );
            }
            return { values: values.map(item => item.PortName) };
          },
          valueSetter: params => setNewValue(params, 'fport_of_lading')
        },
        {
          headerName: 'Consignee Name',
          colId: 'consignee',
          sortable: false,
          valueGetter: params => {
            return params.data
              ? params.data.to_address
                ? params.data.to_address.name &&
                  params.data.to_address.name !== ''
                  ? params.data.to_address.name
                  : null
                : null
              : null;
          },
          editable: true,
          valueSetter: params => setNewValue(params, 'name')
        },
        {
          headerName: 'Consignee Address',
          sortable: false,
          valueGetter: params => {
            return params.data
              ? params.data.to_address
                ? params.data.to_address.address1 &&
                  params.data.to_address.address1 !== ''
                  ? params.data.to_address.address1
                  : null
                : null
              : null;
          },
          editable: true,
          valueSetter: params => setNewValue(params, 'address1')
        },
        {
          headerName: 'Consignee City',
          sortable: false,
          valueGetter: params => {
            return params.data
              ? params.data.to_address
                ? params.data.to_address.city &&
                  params.data.to_address.city !== ''
                  ? params.data.to_address.city
                  : null
                : null
              : null;
          },
          editable: true,
          valueSetter: params => setNewValue(params, 'city')
        },
        {
          headerName: 'Consignee Country',
          sortable: false,
          valueGetter: params => {
            return params.data
              ? params.data.to_address
                ? params.data.to_address.country &&
                  params.data.to_address.country !== ''
                  ? params.data.to_address.country
                  : null
                : null
              : null;
          },
          editable: true,
          singleClickEdit: true,
          cellEditor: 'agRichSelectCellEditor',
          cellEditorParams: { values: ['US (United States of America)'] },
          valueSetter: params => setNewValue(params, 'country')
        },
        {
          headerName: 'Consignee State',
          sortable: false,
          valueGetter: params => {
            return params.data
              ? params.data.to_address
                ? params.data.to_address.state &&
                  params.data.to_address.state !== ''
                  ? params.data.to_address.state
                  : null
                : null
              : null;
          },
          editable: true,
          singleClickEdit: true,
          cellEditor: 'agRichSelectCellEditor',
          cellEditorParams: () => {
            let values = CountryStateCode.filter(
              item => item.CountryCode === 'US'
            );
            return { values: values.map(item => item.StateCode) };
          },
          valueSetter: params => setNewValue(params, 'state')
        },
        {
          headerName: 'Consignee ZIP (Postal Code)',
          sortable: false,
          valueGetter: params => {
            return params.data
              ? params.data.to_address
                ? params.data.to_address.zip &&
                  params.data.to_address.zip !== ''
                  ? params.data.to_address.zip
                  : null
                : null
              : null;
          },
          editable: true,
          valueSetter: params => setNewValue(params, 'zip')
        },
        {
          headerName: 'Product Description',
          sortable: false,
          valueGetter: params => {
            return params.data
              ? params.data.product_description &&
                params.data.product_description !== ''
                ? params.data.product_description
                : null
              : null;
          },
          editable: true,
          valueSetter: params => setNewValue(params, 'product_description')
        },
        {
          headerName: 'Qty',
          sortable: false,
          valueGetter: params => {
            return params.data
              ? params.data.quantity
                ? params.data.quantity
                : null
              : null;
          },
          suppressMovable: true,
          cellStyle: { textAlign: 'right' }
        },
        {
          headerName: 'Product OUM',
          sortable: false,
          rowGroup: false,
          valueGetter: params => {
            return params.data
              ? params.data.product_uom && params.data.product_uom !== ''
                ? params.data.product_uom
                : null
              : null;
          },
          editable: true,
          singleClickEdit: true,
          cellEditor: 'agRichSelectCellEditor',
          cellEditorParams: {
            values: UnitOfMeasure.map(
              item => `${item.UnitOfMeasureCode} (${item.unitOfMeasureName})`
            )
          },
          valueSetter: params => setNewValue(params, 'product_uom')
        },
        {
          headerName: 'Product Weight',
          sortable: false,
          colId: 'weight',
          valueGetter: params => {
            return params.data
              ? params.data.product_weight && params.data.product_weight !== ''
                ? params.data.product_weight
                : null
              : null;
          },
          editable: true,
          cellStyle: { textAlign: 'right' },
          cellEditor: 'numericCellEditor',
          valueSetter: params => setNewValue(params, 'product_weight')
        },
        {
          headerName: 'Unit Weight',
          sortable: false,
          valueGetter: params => {
            return params.data
              ? params.data.weight_unit && params.data.weight_unit !== ''
                ? params.data.weight_unit
                : null
              : null;
          },
          editable: true,
          singleClickEdit: true,
          cellEditor: 'agRichSelectCellEditor',
          cellEditorParams: { values: ['L', 'K'] },
          valueSetter: params => setNewValue(params, 'weight_unit')
        },
        {
          headerName: 'Cost',
          sortable: false,
          colId: 'cost',
          valueGetter: params => {
            return params.data
              ? params.data.shipping_cost && params.data.shipping_cost !== ''
                ? params.data.shipping_cost
                : null
              : null;
          },
          editable: true,
          cellStyle: { textAlign: 'right' },
          cellEditor: 'numericCellEditor',
          valueSetter: params => setNewValue(params, 'shipping_cost')
        },
        {
          headerName: 'Origin Country',
          sortable: false,
          rowGroup: false,
          valueGetter: params => {
            return params.data
              ? params.data.origin_country && params.data.origin_country !== ''
                ? params.data.origin_country
                : null
              : null;
          },
          editable: true,
          singleClickEdit: true,
          valueSetter: params => setNewValue(params, 'origin_country')
        }
      ]);

      setLoader(false);
    } catch (error) {
      console.error('Error validating your selection: ', error);
      setLoader(false);
    }
  };

  const setNewValue = (params, key) => {
    let { oldValue, newValue, data, node } = params;
    let errors = [];
    let { id } = node;

    if (newValue) newValue = newValue.trim();

    if (newValue !== oldValue) {
      switch (key) {
        case 'fcompany':
        case 'faddress1':
        case 'fcity':
        case 'fstate_code':
        case 'fzip':
        case 'fport_of_lading':
          data.from_address[key.slice(1, key.length)] = newValue
            ? newValue
            : oldValue;
          break;
        case 'fcountry_code':
          data.from_address[key.slice(1, key.length)] = newValue
            ? newValue.slice(0, 2)
            : oldValue.slice(0, 2);
          break;
        case 'name':
        case 'address1':
        case 'city':
        case 'state':
        case 'zip':
          data.to_address[key] = newValue ? newValue : oldValue;
          break;
        case 'country':
          data.to_address[key] = newValue
            ? newValue.slice(0, 2)
            : oldValue.slice(0, 2);
          break;
        case 'product_weight':
        case 'weight_unit':
          data[key] =
            key === 'weight'
              ? newValue
                ? isNaN(parseFloat(newValue))
                  ? '0'
                  : parseFloat(newValue)
                : '0'
              : newValue
              ? newValue
              : oldValue;
          break;
        default:
          data[key] =
            key === 'shipping_cost'
              ? newValue
                ? parseFloat(newValue)
                : oldValue
              : newValue
              ? newValue
              : oldValue;
          break;
      }

      let dataGrid = params.api.rowModel.rowsToDisplay.map(item => item.data);

      let existErrors = 0;

      let changes = dataGrid.map((item, i) => {
        if (i === parseInt(id)) {
          errors = getErrors(data, id, dataGrid);
          if (errors.length > 0) existErrors++;
          return { ...data, errors };
        } else {
          errors = getErrors(item, i, dataGrid);
          if (errors.length > 0) existErrors++;
          return { ...item, errors };
        }
      });

      existErrors ? setValidToSent(true) : setValidToSent(false);

      dispatch(validatingData(changes));
      setData(changes);

      let specificError = getErrors(data, id, dataGrid);
      setErrorDetail(specificError);
      return true;
    } else return false;
  };

  const getNumericCellEditor = () => {
    function isCharNumeric(charStr) {
      switch (charStr) {
        case '0':
        case '1':
        case '2':
        case '3':
        case '4':
        case '5':
        case '6':
        case '7':
        case '8':
        case '9':
        case '.':
          return charStr;
        default:
          return /\d/.test(charStr);
      }
    }
    function isKeyPressedNumeric(event) {
      var charCode = getCharCodeFromEvent(event);
      var charStr = String.fromCharCode(charCode);
      return isCharNumeric(charStr);
    }
    function getCharCodeFromEvent(event) {
      event = event || window.event;
      return typeof event.which === 'undefined' ? event.keyCode : event.which;
    }
    function NumericCellEditor() {}
    NumericCellEditor.prototype.init = function(params) {
      this.focusAfterAttached = params.cellStartedEdit;
      this.eInput = document.createElement('input');
      this.eInput.style.width = '100%';
      this.eInput.style.height = '100%';
      this.eInput.value = isCharNumeric(params.charPress)
        ? params.charPress
        : params.value;
      var that = this;
      this.eInput.addEventListener('keypress', function(event) {
        if (!isKeyPressedNumeric(event)) {
          that.eInput.focus();
          if (event.preventDefault) event.preventDefault();
        }
      });
    };
    NumericCellEditor.prototype.getGui = function() {
      return this.eInput;
    };
    NumericCellEditor.prototype.afterGuiAttached = function() {
      if (this.focusAfterAttached) {
        this.eInput.focus();
        this.eInput.select();
      }
    };
    NumericCellEditor.prototype.isCancelBeforeStart = function() {
      return this.cancelBeforeStart;
    };
    NumericCellEditor.prototype.getValue = function() {
      return this.eInput.value;
    };
    NumericCellEditor.prototype.focusIn = function() {
      var eInput = this.getGui();
      eInput.focus();
      eInput.select();
    };
    return NumericCellEditor;
  };

  const sureToSend = async () => {
    try {
      setIsBusy(true);

      let templateFor321 = [
        [
          'Shipment Control Number',
          'Shipment Type',
          'Shipper Name',
          'Shipper Address',
          'Shipper City',
          'Shipper Country',
          'Shipper State',
          'Shipper Postal',
          'Shipper Port of Lading',
          'Consignee Name',
          'Consignee Address',
          'Consignee City',
          'Consignee Country',
          'Consignee State',
          'Consignee Postal',
          'Product Description',
          'Product Qty',
          'Product UOM',
          'Product Weight',
          'Product Unit of Weight',
          'Product Value',
          'Origin Country'
        ]
      ];

      parcelsToSend.forEach(item => {
        templateFor321.push([
          `${item.tracking_number}`,
          `${item.shipment_type}`,
          `${item.from_address.company}`,
          `${item.from_address.address1}`,
          `${item.from_address.city}`,
          `${item.from_address.country_code}`,
          `${item.from_address.state_code}`,
          `${item.from_address.zip}`,
          `${item.from_address.port_of_lading}`,
          `${item.to_address.name}`,
          `${item.to_address.address1}`,
          `${item.to_address.city}`,
          `${item.to_address.country}`,
          `${item.to_address.state}`,
          `${item.to_address.zip}`,
          `${item.product_description}`,
          `${item.quantity}`,
          `${item.product_uom.slice(0, item.product_uom.indexOf(' '))}`,
          `${item.product_weight}`,
          `${item.weight_unit}`,
          `${item.shipping_cost}`,
          `${item.origin_country}`
        ]);
      });

      let res = await feathers
        .service('export-optima-trips')
        .create({ templateFor321, tripId, tripNumber });

      console.log('templateFor321 :', templateFor321);

      dispatch(toggleValidationModal());
      setIsBusy(false);
      res === 200
        ? swal({
            title: 'Finish',
            text: 'Parcels received correctly in E-Manifest',
            icon: 'success',
            buttons: {
              cancel: 'Close'
            }
          })
        : swal({
            title: 'Error',
            text: 'Conection with E-Manifest is closed or missing',
            icon: 'error',
            buttons: {
              cancel: 'Close'
            }
          });
    } catch (error) {
      console.error('Error: ', error);
    }
  };

  const checkForErrors = items =>
    items[0].errors ? setErrorDetail(items[0].errors) : setErrorDetail([]);

  const getErrors = (item, i, allData) => {
    try {
      let onlyErrors = [];
      let errors = [];
      let id = parseInt(i);
      let from = item.from_address;
      let to = item.to_address;
      let dime = item.dimensions;

      validationsStructure.forEach(val => {
        let { col, key, level, max, min } = val;
        switch (level) {
          case 'root':
            errors.push(
              isUndefined(item[key], id, col, max, min, key, item, allData)
            );
            break;
          case 'from':
            if (from)
              errors.push(
                isUndefined(
                  from[key],
                  id,
                  col,
                  max,
                  min,
                  key,
                  item,
                  null,
                  level
                )
              );
            break;
          case 'to':
            if (to)
              errors.push(
                isUndefined(to[key], id, col, max, min, key, item, null, level)
              );
            break;
          case 'dimensions':
            if (dime) errors.push(isUndefined(dime[key], id, col, max, min));
            break;
          default:
            errors.push(
              isUndefined(item[key], id, col, max, min, key, item, allData)
            );
            break;
        }
      });

      errors.forEach(group => {
        group.forEach(err => {
          if (err) onlyErrors.push(err);
        });
      });

      return onlyErrors;
    } catch (error) {
      console.error('Error in validations: ', error);
    }
  };

  const isUndefined = (value, id, col, max, min, key, item, data, level) => {
    let list = [];
    let { undefinedData } = errors;
    if (value) {
      if (key === 'shipping_cost') {
        list.push(getCostErrors(item, id, data));
      } else if (level === 'from' && key === 'country_code') {
        list.push(getCountryError(item.from_address, id, col, level, key));
        list.push(getStateError(item.from_address, id, level, key));
        list.push(getPortError(item.from_address, id, level, key));
      } else if (level === 'to' && key === 'country') {
        list.push(getCountryError(item.to_address, id, col, level, key));
        list.push(getStateError(item.to_address, id, level, key));
      } else {
        list.push(getEmptyError(value, id, col));
        list.push(getMinError(value, id, col, min));
        list.push(getMaxError(value, id, col, max));
        list.push(getSpecError(value, id, col, key));
      }
      return list;
    } else {
      list.push(addError(undefinedData, id, col));
      return list;
    }
  };

  const getEmptyError = (value, id, col) => {
    let { empty } = errors;
    if (value === '') return addError(empty, id, col);
    else return false;
  };

  const getMinError = (value, id, col, min) => {
    let { length } = errors;
    let long = value.length;
    if (long < min) return addError(length, id, col, min, long);
    return false;
  };

  const getMaxError = (value, id, col, max) => {
    let { length } = errors;
    let long = value.length;
    if (long > max) return addError(length, id, col, max, long);
    return false;
  };

  const getSpecError = (value, id, col, key) => {
    let { special } = errors;
    let regExp;
    if (key === 'zip') {
      regExp = new RegExp(`^[a-zA-Z0-9.&() -]*$`);
      if (!regExp.test(value)) return addError(special, id, col);
    }

    if (key === 'product_uom') {
      regExp = new RegExp(`^[a-zA-Z0-9.&() -]*$`);
      if (!regExp.test(value)) return addError(special, id, col);
    }

    regExp = new RegExp(`^[a-zA-Z0-9.&() -]*$`);
    if (!regExp.test(value)) return addError(special, id, col);
    else return false;
  };

  const getCostErrors = ({ shipping_cost }, id) => {
    try {
      let { quantity, type } = errors;

      let cost = parseFloat(shipping_cost);

      if (isNaN(cost)) {
        return addError(type, id, 'Cost');
      } else {
        if (cost > 800)
          return addError(quantity, id, 'Cost', 'higher than', '$800');

        if (cost < 0) return addError(quantity, id, 'Cost', 'lower than', '$0');

        if (cost === 0) return addError(quantity, id, 'Cost', 'equal to', '$0');
      }

      return false;
    } catch (error) {
      console.error('Error validating the cost: ', error);
    }
  };

  const getCountryError = (data, id, col, level, key) => {
    let { direction } = errors;
    if (level === 'from') {
      if (data[key] === 'US')
        return addError(direction, id, col, 'country', level);
    } else if (level === 'to') {
      if (data[key] !== 'US') {
        return addError(direction, id, col, 'country', level);
      }
    }
    return false;
  };

  const getStateError = (data, id, level, key) => {
    let { direction } = errors;
    let states;
    if (level === 'from') {
      states = CountryStateCode.filter(item => item.CountryCode === data[key]);
      if (!states.filter(item => item.StateCode === data.state_code).length)
        return addError(direction, id, 'Shipper State', 'state');
    } else if (level === 'to') {
      states = CountryStateCode.filter(item => item.CountryCode === 'US');
      if (!states.filter(item => item.StateCode === data.state).length)
        return addError(direction, id, 'Consignee State', 'state');
    }
    return false;
  };

  const getPortError = (data, id, level, key) => {
    let { direction } = errors;
    let ports;
    if (level === 'from') {
      ports = PortInternational.filter(
        item => item.PortCountryCode === data[key]
      );
      if (!ports.filter(item => item.PortName === data.port_of_lading).length)
        return addError(direction, id, 'Port of Lading', 'port');
    }
    return false;
  };

  const addError = (error, index, field, text1, text2, text3) => {
    try {
      let {
        special,
        length,
        undefinedData,
        empty,
        emptyItems,
        quantity,
        direction,
        summatory,
        type
      } = errors;
      let message = {
        row: index ? index + 1 : 1,
        id: index ? index : 0,
        error: error ? error : 'Unexpected Error',
        column: field
      };

      switch (error) {
        case empty:
          message.detail = `Cannot be an empty string or contain a blank space.`;
          break;
        case length:
          message.detail = `Cannot be ${
            text1 <= '3' ? 'shorter' : 'longer'
          } than ${text1} characters, the actual data has ${text2} characters.`;
          break;
        case special:
          message.detail = `Cannot contain special characters, only alphanumeric (a-Z, 0-9)`;
          break;
        case quantity:
          message.detail = `Cannot be ${text1} ${text2}, please review the cost of the parcel.`;
          break;
        case summatory:
          message.row = `All ${text2}: ${text1}`;
          message.detail = `Cannot contain a total greater than $800 in the total cost of the sum of all Parcels for the ${text2}: ${text1}. Total amount cost is $${text3}`;
          break;
        case direction:
          if (text1 === 'country') {
            message.detail = `${
              text2 === 'from'
                ? 'Cannot be US, Section 321 only can be sent by MX (Mexico) or CA (Canada)'
                : 'Must be US (United States of America), only US can receive Section 321'
            }`;
          } else if (text1 === 'state') {
            message.detail = `Does not contain a province of the selected country, please review your information.`;
          } else {
            message.detail =
              'Does not contain a port of lading of the selected country, please review your information.';
          }
          break;
        case type:
          message.detail = `Cannot be type string, ${field} must be a number type.`;
          break;
        case emptyItems:
          message.detail = `Cannot be 0, that indicates that the Parcel is empty or is not containing any item.`;
          break;
        case undefinedData:
          message.detail = `Cannot be an undefined element, please check your information.`;
          break;
        default:
          message.detail =
            'Unexpected error, please call support for more details.';
          break;
      }

      return message;
    } catch (error) {
      console.error('Error assigning details: ', error);
    }
  };

  return (
    <Modal open={openModal} size={'fullscreen'}>
      <Modal.Header>
        {loader ? (
          <ActivityIndicator title="Validating Information..." />
        ) : (
          <DropdownSearchSelection parcelsQty={parcelsToSend.length} />
        )}
      </Modal.Header>
      {isBusy ? (
        <Lottie
          options={{
            loop: true,
            autoplay: true,
            animationData: TruckAnimation,
            rendererSettings: {
              preserveAspectRatio: 'xMidYMid slice'
            }
          }}
          width={800}
          height={480}
        />
      ) : (
        <>
          <Modal.Content image scrolling>
            <Modal.Description>
              <div
                className="ag-theme-balham"
                style={{
                  height: errorDetail.length > 0 ? '30vh' : '60vh',
                  width: '100%'
                }}
              >
                <AgGridReact
                  columnDefs={columnHeaders}
                  rowData={data}
                  rowSelection="single"
                  defaultColDef={{ filter: true, sortable: true }}
                  sideBar={false}
                  pagination={true}
                  floatingFilter={true}
                  paginationPageSize={100}
                  modules={AllModules}
                  components={{ numericCellEditor: getNumericCellEditor() }}
                  onSelectionChanged={({ api }) =>
                    checkForErrors(api.getSelectedRows())
                  }
                  suppressDragLeaveHidesColumns={true}
                  suppressMakeColumnVisibleAfterUnGroup={true}
                  groupHideOpenParents={true}
                  onGridReady={() => validateIncomingData()}
                />
              </div>
              {errorDetail.length > 0 ? (
                <div
                  className="ag-theme-balham"
                  style={{ height: '30vh', width: '100%' }}
                >
                  <AgGridReact
                    columnDefs={errorCols}
                    rowData={errorDetail}
                    rowSelection="single"
                    pagination={true}
                  />
                </div>
              ) : null}
            </Modal.Description>
          </Modal.Content>
        </>
      )}
      <Modal.Actions>
        <Button
          basic
          disabled={isBusy ? true : false}
          onClick={() => {
            dispatch(toggleValidationModal());
            dispatch(setExternalTripId({ tripId: null, tripNumber: null }));
          }}
        >
          Cancel
        </Button>
        <Button
          primary
          onClick={() => sureToSend()}
          disabled={
            isBusy ? true : !validToSent && tripId !== null ? false : true
          }
        >
          <Icon name="send" /> Send
        </Button>
      </Modal.Actions>
    </Modal>
  );
};
