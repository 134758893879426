import React from 'react';
import { Switch, Route } from "react-router-dom";
import Packing from './Packing'
import Detail from './Detail'

export default (props) => {
  const path = props.match.path;
  const url = props.match.url;

  return (
    <Switch>
      <Route path={url} exact>
        <Packing {...props} />
      </Route>

      <Route path={`${url}/:id`} exact>
        <Detail {...props} />
      </Route>
    </Switch>
  );
};