import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import TableContainer from '../../components/TableContainer';
import { AgGridReact } from '@ag-grid-community/react';
import { AllModules } from '@ag-grid-enterprise/all-modules';
import 'lodash';
import { Button } from 'semantic-ui-react';
import { options as RolesOptions } from '../../constants/Roles';
import swal from 'sweetalert';
import { valueFormatter } from '../../components/AgGridColumns';
import { useAuth } from '../../services/feathers';

const Table = ({ data, onEditClick, selection, setSelection }) => {
  const auth = useAuth();
  // console.debug('auth.user.clients', auth.user.clients);

  // States
  const [gridApi, setGridApi] = useState();
  const [gridColumnApi, setGridColumnApi] = useState(null);
  const [columnDefs] = useState([
    {
      headerName: '',
      width: 70,
      checkboxSelection: true,
      headerCheckboxSelection: true,
      suppressMenu: true,
      pinned: 'left',
      enableRowGroup: false,
      resizable: false,
      sortable: false,
    },
    {
      headerName: 'Name',
      field: 'name',
      valueFormatter,
    },
    {
      headerName: 'Last Name',
      field: 'lastName',
      valueFormatter,
    },
    {
      headerName: 'Email',
      field: 'email',
      valueFormatter,
    },
    {
      headerName: 'Roles',
      field: 'roles',
      valueFormatter: (params) => {
        if (params && Array.isArray(params.value)) {
          const roles = RolesOptions.filter(({ value }) =>
            params.value.includes(value)
          )
            .map(({ text }) => text)
            .sort();

          return (roles.length && roles.join(', ')) || '-';
        }

        return '-';
      },
    },
    {
      headerName: '',
      field: '',
      pinned: 'right',
      cellStyle: { textAlign: 'center' },

      cellRendererFramework: (params) => {
        if (params.data)
          return (
            <Button.Group>
              <Button
                compact
                primary
                onClick={() =>
                  handleEditClick({
                    ...params.data,
                    client: params.data.client && params.data.client._id,
                  })
                }
              >
                Edit
              </Button>
            </Button.Group>
          );
      },
    },
  ]);
  const [defaultColDef] = useState({});

  // Effect
  useEffect(() => {
    if (
      selection &&
      !selection.length &&
      gridApi &&
      gridApi.getSelectedRows().length
    ) {
      gridApi.deselectAll();
    }
  }, [selection, gridApi]);

  useEffect(() => {
    if (auth && auth.user && auth.user.isSupport && gridApi) {
      // console.count('Table useEffect client, gridApi');

      const end = 4;
      const cols = columnDefs
        .slice(0, end)
        .concat(
          {
            headerName: 'Clients',
            field: 'clients',
            valueFormatter: (params) => {
              if (
                params &&
                Array.isArray(params.value) &&
                params.value.length
              ) {
                return params.value
                  .map(({ name }) => name)
                  .sort()
                  .join(', ');
              }

              return '-';
            },
          },
          {
            headerName: 'Client Active',
            field: 'client',
            valueGetter: 'data && data.client && data.client.name',
            valueFormatter,
          }
        )
        .concat(columnDefs.slice(end));

      gridApi.setColumnDefs(cols);
      gridApi.sizeColumnsToFit();
    }
  }, [auth, columnDefs, gridApi]);

  useEffect(() => {
    if (gridColumnApi) {
      var allColumnIds = gridColumnApi
        .getAllColumns()
        .filter(({ colDef: { field } }) => field !== '')
        .map(({ colId }) => colId);

      gridColumnApi.autoSizeColumns &&
        gridColumnApi.autoSizeColumns(allColumnIds, false);
    }
  }, [data, gridColumnApi]);

  // Handles
  const handleEditClick = (user) => {
    onEditClick && onEditClick({ ...user });
  };

  const handleSelectionChanged = (event) => {
    if (selection && setSelection) {
      let rows = [];

      try {
        if (event && event.api) {
          rows = event.api.getSelectedRows().map((row) => row._id);
        }
      } catch (err) {
        rows = [];

        swal({
          title: 'Error',
          text: 'Failed to select users',
          icon: 'error',
          buttons: {
            cancel: 'Close',
          },
        });
      } finally {
        setSelection(rows);
      }
    }
  };

  const handleGridReady = ({ api, columnApi }) => {
    setGridApi(api);
    setGridColumnApi(columnApi);
  };

  return (
    <TableContainer className="ag-theme-material" rowFilters={1}>
      <AgGridReact
        modules={AllModules}
        columnDefs={columnDefs}
        defaultColDef={defaultColDef}
        rowSelection={'multiple'}
        suppressRowClickSelection={true}
        onSelectionChanged={handleSelectionChanged}
        rowData={data}
        pagination={true}
        paginationPageSize={100}
        onGridReady={handleGridReady}
      />
    </TableContainer>
  );
};

Table.propTypes = {
  data: PropTypes.array,
  onEditClick: PropTypes.func,
  selection: PropTypes.array,
  setSelection: PropTypes.func,
};

export default Table;
