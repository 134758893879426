import { Tags } from '../../../../constants/Tags';
import Integrators from '../../../../constants/Integrators';

export const options = [
  { key: 'MIX', text: 'Mix Items', value: 'MIX' },
  { key: 'YES', text: 'YES', value: 'YES' },
  { key: 'NOT', text: 'NOT', value: 'NOT' },
  { key: 'NO PRODUCT', text: 'No Product', value: 'NO PRODUCT' },
];

export const tagOptions = [
  { value: Tags.noTags, text: 'No Tags' },
  { value: Tags._321, text: '321 Report' },
  { value: Tags._No321, text: 'No 321 Report' },
  { value: Tags.dutyPay, text: 'Duty Pay Report' },
  { value: Tags.international, text: 'International Report' },
  { value: Tags.external, text: 'External Report' },
];

export const countryOptions = [
  { value: 'US', text: 'US' },
  { value: 'INTERNATIONAL', text: 'International' },
];

export const binOptions = [
  { value: 'MIX', text: 'With and whitout BIN' },
  { value: 'NO BIN', text: 'Only without BIN' },
  { value: 'BIN', text: 'Only with BIN' },
];

export const integratorOptions = [
  { value: Integrators.shiphero, text: 'Shiphero' },
  { value: Integrators.rushOrder, text: 'Rush Order' },
];
