import React from 'react';
import DropdownSearchFetch from '../../../components/Formik/DropdownSearchFetch';
import feathers, { useAuth } from '../../../services/feathers';

function DropdownFiguraTransporte({ formik, index, figuraTransporte}) {
  let { setFieldValue } = formik;
  const auth = useAuth();

  const loadChofer = async (id) => {
    const service = feathers.service('choferes');
    let result = await service.find({
      query: {
        id:id
      }
    });
    if(result.data.length)
    return result.data[0]
  };


  const getTipoFigura = async (id) => {
    const service = feathers.service('catalogo-figura-transporte');
    let result = await service.find({
      query: {
        CFtransporteID:id
      }
    });
    
    if(result.data.length)
      return result.data[0].c_FiguraTransporte
  };

  const getPais = async (id) => {
    const service = feathers.service('catalogo-pais');
    let result = await service.find({
      query: {
        CPaisID:id
      }
    });
    if(result.data.length)
      return result.data[0].c_Pais
  };
  let query= (columName, columValue, searchQuery) => {
    let newQuery = {
      $or: [
        { [columName]: { $like: '%' + searchQuery + '%' } },
        { [columValue]: { $like: '%' + searchQuery + '%' } },
      ],
      $limit: 10,
      idOrganizacion: auth.user.client._id
    };
  
    return newQuery;
  };

  let queryInit = (columName, columValue, value) => {
    let newQuery;
    if (value) {
      newQuery = {
        [columValue]: { $like: value },
        $limit: 10,
        idOrganizacion: auth.user.client._id
      };
    } else {
      newQuery = {
        $limit: 10,
        idOrganizacion: auth.user.client._id
      };
    }
  
    return newQuery;
  };

  return (
    <DropdownSearchFetch
      fluid
      selection
      label="Chofer"
      value={figuraTransporte.id}
      onChange={async (e, { value }) =>{
          setFieldValue(`figuraTransportes.${index}.id`,value)
          let chofer = await loadChofer(value)
          if(chofer){
            console.log(chofer)
            setFieldValue(`figuraTransportes.${index}.TipoFigura`, await getTipoFigura(chofer.TipoFiguraTransportista))
            setFieldValue(`figuraTransportes.${index}.RFC`,chofer.RFC)
            setFieldValue(`figuraTransportes.${index}.NumRegTributario`,chofer.NumRegistroTributario)
            setFieldValue(`figuraTransportes.${index}.Calle`,chofer.Calle)
            setFieldValue(`figuraTransportes.${index}.CodigoPostal`,chofer.CodigoPostal)
            setFieldValue(`figuraTransportes.${index}.Colonia`,chofer.Colonia)
            setFieldValue(`figuraTransportes.${index}.Estado`,chofer.Estado)
            setFieldValue(`figuraTransportes.${index}.Localidad`,chofer.Localidad)
            setFieldValue(`figuraTransportes.${index}.Municipio`,chofer.Municipio)
            setFieldValue(`figuraTransportes.${index}.NombreFigura`,chofer.Nombre)
            setFieldValue(`figuraTransportes.${index}.NumExterior`,chofer.NumExterior)
            setFieldValue(`figuraTransportes.${index}.NumInterior`,chofer.NumInterior)
            setFieldValue(`figuraTransportes.${index}.NumLicencia`,chofer.NumeroLicencia)
            setFieldValue(`figuraTransportes.${index}.Pais`,chofer.Pais)
            setFieldValue(`figuraTransportes.${index}.Referencia`,chofer.Referencia)
            setFieldValue(`figuraTransportes.${index}.ResidenciaFiscal`, await getPais(chofer.PaisResidenciaFiscal))
          }else{
            setFieldValue(`figuraTransportes.${index}.TipoFigura`,"")
            setFieldValue(`figuraTransportes.${index}.RFC`,"")
            setFieldValue(`figuraTransportes.${index}.NumRegTributario`,"")
            setFieldValue(`figuraTransportes.${index}.Calle`,"")
            setFieldValue(`figuraTransportes.${index}.CodigoPostal`,"")
            setFieldValue(`figuraTransportes.${index}.Colonia`,"")
            setFieldValue(`figuraTransportes.${index}.Estado`,"")
            setFieldValue(`figuraTransportes.${index}.Localidad`,"")
            setFieldValue(`figuraTransportes.${index}.Municipio`,"")
            setFieldValue(`figuraTransportes.${index}.NombreFigura`,"")
            setFieldValue(`figuraTransportes.${index}.NumExterior`,"")
            setFieldValue(`figuraTransportes.${index}.NumInterior`,"")
            setFieldValue(`figuraTransportes.${index}.NumLicencia`,"")
            setFieldValue(`figuraTransportes.${index}.Pais`,"")
            setFieldValue(`figuraTransportes.${index}.Referencia`,"")
            setFieldValue(`figuraTransportes.${index}.ResidenciaFiscal`,"")
          }
        }
      }
      placeholder="Numero Lisencia"
      featherService="choferes"
      columName="NumeroLicencia"
      columValue="id"
      mapValues={(value) => {
        return {
          key: Math.random(),
          text: `${value['Nombre']} - ${value['NumeroLicencia']}`,
          value: value['id'],
        };
        
      }}
      queryWithSearch = {query}
      queryInit ={queryInit}
    />
  );
}

export default DropdownFiguraTransporte;
