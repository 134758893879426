import React, { forwardRef, useState } from 'react';
import { Form, Segment, Header, Modal, Button, Table } from 'semantic-ui-react';
import DropDown from '../../../components/Formik/Dropdown';
import feathers from '../../../services/feathers';
import DropdownSimpleFetch from '../../../components/Formik/DropdownSimpleFetch';
import DropdownAutoTransporte from './DropdownAutoTransporte';


const FormHeader = ({ formik, isMaterialPeligroso }) => {
  let {
    values,
    errors,
    touched,
    handleChange,
    handleSearchChange,
    getOptions,
    toggleSearch,
    fetchOptions,
    handleBlur,
    handleSubmit,
    isSubmitting,
    setFieldValue,
    /* and other goodies */
  } = formik;
  
  const [open, setOpen] = useState(false);
  return (
    <>
      <Segment>
        <Header as="h3">Autotransporte</Header>
        <Form.Group>
          <Form.Field width={15}>
            <DropdownAutoTransporte formik={formik} autoTransporte={values} />
          </Form.Field>
          <Form.Field width={1}>
            <a
              style={{
                cursor: 'pointer',
                color: '#438D9B',
                height: '100%',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
              }}
              onClick={() => setOpen(true)}
            >
              {' '}
              Editar{' '}
            </a>
          </Form.Field>
        </Form.Group>
        <Modal
          open={open}
          onClose={() => setOpen(false)}
          onOpen={() => setOpen(true)}
          size="fullscreen"
        >
          <Modal.Header>Editar Autotransporte</Modal.Header>
          <Modal.Content>
            <div className="ui form">
              <Form.Group widths={'equal'}>
                <DropdownSimpleFetch
                  fluid
                  selection
                  label="Tipo Permiso SCT"
                  value={values.PermSCT}
                  onChange={(e, { value }) => setFieldValue('PermSCT', value)}
                  placeholder="Tipo Permiso SCT"
                  featherService="catalogo-tipo-permiso"
                  columName="Descripcion"
                  columValue="c_TipoPermiso"
                  mapValues={(value) => {
                    return {
                      key: Math.random(),
                      text: `${value['c_TipoPermiso']} - ${value['Descripcion']}`,
                      value: value['c_TipoPermiso'],
                    };
                  }}
                  error={
                    errors.PermSCT &&
                    touched.PermSCT && { content: errors.PermSCT }
                  }
                />
                <DropdownSimpleFetch
                  fluid
                  selection
                  label="Autotransporte"
                  value={values.AutoConfigVehicular}
                  onChange={(e, { value }) =>{
                    setFieldValue('AutoConfigVehicular', value)
                    // getRemolqueType()
                  }
                  }
                  placeholder="Autotransporte"
                  featherService="catalogo-config-autotransporte"
                  columName="Descripcion"
                  columValue="c_ConfigAutotransporte"
                  mapValues={(value) => {
                    return {
                      key: Math.random(),
                      text: `${value['c_ConfigAutotransporte']} - ${value['Descripcion']}`,
                      value: value['c_ConfigAutotransporte'],
                    };
                  }}
                  error={
                    errors.AutoConfigVehicular &&
                    touched.AutoConfigVehicular && {
                      content: errors.AutoConfigVehicular,
                    }
                  }
                />
              </Form.Group>
              <Form.Group widths={'equal'}>
                <Form.Input
                  placeholder="Número de Permiso Otorgado por la SCT"
                  label="Número de Permiso Otorgado por la SCT"
                  name="NumPermitoSCT"
                  value={values.NumPermitoSCT}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  error={
                    errors.NumPermitoSCT &&
                    touched.NumPermitoSCT && { content: errors.NumPermitoSCT }
                  }
                />
                <Form.Input
                  placeholder="Placa"
                  label="Placa"
                  name="AutoPlacaVM"
                  value={values.AutoPlacaVM}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  error={
                    errors.AutoPlacaVM &&
                    touched.AutoPlacaVM && { content: errors.AutoPlacaVM }
                  }
                />
                <Form.Input
                  type="number"
                  min={0}
                  placeholder="Año Modelo"
                  label="Año Modelo"
                  name="AutoAnioModeloVM"
                  value={values.AutoAnioModeloVM}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  error={
                    errors.AutoAnioModeloVM &&
                    touched.AutoAnioModeloVM && {
                      content: errors.AutoAnioModeloVM,
                    }
                  }
                />
              </Form.Group>
              <Form.Group widths={'equal'}>
                <Form.Input
                  placeholder="Responsabilidad Civil (Aseguradora)"
                  label="Responsabilidad Civil (Aseguradora)"
                  name="AseguraRespCivil"
                  value={values.AseguraRespCivil}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  error={
                    errors.AseguraRespCivil &&
                    touched.AseguraRespCivil && {
                      content: errors.AseguraRespCivil,
                    }
                  }
                />
                <Form.Input
                  placeholder="Responsabilidad Civil (Poliza)"
                  label="Responsabilidad Civil (Poliza)"
                  name="PolizaRespCivil"
                  value={values.PolizaRespCivil}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  error={
                    errors.PolizaRespCivil &&
                    touched.PolizaRespCivil && {
                      content: errors.PolizaRespCivil,
                    }
                  }
                />
                {isMaterialPeligroso && (
                  <>
                    <Form.Input
                      placeholder="Medio Ambiente (Aseguradora)"
                      label="Medio Ambiente (Aseguradora)"
                      name="AseguraMedAmbiente"
                      value={values.AseguraMedAmbiente}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      error={
                        errors.AseguraMedAmbiente &&
                        touched.AseguraMedAmbiente && {
                          content: errors.AseguraMedAmbiente,
                        }
                      }
                    />
                    <Form.Input
                      placeholder="Medio Ambiente (Poliza)"
                      label="Medio Ambiente (Poliza)"
                      name="PolizaMedAmbiente"
                      value={values.PolizaMedAmbiente}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      error={
                        errors.PolizaMedAmbiente &&
                        touched.PolizaMedAmbiente && {
                          content: errors.PolizaMedAmbiente,
                        }
                      }
                    />
                  </>
                )}
                <Form.Input
                  placeholder="Carga (Aseguradora)"
                  label="Carga (Aseguradora)"
                  name="AseguraCarga"
                  value={values.AseguraCarga}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  error={
                    errors.AseguraCarga &&
                    touched.AseguraCarga && { content: errors.AseguraCarga }
                  }
                />
                <Form.Input
                  placeholder="Carga (Poliza)"
                  label="Carga (Poliza)"
                  name="PolizaCarga"
                  value={values.PolizaCarga}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  error={
                    errors.PolizaCarga &&
                    touched.PolizaCarga && { content: errors.PolizaCarga }
                  }
                />
                <Form.Input
                  type="decimal"
                  placeholder="Prima Seguro"
                  min={0}
                  label="Prima Seguro"
                  name="PrimaSeguro"
                  value={values.PrimaSeguro}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  error={
                    errors.PrimaSeguro &&
                    touched.PrimaSeguro && { content: errors.PrimaSeguro }
                  }
                />
              </Form.Group>
              <Header as="h5">Remolque 1</Header>
              <Form.Group>
                <Form.Input
                  placeholder="Placa"
                  label="Placa"
                  name="PlacaR1"
                  value={values.PlacaR1}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  error={
                    errors.PlacaR1 &&
                    touched.PlacaR1 && { content: errors.PlacaR1 }
                  }
                />
                <DropdownSimpleFetch
                  fluid
                  selection
                  label="Remolque/Semiremolque"
                  value={values.Remolque1}
                  onChange={(e, { value }) => setFieldValue('Remolque1', value)}
                  placeholder="Remolque/Semiremolque"
                  featherService="catalogo-sub-tipo-rem"
                  columName="RemSemiRem"
                  columValue="c_SubTipoRem"
                  mapValues={(value) => {
                    return {
                      key: Math.random(),
                      text: `${value['c_SubTipoRem']} - ${value['RemSemiRem']}`,
                      value: value['c_SubTipoRem'],
                    };
                  }}
                  error={
                    errors.Remolque1 &&
                    touched.Remolque1 && { content: errors.Remolque1 }
                  }
                />
              </Form.Group>
              <Header as="h5">Remolque 2</Header>
              <Form.Group>
                <Form.Input
                  placeholder="Placa"
                  label="Placa"
                  name="PlacaR2"
                  value={values.PlacaR2}
                  onChange={handleChange}
                  onBlur={handleBlur}
                />
                <DropdownSimpleFetch
                  fluid
                  selection
                  label="Remolque/Semiremolque"
                  value={values.Remolque2}
                  onChange={(e, { value }) => setFieldValue('Remolque2', value)}
                  placeholder="Remolque/Semiremolque"
                  featherService="catalogo-sub-tipo-rem"
                  columName="RemSemiRem"
                  columValue="c_SubTipoRem"
                  mapValues={(value) => {
                    return {
                      key: Math.random(),
                      text: `${value['c_SubTipoRem']} - ${value['RemSemiRem']}`,
                      value: value['c_SubTipoRem'],
                    };
                  }}
                />
              </Form.Group>
            </div>
          </Modal.Content>
          <Modal.Actions>
            <Button
              content="Cerrar y guardar"
              labelPosition="right"
              icon="checkmark"
              onClick={() => setOpen(false)}
              positive
            />
          </Modal.Actions>
        </Modal>
      </Segment>
    </>
  );
};

export default forwardRef((props, ref) => (
  <FormHeader {...props} innerRef={ref} />
));
