import React, { useEffect, useReducer } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import moment from 'moment';
import DrawerFiltersWrapper from '../../components/DrawerFiltersWrapper';
import SemanticInput from '../../components/SemanticInput';
import DateRangePicker from '../../components/DateRangePicker';
import ButtonsFilter from '../../components/ButtonsFilter';

const queryInit = {
  fromDate: moment().subtract(7, 'day').startOf('day'),
  toDate: moment().endOf('day'),
  clientId: '5e18b22fc48839722c00add2',
};

const reducerQuery = (prevState, { name, value, type, query }) => {
  if (type === 'init' && query) {
    return query;
  } else if (name) {
    return { ...prevState, fromDate: value.from, toDate: value.to };
  }

  return prevState;
};

const Filters = ({ isBusy, query: base, setQuery }) => {
  // States
  const [query, dispatchQuery] = useReducer(reducerQuery, { ...queryInit });

  // Effects
  useEffect(() => {
    // console.count('Filters query');
    const sendQuery = () => {
      handleSearchClick();
    };

    sendQuery();
  }, []);

  const handleSearchClick = () => {
    // Send query to Partners
    setQuery && setQuery({ ...query });
  };

  const handleClearClick = (event, data) => {
    dispatchQuery({
      type: 'init',
      query: {
        ...queryInit,
      },
    });
  };

  const handleOnChange = ([from, to]) => {
    console.log('ho');
    // ([fromDate, toDate]) => {
    dispatchQuery({
      name: 'Date',
      value: { from, to },
    });
    // };
  };
  return (
    <>
      {/* <pre>query: {JSON.stringify(query, null, 2)}</pre> */}

      <DrawerFiltersWrapper>
        <FiltersGrid>
          <DateRangePicker
            name="shipped_date"
            label="From Date - To Date"
            timePicker={false}
            startDate={query.fromDate}
            endDate={query.toDate}
            maxDate={moment()}
            minDate={moment().subtract(6, 'month')}
            onChange={handleOnChange}
            style={{ gridArea: 'range' }}
            disabled={isBusy}
          />

          <ButtonsFilter
            isBusy={isBusy}
            onSearchClick={handleSearchClick}
            onClearClick={handleClearClick}
          />
        </FiltersGrid>
      </DrawerFiltersWrapper>
    </>
  );
};

const FiltersGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-template-rows: auto;
  grid-gap: 1em;
  grid-template-areas: 'range range . buttons';
`;

Filters.propTypes = {
  isBusy: PropTypes.bool,
  query: PropTypes.object,
  setQuery: PropTypes.func,
};

export default Filters;
