import React, { useState, useEffect } from 'react';
import { Form as SemanticForm } from 'semantic-ui-react';
import SemanticDropdown from '../../../components/SemanticDropdown';
import styled from 'styled-components';
import SemanticInput from '../../../components/SemanticInput';
import swal from 'sweetalert';
import feathers from '../../../services/feathers';

const Form = ({
  isBusy,
  setIsBusy,
  partnership: current,
  service,
  onSuccess,
  children,
}) => {
  const partnersService = feathers.service('partners');
  const clientsService = feathers.service('clients');
  const [partnerOptions, setPartnerOptions] = useState([]);
  const [clientsOptions, setClientsOptions] = useState([]);
  const [partnership, setPartnership] = useState({ ...current });
  const [validations, setValidations] = useState({
    name: false,
  });

  useEffect(() => {
    getOptions();
  }, []);

  const getOptions = async () => {
    let partnerMappedOptions = [],
      clientMappedOptions = [];
    try {
      const partners = await partnersService.find({});
      partnerMappedOptions = partners.data.map(({ _id, name }) => ({
        key: _id,
        text: name,
        value: _id,
      }));
      const clients = await clientsService.find({});
      clientMappedOptions = clients.data.map(({ _id, name }) => ({
        key: _id,
        text: name,
        value: _id,
      }));
    } catch (error) {
      console.log('error :>> ', error);
      swal('Error', `Error getting the partners at: ${error.message}`, 'error');
    } finally {
      setPartnerOptions(
        (partnerMappedOptions.length && partnerMappedOptions) || []
      );
      setClientsOptions(
        (clientMappedOptions.length && clientMappedOptions) || []
      );
    }
  };

  const handledOnSubmit = async (event) => {
    let success = false,
      result = null;
    try {
      setIsBusy(true);
      event.preventDefault();

      const firstInvalid = isInvalid();

      if (!firstInvalid) {
        let partnershipDTO = { ...partnership };
        // console.log('partnershipDTO :>> ', partnershipDTO);
        result = await (!partnership._id
          ? service.create(partnershipDTO)
          : service.patch(partnership._id, partnershipDTO));

        success = true;
      }
    } catch (error) {
      console.error('error :>> ', error);
      await swal(
        'Error',
        `Partnership not added at: ${error.message}`,
        'error'
      );
    } finally {
      setIsBusy(false);

      if (success) {
        await swal('Done', 'Finished', 'success');
        onSuccess && onSuccess(result);
      }
    }
  };

  const isInvalid = () => {
    let firstInvalid = null;

    const newValidations = Object.keys(validations).reduce((prev, field) => {
      const ret = { ...prev, [field]: partnership[field] ? false : true };

      firstInvalid = firstInvalid || (ret[field] && field);

      return ret;
    }, {});

    setValidations(newValidations);

    return firstInvalid;
  };

  const handledChange = (_, { name, value }) => {
    if (value) setPartnership({ ...partnership, [name]: value });
    else setPartnership({ ...partnership, [name]: null });
  };

  return (
    <SemanticForm onSubmit={handledOnSubmit} loading={isBusy}>
      <FormGrid>
        <SemanticInput
          placeholder="Name"
          label="Name"
          name="name"
          value={partnership.name || ''}
          onChange={handledChange}
          style={{ gridArea: 'name' }}
        />
        <SemanticDropdown
          placeholder="Partners"
          label="Partners"
          name="partners"
          options={partnerOptions}
          onChange={handledChange}
          value={partnership.partners || ''}
          style={{ gridArea: 'partners' }}
          search
          selection
          clearable
          fluid
        />
        <SemanticDropdown
          placeholder="Clients"
          label="Clients"
          name="clients"
          options={clientsOptions}
          onChange={handledChange}
          value={partnership.clients || ''}
          style={{ gridArea: 'clients' }}
          search
          selection
          clearable
          fluid
        />
      </FormGrid>
      {children}
    </SemanticForm>
  );
};

const FormGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-template-rows: auto;
  grid-gap: 1em;
  grid-template-areas: 'name partners clients';
`;

export default Form;
