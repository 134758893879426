import React, { forwardRef, useState } from 'react';
import {
  Form,
  Segment,
  Header,
  Table,
  Label,
  Modal,
  Button,
} from 'semantic-ui-react';
import styled from 'styled-components';
import { Formik, Field, FieldArray } from 'formik';
import FormFiguraTransporteChofer from './FormFiguraTransporteChofer';
import DropdownFiguraTransporte from './DropdownFiguraTransporte';

const FormFiguraTransporte = ({ formik }) => {
  let {
    values,
    errors,
    touched,
    handleChange,
    handleBlur,
    setFieldValue,
    /* and other goodies */
  } = formik;

  const [open, setOpen] = useState(false);
  const [index, setIndex] = useState(0);

  const handledEdit = (index) => {
    setIndex(index);
    setOpen(true);
  };

  // console.log('errorsTranposte: ', errors.figuraTransportes);
  // console.log('touchedTranposte: ', touched);

  return (
    <>
      <Segment>
        <Header as="h3">Figura Transporte</Header>
        <Table celled striped>
          <Table.Header>
            <Table.Row>
              <Table.HeaderCell collapsing>Chofer</Table.HeaderCell>
              <Table.HeaderCell>Nombre del chofer</Table.HeaderCell>
              <Table.HeaderCell collapsing>Acciones</Table.HeaderCell>
            </Table.Row>
          </Table.Header>
          <Table.Body>
            {values.figuraTransportes.map((figuraTransporte, index) => (
              <Table.Row
                error={
                  errors.figuraTransportes &&
                  touched.figuraTransportes &&
                  errors.figuraTransportes[index]
                }
              >
                <Table.Cell textAlign="center">{index + 1}</Table.Cell>
                <Table.Cell>
                  {' '}
                  <Form.Group widths={'equal'}>
                    <DropdownFiguraTransporte
                      formik={formik}
                      index={index}
                      figuraTransporte={figuraTransporte}
                    />
                  </Form.Group>{' '}
                </Table.Cell>
                <Table.Cell>
                  {/* {errors.figuraTransportes && errors.figuraTransportes[index] ? (
                    <Label ribbon color="red">
                      {' '}
                      Faltan Datos requeridos
                    </Label>
                  ) : null} */}
                  <pre>
                    <a
                      style={{ cursor: 'pointer', color: '#438D9B' }}
                      onClick={() => handledEdit(index)}
                    >
                      Editar
                    </a>
                    {' | '}
                    <FieldArray
                      name="figuraTransportes"
                      render={(arrayHelpers) => (
                        <a
                          style={{
                            marginTop: '2px',
                            color: 'red',
                            cursor: 'pointer',
                          }}
                          onClick={() => arrayHelpers.remove(index)} // remove a friend from the list
                        >
                          Eliminar
                        </a>
                      )}
                    />
                  </pre>
                </Table.Cell>
              </Table.Row>
            ))}
          </Table.Body>
        </Table>
        <FieldArray
          name="figuraTransportes"
          render={(arrayHelpers) => (
            <div>
              <Button
                type="button"
                content="Agregar nueva Figura Transporte"
                primary
                onClick={() => arrayHelpers.push('')}
              >
                {/* show this when user has removed all friends from the list */}
              </Button>
            </div>
          )}
        />
        <ModalFiguraTransporte
          open={open}
          setOpen={setOpen}
          formik={formik}
          index={index}
        />
      </Segment>
    </>
  );
};

const ModalFiguraTransporte = ({ open, setOpen, formik, index }) => {
  let { values, errors, touched, handleChange, handleBlur, setFieldValue } =
    formik;

  return (
    <Modal
      onClose={() => setOpen(false)}
      onOpen={() => setOpen(true)}
      open={open}
      size={'fullscreen'}
    >
      <Modal.Header> Figura Transporte {index + 1} </Modal.Header>
      <Modal.Content>
        <FormFiguraTransporteChofer
          formik={formik}
          index={index}
          figuraTransporte={values.figuraTransportes[index]}
        />
      </Modal.Content>
      <Modal.Actions>
        <Button
          content="Cerrar y guardar"
          labelPosition="right"
          icon="checkmark"
          onClick={() => setOpen(false)}
          positive
        />
      </Modal.Actions>
    </Modal>
  );
};

const WrapContent = styled.div``;

export default forwardRef((props, ref) => (
  <FormFiguraTransporte {...props} innerRef={ref} />
));
