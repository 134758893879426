import React, { useState, useEffect } from 'react';
import DefaulLayout from '../../layouts/DefaultLayout';
import HeaderBG from '../../components/HeaderBG';
import { Container } from 'semantic-ui-react';
import SectionTitle from '../../components/SectionTitle';
import Filters from './Filters';
import TableNavigation from './TableNavigation';
import Table from './Table';
import feathers from '../../services/feathers';
import swal from 'sweetalert';
import templateTransactionHistory from './functions/transactionCsv';

const Transactions = (props) => {
  const service = feathers.service('integration-pitneybowes');

  // States
  const [openPanel, setOpenPanel] = useState(false);
  const [isBusy, setIsBusy] = useState(false);
  const [data, setData] = useState([]);
  //const [currentTransaction, setCurrentTransaction] = useState({});
  const [selection, setSelection] = useState([]);
  const [messageAction, setMessageAction] = useState();
  const [query, setQuery] = useState(null);
  const [clearSelection, setClearSelection] = useState(false);

  // Effects
  useEffect(() => {
    const filter = () => {
      if (query) {
        getData();
      }
    };

    filter();
  }, [query]);

  // Functions
  const getData = async () => {
    if (query) {
      let data = [],
        result = [];

      try {
        setIsBusy(true);
        setSelection([]);

        // console.debug('query', JSON.stringify(query, null, 2));
        // do {
        result = await service.find({
          query: {
            ...query,
            // $skip: data.length,
          },
        });

        data = data.concat(result);

        // console.debug('result', result);
        // } while (data.length !== result.total);
      } catch (err) {
        console.error('Error', err);
        data = [];

        swal({
          title: 'Error',
          text: 'Failed getting Transactions',
          icon: 'error',
          buttons: {
            cancel: 'Close',
          },
        });
      } finally {
        setData(data);
        setIsBusy(false);

        // refFilters && refFilters.current && refFilters.current.focus();
      }
    }
  };

  const onClose = (isSuccess) => {
    //setCurrentTransaction({});
    setOpenPanel(!openPanel);

    isSuccess && getData();
  };

  const openDrawer = (transaction) => {
    //setCurrentTransaction(transaction);
    setOpenPanel(true);
  };

  // Handles
  const handledEditClick = (transaction) => {
    openDrawer(transaction);
  };

  const handleDownloadClick = async () => {
    try {
      setIsBusy(true);
      setClearSelection(true);

      const total = selection.length;
      await templateTransactionHistory(selection, query.fromDate, query.toDate);

      setIsBusy(false);
      setClearSelection(false);
      setSelection([]);
      // let msg = `Do you want to delete ${total} Partner${
      //   (total > 1 && 's') || ''
      // }?`;
      // let confirm = await swal(msg, {
      //   buttons: ['No', 'Yes'],
      // });

      // if (confirm) {
      //   let ids = selection.map((item) => item._id);
      //   setIsBusy(true);
      //   setMessageAction('Deleting ...');
      //   await service.remove(ids);
      //   success = true;
      // }
    } catch (error) {
      console.error(error);
      await swal('Error', `CSV couldn't generate the report`, 'error');
    }
  };

  return (
    <DefaulLayout currentPath={props.match.path}>
      <HeaderBG />
      <Container>
        <SectionTitle>Transaction History</SectionTitle>

        <Filters isBusy={isBusy} query={query} setQuery={setQuery} />

        <TableNavigation
          isBusy={isBusy}
          hasSelection={selection.length}
          messageAction={messageAction}
          onDownloadClick={handleDownloadClick}
        />
        <Table
          data={data}
          onEditClick={handledEditClick}
          getData={getData}
          setSelection={setSelection}
          clearSelection={clearSelection}
        />
      </Container>
    </DefaulLayout>
  );
};

export default Transactions;
