import React, { useState } from 'react';
import PropTypes from 'prop-types';
import TableContainer from '../../components/TableContainer';
import { AgGridReact } from '@ag-grid-community/react';
import { AllModules } from '@ag-grid-enterprise/all-modules';
import 'lodash';
import { Button } from 'semantic-ui-react';
import swal from 'sweetalert';

const Table = ({ data, onEditClick, selection, setSelection, ...other }) => {
  // States
  const [gridApi, setGridApi] = useState();
  const [columnDefs] = useState([
    {
      headerName: '',
      width: 70,
      checkboxSelection: true,
      headerCheckboxSelection: true,
      suppressMenu: true,
      pinned: 'left',
      enableRowGroup: false,
      resizable: false,
      sortable: false,
    },

    { field: 'name', headerName: 'Name' },
    {
      headerName: '',
      field: '',
      pinned: 'right',
      cellStyle: { textAlign: 'center' },

      cellRendererFramework: (params) => {
        if (params.data)
          return (
            <Button
              compact
              primary
              onClick={() => handleEditClick(params.data)}
            >
              Edit
            </Button>
          );
      },
    },
  ]);

  const [defaultColDef] = useState({
    flex: 1,
  });

  // Effects
  React.useEffect(() => {
    // console.debug('useEffect Table', selection, (gridApi && gridApi.getSelectedRows()));
    if (
      selection &&
      !selection.length &&
      gridApi &&
      gridApi.getSelectedRows().length
    ) {
      gridApi.deselectAll();
    }
  }, [selection, gridApi]);

  // Handles
  const handleEditClick = (shipper) => {
    onEditClick && onEditClick({ ...shipper });
  };

  const handleSelectionChanged = (event) => {
    if (selection && setSelection) {
      let rows = [];

      try {
        if (event && event.api) {
          rows = event.api.getSelectedRows().map((row) => row._id);
        }
      } catch (err) {
        rows = [];

        swal({
          title: 'Error',
          text: 'Failed to select shippers',
          icon: 'error',
          buttons: {
            cancel: 'Close',
          },
        });
      } finally {
        // console.debug('onSelectionChanged event rows', '[' + rows.map(item => `ObjectId('${item}')`).join(', ') + ']');
        setSelection(rows);
      }
    }
  };

  const handleGridReady = (params) => setGridApi(params.api);

  return (
    <TableContainer className="ag-theme-material" rowFilters={1}>
      <AgGridReact
        modules={AllModules}
        columnDefs={columnDefs}
        defaultColDef={defaultColDef}
        rowSelection={'multiple'}
        suppressRowClickSelection={true}
        onSelectionChanged={handleSelectionChanged}
        rowData={data}
        pagination={true}
        paginationPageSize={100}
        onGridReady={handleGridReady}
        {...other}
      />
    </TableContainer>
  );
};

Table.propTypes = {
  data: PropTypes.array,
  onEditClick: PropTypes.func,
  selection: PropTypes.array,
  setSelection: PropTypes.func,
};

export default Table;
