import React, { Component, Fragment } from 'react';
import DRP from 'react-bootstrap-daterangepicker';
import SemanticInput from './SemanticInput';
import moment from 'moment';
import { Ref } from 'semantic-ui-react';
import styled from 'styled-components';

class DateRangePicker extends Component {
  constructor(props) {
    super(props);
    this.refInput = React.createRef();
    this.handleApply = this.handleApply.bind(this);
    this.getFormat = this.getFormat.bind(this);
    this.getInputText = this.getInputText.bind(this);

    this.state = {
      startDate: this.props.startDate,
      endDate: this.props.endDate,
      tempFix: null,
      inputText: this.getInputText()
    };
  }

  componentDidUpdate(prevProps, _) {
    const { startDate: prevStartDate, endDate: prevEndDate } = prevProps;
    const { startDate, endDate, singleDatePicker } = this.props;

    if ((prevStartDate !== startDate || prevEndDate !== endDate) && (singleDatePicker || moment(startDate).isSameOrBefore(moment(endDate)))) {
      this.setState({
        startDate: startDate,
        tempFix: singleDatePicker ? null : endDate,
        inputText: this.getInputText()
      });
    } else if (this.state.tempFix) {
      this.setState({
        endDate: this.state.tempFix,
        tempFix: null,
        inputText: this.getInputText()
      });
    }

    this.fillElement(this.refInput.current);
  }

  fillElement(ref) {
    if (ref) {
      // console.debug('current', ref);
      ref.style.width = '100%';

      if (ref.children) {
        [...ref.children].forEach((current) => {
          this.fillElement(current);
        });
      }
    }
  }

  getFormat(props) {
    const { locale, timePicker } = (props || this.props);
    if (locale && locale.format) {
      return locale.format;
    } else if (timePicker) {
      return 'MM/DD/YYYY h:mm A';
    } else {
      return 'MM/DD/YYYY';
    }
  }

  getInputText(props) {
    const { startDate, endDate, singleDatePicker } = (props || this.props);
    const format = this.getFormat(props);

    if (singleDatePicker) {
      return startDate.format(format);
    } else {
      return `From: ${startDate.format(format)} To: ${endDate.format(format)}`;
    }
  }

  handleApply(_, picker) {
    const { singleDatePicker, startDate, endDate } = picker;

    this.setState({
      startDate,
      endDate
    });

    let value = startDate.clone();
    if (!singleDatePicker) {
      value = [value, endDate.clone()];
    }

    this.props.onChange(value);
  }

  render() {
    const { timePicker, locale, label, style, disabled = false, ...others } = this.props;
    const { startDate, endDate, inputText } = this.state;

    const input = <Fragment>
      <Label>{label || 'From/To Date'}</Label>
      <SemanticInput value={inputText} disabled={disabled} />
    </Fragment>;

    let component = null;

    if (disabled) {
      component = <Fragment>
        <div style={style}>
          {input}
        </div>
      </Fragment>;
    } else {
      delete others.startDate;
      delete others.endDate;

      const defaultProps = {
        timePicker: timePicker || false,
        locale: locale || {},

        // singleDatePicker: true,
        // autoApply: true,
        // autoUpdateInput: true,
        startDate: startDate,
        endDate: endDate
      };

      defaultProps.locale.format = this.getFormat(defaultProps);

      component = <Fragment>
        <DRP
          {...defaultProps}
          {...others}

          containerStyles={style}

          onApply={this.handleApply}
        >
          {input}
        </DRP>
      </Fragment>;
    }

    return (
      <Ref innerRef={this.refInput}>
        {component}
      </Ref>
    );
  }
}

const Label = styled.label`
  display: block;
  color: rgba(0, 0, 0, 0.87);
  text-transform: none;
`;

export default DateRangePicker;