import React, { useState, useEffect } from 'react';
import { Form } from 'semantic-ui-react';
import swal from 'sweetalert';
import { Formik } from 'formik';
import Actions from './Actions';
import FormChofer from './FormChofer';
import axios from 'axios';
import feathers from '../../../services/feathers';
import SlidingPanel from '@bit/softwareland.flex.sl-sliding-panel';
import * as Yup from 'yup';

const Drawer = ({ openPanel, onClose, currentChofer }) => {
  const service = feathers.service('choferes');
  const [currentSection, setCurrentSection] = useState(
    (currentChofer && currentChofer.id && 'EDIT') || 'CREATE'
  );
  useEffect(() => {});
  console.log(currentChofer);

  const SignupSchema = Yup.object().shape({
    CodigoChofer: Yup.string()
      .max(6, 'Too Long!'),
      Nombre: Yup.string()
      .max(100, 'Too Long!')
      .required('Required'),
      TipoFiguraTransportista: Yup.number()
      .required('Required'),
      NumeroLicencia: Yup.string()
      .max(30, 'Too Long!')
      .required('Required'),
      RFC: Yup.string()
      .max(20, 'Too Long!')
      .required('Required'),
      PaisResidenciaFisca: Yup.number(),
      NumRegistroTributario: Yup.string()
      .max(250, 'Too Long!')
  });

  const handleSave = async (values, { setSubmitting }) => {
    if (values) {
      console.log('values', values);
      let success = true;
      try{
        if(values.id){
          let result = await service.patch(values.id, values);
          console.log('resultCreate', result);
          swal('Actualizado', '¡Se ha actualizado correctamente!', 'success');
        }else{
        
            let resultCreate = await service.create(values);
            console.log('resultCreate', resultCreate);
            swal('Guardado', '¡Se ha guardado correctamente!', 'success');
          
        }
      }catch(exception){
        console.log(exception)
        swal('Error', 'Dont Save chofer', 'error');
        success = false;
      }
      if(success){
        setCurrentSection('CREATE');
        onClose(true);
      }
    } else swal('Error', 'Dont Save chofer', 'error');
  };

  const handledOnClose = async () => {
    let confirmation = await swal('Do you want close this form?', {
      buttons: ['No', 'Yes'],
    });

    if (confirmation) {
      setCurrentSection('CREATE');
      onClose(false);
    }
  };

  let drawerTitles = {
    CREATE: {
      title: 'Nuevo Chofer',
      subtitle: '',
    },
    EDIT: {
      title: 'Editar Chofer',
      subtitle: '',
    },
  };

  let title = drawerTitles[currentSection].title;
  let subtitle = drawerTitles[currentSection].subtitle;


  return (
    <SlidingPanel
      isOpen={openPanel}
      size={100}
      title={title}
      subtitle={subtitle}
      onClose={handledOnClose}
    >
      <Formik
        initialValues={currentChofer}
        onSubmit={handleSave}
        validationSchema={SignupSchema}
      >
        {(formikProps) => (
          
          <Form
            onSubmit={formikProps.handleSubmit}
            style={{ marginRight: '15px' }}
            
          >
            <FormChofer
              onSave={handleSave}
              // ref={refForm}
              // isValid={isValid}
              // setIsValid={setIsValid}
              formik={formikProps}
            />

            <Actions formik={formikProps} 
            handledOnClose={handledOnClose}/>
          </Form>
        )}
      </Formik>
    </SlidingPanel>
  );
};

export default Drawer;
