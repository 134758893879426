import React from 'react';
import styled from 'styled-components';
import DateRangePicker from '../../../../components/DateRangePicker';
import moment from 'moment';

const InputsForm = ({ inputs, setInputs, children, currentConsolidation }) => {
  let { _id } = currentConsolidation;

  const handledOnChange = (date) => {
    if (date)
      setInputs({
        ...inputs,
        date: `${date.format('YYYY-MM-DDTHH:mm:ss')}`,
      });
    else setInputs({ ...inputs, date });
  };

  return (
    <InputContainer>
      <DateRangePicker
        label={(_id && 'Created Date') || 'Creation Date'}
        timePicker={true}
        startDate={moment(inputs.date)}
        maxDate={moment()}
        singleDatePicker={true}
        onChange={(date) => handledOnChange(date)}
        style={{ gridArea: 'dates' }}
      />
      <Navigate style={{ gridArea: 'nav' }}>{children}</Navigate>
    </InputContainer>
  );
};

const InputContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(1, 1fr);
  grid-template-rows: auto auto;
  grid-template-areas: 'dates nav';
  grid-gap: 1em;
`;

const Navigate = styled.div`
  width: 90em;
  text-align: right;
`;

export default InputsForm;
