import React from 'react';
import styled from 'styled-components';
import { Button, Icon } from 'semantic-ui-react';

const Actions = ({
  selection,
  currentConsolidation,
  isBusy,
  handleOnBulk,
  setCurrentSection,
  isDissociating,
}) => {

  const handledSaveClick = async () => {
    const result = await handleOnBulk('associate');
    if(result)
    {
      setCurrentSection('FORM_CONSOLIDATED_CARGO')
    }
  }
  
  return (
    <ActionContainer>
      <Button
        primary
        compact
        onClick={handledSaveClick}
        disabled={((!selection.length && currentConsolidation._id) ||isBusy || isDissociating) && true}
      >
        Import Selected
      </Button>
      <Button
        style={{ width: '50%' }}
        compact
        onClick={() => setCurrentSection('FORM_CONSOLIDATED_CARGO')}
      >
        <Icon name="reply" /> Back
      </Button>
    </ActionContainer>
  );
};

const ActionContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(6, 1fr);
  grid-template-rows: auto;
  grid-template-areas: '. . . . . .';
`;

export default Actions;
