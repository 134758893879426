import swal from 'sweetalert';
import downloadFile from '../../../functions/downloadFile';
const ExcelJS = require('exceljs');

export default async exportData => {
  if (exportData && exportData.length > 0) {
    try {
      const mappedData = exportData.map(shippedItem => ({
        'Count Order Num': shippedItem.count,
        'Import Code': shippedItem.exportCode
      }));
      const exportName = 'Import Export Report.xlsx';
      const wb = new ExcelJS.Workbook();
      const ws = wb.addWorksheet('Import-Export');

      let headers = [];
      ws.columns = Object.keys(mappedData[0]).map(field => {
        headers.push(field);
        return { key: field, width: 30 };
      }, {});
      ws.getRow(1).values = headers;
      ws.addRows(mappedData);

      let cells = [
        'A1',
        'B1',
      ];

      cells.forEach(cell => {
        ws.getCell(cell).font = {
          name: 'Courier New',
          size: 11,
          bold: true,
        };

        ws.getCell(cell).fill = {
          type: 'pattern',
          pattern: 'solid',
          fgColor: { argb: 'A4C2F4' },
        };
      });

      const buf = await wb.xlsx.writeBuffer();
      downloadFile(
        buf,
        exportName,
        'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
      );
    } catch (error) {
      console.error('error', error);
      swal(`Could Not Export`, error, 'error');
    }
  } else {
    swal(`Could Not Export`, `No records were the Import/Export Report`, 'error');
  }
}