import TabMenuItemPackage from './TabMenuItemPackage';
import createTab from './createTab';

const reducerTabs = (prevState, action) => {
  let newsValues = {};

  if (action.type) {
    const { activeIndex: prevActiveIndex, panes: prevPanes } = prevState;
    const { type, data, activeIndex, panes } = action;
    let newActiveIndex = 0;
    switch (type) {
      case 'add':
        newActiveIndex = prevPanes.length;
        newsValues = {
          activeIndex: newActiveIndex,
          panes: [
            ...prevPanes,
            {
              ...createTab(newActiveIndex, data),
              new: true,
            },
          ],
        };
        break;

      case 'update':
        newsValues = {
          panes: [
            ...prevPanes.slice(0, prevActiveIndex),
            {
              ...prevPanes[prevActiveIndex],
              data,
              forceRender: true,
            },
            ...prevPanes.slice(prevActiveIndex + 1),
          ],
        };
        break;

      case 'delete':
        newActiveIndex =
          prevActiveIndex -
          (activeIndex < prevActiveIndex
            ? 1
            : prevActiveIndex === prevPanes.length - 1
            ? 1
            : 0);

        prevPanes.splice(activeIndex, 1);
        if (newActiveIndex >= 0) {
          newsValues = {
            activeIndex: newActiveIndex,
            panes: [
              ...prevPanes.slice(0, newActiveIndex),
              {
                ...prevPanes[newActiveIndex],
                forceRender: true,
              },
              ...prevPanes.slice(newActiveIndex + 1),
            ],
          };
        } else {
          newsValues = { activeIndex: 0, panes: [] };
        }
        break;

      case 'render':
        newsValues = {
          panes: prevPanes.map((pane, key) => {
            if (key === prevActiveIndex) {
              return createTab(
                key,
                [...pane.data],
                action.isBusy,
                action.handleDeletePackageClick,
                action.handleUnpackingClick
              );
            } else {
              pane.menuItem = TabMenuItemPackage(
                key,
                action.handleDeletePackageClick,
                pane.data.length
              );
              delete pane.forceRender;
              delete pane.new;

              return pane;
            }
          }),
        };
        break;
    }
  } else {
    newsValues = { ...action };
  }

  const { activeIndex, panes } = newsValues;
  return {
    ...prevState,
    ...{
      ...(Number.isInteger(activeIndex) ? { activeIndex } : {}),
      ...(Array.isArray(panes) ? { panes } : {}),
    },
  };
};

export default reducerTabs;
