import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Modal, Button, Icon } from 'semantic-ui-react';
import feathers from '../../../../../services/feathers';
import FormAddProduct from '../../../../../components/FormAddProduct';
import swal from 'sweetalert';

const AddProductModal = ({
  shippedItem,
  onClose,
  shippedItems
}) => {
  // States
  const [currentProduct, setCurrentProduct] = useState(null);
  const [isValid, setIsValid] = useState(false);
  const refForm = React.useRef(null);

  // Effects
  useEffect(() => {
    // console.count('AddProductModal shippedItem');
    if (shippedItem) {
      const product = (shippedItem.product && shippedItem.product.length && shippedItem.product[0]) || {
        barcode: shippedItem.barcode,
        sku: shippedItem.SKU,
        name: shippedItem.productName,
        cost: shippedItem.price,
        coco: shippedItem.countryOfManufacturer,
        fiber: '',
        hts: '',
        exportCode: '',
        rate: '',
        description: shippedItem.customsDescription,
        customer: '',
        tariffAdditional: '',
        tariff321: '',
        is321: false,
        isTextile: false,
        isKit: false,
      }

      setCurrentProduct(product);
    }
  }, [shippedItem]);

  const handleSaveClick = async () => {
    refForm.current.submit();
  };

  const handleSave = async product => {
    const lstShippedItems = shippedItems
      .filter(item => item.SKU === shippedItem.SKU)
      .map(item => item._id);

    if (lstShippedItems.length) {
      const result = await feathers.service('shipped-items').patch({}, {
        sku: currentProduct.sku,
        status: 'DRAFT',
        shippedItems: lstShippedItems
      });

      if (result && result.ok) {
        shippedItems.forEach(item => {
          if (lstShippedItems.includes(item._id)) {
            item.product = [product];
          }
        });

        swal('Done', 'Finished', 'success');
      } else {
        swal('Error', result.message, 'error');
      }

      onClose && onClose(true);
    }
  };

  const handleCloseClick = event => onClose && onClose(false);

  return (
    <Modal open={true} size="small">
      <Modal.Header>
        {currentProduct && currentProduct._id ? 'Edit product' : 'Add product'}
      </Modal.Header>

      <Modal.Content scrolling>
        <FormAddProduct
          currentProduct={currentProduct}
          onSave={handleSave}
          ref={refForm}
          setIsValid={setIsValid}
          addFromValidation={true}
        ></FormAddProduct>
      </Modal.Content>

      <Modal.Actions>
        <Button
          primary
          compact
          disabled={isValid}

          onClick={handleSaveClick}
        >
          <Icon name="save" /> Save
        </Button>

        <Button
          compact
          onClick={handleCloseClick}
        >
          <Icon name="reply" /> Close
        </Button>
      </Modal.Actions>
    </Modal>
  );
};

AddProductModal.propTypes = {
  shippedItem: PropTypes.object,
  onClose: PropTypes.func,
  shippedItems: PropTypes.array
};

export default AddProductModal;