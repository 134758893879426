import swal from 'sweetalert';
import moment from 'moment';
import downloadFile from '../../../functions/downloadFile';
import { number } from '../../../components/AgGridColumns';

const ExcelJS = require('exceljs');

export default async (exportData, fromDate, toDate) => {
  if (exportData && exportData.length > 0) {
    try {
      let mappedData = await exportData.map((item) => {
        return {
          'Pitneybowes transactionId': item.transactionId,
          Client: item.client,
          'Transaction Date': item.transactionDateTime,
          'Transaction Type': item.transactionType,
          'Tracking Number': item.parcelTrackingNumber,
          'Package Type': item.packageType,
          Method: item.mailClass,
          'Pitneybowes Shipment Id': item.shipmentId,
          'Label cost': item.developerRateAmount,
        };
      });

      const exportName =
        'PB Transactions ' +
        fromDate.format('MM-DD-YYYY') +
        '-' +
        toDate.format('MM-DD-YYYY') +
        '.xlsx';
      const wb = new ExcelJS.Workbook();
      const ws = wb.addWorksheet('PB Transactions');
      ws.columns = Object.keys(mappedData[0]).map((field) => {
        return { header: field, key: field, width: 30 };
      }, {});

      ws.addRows(mappedData);
      const buf = await wb.xlsx.writeBuffer();
      downloadFile(
        buf,
        exportName,
        'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
      );
    } catch (error) {
      console.error('error', error);
      swal(`Could Not Export`, error, 'error');
    }
  } else {
    swal(`Could Not Export`, `No records were the Duty Paid`, 'error');
  }
};
