import React, { useEffect, useReducer } from 'react';
import styled from 'styled-components';
import DrawerFiltersWrapper from '../../../../components/DrawerFiltersWrapper';
import DateRangePicker from '../../../../components/DateRangePicker';
import SemanticInput from '../../../../components/SemanticInput';
import SemanticDropdown from '../../../../components/SemanticDropdown';
import moment from 'moment';
import {
  options,
  tagOptions,
  countryOptions,
  binOptions,
  integratorOptions,
} from '../constants/filters';
import ButtonsFilter from '../../../../components/ButtonsFilter';

const queryInit = {
  createdDateTime: {
    $gte: moment().startOf('day'),
    $lte: moment(),
  },
  bin: '',
  orderNumber: '',
  trackingNumber: '',
  SKU: '',
  coco: '',
  exportCode: '',
  productName: '',
  country: [],
  is321: [],
  tags: [],
  customer3PL: '',
  email: '',
  showBin: binOptions[0].value,
  integrator: integratorOptions.map(({ value }) => value),
};

const reducerQuery = (prevState, action) => {
  if (action.type === 'init' && action.query) {
    return action.query;
  } else if (action.name) {
    return { ...prevState, [action.name]: action.value };
  }

  return prevState;
};

const Filter = ({ onChange, isBusy, shippedItems }) => {
  // States
  const [query, dispatchQuery] = useReducer(reducerQuery, { ...queryInit });

  // Effects
  useEffect(() => {
    if (shippedItems && shippedItems.length) {
      // console.debug('shippedItems', shippedItems.length);
      const moments = shippedItems.map((item) =>
        moment.utc(item.createdDateTime).local()
      );

      dispatchQuery({
        name: 'createdDateTime',
        value: {
          $gte: moment.min(moments),
          $lte: moment.max(moments),
          force: true,
        },
      });
    }
  }, [shippedItems]);

  useEffect(() => {
    if (query.createdDateTime.force) {
      const createdDateTime = query.createdDateTime;
      delete createdDateTime.force;
      queryInit.createdDateTime = { ...createdDateTime };

      dispatchQuery({
        name: 'createdDateTime',
        value: createdDateTime,
      });

      handleSearchClick();
    }
  }, [query.createdDateTime.force]);

  // Handles
  const handleChangeInput = (_, { name, value }) => {
    dispatchQuery({
      name,
      value: ['bin', 'orderNumber', 'trackingNumber'].includes(name)
        ? value.toLowerCase()
        : value,
    });

    if (name === 'showBin' && value === 'NO BIN') {
      dispatchQuery({ name: 'bin', value: '' });
    }
  };

  const handleSearchClick = (event, data) => {
    const queryMap = {
      createdDateTime: {
        $gte: query.createdDateTime && fixDate(query.createdDateTime.$gte),
        $lte: query.createdDateTime && fixDate(query.createdDateTime.$lte),
      },

      bin: fixString(query.bin),
      orderNumber: fixString(query.orderNumber),
      trackingNumber: fixString(query.trackingNumber),
      SKU: fixString(query.SKU),
      coco: fixString(query.coco),
      exportCode: fixString(query.exportCode),
      productName: fixString(query.productName),
      country: query.country || [],
      is321: query.is321 || [],
      tags: query.tags || [],
      customer3PL: fixString(query.customer3PL),
      email: fixString(query.email),
      showBin: query.showBin,
      integrator: query.integrator || [],
    };

    onChange && onChange(queryMap);
  };

  const handleClearClick = (event, data) => {
    dispatchQuery({ type: 'init', query: { ...queryInit } });
  };

  // Functions
  const fixDate = (date) => (date && moment(date)) || null;

  const fixString = (str) => (str && str.trim() && str.toLowerCase()) || null;

  return (
    <DrawerFiltersWrapper>
      {/* <pre>{JSON.stringify(query, null, 2)}</pre> */}
      <FiltersGrid>
        <DateRangePicker
          name="fromTo"
          label="Created Date"
          timePicker={true}
          startDate={query.createdDateTime.$gte}
          endDate={query.createdDateTime.$lte}
          maxDate={moment()}
          onChange={(arg) => {
            dispatchQuery({
              name: 'createdDateTime',
              value: {
                $gte: arg[0],
                $lte: arg[1],
              },
            });
          }}
          style={{ gridArea: 'range' }}
          disabled={isBusy}
        />

        <SemanticInput
          name="bin"
          label="BIN"
          value={query.bin}
          onChange={handleChangeInput}
          fluid
          style={{ gridArea: 'bin' }}
          disabled={isBusy || query.showBin === 'NO BIN'}
        />

        <SemanticInput
          name="orderNumber"
          label="Order #"
          value={query.orderNumber}
          onChange={handleChangeInput}
          fluid
          style={{ gridArea: 'order' }}
          disabled={isBusy}
        />

        <SemanticInput
          name="trackingNumber"
          label="Tracking #"
          value={query.trackingNumber}
          onChange={handleChangeInput}
          fluid
          style={{ gridArea: 'tracking' }}
          disabled={isBusy}
        />

        <SemanticInput
          name="SKU"
          label="SKU"
          value={query.SKU}
          onChange={handleChangeInput}
          fluid
          style={{ gridArea: 'sku' }}
          disabled={isBusy}
        />

        <SemanticInput
          name="coco"
          label="COO"
          value={query.coco}
          onChange={handleChangeInput}
          fluid
          style={{ gridArea: 'coo' }}
          disabled={isBusy}
        />

        <SemanticInput
          name="exportCode"
          label="Import Code"
          value={query.exportCode}
          onChange={handleChangeInput}
          fluid
          style={{ gridArea: 'importCode' }}
          disabled={isBusy}
        />

        <SemanticInput
          name="productName"
          label="Product Name"
          value={query.productName}
          onChange={handleChangeInput}
          fluid
          style={{ gridArea: 'productName' }}
          disabled={isBusy}
        />

        <SemanticDropdown
          name="country"
          label="Country"
          value={query.country}
          onChange={handleChangeInput}
          fluid
          multiple
          selection
          options={countryOptions}
          renderLabel={(item) => item && item.text}
          style={{ gridArea: 'country' }}
          disabled={isBusy}
        />

        <SemanticDropdown
          name="is321"
          label="Is 321"
          value={query.is321}
          onChange={handleChangeInput}
          fluid
          multiple
          selection
          options={options}
          renderLabel={(item) => item && item.text}
          style={{ gridArea: 'is321' }}
          disabled={isBusy}
        />

        <SemanticDropdown
          name="tags"
          label="Tags"
          value={query.tags}
          onChange={handleChangeInput}
          fluid
          multiple
          selection
          options={tagOptions}
          renderLabel={(item) => item && item.text}
          style={{ gridArea: 'tags' }}
          disabled={isBusy}
        />

        <SemanticInput
          name="customer3PL"
          label="3PL Customer"
          value={query.customer3PL}
          onChange={handleChangeInput}
          fluid
          style={{ gridArea: 'customer3PL' }}
          disabled={isBusy}
        />

        <SemanticInput
          name="email"
          label="Email"
          value={query.email}
          onChange={handleChangeInput}
          fluid
          style={{ gridArea: 'email' }}
          disabled={isBusy}
        />

        <SemanticDropdown
          name="showBin"
          label="Show"
          value={query.showBin}
          onChange={handleChangeInput}
          fluid
          selection
          options={binOptions}
          style={{ gridArea: 'showBin' }}
          disabled={isBusy}
        />

        <SemanticDropdown
          name="integrator"
          label="Integrator"
          value={query.integrator}
          onChange={handleChangeInput}
          fluid
          multiple
          selection
          options={integratorOptions}
          renderLabel={(item) => item && item.text}
          style={{ gridArea: 'integrator' }}
          disabled={isBusy}
        />

        <ButtonsFilter
          isBusy={isBusy}
          onSearchClick={handleSearchClick}
          onClearClick={handleClearClick}
        />
      </FiltersGrid>
    </DrawerFiltersWrapper>
  );
};

const FiltersGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(6, 1fr);
  grid-template-rows: auto auto auto;
  grid-gap: 1em;
  grid-template-areas: 'range range bin order tracking sku' 'coo importCode productName country is321 tags' 'customer3PL email showBin integrator . buttons';
`;

export default Filter;
